@import "../sprite";
@import "../misc/bootstrap";

.activity{
  position: relative;
  height: auto;
  background: #161717;
  z-index: 1056;
  color: white;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.activity-content{
  padding-top: 130px;
  padding-bottom: 120px;
  h2{
    margin: 0;
  }

  @include media-max(md){
    padding-top: 60px;
    padding-bottom: 130px;
  }
}

.slider-activity{
  @include media-max(md){
    display: none;
  }
}

.activity-slider-md{
  display: none;

  @include media-max(md){
    display: block;
  }
}

.activity-slider{
  margin-top: 30px;
  position: relative;
  z-index: 1056;
}

.slider-activity-icon{
  width: 67px;
  height: 61px;
  margin: 0 auto;

  @include media-max(md){
    width: 45px;
  }
}

.slider-activity-text{
  margin: 40px auto 0;
  p{
    margin: 0;
    color: white;
    max-width: 240px;
    text-align: center;
  }

  @include media-max(md){
    margin: 5px auto 0;
  }
}

.slider-activity-item{
  background: rgba(0,0,0,.4);
  width: 287px;
  height: 270px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 30px;

}

.slide-activity-mobileItem{
  background: rgba(0,0,0,.4);
  width: 328px;
  height: 193px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 500px){
    width: 100%;
  }
}


.activity-navigation{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider-activity-button{
  display: flex;
  align-items: baseline;
  max-width: 162px;
  justify-content: space-between;
  width: 100%;
  z-index: 1056;

  @media (max-width: 978px){
    max-width: 181px;
  }

  @include media-max(md){
    max-width: 188px;
    position: absolute;
    margin: 0 auto;
    width: 100%;
    transform: translateX(-50%);
    left: 50%;
    bottom: 43px;
  }
}

.slider-activity-button button{
  background: rgba(0, 0, 0, 0.4);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-previous-2:before{
  content: "";
  background-image: url("../../img/icons/prev-2.png");
  width: 20px;
  height: 20px;
}

.button-previous-2:hover:before{
  background-image: url("../../img/icons/prev-mobile.png");
  width: 20px;
  height: 20px;
  transition: all .2s;
}

.button-next-2:before{
  content: "";
  background-image: url("../../img/icons/next-2.png");
  width: 21px;
  height: 20px;
}

.button-next-2:hover:before{
  background-image: url("../../img/icons/next-mobile.png");
  width: 21px;
  height: 20px;
  transition: all .2s;
}

.button-previous-3:before{
  content: "";
  background-image: url("../../img/icons/prev-2.png");
  width: 20px;
  height: 20px;
}


.button-next-3:before{
  content: "";
  background-image: url("../../img/icons/next-2.png");
  width: 21px;
  height: 20px;
}

.slider-activity {

  .flickity-page-dots{
    position: absolute;
    width: auto;
    padding: 0;
    margin: 0;
    top: -71px;
    right: 66px;

    @include media-max(xl){
      top: -69px;
    }

    @media (max-width: 1070px) {
      top: -65px;
    }

    @media (max-width: 960px){
      top: -61px;
    }

    @include media-max(md){
      position: absolute;
      width: 100%;
      bottom: -65px;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      line-height: 1;
      top: auto;
      right: auto;
    }
  }
}

.slide-activity-mobileItem + .slide-activity-mobileItem{
  margin-top: 30px;
}

.activity-slider-md{
  .flickity-page-dots {
    bottom: -65px;
  }
}

.slider-activity-mobile {
  width: 100%;
}

.flickity-enabled {
  position: relative;
  z-index: 1056;
}
