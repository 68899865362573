@import "../sprite";
@import "../misc/bootstrap";

.btn2{
  background: #AEDFE4;
  height: 60px;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-family: 'Exo 2', sans-serif;
  font-weight: 800;
  color: #000000;

  @media (pointer: fine){
    &:hover{
      transition: all .2s;
      background: #88D4DC;
    }
  }
}