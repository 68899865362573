@import "../sprite";
@import "../misc/bootstrap";


.footer {
	background: #000;
	z-index: 1059;
	position: relative;
}

.footer-content {
	padding-top: 50px;
	display: flex;
	align-content: baseline;
	justify-content: space-between;
	color: white;

	@include media-max(md){
		flex-direction: column;
		padding-top: 25px;
	}
}

.footer-text{
	display: flex;
	align-content: baseline;
	justify-content: space-between;
	max-width: 780px;
	width: 100%;

	@include media-max(md){
		max-width: 100%;
		flex-direction: column;
	}
}

.footer-navigation{

	@include media-max(md){
		margin-top: 25px;
	}

	h3{
		margin-bottom: 10px;
	}
	ul{
		padding-left: 0;
		list-style: none;
		margin: 0;
	}

	a{
		font-size: 14px;
		margin-bottom: 9px;
		color: #CFCEDC;
		text-decoration: none;
		display: inline-block;

		&:hover{
			text-decoration: none;
			color: #AEDFE4;
			transition: all .2s;
		}
	}
}

.footer-contacts{

	@include media-max(md){
		margin-top: 25px;
	}

	h3{
		margin-bottom: 17px;
	}
}

.footer-list{
	column-count: 2;
	column-gap: 100px;

	@include media-max(md){
		max-width: 290px;
	}
}

.footer-address{
	max-width: 360px;
	display: flex;
	align-items: flex-start;
	p{
		margin: 0;
		font-size: 14px;
		color: #CFCEDC;
		padding-left: 10px;
	}
}

.footer-phone, .footer-mail{
	display: flex;
	align-items: center;
	margin-top: 10px;

	a{
		margin: 0;
		font-size: 14px;
		color: #CFCEDC;
		padding-left: 10px;
		text-decoration: none;

		&:hover{
			text-decoration: none;
			color: #AEDFE4;
			transition: all .2s;
		}
	}
}

.footer-logo{
	margin-right: 50px;

	@include media-max(md){
		margin-right: 0;
	}
}

