@import "../sprite";
@import "../misc/bootstrap";


.copyright {
  position: relative;
  height: auto;
  border-top: 1px solid #56697A;
  padding-bottom: 35px;
  margin-top: 25px;
  color: #CFCEDC;

  @include media-max(md){
    padding-bottom: 20px;
  }
}

.copyright-content{
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 20px;

  @include media-max(md){
    flex-direction: column;
  }

  p{
    color: #CFCEDC;
    font-size: 14px;

    @include media-max(md){
      margin-bottom: 10px;
    }
  }

  a{
    color: #CFCEDC;
    font-size: 14px;
    text-decoration: none;

    &:hover{
      text-decoration: none;
      color: #AEDFE4;
      transition: all .2s;
    }
  }
}




