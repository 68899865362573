@import "../sprite";
@import "../misc/bootstrap";

.customModal{
  position: relative;
  z-index: 1061;
  .modal-dialog {
    max-width: 563px;
  }

  .modal-content{
    border-radius: 0;
    border: none;
    padding-bottom: 40px;
  }

  .modal-header{
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    justify-content: flex-end;
  }

  .modal-title{
    text-align: center;
    margin: 0 auto;

    h4{
      font-family: 'Exo 2', sans-serif;
      font-weight: 800;
      font-size: 32px;

      @include media-max(sm){
        font-size: 24px;
      }
    }

    p{
      margin: 15px auto 0;
      max-width: 328px;

      @include media-max(sm){
        font-size: 14px;
      }
    }
  }


  .modal-body{
    padding: 0 50px;

    @include media-max(sm){
      padding: 0 25px;
    }
  }

  .form-control{
    border: none;
    border-bottom: 1px solid #747474;
    border-radius: 0;
    font-size: 16px;
    color: #2E3234;

    &:focus{
      box-shadow: none;
    }

    &::placeholder{
      color: #2E3234;
    }
  }

  .form-group{
    margin-bottom: 30px;
    position: relative;
  }

  .modal-form{
    margin-top: 30px;
  }

  .form-textarea{
    border: 1px solid #747474;
    border-radius: 0;
    font-size: 16px;
    color: #2E3234;
    width: 100%;
    resize: none;
    padding: 0.375rem 0.75rem;

    &::placeholder{
      color: #2E3234;
    }
  }

  .modal-politics{
    max-width: 343px;
    text-align: center;
    margin: 25px auto 0;

    p{
      margin: 0;
      color: #747474;
      font-size: 14px;
    }
  }


}

.modal-backdrop{
  z-index: 1060;
}