@import "../sprite";
@import "../misc/bootstrap";


.technology{
  position: relative;
  height: auto;
  z-index: 1056;
  background: #FAF9FF;

  display: flex;
  flex-direction: column;
}

.technology-content{
  padding-top: 130px;
  position: relative;
  z-index: 1056;

  @include media-max(md){
    padding-top: 0;
  }
}

.technology-title{
  h2{
    margin: 0;
  }
}

.technology-stack{
  padding-top: 30px;

}


.tab-content{
  background: #F9F9FF;
  border: 1px solid #AEDFE4;
  width: 100%;
  height: 100%;

  @include media-max(md){
    height: 386px;
  }
}

.nav-link{
  font-size: 16px;
  color: #1f1f1f;
  border: 1px solid #aedfe4;
  background: #faf9ff;
  font-family: 'Exo 2',sans-serif;
  font-weight: 800;
  padding: 0;
  text-align: center;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0 !important;
  margin-bottom: 30px;

  &:hover{
    color: #1f1f1f;
    background: #AEDFE4;
    border: 1px solid #AEDFE4;
    transition: all .2s;
  }

  @include media-max(md){
    text-transform: uppercase;
    font-size: 14px;
    height: 50px;
    margin-bottom: 20px;
  }
}

.nav{
  display: grid;
  grid-template-columns: 48% 45%;
  justify-content: space-between;

  .row{
    width: 100%;
  }

  @include media-max(md){
    grid-template-columns: 48% 48%;
    margin-bottom: 30px;
  }
}

.nav-pills .nav-link{
  &:hover{
    color: #1f1f1f;
  }
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #1f1f1f;
  background: #AEDFE4;
  border: 1px solid #AEDFE4;
  border-radius: 0;
}

.nav-link.noPadding{
  margin-bottom: 0;
}

.technology-logo{
  max-width: 755px;
  margin: 0 auto;
  position: relative;
  top: -20px;


  .row{
    align-items: center;
    width: 100%;
    @include media-max(md){
      display: grid;
      grid-template-columns: repeat(6, calc((100% / 3)));
    }
  }
}

.technology-item{
  text-align: center;
  height: 125px;
  width: 162px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.technology-scroll{
  overflow-y: scroll;
  height: 213px;
  margin-top: 20px;
  margin-right: 5px;

  @include media-max(md){
    overflow-y: clip;
    overflow-x: scroll;
    margin-right: 25px;
    margin-left: 25px;
    height: 360px;
  }

  &::-webkit-scrollbar {
    width: 3px;               /* ширина всей полосы прокрутки */
  }

  &::-webkit-scrollbar-track {
    background: #E4F0F2;        /* цвет зоны отслеживания */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #AEDFE4;    /* цвет бегунка */

  }
}


.tab-flex{
  display: flex;
  width: 60%;
}
