@import "../sprite";
@import "../misc/bootstrap";



.sideBar {
	position: fixed;
	top: 0;
	left: 0;
	transform: translateX(-100%);
	height: 100%;
	width: 100%;
	margin: 0;
	z-index: 999;
	transition: .2s;
}

.sideBar-content {
	width: 100%;
	max-width: 100%;
	height: 100vh;
	overflow: auto;
	background: #161717;
	position: relative;
}


.sideBar_visible {
	transform: translateX(0);
}
