@import "../sprite";
@import "../misc/bootstrap";

.benefits{
  position: relative;
  height: auto;
}

.benefits-content{
  margin-top: 150px;
  padding-bottom: 115px;


  @include media-max(md){
    margin-top: 65px;
    padding-bottom: 40px;
  }
}

.benefits-content{
  position: relative;
  z-index: 1056;
  h2{
    margin: 0;
  }
}

.benefits-icon{
  width: 61px;
  height: 61px;

  @include media-max(md){
    margin: 0 auto;
  }

  @include media-max(sm){
    margin: 0;
  }
}

.benefits-grid{
  margin-top: 30px;

  @include media-max(md){
    display: none;
  }
}

.benefits-slider{
  margin-top: 40px;
  display: none;

  @include media-max(md){
    display: block;
  }
}

.benefits-text{
  max-width: 255px;
  margin-top: 20px;
  margin-bottom: 75px;

  @include media-max(md){
    margin: 20px auto 75px;
    text-align: center;
  }

  @include media-max(sm){
    text-align: left;
    max-width: 150px;
    margin: 20px 0 55px;
  }

  p{
    margin: 0;
    color: #2E3234;
  }

  span{
    display: block;
  }
}

.benefits-slider{
  margin-top: 35px;
}

.benefits-slider-item{
  width: 100%;
  margin-right: 30px;
}


.slider-benefits-button{
  display: none;

  @include media-max(md){
    display: flex;
    max-width: 140px;
    justify-content: space-between;
    margin: 0 auto;
    z-index: 1056;
    position: relative;
  }
}

.benefits-slider{
  .flickity-page-dots {
    bottom: -28px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
  }
}