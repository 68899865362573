// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$burger-name: 'burger';
$burger-x: 150px;
$burger-y: 70px;
$burger-offset-x: -150px;
$burger-offset-y: -70px;
$burger-width: 29px;
$burger-height: 18px;
$burger-total-width: 256px;
$burger-total-height: 223px;
$burger-image: '../img/sprite.png?t1669380866883';
$burger: (150px, 70px, -150px, -70px, 29px, 18px, 256px, 223px, '../img/sprite.png?t1669380866883', 'burger', );
$logo-fixed-name: 'logo-fixed';
$logo-fixed-x: 150px;
$logo-fixed-y: 0px;
$logo-fixed-offset-x: -150px;
$logo-fixed-offset-y: 0px;
$logo-fixed-width: 106px;
$logo-fixed-height: 68px;
$logo-fixed-total-width: 256px;
$logo-fixed-total-height: 223px;
$logo-fixed-image: '../img/sprite.png?t1669380866883';
$logo-fixed: (150px, 0px, -150px, 0px, 106px, 68px, 256px, 223px, '../img/sprite.png?t1669380866883', 'logo-fixed', );
$logo-footer-mobile-name: 'logo-footer-mobile';
$logo-footer-mobile-x: 108px;
$logo-footer-mobile-y: 97px;
$logo-footer-mobile-offset-x: -108px;
$logo-footer-mobile-offset-y: -97px;
$logo-footer-mobile-width: 87px;
$logo-footer-mobile-height: 56px;
$logo-footer-mobile-total-width: 256px;
$logo-footer-mobile-total-height: 223px;
$logo-footer-mobile-image: '../img/sprite.png?t1669380866883';
$logo-footer-mobile: (108px, 97px, -108px, -97px, 87px, 56px, 256px, 223px, '../img/sprite.png?t1669380866883', 'logo-footer-mobile', );
$logo-footer-name: 'logo-footer';
$logo-footer-x: 0px;
$logo-footer-y: 0px;
$logo-footer-offset-x: 0px;
$logo-footer-offset-y: 0px;
$logo-footer-width: 148px;
$logo-footer-height: 95px;
$logo-footer-total-width: 256px;
$logo-footer-total-height: 223px;
$logo-footer-image: '../img/sprite.png?t1669380866883';
$logo-footer: (0px, 0px, 0px, 0px, 148px, 95px, 256px, 223px, '../img/sprite.png?t1669380866883', 'logo-footer', );
$logo-mobile-name: 'logo-mobile';
$logo-mobile-x: 0px;
$logo-mobile-y: 167px;
$logo-mobile-offset-x: 0px;
$logo-mobile-offset-y: -167px;
$logo-mobile-width: 87px;
$logo-mobile-height: 56px;
$logo-mobile-total-width: 256px;
$logo-mobile-total-height: 223px;
$logo-mobile-image: '../img/sprite.png?t1669380866883';
$logo-mobile: (0px, 167px, 0px, -167px, 87px, 56px, 256px, 223px, '../img/sprite.png?t1669380866883', 'logo-mobile', );
$logo-name: 'logo';
$logo-x: 0px;
$logo-y: 97px;
$logo-offset-x: 0px;
$logo-offset-y: -97px;
$logo-width: 106px;
$logo-height: 68px;
$logo-total-width: 256px;
$logo-total-height: 223px;
$logo-image: '../img/sprite.png?t1669380866883';
$logo: (0px, 97px, 0px, -97px, 106px, 68px, 256px, 223px, '../img/sprite.png?t1669380866883', 'logo', );
$mail-name: 'mail';
$mail-x: 221px;
$mail-y: 70px;
$mail-offset-x: -221px;
$mail-offset-y: -70px;
$mail-width: 19px;
$mail-height: 15px;
$mail-total-width: 256px;
$mail-total-height: 223px;
$mail-image: '../img/sprite.png?t1669380866883';
$mail: (221px, 70px, -221px, -70px, 19px, 15px, 256px, 223px, '../img/sprite.png?t1669380866883', 'mail', );
$marker-name: 'marker';
$marker-x: 141px;
$marker-y: 167px;
$marker-offset-x: -141px;
$marker-offset-y: -167px;
$marker-width: 14px;
$marker-height: 20px;
$marker-total-width: 256px;
$marker-total-height: 223px;
$marker-image: '../img/sprite.png?t1669380866883';
$marker: (141px, 167px, -141px, -167px, 14px, 20px, 256px, 223px, '../img/sprite.png?t1669380866883', 'marker', );
$next-benefits-name: 'next-benefits';
$next-benefits-x: 197px;
$next-benefits-y: 97px;
$next-benefits-offset-x: -197px;
$next-benefits-offset-y: -97px;
$next-benefits-width: 50px;
$next-benefits-height: 50px;
$next-benefits-total-width: 256px;
$next-benefits-total-height: 223px;
$next-benefits-image: '../img/sprite.png?t1669380866883';
$next-benefits: (197px, 97px, -197px, -97px, 50px, 50px, 256px, 223px, '../img/sprite.png?t1669380866883', 'next-benefits', );
$phone-2-name: 'phone-2';
$phone-2-x: 181px;
$phone-2-y: 70px;
$phone-2-offset-x: -181px;
$phone-2-offset-y: -70px;
$phone-2-width: 18px;
$phone-2-height: 18px;
$phone-2-total-width: 256px;
$phone-2-total-height: 223px;
$phone-2-image: '../img/sprite.png?t1669380866883';
$phone-2: (181px, 70px, -181px, -70px, 18px, 18px, 256px, 223px, '../img/sprite.png?t1669380866883', 'phone-2', );
$phone-name: 'phone';
$phone-x: 201px;
$phone-y: 70px;
$phone-offset-x: -201px;
$phone-offset-y: -70px;
$phone-width: 18px;
$phone-height: 18px;
$phone-total-width: 256px;
$phone-total-height: 223px;
$phone-image: '../img/sprite.png?t1669380866883';
$phone: (201px, 70px, -201px, -70px, 18px, 18px, 256px, 223px, '../img/sprite.png?t1669380866883', 'phone', );
$prev-benefits-name: 'prev-benefits';
$prev-benefits-x: 89px;
$prev-benefits-y: 167px;
$prev-benefits-offset-x: -89px;
$prev-benefits-offset-y: -167px;
$prev-benefits-width: 50px;
$prev-benefits-height: 50px;
$prev-benefits-total-width: 256px;
$prev-benefits-total-height: 223px;
$prev-benefits-image: '../img/sprite.png?t1669380866883';
$prev-benefits: (89px, 167px, -89px, -167px, 50px, 50px, 256px, 223px, '../img/sprite.png?t1669380866883', 'prev-benefits', );
$burger-2x-name: 'burger@2x';
$burger-2x-x: 300px;
$burger-2x-y: 140px;
$burger-2x-offset-x: -300px;
$burger-2x-offset-y: -140px;
$burger-2x-width: 58px;
$burger-2x-height: 36px;
$burger-2x-total-width: 512px;
$burger-2x-total-height: 446px;
$burger-2x-image: '../img/sprite@2x.png?t1669380866883';
$burger-2x: (300px, 140px, -300px, -140px, 58px, 36px, 512px, 446px, '../img/sprite@2x.png?t1669380866883', 'burger@2x', );
$logo-fixed-2x-name: 'logo-fixed@2x';
$logo-fixed-2x-x: 300px;
$logo-fixed-2x-y: 0px;
$logo-fixed-2x-offset-x: -300px;
$logo-fixed-2x-offset-y: 0px;
$logo-fixed-2x-width: 212px;
$logo-fixed-2x-height: 136px;
$logo-fixed-2x-total-width: 512px;
$logo-fixed-2x-total-height: 446px;
$logo-fixed-2x-image: '../img/sprite@2x.png?t1669380866883';
$logo-fixed-2x: (300px, 0px, -300px, 0px, 212px, 136px, 512px, 446px, '../img/sprite@2x.png?t1669380866883', 'logo-fixed@2x', );
$logo-footer-mobile-2x-name: 'logo-footer-mobile@2x';
$logo-footer-mobile-2x-x: 216px;
$logo-footer-mobile-2x-y: 194px;
$logo-footer-mobile-2x-offset-x: -216px;
$logo-footer-mobile-2x-offset-y: -194px;
$logo-footer-mobile-2x-width: 174px;
$logo-footer-mobile-2x-height: 112px;
$logo-footer-mobile-2x-total-width: 512px;
$logo-footer-mobile-2x-total-height: 446px;
$logo-footer-mobile-2x-image: '../img/sprite@2x.png?t1669380866883';
$logo-footer-mobile-2x: (216px, 194px, -216px, -194px, 174px, 112px, 512px, 446px, '../img/sprite@2x.png?t1669380866883', 'logo-footer-mobile@2x', );
$logo-footer-2x-name: 'logo-footer@2x';
$logo-footer-2x-x: 0px;
$logo-footer-2x-y: 0px;
$logo-footer-2x-offset-x: 0px;
$logo-footer-2x-offset-y: 0px;
$logo-footer-2x-width: 296px;
$logo-footer-2x-height: 190px;
$logo-footer-2x-total-width: 512px;
$logo-footer-2x-total-height: 446px;
$logo-footer-2x-image: '../img/sprite@2x.png?t1669380866883';
$logo-footer-2x: (0px, 0px, 0px, 0px, 296px, 190px, 512px, 446px, '../img/sprite@2x.png?t1669380866883', 'logo-footer@2x', );
$logo-mobile-2x-name: 'logo-mobile@2x';
$logo-mobile-2x-x: 0px;
$logo-mobile-2x-y: 334px;
$logo-mobile-2x-offset-x: 0px;
$logo-mobile-2x-offset-y: -334px;
$logo-mobile-2x-width: 174px;
$logo-mobile-2x-height: 112px;
$logo-mobile-2x-total-width: 512px;
$logo-mobile-2x-total-height: 446px;
$logo-mobile-2x-image: '../img/sprite@2x.png?t1669380866883';
$logo-mobile-2x: (0px, 334px, 0px, -334px, 174px, 112px, 512px, 446px, '../img/sprite@2x.png?t1669380866883', 'logo-mobile@2x', );
$logo-2x-name: 'logo@2x';
$logo-2x-x: 0px;
$logo-2x-y: 194px;
$logo-2x-offset-x: 0px;
$logo-2x-offset-y: -194px;
$logo-2x-width: 212px;
$logo-2x-height: 136px;
$logo-2x-total-width: 512px;
$logo-2x-total-height: 446px;
$logo-2x-image: '../img/sprite@2x.png?t1669380866883';
$logo-2x: (0px, 194px, 0px, -194px, 212px, 136px, 512px, 446px, '../img/sprite@2x.png?t1669380866883', 'logo@2x', );
$mail-2x-name: 'mail@2x';
$mail-2x-x: 442px;
$mail-2x-y: 140px;
$mail-2x-offset-x: -442px;
$mail-2x-offset-y: -140px;
$mail-2x-width: 38px;
$mail-2x-height: 30px;
$mail-2x-total-width: 512px;
$mail-2x-total-height: 446px;
$mail-2x-image: '../img/sprite@2x.png?t1669380866883';
$mail-2x: (442px, 140px, -442px, -140px, 38px, 30px, 512px, 446px, '../img/sprite@2x.png?t1669380866883', 'mail@2x', );
$marker-2x-name: 'marker@2x';
$marker-2x-x: 282px;
$marker-2x-y: 334px;
$marker-2x-offset-x: -282px;
$marker-2x-offset-y: -334px;
$marker-2x-width: 28px;
$marker-2x-height: 40px;
$marker-2x-total-width: 512px;
$marker-2x-total-height: 446px;
$marker-2x-image: '../img/sprite@2x.png?t1669380866883';
$marker-2x: (282px, 334px, -282px, -334px, 28px, 40px, 512px, 446px, '../img/sprite@2x.png?t1669380866883', 'marker@2x', );
$next-benefits-2x-name: 'next-benefits@2x';
$next-benefits-2x-x: 394px;
$next-benefits-2x-y: 194px;
$next-benefits-2x-offset-x: -394px;
$next-benefits-2x-offset-y: -194px;
$next-benefits-2x-width: 100px;
$next-benefits-2x-height: 100px;
$next-benefits-2x-total-width: 512px;
$next-benefits-2x-total-height: 446px;
$next-benefits-2x-image: '../img/sprite@2x.png?t1669380866883';
$next-benefits-2x: (394px, 194px, -394px, -194px, 100px, 100px, 512px, 446px, '../img/sprite@2x.png?t1669380866883', 'next-benefits@2x', );
$phone-2-2x-name: 'phone-2@2x';
$phone-2-2x-x: 362px;
$phone-2-2x-y: 140px;
$phone-2-2x-offset-x: -362px;
$phone-2-2x-offset-y: -140px;
$phone-2-2x-width: 36px;
$phone-2-2x-height: 36px;
$phone-2-2x-total-width: 512px;
$phone-2-2x-total-height: 446px;
$phone-2-2x-image: '../img/sprite@2x.png?t1669380866883';
$phone-2-2x: (362px, 140px, -362px, -140px, 36px, 36px, 512px, 446px, '../img/sprite@2x.png?t1669380866883', 'phone-2@2x', );
$phone-2x-name: 'phone@2x';
$phone-2x-x: 402px;
$phone-2x-y: 140px;
$phone-2x-offset-x: -402px;
$phone-2x-offset-y: -140px;
$phone-2x-width: 36px;
$phone-2x-height: 36px;
$phone-2x-total-width: 512px;
$phone-2x-total-height: 446px;
$phone-2x-image: '../img/sprite@2x.png?t1669380866883';
$phone-2x: (402px, 140px, -402px, -140px, 36px, 36px, 512px, 446px, '../img/sprite@2x.png?t1669380866883', 'phone@2x', );
$prev-benefits-2x-name: 'prev-benefits@2x';
$prev-benefits-2x-x: 178px;
$prev-benefits-2x-y: 334px;
$prev-benefits-2x-offset-x: -178px;
$prev-benefits-2x-offset-y: -334px;
$prev-benefits-2x-width: 100px;
$prev-benefits-2x-height: 100px;
$prev-benefits-2x-total-width: 512px;
$prev-benefits-2x-total-height: 446px;
$prev-benefits-2x-image: '../img/sprite@2x.png?t1669380866883';
$prev-benefits-2x: (178px, 334px, -178px, -334px, 100px, 100px, 512px, 446px, '../img/sprite@2x.png?t1669380866883', 'prev-benefits@2x', );
$spritesheet-width: 256px;
$spritesheet-height: 223px;
$spritesheet-image: '../img/sprite.png?t1669380866883';
$spritesheet-sprites: ($burger, $logo-fixed, $logo-footer-mobile, $logo-footer, $logo-mobile, $logo, $mail, $marker, $next-benefits, $phone-2, $phone, $prev-benefits, );
$spritesheet: (256px, 223px, '../img/sprite.png?t1669380866883', $spritesheet-sprites, );
$retina-spritesheet-width: 512px;
$retina-spritesheet-height: 446px;
$retina-spritesheet-image: '../img/sprite@2x.png?t1669380866883';
$retina-spritesheet-sprites: ($burger-2x, $logo-fixed-2x, $logo-footer-mobile-2x, $logo-footer-2x, $logo-mobile-2x, $logo-2x, $mail-2x, $marker-2x, $next-benefits-2x, $phone-2-2x, $phone-2x, $prev-benefits-2x, );
$retina-spritesheet: (512px, 446px, '../img/sprite@2x.png?t1669380866883', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$burger-group-name: 'burger';
$burger-group: ('burger', $burger, $burger-2x, );
$logo-fixed-group-name: 'logo-fixed';
$logo-fixed-group: ('logo-fixed', $logo-fixed, $logo-fixed-2x, );
$logo-footer-mobile-group-name: 'logo-footer-mobile';
$logo-footer-mobile-group: ('logo-footer-mobile', $logo-footer-mobile, $logo-footer-mobile-2x, );
$logo-footer-group-name: 'logo-footer';
$logo-footer-group: ('logo-footer', $logo-footer, $logo-footer-2x, );
$logo-mobile-group-name: 'logo-mobile';
$logo-mobile-group: ('logo-mobile', $logo-mobile, $logo-mobile-2x, );
$logo-group-name: 'logo';
$logo-group: ('logo', $logo, $logo-2x, );
$mail-group-name: 'mail';
$mail-group: ('mail', $mail, $mail-2x, );
$marker-group-name: 'marker';
$marker-group: ('marker', $marker, $marker-2x, );
$next-benefits-group-name: 'next-benefits';
$next-benefits-group: ('next-benefits', $next-benefits, $next-benefits-2x, );
$phone-2-group-name: 'phone-2';
$phone-2-group: ('phone-2', $phone-2, $phone-2-2x, );
$phone-group-name: 'phone';
$phone-group: ('phone', $phone, $phone-2x, );
$prev-benefits-group-name: 'prev-benefits';
$prev-benefits-group: ('prev-benefits', $prev-benefits, $prev-benefits-2x, );
$retina-groups: ($burger-group, $logo-fixed-group, $logo-footer-mobile-group, $logo-footer-group, $logo-mobile-group, $logo-group, $mail-group, $marker-group, $next-benefits-group, $phone-2-group, $phone-group, $prev-benefits-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
