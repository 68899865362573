@import "../misc/bootstrap";
@import "../sprite";

//.burger {
//	@include retina-sprite($burger-group);
//	display: block;
//	position: relative;
//}

.burger-toggle {
	position: relative;
}

.burger-toggle:focus {
	outline-width: 0;
}

.burger-toggle [class*='bar-'] {
	background: #AEDF;
	display: block;
	transform: rotate(0);
	transition: .2s ease all;
	width: 29px;
	height: 2px;
	margin-bottom: 6px;
}

.burger-toggle .bar-bot {
	margin-bottom: 0;
}

.opened .bar-top {
	transform: rotate(45deg);
	transform-origin: 15% 15%;
}
.opened .bar-mid {
	opacity: 0;
}
.opened .bar-bot {
	transform: rotate(-45deg);
	transform-origin: 6% 95%;
}


