@import "bootstrap";

h1, .h1 {
	font-weight: 800;
	font-size: clamp(36px, 7vw, 70px);
	line-height: 130%;
	font-family: 'Exo 2', sans-serif;
}

h2, .h2 {
	font-size: clamp(36px, 4vw, 50px);
	line-height: 150%;
	font-weight: 800;
	font-family: 'Exo 2', sans-serif;
}

h3, .h3 {
	font-size: clamp(18px, 4vw, 22px);
	line-height: 125%;
	font-weight: 800;
	font-family: 'Exo 2', sans-serif;
}

p, .text {
	font-size: clamp(14px, 3vw, 16px);
	line-height: 130%;
	font-style: normal;
	font-weight: 400;
}


