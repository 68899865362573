@import "../sprite";
@import "../misc/bootstrap";


.up{
  width: 98px;
  height: 98px;
  background: #161717;
  border-radius: 100%;
  top: -43%;
  right: 0;
  position: absolute;
  z-index: 1060;
  display: flex;
  justify-content: center;

  @include media-max(md){
    display: none;
  }

  &::before{
    content: "";
    background-image: url("../../img/icons/up.png");
    background-repeat: no-repeat;
    width: 52px;
    height: 43px;
    display: block;
    margin-top: 15px;
  }

  &:hover::before{
    background-image: url("../../img/icons/up_hover.png");
    transition: all .3s;
  }
}