@import "../sprite";
@import "../misc/bootstrap";


.callback{
  position: relative;
  height: auto;
  background: #161717;
  padding: 125px 0 100px;
  z-index: 1058;

  display: flex;
  flex-direction: column;

  @include media-max(md){
    padding: 60px 0;
  }
}

.callback-title{
  h2{
    margin: 0;
    color: white;
  }
}

.form{
  margin-top: 75px;
  max-width: 920px;
  position: relative;
  z-index: 1056;

  @include media-max(md){
    max-width: 100%;
    margin-top: 25px;
  }

  .form-control{
    border-radius: 0;
    border: none;
    background: transparent;
    color: #CFCEDC;
    border-bottom: 1px solid #AEDFE4;
    font-size: 16px;
    padding-left: 10px;

    &:focus{
      box-shadow: none;
      background: transparent;
      outline: none;
    }

    &::placeholder{
      color: #CFCEDC;
    }

    @include media-max(md){
      width: 350px;

      @include media-max(sm){
        width: 100%;
      }
    }

  }

  .form-area{
    margin-top: 50px;

    @include media-max(md){
      margin-top: 35px;
    }

    textarea{
      border-radius: 0;
      border: none;
      background: transparent;
      color: #CFCEDC;
      border-bottom: 1px solid #AEDFE4;
      font-size: 16px;
      resize: none;
      width: 100%;
      height: calc(1.5em + 0.75rem + -2px);
      padding: 0.375rem 0 0 10px;
      overflow-y: clip;

      &::placeholder{
        color: #CFCEDC;
      }

      @include media-max(md){
        border: 1px solid #AEDFE4;
        height: calc(1em * 1.5 * 5 + 3px + 3px + 1px + 1px);
        padding-right: 10px;
      }
    }
  }

  .form-row{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;

    @include media-max(md){
      grid-template-columns: 100%;
      grid-gap: 25px;
    }
  }

  .form-politics{
    max-width: 287px;
    margin-right: 20px;
    p{
      margin: 0;
      font-size: 14px;
      color: #6A6883;
    }

    @include media-max(md){
      margin-top: 15px;
      margin-right: 0;
    }
  }

  .form-button{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;

    @include media-max(md){
      flex-direction: column-reverse;
      margin-top: 30px;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

}
