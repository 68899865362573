@import "../sprite";
@import "../misc/bootstrap";


.landmarks{
  position: relative;
  height: auto;
  z-index: 1057;
  background: #FAF9FF;

  display: flex;
  flex-direction: column;
}

.landmarks-content{
  padding-top: 130px;

  @include media-max(md){
    padding-top: 60px;
  }

  h2{
    margin-bottom: 0;
  }
}

.landmarks-all{
  max-width: 1100px;
  margin-top: 30px;
}

.landmarks-slider{
  height: 429px;
  background: #D0F2F5;
  padding: 50px 50px 35px;
  width: 100%;
  position: relative;

  @include media-max(sm){
    padding: 18px 18px 20px;
    height: 380px;
  }
}

.landmarks-text{
  margin-top: 30px;

  @include media-max(sm){
    margin-top: 15px;
  }

  h3{
    margin-bottom: 15px;

    @include media-max(sm){
      margin-bottom: 5px;
    }
  }

  p{
    max-width: 473px;
    margin: 0;
  }
}

.landmarks-list{
  position: relative;
  margin-left: 100px;
  margin-top: 25px;

  @include media-max(lg){
    display: none;
  }

  p{
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #B9B9B9;
    position: relative;
  }

  .active_slide{
    color: #1F1F1F;

    &::before{
      content: "";
      background-image: url("../../img/icons/arrow-right.png");
      width: 23px;
      height: 22px;
      display: block;
      position: absolute;
      left: -35px;
      top: 3px;
    }
  }
}

.landmarks-carousel-item {
  width: 100%;
  padding: 1px;
  margin-right: 30px;
}

.slider-landmarks-button {
  display: flex;
  align-items: baseline;
  max-width: 120px;
  justify-content: space-between;
  width: 100%;
  z-index: 1056;
  position: absolute;
  bottom: 30px;
}

.slider-landmarks-button button{
  border: 1px solid #7E889B;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (pointer: fine){
    &:hover{
      border: 1px solid #88D4DC;
      transition: all .2s;
    }
  }
}

.button-previous-5:before{
  content: "";
  background-image: url("../../img/icons/prev-gray.png");
  width: 21px;
  height: 20px;
}


.button-next-5:before{
  content: "";
  background-image: url("../../img/icons/next-gray.png");
  width: 21px;
  height: 20px;
}

@media (pointer: fine){
  .button-previous-5:hover:before{
    background-image: url("../../img/icons/prev-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s;
  }

  .button-next-5:hover:before{
    background-image: url("../../img/icons/next-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s;
  }
}

.landmarks-button{
  margin-top: 30px;
}

.slider-landmarks-counter{
  position: absolute;
  bottom: 30px;
  right: 30px;

  span{
    font-family: 'Exo 2', sans-serif;
    font-weight: 800;
    color: #AEDFE4;
    font-size: 24px;
  }
}