@import "../sprite";
@import "../misc/bootstrap";

.banner{
  position: relative;
  height: 790px;
  background: #161717;
  z-index: 1057;


  @include media-max(xl){
    height: 710px;
  }

  @include media-max(lg){
    height: 576px;
  }

  @include media-max(md){
    height: 780px;
  }

  @include media-max(sm){
    height: 685px;
  }
}

.banner-position{
  display: flex;
  align-items: center;
  height: 100%;
}

.banner-content{
  display: flex;
  align-items: center;
  justify-content: space-between;



  @include media-max(md){
    margin-top: 50px;
    flex-direction: column;
    //align-items: flex-start;
    justify-content: normal;
    width: 100%;
  }
}

.banner-video{
  height: 690px;

  @include media-max(md){
    margin: 40px auto 0;
    height: auto;
  }


  video{
    width: 100%;
    height: 100%;

    @include media-max(md){
      width: 380px;
      height: auto;
    }

    @include media-max(sm){
      width: 295px;
    }
  }
}

.banner-title {
  position: relative;
  z-index: 1055;

  h1{
    color: white;
    margin-bottom: 30px;

    @include media-max(sm){
      margin-bottom: 10px;
    }
  }

  p{
    color: #BCCDD7;
    margin: 0;
    max-width: 647px;
  }

  &:after{
    content: "";
    background-image: url(../../img/rutilans/e_3.png);
    background-repeat: no-repeat;
    width: 473px;
    height: 473px;
    position: absolute;
    z-index: 1055;
    bottom: 0;
    right: -70px;

    @include media-max(lg){
      right: -200px;
    }

    @include media-max(md){
      right: 65px;
      bottom: -55px;
    }

    @include media-max(sm){
      right: -165px;
      bottom: 0;
      top: 240px;
    }
  }

}

.video-1{
  display: none;

  @include media-max(md){
    display: block;
  }
}

.video-2{
  @include media-max(md){
    display: none;
  }
}

.banner-img{

  @include media-max(md){
    width: 380px;
    height: 350px;
    position: relative;
    left: -25px;
  }

  img{
    width: 100%;
    min-height: 100%;
    object-fit: cover;

  }
}

@media screen and (-webkit-min-device-pixel-ratio:0)
and (min-resolution:.001dpcm) {
  .banner-img{display: none}
}

@supports (-moz-appearance:none) {
  .banner-img{display: none}
}

@supports (-ms-ime-align:auto) {
  .banner-img{display: none}
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

  .banner-img{display: block}
}}

.safari .safariSpecific{
  display: block;
}

.safari .safariNone{
  display: none;
}


