@import "../sprite";
@import "../misc/bootstrap";

.vacancies{
  position: relative;
  height: auto;
  z-index: 1058;
  background: #FAF9FF;

  display: flex;
  flex-direction: column;

  @include media-max(md){
    z-index: 1059;
  }
}

.vacancies-content{
  padding-top: 130px;
  padding-bottom: 130px;

  @include media-max(md){
    padding-top: 0;
  }
}

.vacancies-title{
  h2{
    margin-bottom: 0;
  }
}

.vacancies-carousel{
  margin-top: 30px;
  position: relative;
}

.slider-vacancies-button {
  display: flex;
  align-items: baseline;
  max-width: 162px;
  justify-content: space-between;
  width: 100%;
  z-index: 1056;

  @include media-max(md){
    max-width: 155px;
    position: absolute;
    margin: 0 auto;
    width: 100%;
    transform: translateX(-50%);
    left: 50%;
    bottom: -86px;
  }
}

.vacancies-navigation{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider-vacancies-button button{
  border: 1px solid #7E889B;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (pointer: fine){
    &:hover{
      border: 1px solid #88D4DC;
      transition: all .2s;
    }
  }
}

.button-previous-7:before{
  content: "";
  background-image: url("../../img/icons/prev-gray.png");
  width: 21px;
  height: 20px;
}


.button-next-7:before{
  content: "";
  background-image: url("../../img/icons/next-gray.png");
  width: 21px;
  height: 20px;
}

@media (pointer: fine){
  .button-previous-7:hover:before{
    background-image: url("../../img/icons/prev-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s;
  }

  .button-next-7:hover:before{
    background-image: url("../../img/icons/next-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s;
  }
}

.vacancies-slider{
  .flickity-page-dots{
    position: absolute;
    width: auto;
    padding: 0;
    margin: 0;
    top: -70px;
    right: 61px;

    @media (max-width: 1140px){
      top: -66px;
    }

    @media (max-width: 992px) {
      top: -62px;
    }

    @media (max-width: 820px){
      top: -59px;
    }

    @include media-max(md){
      position: absolute;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      line-height: 1;
      top: auto;
      right: auto;
      bottom: -65px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
    }
  }
}

.vacancies-slider-item{
  background: #FFFFFF;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-right: 30px;
  width: 393px;

  @media (max-width: 500px){
    width: 100%;
    margin-right: 0;
  }
}

.vacancies-slider-text{
  margin-top: 20px;

  p{
    margin: 0;
    color: #2E3234;
    max-width: 333px;
  }
}

.vacancies-slider-button{
  margin-top: 15px;
}

.flickity-slider{
  height: 400px;
}
