@import "../sprite";
@import "../misc/bootstrap";


.logo {
	@include retina-sprite($logo-group);
	display: block;

	@include media-max(lg){
		@include retina-sprite($logo-mobile-group);
	}
}

.logo-footer {
	@include retina-sprite($logo-footer-group);
	display: block;

	@include media-max(lg){
		@include retina-sprite($logo-footer-mobile-group);
	}
}

.logo-fixed {
	@include retina-sprite($logo-fixed-group);
	display: block;
}


