@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700;800;900&display=swap");
.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 767px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 767px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 767px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-q1 {
  margin: 0.25rem !important; }

.mt-q1,
.my-q1 {
  margin-top: 0.25rem !important; }

.mr-q1,
.mx-q1 {
  margin-right: 0.25rem !important; }

.mb-q1,
.my-q1 {
  margin-bottom: 0.25rem !important; }

.ml-q1,
.mx-q1 {
  margin-left: 0.25rem !important; }

.m-q2 {
  margin: 0.5rem !important; }

.mt-q2,
.my-q2 {
  margin-top: 0.5rem !important; }

.mr-q2,
.mx-q2 {
  margin-right: 0.5rem !important; }

.mb-q2,
.my-q2 {
  margin-bottom: 0.5rem !important; }

.ml-q2,
.mx-q2 {
  margin-left: 0.5rem !important; }

.m-q3 {
  margin: 0.75rem !important; }

.mt-q3,
.my-q3 {
  margin-top: 0.75rem !important; }

.mr-q3,
.mx-q3 {
  margin-right: 0.75rem !important; }

.mb-q3,
.my-q3 {
  margin-bottom: 0.75rem !important; }

.ml-q3,
.mx-q3 {
  margin-left: 0.75rem !important; }

.m-0 {
  margin: 0rem !important; }

.mt-0,
.my-0 {
  margin-top: 0rem !important; }

.mr-0,
.mx-0 {
  margin-right: 0rem !important; }

.mb-0,
.my-0 {
  margin-bottom: 0rem !important; }

.ml-0,
.mx-0 {
  margin-left: 0rem !important; }

.m-1 {
  margin: 1rem !important; }

.mt-1,
.my-1 {
  margin-top: 1rem !important; }

.mr-1,
.mx-1 {
  margin-right: 1rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 1rem !important; }

.ml-1,
.mx-1 {
  margin-left: 1rem !important; }

.m-1q1 {
  margin: 1.25rem !important; }

.mt-1q1,
.my-1q1 {
  margin-top: 1.25rem !important; }

.mr-1q1,
.mx-1q1 {
  margin-right: 1.25rem !important; }

.mb-1q1,
.my-1q1 {
  margin-bottom: 1.25rem !important; }

.ml-1q1,
.mx-1q1 {
  margin-left: 1.25rem !important; }

.m-1q2 {
  margin: 1.5rem !important; }

.mt-1q2,
.my-1q2 {
  margin-top: 1.5rem !important; }

.mr-1q2,
.mx-1q2 {
  margin-right: 1.5rem !important; }

.mb-1q2,
.my-1q2 {
  margin-bottom: 1.5rem !important; }

.ml-1q2,
.mx-1q2 {
  margin-left: 1.5rem !important; }

.m-1q3 {
  margin: 1.75rem !important; }

.mt-1q3,
.my-1q3 {
  margin-top: 1.75rem !important; }

.mr-1q3,
.mx-1q3 {
  margin-right: 1.75rem !important; }

.mb-1q3,
.my-1q3 {
  margin-bottom: 1.75rem !important; }

.ml-1q3,
.mx-1q3 {
  margin-left: 1.75rem !important; }

.m-2 {
  margin: 2rem !important; }

.mt-2,
.my-2 {
  margin-top: 2rem !important; }

.mr-2,
.mx-2 {
  margin-right: 2rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 2rem !important; }

.ml-2,
.mx-2 {
  margin-left: 2rem !important; }

.m-2q1 {
  margin: 2.25rem !important; }

.mt-2q1,
.my-2q1 {
  margin-top: 2.25rem !important; }

.mr-2q1,
.mx-2q1 {
  margin-right: 2.25rem !important; }

.mb-2q1,
.my-2q1 {
  margin-bottom: 2.25rem !important; }

.ml-2q1,
.mx-2q1 {
  margin-left: 2.25rem !important; }

.m-2q2 {
  margin: 2.5rem !important; }

.mt-2q2,
.my-2q2 {
  margin-top: 2.5rem !important; }

.mr-2q2,
.mx-2q2 {
  margin-right: 2.5rem !important; }

.mb-2q2,
.my-2q2 {
  margin-bottom: 2.5rem !important; }

.ml-2q2,
.mx-2q2 {
  margin-left: 2.5rem !important; }

.m-2q3 {
  margin: 2.75rem !important; }

.mt-2q3,
.my-2q3 {
  margin-top: 2.75rem !important; }

.mr-2q3,
.mx-2q3 {
  margin-right: 2.75rem !important; }

.mb-2q3,
.my-2q3 {
  margin-bottom: 2.75rem !important; }

.ml-2q3,
.mx-2q3 {
  margin-left: 2.75rem !important; }

.m-3 {
  margin: 3rem !important; }

.mt-3,
.my-3 {
  margin-top: 3rem !important; }

.mr-3,
.mx-3 {
  margin-right: 3rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 3rem !important; }

.ml-3,
.mx-3 {
  margin-left: 3rem !important; }

.m-3q1 {
  margin: 3.25rem !important; }

.mt-3q1,
.my-3q1 {
  margin-top: 3.25rem !important; }

.mr-3q1,
.mx-3q1 {
  margin-right: 3.25rem !important; }

.mb-3q1,
.my-3q1 {
  margin-bottom: 3.25rem !important; }

.ml-3q1,
.mx-3q1 {
  margin-left: 3.25rem !important; }

.m-3q2 {
  margin: 3.5rem !important; }

.mt-3q2,
.my-3q2 {
  margin-top: 3.5rem !important; }

.mr-3q2,
.mx-3q2 {
  margin-right: 3.5rem !important; }

.mb-3q2,
.my-3q2 {
  margin-bottom: 3.5rem !important; }

.ml-3q2,
.mx-3q2 {
  margin-left: 3.5rem !important; }

.m-3q3 {
  margin: 3.75rem !important; }

.mt-3q3,
.my-3q3 {
  margin-top: 3.75rem !important; }

.mr-3q3,
.mx-3q3 {
  margin-right: 3.75rem !important; }

.mb-3q3,
.my-3q3 {
  margin-bottom: 3.75rem !important; }

.ml-3q3,
.mx-3q3 {
  margin-left: 3.75rem !important; }

.m-4 {
  margin: 4rem !important; }

.mt-4,
.my-4 {
  margin-top: 4rem !important; }

.mr-4,
.mx-4 {
  margin-right: 4rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 4rem !important; }

.ml-4,
.mx-4 {
  margin-left: 4rem !important; }

.m-4q1 {
  margin: 4.25rem !important; }

.mt-4q1,
.my-4q1 {
  margin-top: 4.25rem !important; }

.mr-4q1,
.mx-4q1 {
  margin-right: 4.25rem !important; }

.mb-4q1,
.my-4q1 {
  margin-bottom: 4.25rem !important; }

.ml-4q1,
.mx-4q1 {
  margin-left: 4.25rem !important; }

.m-4q2 {
  margin: 4.5rem !important; }

.mt-4q2,
.my-4q2 {
  margin-top: 4.5rem !important; }

.mr-4q2,
.mx-4q2 {
  margin-right: 4.5rem !important; }

.mb-4q2,
.my-4q2 {
  margin-bottom: 4.5rem !important; }

.ml-4q2,
.mx-4q2 {
  margin-left: 4.5rem !important; }

.m-4q3 {
  margin: 4.75rem !important; }

.mt-4q3,
.my-4q3 {
  margin-top: 4.75rem !important; }

.mr-4q3,
.mx-4q3 {
  margin-right: 4.75rem !important; }

.mb-4q3,
.my-4q3 {
  margin-bottom: 4.75rem !important; }

.ml-4q3,
.mx-4q3 {
  margin-left: 4.75rem !important; }

.m-5 {
  margin: 5rem !important; }

.mt-5,
.my-5 {
  margin-top: 5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 5rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 5rem !important; }

.m-5q1 {
  margin: 5.25rem !important; }

.mt-5q1,
.my-5q1 {
  margin-top: 5.25rem !important; }

.mr-5q1,
.mx-5q1 {
  margin-right: 5.25rem !important; }

.mb-5q1,
.my-5q1 {
  margin-bottom: 5.25rem !important; }

.ml-5q1,
.mx-5q1 {
  margin-left: 5.25rem !important; }

.m-5q2 {
  margin: 5.5rem !important; }

.mt-5q2,
.my-5q2 {
  margin-top: 5.5rem !important; }

.mr-5q2,
.mx-5q2 {
  margin-right: 5.5rem !important; }

.mb-5q2,
.my-5q2 {
  margin-bottom: 5.5rem !important; }

.ml-5q2,
.mx-5q2 {
  margin-left: 5.5rem !important; }

.m-5q3 {
  margin: 5.75rem !important; }

.mt-5q3,
.my-5q3 {
  margin-top: 5.75rem !important; }

.mr-5q3,
.mx-5q3 {
  margin-right: 5.75rem !important; }

.mb-5q3,
.my-5q3 {
  margin-bottom: 5.75rem !important; }

.ml-5q3,
.mx-5q3 {
  margin-left: 5.75rem !important; }

.m-6 {
  margin: 6rem !important; }

.mt-6,
.my-6 {
  margin-top: 6rem !important; }

.mr-6,
.mx-6 {
  margin-right: 6rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 6rem !important; }

.ml-6,
.mx-6 {
  margin-left: 6rem !important; }

.m-6q1 {
  margin: 6.25rem !important; }

.mt-6q1,
.my-6q1 {
  margin-top: 6.25rem !important; }

.mr-6q1,
.mx-6q1 {
  margin-right: 6.25rem !important; }

.mb-6q1,
.my-6q1 {
  margin-bottom: 6.25rem !important; }

.ml-6q1,
.mx-6q1 {
  margin-left: 6.25rem !important; }

.m-6q2 {
  margin: 6.5rem !important; }

.mt-6q2,
.my-6q2 {
  margin-top: 6.5rem !important; }

.mr-6q2,
.mx-6q2 {
  margin-right: 6.5rem !important; }

.mb-6q2,
.my-6q2 {
  margin-bottom: 6.5rem !important; }

.ml-6q2,
.mx-6q2 {
  margin-left: 6.5rem !important; }

.m-6q3 {
  margin: 6.75rem !important; }

.mt-6q3,
.my-6q3 {
  margin-top: 6.75rem !important; }

.mr-6q3,
.mx-6q3 {
  margin-right: 6.75rem !important; }

.mb-6q3,
.my-6q3 {
  margin-bottom: 6.75rem !important; }

.ml-6q3,
.mx-6q3 {
  margin-left: 6.75rem !important; }

.m-7 {
  margin: 7rem !important; }

.mt-7,
.my-7 {
  margin-top: 7rem !important; }

.mr-7,
.mx-7 {
  margin-right: 7rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 7rem !important; }

.ml-7,
.mx-7 {
  margin-left: 7rem !important; }

.m-7q1 {
  margin: 7.25rem !important; }

.mt-7q1,
.my-7q1 {
  margin-top: 7.25rem !important; }

.mr-7q1,
.mx-7q1 {
  margin-right: 7.25rem !important; }

.mb-7q1,
.my-7q1 {
  margin-bottom: 7.25rem !important; }

.ml-7q1,
.mx-7q1 {
  margin-left: 7.25rem !important; }

.m-7q2 {
  margin: 7.5rem !important; }

.mt-7q2,
.my-7q2 {
  margin-top: 7.5rem !important; }

.mr-7q2,
.mx-7q2 {
  margin-right: 7.5rem !important; }

.mb-7q2,
.my-7q2 {
  margin-bottom: 7.5rem !important; }

.ml-7q2,
.mx-7q2 {
  margin-left: 7.5rem !important; }

.m-7q3 {
  margin: 7.75rem !important; }

.mt-7q3,
.my-7q3 {
  margin-top: 7.75rem !important; }

.mr-7q3,
.mx-7q3 {
  margin-right: 7.75rem !important; }

.mb-7q3,
.my-7q3 {
  margin-bottom: 7.75rem !important; }

.ml-7q3,
.mx-7q3 {
  margin-left: 7.75rem !important; }

.m-8 {
  margin: 8rem !important; }

.mt-8,
.my-8 {
  margin-top: 8rem !important; }

.mr-8,
.mx-8 {
  margin-right: 8rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 8rem !important; }

.ml-8,
.mx-8 {
  margin-left: 8rem !important; }

.m-8q1 {
  margin: 8.25rem !important; }

.mt-8q1,
.my-8q1 {
  margin-top: 8.25rem !important; }

.mr-8q1,
.mx-8q1 {
  margin-right: 8.25rem !important; }

.mb-8q1,
.my-8q1 {
  margin-bottom: 8.25rem !important; }

.ml-8q1,
.mx-8q1 {
  margin-left: 8.25rem !important; }

.m-8q2 {
  margin: 8.5rem !important; }

.mt-8q2,
.my-8q2 {
  margin-top: 8.5rem !important; }

.mr-8q2,
.mx-8q2 {
  margin-right: 8.5rem !important; }

.mb-8q2,
.my-8q2 {
  margin-bottom: 8.5rem !important; }

.ml-8q2,
.mx-8q2 {
  margin-left: 8.5rem !important; }

.m-8q3 {
  margin: 8.75rem !important; }

.mt-8q3,
.my-8q3 {
  margin-top: 8.75rem !important; }

.mr-8q3,
.mx-8q3 {
  margin-right: 8.75rem !important; }

.mb-8q3,
.my-8q3 {
  margin-bottom: 8.75rem !important; }

.ml-8q3,
.mx-8q3 {
  margin-left: 8.75rem !important; }

.m-9 {
  margin: 9rem !important; }

.mt-9,
.my-9 {
  margin-top: 9rem !important; }

.mr-9,
.mx-9 {
  margin-right: 9rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 9rem !important; }

.ml-9,
.mx-9 {
  margin-left: 9rem !important; }

.m-9q1 {
  margin: 9.25rem !important; }

.mt-9q1,
.my-9q1 {
  margin-top: 9.25rem !important; }

.mr-9q1,
.mx-9q1 {
  margin-right: 9.25rem !important; }

.mb-9q1,
.my-9q1 {
  margin-bottom: 9.25rem !important; }

.ml-9q1,
.mx-9q1 {
  margin-left: 9.25rem !important; }

.m-9q2 {
  margin: 9.5rem !important; }

.mt-9q2,
.my-9q2 {
  margin-top: 9.5rem !important; }

.mr-9q2,
.mx-9q2 {
  margin-right: 9.5rem !important; }

.mb-9q2,
.my-9q2 {
  margin-bottom: 9.5rem !important; }

.ml-9q2,
.mx-9q2 {
  margin-left: 9.5rem !important; }

.m-9q3 {
  margin: 9.75rem !important; }

.mt-9q3,
.my-9q3 {
  margin-top: 9.75rem !important; }

.mr-9q3,
.mx-9q3 {
  margin-right: 9.75rem !important; }

.mb-9q3,
.my-9q3 {
  margin-bottom: 9.75rem !important; }

.ml-9q3,
.mx-9q3 {
  margin-left: 9.75rem !important; }

.m-10 {
  margin: 10rem !important; }

.mt-10,
.my-10 {
  margin-top: 10rem !important; }

.mr-10,
.mx-10 {
  margin-right: 10rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 10rem !important; }

.ml-10,
.mx-10 {
  margin-left: 10rem !important; }

.m-10q1 {
  margin: 10.25rem !important; }

.mt-10q1,
.my-10q1 {
  margin-top: 10.25rem !important; }

.mr-10q1,
.mx-10q1 {
  margin-right: 10.25rem !important; }

.mb-10q1,
.my-10q1 {
  margin-bottom: 10.25rem !important; }

.ml-10q1,
.mx-10q1 {
  margin-left: 10.25rem !important; }

.m-10q2 {
  margin: 10.5rem !important; }

.mt-10q2,
.my-10q2 {
  margin-top: 10.5rem !important; }

.mr-10q2,
.mx-10q2 {
  margin-right: 10.5rem !important; }

.mb-10q2,
.my-10q2 {
  margin-bottom: 10.5rem !important; }

.ml-10q2,
.mx-10q2 {
  margin-left: 10.5rem !important; }

.m-10q3 {
  margin: 10.75rem !important; }

.mt-10q3,
.my-10q3 {
  margin-top: 10.75rem !important; }

.mr-10q3,
.mx-10q3 {
  margin-right: 10.75rem !important; }

.mb-10q3,
.my-10q3 {
  margin-bottom: 10.75rem !important; }

.ml-10q3,
.mx-10q3 {
  margin-left: 10.75rem !important; }

.m-11 {
  margin: 11rem !important; }

.mt-11,
.my-11 {
  margin-top: 11rem !important; }

.mr-11,
.mx-11 {
  margin-right: 11rem !important; }

.mb-11,
.my-11 {
  margin-bottom: 11rem !important; }

.ml-11,
.mx-11 {
  margin-left: 11rem !important; }

.m-11q1 {
  margin: 11.25rem !important; }

.mt-11q1,
.my-11q1 {
  margin-top: 11.25rem !important; }

.mr-11q1,
.mx-11q1 {
  margin-right: 11.25rem !important; }

.mb-11q1,
.my-11q1 {
  margin-bottom: 11.25rem !important; }

.ml-11q1,
.mx-11q1 {
  margin-left: 11.25rem !important; }

.m-11 q2 {
  margin: 11.5rem !important; }

.mt-11 q2,
.my-11 q2 {
  margin-top: 11.5rem !important; }

.mr-11 q2,
.mx-11 q2 {
  margin-right: 11.5rem !important; }

.mb-11 q2,
.my-11 q2 {
  margin-bottom: 11.5rem !important; }

.ml-11 q2,
.mx-11 q2 {
  margin-left: 11.5rem !important; }

.m-11 q3 {
  margin: 11.75rem !important; }

.mt-11 q3,
.my-11 q3 {
  margin-top: 11.75rem !important; }

.mr-11 q3,
.mx-11 q3 {
  margin-right: 11.75rem !important; }

.mb-11 q3,
.my-11 q3 {
  margin-bottom: 11.75rem !important; }

.ml-11 q3,
.mx-11 q3 {
  margin-left: 11.75rem !important; }

.m-12 {
  margin: 12rem !important; }

.mt-12,
.my-12 {
  margin-top: 12rem !important; }

.mr-12,
.mx-12 {
  margin-right: 12rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 12rem !important; }

.ml-12,
.mx-12 {
  margin-left: 12rem !important; }

.m-12 q1 {
  margin: 12.25rem !important; }

.mt-12 q1,
.my-12 q1 {
  margin-top: 12.25rem !important; }

.mr-12 q1,
.mx-12 q1 {
  margin-right: 12.25rem !important; }

.mb-12 q1,
.my-12 q1 {
  margin-bottom: 12.25rem !important; }

.ml-12 q1,
.mx-12 q1 {
  margin-left: 12.25rem !important; }

.m-12 q2 {
  margin: 12.5rem !important; }

.mt-12 q2,
.my-12 q2 {
  margin-top: 12.5rem !important; }

.mr-12 q2,
.mx-12 q2 {
  margin-right: 12.5rem !important; }

.mb-12 q2,
.my-12 q2 {
  margin-bottom: 12.5rem !important; }

.ml-12 q2,
.mx-12 q2 {
  margin-left: 12.5rem !important; }

.m-12 q3 {
  margin: 12.75rem !important; }

.mt-12 q3,
.my-12 q3 {
  margin-top: 12.75rem !important; }

.mr-12 q3,
.mx-12 q3 {
  margin-right: 12.75rem !important; }

.mb-12 q3,
.my-12 q3 {
  margin-bottom: 12.75rem !important; }

.ml-12 q3,
.mx-12 q3 {
  margin-left: 12.75rem !important; }

.p-q1 {
  padding: 0.25rem !important; }

.pt-q1,
.py-q1 {
  padding-top: 0.25rem !important; }

.pr-q1,
.px-q1 {
  padding-right: 0.25rem !important; }

.pb-q1,
.py-q1 {
  padding-bottom: 0.25rem !important; }

.pl-q1,
.px-q1 {
  padding-left: 0.25rem !important; }

.p-q2 {
  padding: 0.5rem !important; }

.pt-q2,
.py-q2 {
  padding-top: 0.5rem !important; }

.pr-q2,
.px-q2 {
  padding-right: 0.5rem !important; }

.pb-q2,
.py-q2 {
  padding-bottom: 0.5rem !important; }

.pl-q2,
.px-q2 {
  padding-left: 0.5rem !important; }

.p-q3 {
  padding: 0.75rem !important; }

.pt-q3,
.py-q3 {
  padding-top: 0.75rem !important; }

.pr-q3,
.px-q3 {
  padding-right: 0.75rem !important; }

.pb-q3,
.py-q3 {
  padding-bottom: 0.75rem !important; }

.pl-q3,
.px-q3 {
  padding-left: 0.75rem !important; }

.p-0 {
  padding: 0rem !important; }

.pt-0,
.py-0 {
  padding-top: 0rem !important; }

.pr-0,
.px-0 {
  padding-right: 0rem !important; }

.pb-0,
.py-0 {
  padding-bottom: 0rem !important; }

.pl-0,
.px-0 {
  padding-left: 0rem !important; }

.p-1 {
  padding: 1rem !important; }

.pt-1,
.py-1 {
  padding-top: 1rem !important; }

.pr-1,
.px-1 {
  padding-right: 1rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 1rem !important; }

.pl-1,
.px-1 {
  padding-left: 1rem !important; }

.p-1q1 {
  padding: 1.25rem !important; }

.pt-1q1,
.py-1q1 {
  padding-top: 1.25rem !important; }

.pr-1q1,
.px-1q1 {
  padding-right: 1.25rem !important; }

.pb-1q1,
.py-1q1 {
  padding-bottom: 1.25rem !important; }

.pl-1q1,
.px-1q1 {
  padding-left: 1.25rem !important; }

.p-1q2 {
  padding: 1.5rem !important; }

.pt-1q2,
.py-1q2 {
  padding-top: 1.5rem !important; }

.pr-1q2,
.px-1q2 {
  padding-right: 1.5rem !important; }

.pb-1q2,
.py-1q2 {
  padding-bottom: 1.5rem !important; }

.pl-1q2,
.px-1q2 {
  padding-left: 1.5rem !important; }

.p-1q3 {
  padding: 1.75rem !important; }

.pt-1q3,
.py-1q3 {
  padding-top: 1.75rem !important; }

.pr-1q3,
.px-1q3 {
  padding-right: 1.75rem !important; }

.pb-1q3,
.py-1q3 {
  padding-bottom: 1.75rem !important; }

.pl-1q3,
.px-1q3 {
  padding-left: 1.75rem !important; }

.p-2 {
  padding: 2rem !important; }

.pt-2,
.py-2 {
  padding-top: 2rem !important; }

.pr-2,
.px-2 {
  padding-right: 2rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 2rem !important; }

.pl-2,
.px-2 {
  padding-left: 2rem !important; }

.p-2q1 {
  padding: 2.25rem !important; }

.pt-2q1,
.py-2q1 {
  padding-top: 2.25rem !important; }

.pr-2q1,
.px-2q1 {
  padding-right: 2.25rem !important; }

.pb-2q1,
.py-2q1 {
  padding-bottom: 2.25rem !important; }

.pl-2q1,
.px-2q1 {
  padding-left: 2.25rem !important; }

.p-2q2 {
  padding: 2.5rem !important; }

.pt-2q2,
.py-2q2 {
  padding-top: 2.5rem !important; }

.pr-2q2,
.px-2q2 {
  padding-right: 2.5rem !important; }

.pb-2q2,
.py-2q2 {
  padding-bottom: 2.5rem !important; }

.pl-2q2,
.px-2q2 {
  padding-left: 2.5rem !important; }

.p-2q3 {
  padding: 2.75rem !important; }

.pt-2q3,
.py-2q3 {
  padding-top: 2.75rem !important; }

.pr-2q3,
.px-2q3 {
  padding-right: 2.75rem !important; }

.pb-2q3,
.py-2q3 {
  padding-bottom: 2.75rem !important; }

.pl-2q3,
.px-2q3 {
  padding-left: 2.75rem !important; }

.p-3 {
  padding: 3rem !important; }

.pt-3,
.py-3 {
  padding-top: 3rem !important; }

.pr-3,
.px-3 {
  padding-right: 3rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 3rem !important; }

.pl-3,
.px-3 {
  padding-left: 3rem !important; }

.p-3q1 {
  padding: 3.25rem !important; }

.pt-3q1,
.py-3q1 {
  padding-top: 3.25rem !important; }

.pr-3q1,
.px-3q1 {
  padding-right: 3.25rem !important; }

.pb-3q1,
.py-3q1 {
  padding-bottom: 3.25rem !important; }

.pl-3q1,
.px-3q1 {
  padding-left: 3.25rem !important; }

.p-3q2 {
  padding: 3.5rem !important; }

.pt-3q2,
.py-3q2 {
  padding-top: 3.5rem !important; }

.pr-3q2,
.px-3q2 {
  padding-right: 3.5rem !important; }

.pb-3q2,
.py-3q2 {
  padding-bottom: 3.5rem !important; }

.pl-3q2,
.px-3q2 {
  padding-left: 3.5rem !important; }

.p-3q3 {
  padding: 3.75rem !important; }

.pt-3q3,
.py-3q3 {
  padding-top: 3.75rem !important; }

.pr-3q3,
.px-3q3 {
  padding-right: 3.75rem !important; }

.pb-3q3,
.py-3q3 {
  padding-bottom: 3.75rem !important; }

.pl-3q3,
.px-3q3 {
  padding-left: 3.75rem !important; }

.p-4 {
  padding: 4rem !important; }

.pt-4,
.py-4 {
  padding-top: 4rem !important; }

.pr-4,
.px-4 {
  padding-right: 4rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 4rem !important; }

.pl-4,
.px-4 {
  padding-left: 4rem !important; }

.p-4q1 {
  padding: 4.25rem !important; }

.pt-4q1,
.py-4q1 {
  padding-top: 4.25rem !important; }

.pr-4q1,
.px-4q1 {
  padding-right: 4.25rem !important; }

.pb-4q1,
.py-4q1 {
  padding-bottom: 4.25rem !important; }

.pl-4q1,
.px-4q1 {
  padding-left: 4.25rem !important; }

.p-4q2 {
  padding: 4.5rem !important; }

.pt-4q2,
.py-4q2 {
  padding-top: 4.5rem !important; }

.pr-4q2,
.px-4q2 {
  padding-right: 4.5rem !important; }

.pb-4q2,
.py-4q2 {
  padding-bottom: 4.5rem !important; }

.pl-4q2,
.px-4q2 {
  padding-left: 4.5rem !important; }

.p-4q3 {
  padding: 4.75rem !important; }

.pt-4q3,
.py-4q3 {
  padding-top: 4.75rem !important; }

.pr-4q3,
.px-4q3 {
  padding-right: 4.75rem !important; }

.pb-4q3,
.py-4q3 {
  padding-bottom: 4.75rem !important; }

.pl-4q3,
.px-4q3 {
  padding-left: 4.75rem !important; }

.p-5 {
  padding: 5rem !important; }

.pt-5,
.py-5 {
  padding-top: 5rem !important; }

.pr-5,
.px-5 {
  padding-right: 5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 5rem !important; }

.pl-5,
.px-5 {
  padding-left: 5rem !important; }

.p-5q1 {
  padding: 5.25rem !important; }

.pt-5q1,
.py-5q1 {
  padding-top: 5.25rem !important; }

.pr-5q1,
.px-5q1 {
  padding-right: 5.25rem !important; }

.pb-5q1,
.py-5q1 {
  padding-bottom: 5.25rem !important; }

.pl-5q1,
.px-5q1 {
  padding-left: 5.25rem !important; }

.p-5q2 {
  padding: 5.5rem !important; }

.pt-5q2,
.py-5q2 {
  padding-top: 5.5rem !important; }

.pr-5q2,
.px-5q2 {
  padding-right: 5.5rem !important; }

.pb-5q2,
.py-5q2 {
  padding-bottom: 5.5rem !important; }

.pl-5q2,
.px-5q2 {
  padding-left: 5.5rem !important; }

.p-5q3 {
  padding: 5.75rem !important; }

.pt-5q3,
.py-5q3 {
  padding-top: 5.75rem !important; }

.pr-5q3,
.px-5q3 {
  padding-right: 5.75rem !important; }

.pb-5q3,
.py-5q3 {
  padding-bottom: 5.75rem !important; }

.pl-5q3,
.px-5q3 {
  padding-left: 5.75rem !important; }

.p-6 {
  padding: 6rem !important; }

.pt-6,
.py-6 {
  padding-top: 6rem !important; }

.pr-6,
.px-6 {
  padding-right: 6rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 6rem !important; }

.pl-6,
.px-6 {
  padding-left: 6rem !important; }

.p-6q1 {
  padding: 6.25rem !important; }

.pt-6q1,
.py-6q1 {
  padding-top: 6.25rem !important; }

.pr-6q1,
.px-6q1 {
  padding-right: 6.25rem !important; }

.pb-6q1,
.py-6q1 {
  padding-bottom: 6.25rem !important; }

.pl-6q1,
.px-6q1 {
  padding-left: 6.25rem !important; }

.p-6q2 {
  padding: 6.5rem !important; }

.pt-6q2,
.py-6q2 {
  padding-top: 6.5rem !important; }

.pr-6q2,
.px-6q2 {
  padding-right: 6.5rem !important; }

.pb-6q2,
.py-6q2 {
  padding-bottom: 6.5rem !important; }

.pl-6q2,
.px-6q2 {
  padding-left: 6.5rem !important; }

.p-6q3 {
  padding: 6.75rem !important; }

.pt-6q3,
.py-6q3 {
  padding-top: 6.75rem !important; }

.pr-6q3,
.px-6q3 {
  padding-right: 6.75rem !important; }

.pb-6q3,
.py-6q3 {
  padding-bottom: 6.75rem !important; }

.pl-6q3,
.px-6q3 {
  padding-left: 6.75rem !important; }

.p-7 {
  padding: 7rem !important; }

.pt-7,
.py-7 {
  padding-top: 7rem !important; }

.pr-7,
.px-7 {
  padding-right: 7rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 7rem !important; }

.pl-7,
.px-7 {
  padding-left: 7rem !important; }

.p-7q1 {
  padding: 7.25rem !important; }

.pt-7q1,
.py-7q1 {
  padding-top: 7.25rem !important; }

.pr-7q1,
.px-7q1 {
  padding-right: 7.25rem !important; }

.pb-7q1,
.py-7q1 {
  padding-bottom: 7.25rem !important; }

.pl-7q1,
.px-7q1 {
  padding-left: 7.25rem !important; }

.p-7q2 {
  padding: 7.5rem !important; }

.pt-7q2,
.py-7q2 {
  padding-top: 7.5rem !important; }

.pr-7q2,
.px-7q2 {
  padding-right: 7.5rem !important; }

.pb-7q2,
.py-7q2 {
  padding-bottom: 7.5rem !important; }

.pl-7q2,
.px-7q2 {
  padding-left: 7.5rem !important; }

.p-7q3 {
  padding: 7.75rem !important; }

.pt-7q3,
.py-7q3 {
  padding-top: 7.75rem !important; }

.pr-7q3,
.px-7q3 {
  padding-right: 7.75rem !important; }

.pb-7q3,
.py-7q3 {
  padding-bottom: 7.75rem !important; }

.pl-7q3,
.px-7q3 {
  padding-left: 7.75rem !important; }

.p-8 {
  padding: 8rem !important; }

.pt-8,
.py-8 {
  padding-top: 8rem !important; }

.pr-8,
.px-8 {
  padding-right: 8rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 8rem !important; }

.pl-8,
.px-8 {
  padding-left: 8rem !important; }

.p-8q1 {
  padding: 8.25rem !important; }

.pt-8q1,
.py-8q1 {
  padding-top: 8.25rem !important; }

.pr-8q1,
.px-8q1 {
  padding-right: 8.25rem !important; }

.pb-8q1,
.py-8q1 {
  padding-bottom: 8.25rem !important; }

.pl-8q1,
.px-8q1 {
  padding-left: 8.25rem !important; }

.p-8q2 {
  padding: 8.5rem !important; }

.pt-8q2,
.py-8q2 {
  padding-top: 8.5rem !important; }

.pr-8q2,
.px-8q2 {
  padding-right: 8.5rem !important; }

.pb-8q2,
.py-8q2 {
  padding-bottom: 8.5rem !important; }

.pl-8q2,
.px-8q2 {
  padding-left: 8.5rem !important; }

.p-8q3 {
  padding: 8.75rem !important; }

.pt-8q3,
.py-8q3 {
  padding-top: 8.75rem !important; }

.pr-8q3,
.px-8q3 {
  padding-right: 8.75rem !important; }

.pb-8q3,
.py-8q3 {
  padding-bottom: 8.75rem !important; }

.pl-8q3,
.px-8q3 {
  padding-left: 8.75rem !important; }

.p-9 {
  padding: 9rem !important; }

.pt-9,
.py-9 {
  padding-top: 9rem !important; }

.pr-9,
.px-9 {
  padding-right: 9rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 9rem !important; }

.pl-9,
.px-9 {
  padding-left: 9rem !important; }

.p-9q1 {
  padding: 9.25rem !important; }

.pt-9q1,
.py-9q1 {
  padding-top: 9.25rem !important; }

.pr-9q1,
.px-9q1 {
  padding-right: 9.25rem !important; }

.pb-9q1,
.py-9q1 {
  padding-bottom: 9.25rem !important; }

.pl-9q1,
.px-9q1 {
  padding-left: 9.25rem !important; }

.p-9q2 {
  padding: 9.5rem !important; }

.pt-9q2,
.py-9q2 {
  padding-top: 9.5rem !important; }

.pr-9q2,
.px-9q2 {
  padding-right: 9.5rem !important; }

.pb-9q2,
.py-9q2 {
  padding-bottom: 9.5rem !important; }

.pl-9q2,
.px-9q2 {
  padding-left: 9.5rem !important; }

.p-9q3 {
  padding: 9.75rem !important; }

.pt-9q3,
.py-9q3 {
  padding-top: 9.75rem !important; }

.pr-9q3,
.px-9q3 {
  padding-right: 9.75rem !important; }

.pb-9q3,
.py-9q3 {
  padding-bottom: 9.75rem !important; }

.pl-9q3,
.px-9q3 {
  padding-left: 9.75rem !important; }

.p-10 {
  padding: 10rem !important; }

.pt-10,
.py-10 {
  padding-top: 10rem !important; }

.pr-10,
.px-10 {
  padding-right: 10rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 10rem !important; }

.pl-10,
.px-10 {
  padding-left: 10rem !important; }

.p-10q1 {
  padding: 10.25rem !important; }

.pt-10q1,
.py-10q1 {
  padding-top: 10.25rem !important; }

.pr-10q1,
.px-10q1 {
  padding-right: 10.25rem !important; }

.pb-10q1,
.py-10q1 {
  padding-bottom: 10.25rem !important; }

.pl-10q1,
.px-10q1 {
  padding-left: 10.25rem !important; }

.p-10q2 {
  padding: 10.5rem !important; }

.pt-10q2,
.py-10q2 {
  padding-top: 10.5rem !important; }

.pr-10q2,
.px-10q2 {
  padding-right: 10.5rem !important; }

.pb-10q2,
.py-10q2 {
  padding-bottom: 10.5rem !important; }

.pl-10q2,
.px-10q2 {
  padding-left: 10.5rem !important; }

.p-10q3 {
  padding: 10.75rem !important; }

.pt-10q3,
.py-10q3 {
  padding-top: 10.75rem !important; }

.pr-10q3,
.px-10q3 {
  padding-right: 10.75rem !important; }

.pb-10q3,
.py-10q3 {
  padding-bottom: 10.75rem !important; }

.pl-10q3,
.px-10q3 {
  padding-left: 10.75rem !important; }

.p-11 {
  padding: 11rem !important; }

.pt-11,
.py-11 {
  padding-top: 11rem !important; }

.pr-11,
.px-11 {
  padding-right: 11rem !important; }

.pb-11,
.py-11 {
  padding-bottom: 11rem !important; }

.pl-11,
.px-11 {
  padding-left: 11rem !important; }

.p-11q1 {
  padding: 11.25rem !important; }

.pt-11q1,
.py-11q1 {
  padding-top: 11.25rem !important; }

.pr-11q1,
.px-11q1 {
  padding-right: 11.25rem !important; }

.pb-11q1,
.py-11q1 {
  padding-bottom: 11.25rem !important; }

.pl-11q1,
.px-11q1 {
  padding-left: 11.25rem !important; }

.p-11 q2 {
  padding: 11.5rem !important; }

.pt-11 q2,
.py-11 q2 {
  padding-top: 11.5rem !important; }

.pr-11 q2,
.px-11 q2 {
  padding-right: 11.5rem !important; }

.pb-11 q2,
.py-11 q2 {
  padding-bottom: 11.5rem !important; }

.pl-11 q2,
.px-11 q2 {
  padding-left: 11.5rem !important; }

.p-11 q3 {
  padding: 11.75rem !important; }

.pt-11 q3,
.py-11 q3 {
  padding-top: 11.75rem !important; }

.pr-11 q3,
.px-11 q3 {
  padding-right: 11.75rem !important; }

.pb-11 q3,
.py-11 q3 {
  padding-bottom: 11.75rem !important; }

.pl-11 q3,
.px-11 q3 {
  padding-left: 11.75rem !important; }

.p-12 {
  padding: 12rem !important; }

.pt-12,
.py-12 {
  padding-top: 12rem !important; }

.pr-12,
.px-12 {
  padding-right: 12rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 12rem !important; }

.pl-12,
.px-12 {
  padding-left: 12rem !important; }

.p-12 q1 {
  padding: 12.25rem !important; }

.pt-12 q1,
.py-12 q1 {
  padding-top: 12.25rem !important; }

.pr-12 q1,
.px-12 q1 {
  padding-right: 12.25rem !important; }

.pb-12 q1,
.py-12 q1 {
  padding-bottom: 12.25rem !important; }

.pl-12 q1,
.px-12 q1 {
  padding-left: 12.25rem !important; }

.p-12 q2 {
  padding: 12.5rem !important; }

.pt-12 q2,
.py-12 q2 {
  padding-top: 12.5rem !important; }

.pr-12 q2,
.px-12 q2 {
  padding-right: 12.5rem !important; }

.pb-12 q2,
.py-12 q2 {
  padding-bottom: 12.5rem !important; }

.pl-12 q2,
.px-12 q2 {
  padding-left: 12.5rem !important; }

.p-12 q3 {
  padding: 12.75rem !important; }

.pt-12 q3,
.py-12 q3 {
  padding-top: 12.75rem !important; }

.pr-12 q3,
.px-12 q3 {
  padding-right: 12.75rem !important; }

.pb-12 q3,
.py-12 q3 {
  padding-bottom: 12.75rem !important; }

.pl-12 q3,
.px-12 q3 {
  padding-left: 12.75rem !important; }

.m-nq1 {
  margin: -0.25rem !important; }

.mt-nq1,
.my-nq1 {
  margin-top: -0.25rem !important; }

.mr-nq1,
.mx-nq1 {
  margin-right: -0.25rem !important; }

.mb-nq1,
.my-nq1 {
  margin-bottom: -0.25rem !important; }

.ml-nq1,
.mx-nq1 {
  margin-left: -0.25rem !important; }

.m-nq2 {
  margin: -0.5rem !important; }

.mt-nq2,
.my-nq2 {
  margin-top: -0.5rem !important; }

.mr-nq2,
.mx-nq2 {
  margin-right: -0.5rem !important; }

.mb-nq2,
.my-nq2 {
  margin-bottom: -0.5rem !important; }

.ml-nq2,
.mx-nq2 {
  margin-left: -0.5rem !important; }

.m-nq3 {
  margin: -0.75rem !important; }

.mt-nq3,
.my-nq3 {
  margin-top: -0.75rem !important; }

.mr-nq3,
.mx-nq3 {
  margin-right: -0.75rem !important; }

.mb-nq3,
.my-nq3 {
  margin-bottom: -0.75rem !important; }

.ml-nq3,
.mx-nq3 {
  margin-left: -0.75rem !important; }

.m-n1 {
  margin: -1rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -1rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -1rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -1rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -1rem !important; }

.m-n1q1 {
  margin: -1.25rem !important; }

.mt-n1q1,
.my-n1q1 {
  margin-top: -1.25rem !important; }

.mr-n1q1,
.mx-n1q1 {
  margin-right: -1.25rem !important; }

.mb-n1q1,
.my-n1q1 {
  margin-bottom: -1.25rem !important; }

.ml-n1q1,
.mx-n1q1 {
  margin-left: -1.25rem !important; }

.m-n1q2 {
  margin: -1.5rem !important; }

.mt-n1q2,
.my-n1q2 {
  margin-top: -1.5rem !important; }

.mr-n1q2,
.mx-n1q2 {
  margin-right: -1.5rem !important; }

.mb-n1q2,
.my-n1q2 {
  margin-bottom: -1.5rem !important; }

.ml-n1q2,
.mx-n1q2 {
  margin-left: -1.5rem !important; }

.m-n1q3 {
  margin: -1.75rem !important; }

.mt-n1q3,
.my-n1q3 {
  margin-top: -1.75rem !important; }

.mr-n1q3,
.mx-n1q3 {
  margin-right: -1.75rem !important; }

.mb-n1q3,
.my-n1q3 {
  margin-bottom: -1.75rem !important; }

.ml-n1q3,
.mx-n1q3 {
  margin-left: -1.75rem !important; }

.m-n2 {
  margin: -2rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -2rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -2rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -2rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -2rem !important; }

.m-n2q1 {
  margin: -2.25rem !important; }

.mt-n2q1,
.my-n2q1 {
  margin-top: -2.25rem !important; }

.mr-n2q1,
.mx-n2q1 {
  margin-right: -2.25rem !important; }

.mb-n2q1,
.my-n2q1 {
  margin-bottom: -2.25rem !important; }

.ml-n2q1,
.mx-n2q1 {
  margin-left: -2.25rem !important; }

.m-n2q2 {
  margin: -2.5rem !important; }

.mt-n2q2,
.my-n2q2 {
  margin-top: -2.5rem !important; }

.mr-n2q2,
.mx-n2q2 {
  margin-right: -2.5rem !important; }

.mb-n2q2,
.my-n2q2 {
  margin-bottom: -2.5rem !important; }

.ml-n2q2,
.mx-n2q2 {
  margin-left: -2.5rem !important; }

.m-n2q3 {
  margin: -2.75rem !important; }

.mt-n2q3,
.my-n2q3 {
  margin-top: -2.75rem !important; }

.mr-n2q3,
.mx-n2q3 {
  margin-right: -2.75rem !important; }

.mb-n2q3,
.my-n2q3 {
  margin-bottom: -2.75rem !important; }

.ml-n2q3,
.mx-n2q3 {
  margin-left: -2.75rem !important; }

.m-n3 {
  margin: -3rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -3rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -3rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -3rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -3rem !important; }

.m-n3q1 {
  margin: -3.25rem !important; }

.mt-n3q1,
.my-n3q1 {
  margin-top: -3.25rem !important; }

.mr-n3q1,
.mx-n3q1 {
  margin-right: -3.25rem !important; }

.mb-n3q1,
.my-n3q1 {
  margin-bottom: -3.25rem !important; }

.ml-n3q1,
.mx-n3q1 {
  margin-left: -3.25rem !important; }

.m-n3q2 {
  margin: -3.5rem !important; }

.mt-n3q2,
.my-n3q2 {
  margin-top: -3.5rem !important; }

.mr-n3q2,
.mx-n3q2 {
  margin-right: -3.5rem !important; }

.mb-n3q2,
.my-n3q2 {
  margin-bottom: -3.5rem !important; }

.ml-n3q2,
.mx-n3q2 {
  margin-left: -3.5rem !important; }

.m-n3q3 {
  margin: -3.75rem !important; }

.mt-n3q3,
.my-n3q3 {
  margin-top: -3.75rem !important; }

.mr-n3q3,
.mx-n3q3 {
  margin-right: -3.75rem !important; }

.mb-n3q3,
.my-n3q3 {
  margin-bottom: -3.75rem !important; }

.ml-n3q3,
.mx-n3q3 {
  margin-left: -3.75rem !important; }

.m-n4 {
  margin: -4rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -4rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -4rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -4rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -4rem !important; }

.m-n4q1 {
  margin: -4.25rem !important; }

.mt-n4q1,
.my-n4q1 {
  margin-top: -4.25rem !important; }

.mr-n4q1,
.mx-n4q1 {
  margin-right: -4.25rem !important; }

.mb-n4q1,
.my-n4q1 {
  margin-bottom: -4.25rem !important; }

.ml-n4q1,
.mx-n4q1 {
  margin-left: -4.25rem !important; }

.m-n4q2 {
  margin: -4.5rem !important; }

.mt-n4q2,
.my-n4q2 {
  margin-top: -4.5rem !important; }

.mr-n4q2,
.mx-n4q2 {
  margin-right: -4.5rem !important; }

.mb-n4q2,
.my-n4q2 {
  margin-bottom: -4.5rem !important; }

.ml-n4q2,
.mx-n4q2 {
  margin-left: -4.5rem !important; }

.m-n4q3 {
  margin: -4.75rem !important; }

.mt-n4q3,
.my-n4q3 {
  margin-top: -4.75rem !important; }

.mr-n4q3,
.mx-n4q3 {
  margin-right: -4.75rem !important; }

.mb-n4q3,
.my-n4q3 {
  margin-bottom: -4.75rem !important; }

.ml-n4q3,
.mx-n4q3 {
  margin-left: -4.75rem !important; }

.m-n5 {
  margin: -5rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -5rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -5rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -5rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -5rem !important; }

.m-n5q1 {
  margin: -5.25rem !important; }

.mt-n5q1,
.my-n5q1 {
  margin-top: -5.25rem !important; }

.mr-n5q1,
.mx-n5q1 {
  margin-right: -5.25rem !important; }

.mb-n5q1,
.my-n5q1 {
  margin-bottom: -5.25rem !important; }

.ml-n5q1,
.mx-n5q1 {
  margin-left: -5.25rem !important; }

.m-n5q2 {
  margin: -5.5rem !important; }

.mt-n5q2,
.my-n5q2 {
  margin-top: -5.5rem !important; }

.mr-n5q2,
.mx-n5q2 {
  margin-right: -5.5rem !important; }

.mb-n5q2,
.my-n5q2 {
  margin-bottom: -5.5rem !important; }

.ml-n5q2,
.mx-n5q2 {
  margin-left: -5.5rem !important; }

.m-n5q3 {
  margin: -5.75rem !important; }

.mt-n5q3,
.my-n5q3 {
  margin-top: -5.75rem !important; }

.mr-n5q3,
.mx-n5q3 {
  margin-right: -5.75rem !important; }

.mb-n5q3,
.my-n5q3 {
  margin-bottom: -5.75rem !important; }

.ml-n5q3,
.mx-n5q3 {
  margin-left: -5.75rem !important; }

.m-n6 {
  margin: -6rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -6rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -6rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -6rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -6rem !important; }

.m-n6q1 {
  margin: -6.25rem !important; }

.mt-n6q1,
.my-n6q1 {
  margin-top: -6.25rem !important; }

.mr-n6q1,
.mx-n6q1 {
  margin-right: -6.25rem !important; }

.mb-n6q1,
.my-n6q1 {
  margin-bottom: -6.25rem !important; }

.ml-n6q1,
.mx-n6q1 {
  margin-left: -6.25rem !important; }

.m-n6q2 {
  margin: -6.5rem !important; }

.mt-n6q2,
.my-n6q2 {
  margin-top: -6.5rem !important; }

.mr-n6q2,
.mx-n6q2 {
  margin-right: -6.5rem !important; }

.mb-n6q2,
.my-n6q2 {
  margin-bottom: -6.5rem !important; }

.ml-n6q2,
.mx-n6q2 {
  margin-left: -6.5rem !important; }

.m-n6q3 {
  margin: -6.75rem !important; }

.mt-n6q3,
.my-n6q3 {
  margin-top: -6.75rem !important; }

.mr-n6q3,
.mx-n6q3 {
  margin-right: -6.75rem !important; }

.mb-n6q3,
.my-n6q3 {
  margin-bottom: -6.75rem !important; }

.ml-n6q3,
.mx-n6q3 {
  margin-left: -6.75rem !important; }

.m-n7 {
  margin: -7rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -7rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -7rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -7rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -7rem !important; }

.m-n7q1 {
  margin: -7.25rem !important; }

.mt-n7q1,
.my-n7q1 {
  margin-top: -7.25rem !important; }

.mr-n7q1,
.mx-n7q1 {
  margin-right: -7.25rem !important; }

.mb-n7q1,
.my-n7q1 {
  margin-bottom: -7.25rem !important; }

.ml-n7q1,
.mx-n7q1 {
  margin-left: -7.25rem !important; }

.m-n7q2 {
  margin: -7.5rem !important; }

.mt-n7q2,
.my-n7q2 {
  margin-top: -7.5rem !important; }

.mr-n7q2,
.mx-n7q2 {
  margin-right: -7.5rem !important; }

.mb-n7q2,
.my-n7q2 {
  margin-bottom: -7.5rem !important; }

.ml-n7q2,
.mx-n7q2 {
  margin-left: -7.5rem !important; }

.m-n7q3 {
  margin: -7.75rem !important; }

.mt-n7q3,
.my-n7q3 {
  margin-top: -7.75rem !important; }

.mr-n7q3,
.mx-n7q3 {
  margin-right: -7.75rem !important; }

.mb-n7q3,
.my-n7q3 {
  margin-bottom: -7.75rem !important; }

.ml-n7q3,
.mx-n7q3 {
  margin-left: -7.75rem !important; }

.m-n8 {
  margin: -8rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -8rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -8rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -8rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -8rem !important; }

.m-n8q1 {
  margin: -8.25rem !important; }

.mt-n8q1,
.my-n8q1 {
  margin-top: -8.25rem !important; }

.mr-n8q1,
.mx-n8q1 {
  margin-right: -8.25rem !important; }

.mb-n8q1,
.my-n8q1 {
  margin-bottom: -8.25rem !important; }

.ml-n8q1,
.mx-n8q1 {
  margin-left: -8.25rem !important; }

.m-n8q2 {
  margin: -8.5rem !important; }

.mt-n8q2,
.my-n8q2 {
  margin-top: -8.5rem !important; }

.mr-n8q2,
.mx-n8q2 {
  margin-right: -8.5rem !important; }

.mb-n8q2,
.my-n8q2 {
  margin-bottom: -8.5rem !important; }

.ml-n8q2,
.mx-n8q2 {
  margin-left: -8.5rem !important; }

.m-n8q3 {
  margin: -8.75rem !important; }

.mt-n8q3,
.my-n8q3 {
  margin-top: -8.75rem !important; }

.mr-n8q3,
.mx-n8q3 {
  margin-right: -8.75rem !important; }

.mb-n8q3,
.my-n8q3 {
  margin-bottom: -8.75rem !important; }

.ml-n8q3,
.mx-n8q3 {
  margin-left: -8.75rem !important; }

.m-n9 {
  margin: -9rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -9rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -9rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -9rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -9rem !important; }

.m-n9q1 {
  margin: -9.25rem !important; }

.mt-n9q1,
.my-n9q1 {
  margin-top: -9.25rem !important; }

.mr-n9q1,
.mx-n9q1 {
  margin-right: -9.25rem !important; }

.mb-n9q1,
.my-n9q1 {
  margin-bottom: -9.25rem !important; }

.ml-n9q1,
.mx-n9q1 {
  margin-left: -9.25rem !important; }

.m-n9q2 {
  margin: -9.5rem !important; }

.mt-n9q2,
.my-n9q2 {
  margin-top: -9.5rem !important; }

.mr-n9q2,
.mx-n9q2 {
  margin-right: -9.5rem !important; }

.mb-n9q2,
.my-n9q2 {
  margin-bottom: -9.5rem !important; }

.ml-n9q2,
.mx-n9q2 {
  margin-left: -9.5rem !important; }

.m-n9q3 {
  margin: -9.75rem !important; }

.mt-n9q3,
.my-n9q3 {
  margin-top: -9.75rem !important; }

.mr-n9q3,
.mx-n9q3 {
  margin-right: -9.75rem !important; }

.mb-n9q3,
.my-n9q3 {
  margin-bottom: -9.75rem !important; }

.ml-n9q3,
.mx-n9q3 {
  margin-left: -9.75rem !important; }

.m-n10 {
  margin: -10rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -10rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -10rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -10rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -10rem !important; }

.m-n10q1 {
  margin: -10.25rem !important; }

.mt-n10q1,
.my-n10q1 {
  margin-top: -10.25rem !important; }

.mr-n10q1,
.mx-n10q1 {
  margin-right: -10.25rem !important; }

.mb-n10q1,
.my-n10q1 {
  margin-bottom: -10.25rem !important; }

.ml-n10q1,
.mx-n10q1 {
  margin-left: -10.25rem !important; }

.m-n10q2 {
  margin: -10.5rem !important; }

.mt-n10q2,
.my-n10q2 {
  margin-top: -10.5rem !important; }

.mr-n10q2,
.mx-n10q2 {
  margin-right: -10.5rem !important; }

.mb-n10q2,
.my-n10q2 {
  margin-bottom: -10.5rem !important; }

.ml-n10q2,
.mx-n10q2 {
  margin-left: -10.5rem !important; }

.m-n10q3 {
  margin: -10.75rem !important; }

.mt-n10q3,
.my-n10q3 {
  margin-top: -10.75rem !important; }

.mr-n10q3,
.mx-n10q3 {
  margin-right: -10.75rem !important; }

.mb-n10q3,
.my-n10q3 {
  margin-bottom: -10.75rem !important; }

.ml-n10q3,
.mx-n10q3 {
  margin-left: -10.75rem !important; }

.m-n11 {
  margin: -11rem !important; }

.mt-n11,
.my-n11 {
  margin-top: -11rem !important; }

.mr-n11,
.mx-n11 {
  margin-right: -11rem !important; }

.mb-n11,
.my-n11 {
  margin-bottom: -11rem !important; }

.ml-n11,
.mx-n11 {
  margin-left: -11rem !important; }

.m-n11q1 {
  margin: -11.25rem !important; }

.mt-n11q1,
.my-n11q1 {
  margin-top: -11.25rem !important; }

.mr-n11q1,
.mx-n11q1 {
  margin-right: -11.25rem !important; }

.mb-n11q1,
.my-n11q1 {
  margin-bottom: -11.25rem !important; }

.ml-n11q1,
.mx-n11q1 {
  margin-left: -11.25rem !important; }

.m-n11 q2 {
  margin: -11.5rem !important; }

.mt-n11 q2,
.my-n11 q2 {
  margin-top: -11.5rem !important; }

.mr-n11 q2,
.mx-n11 q2 {
  margin-right: -11.5rem !important; }

.mb-n11 q2,
.my-n11 q2 {
  margin-bottom: -11.5rem !important; }

.ml-n11 q2,
.mx-n11 q2 {
  margin-left: -11.5rem !important; }

.m-n11 q3 {
  margin: -11.75rem !important; }

.mt-n11 q3,
.my-n11 q3 {
  margin-top: -11.75rem !important; }

.mr-n11 q3,
.mx-n11 q3 {
  margin-right: -11.75rem !important; }

.mb-n11 q3,
.my-n11 q3 {
  margin-bottom: -11.75rem !important; }

.ml-n11 q3,
.mx-n11 q3 {
  margin-left: -11.75rem !important; }

.m-n12 {
  margin: -12rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -12rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -12rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -12rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -12rem !important; }

.m-n12 q1 {
  margin: -12.25rem !important; }

.mt-n12 q1,
.my-n12 q1 {
  margin-top: -12.25rem !important; }

.mr-n12 q1,
.mx-n12 q1 {
  margin-right: -12.25rem !important; }

.mb-n12 q1,
.my-n12 q1 {
  margin-bottom: -12.25rem !important; }

.ml-n12 q1,
.mx-n12 q1 {
  margin-left: -12.25rem !important; }

.m-n12 q2 {
  margin: -12.5rem !important; }

.mt-n12 q2,
.my-n12 q2 {
  margin-top: -12.5rem !important; }

.mr-n12 q2,
.mx-n12 q2 {
  margin-right: -12.5rem !important; }

.mb-n12 q2,
.my-n12 q2 {
  margin-bottom: -12.5rem !important; }

.ml-n12 q2,
.mx-n12 q2 {
  margin-left: -12.5rem !important; }

.m-n12 q3 {
  margin: -12.75rem !important; }

.mt-n12 q3,
.my-n12 q3 {
  margin-top: -12.75rem !important; }

.mr-n12 q3,
.mx-n12 q3 {
  margin-right: -12.75rem !important; }

.mb-n12 q3,
.my-n12 q3 {
  margin-bottom: -12.75rem !important; }

.ml-n12 q3,
.mx-n12 q3 {
  margin-left: -12.75rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-q1 {
    margin: 0.25rem !important; }
  .mt-sm-q1,
  .my-sm-q1 {
    margin-top: 0.25rem !important; }
  .mr-sm-q1,
  .mx-sm-q1 {
    margin-right: 0.25rem !important; }
  .mb-sm-q1,
  .my-sm-q1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-q1,
  .mx-sm-q1 {
    margin-left: 0.25rem !important; }
  .m-sm-q2 {
    margin: 0.5rem !important; }
  .mt-sm-q2,
  .my-sm-q2 {
    margin-top: 0.5rem !important; }
  .mr-sm-q2,
  .mx-sm-q2 {
    margin-right: 0.5rem !important; }
  .mb-sm-q2,
  .my-sm-q2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-q2,
  .mx-sm-q2 {
    margin-left: 0.5rem !important; }
  .m-sm-q3 {
    margin: 0.75rem !important; }
  .mt-sm-q3,
  .my-sm-q3 {
    margin-top: 0.75rem !important; }
  .mr-sm-q3,
  .mx-sm-q3 {
    margin-right: 0.75rem !important; }
  .mb-sm-q3,
  .my-sm-q3 {
    margin-bottom: 0.75rem !important; }
  .ml-sm-q3,
  .mx-sm-q3 {
    margin-left: 0.75rem !important; }
  .m-sm-0 {
    margin: 0rem !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0rem !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0rem !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0rem !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0rem !important; }
  .m-sm-1 {
    margin: 1rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 1rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 1rem !important; }
  .m-sm-1q1 {
    margin: 1.25rem !important; }
  .mt-sm-1q1,
  .my-sm-1q1 {
    margin-top: 1.25rem !important; }
  .mr-sm-1q1,
  .mx-sm-1q1 {
    margin-right: 1.25rem !important; }
  .mb-sm-1q1,
  .my-sm-1q1 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-1q1,
  .mx-sm-1q1 {
    margin-left: 1.25rem !important; }
  .m-sm-1q2 {
    margin: 1.5rem !important; }
  .mt-sm-1q2,
  .my-sm-1q2 {
    margin-top: 1.5rem !important; }
  .mr-sm-1q2,
  .mx-sm-1q2 {
    margin-right: 1.5rem !important; }
  .mb-sm-1q2,
  .my-sm-1q2 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-1q2,
  .mx-sm-1q2 {
    margin-left: 1.5rem !important; }
  .m-sm-1q3 {
    margin: 1.75rem !important; }
  .mt-sm-1q3,
  .my-sm-1q3 {
    margin-top: 1.75rem !important; }
  .mr-sm-1q3,
  .mx-sm-1q3 {
    margin-right: 1.75rem !important; }
  .mb-sm-1q3,
  .my-sm-1q3 {
    margin-bottom: 1.75rem !important; }
  .ml-sm-1q3,
  .mx-sm-1q3 {
    margin-left: 1.75rem !important; }
  .m-sm-2 {
    margin: 2rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 2rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 2rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 2rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 2rem !important; }
  .m-sm-2q1 {
    margin: 2.25rem !important; }
  .mt-sm-2q1,
  .my-sm-2q1 {
    margin-top: 2.25rem !important; }
  .mr-sm-2q1,
  .mx-sm-2q1 {
    margin-right: 2.25rem !important; }
  .mb-sm-2q1,
  .my-sm-2q1 {
    margin-bottom: 2.25rem !important; }
  .ml-sm-2q1,
  .mx-sm-2q1 {
    margin-left: 2.25rem !important; }
  .m-sm-2q2 {
    margin: 2.5rem !important; }
  .mt-sm-2q2,
  .my-sm-2q2 {
    margin-top: 2.5rem !important; }
  .mr-sm-2q2,
  .mx-sm-2q2 {
    margin-right: 2.5rem !important; }
  .mb-sm-2q2,
  .my-sm-2q2 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-2q2,
  .mx-sm-2q2 {
    margin-left: 2.5rem !important; }
  .m-sm-2q3 {
    margin: 2.75rem !important; }
  .mt-sm-2q3,
  .my-sm-2q3 {
    margin-top: 2.75rem !important; }
  .mr-sm-2q3,
  .mx-sm-2q3 {
    margin-right: 2.75rem !important; }
  .mb-sm-2q3,
  .my-sm-2q3 {
    margin-bottom: 2.75rem !important; }
  .ml-sm-2q3,
  .mx-sm-2q3 {
    margin-left: 2.75rem !important; }
  .m-sm-3 {
    margin: 3rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 3rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 3rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 3rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 3rem !important; }
  .m-sm-3q1 {
    margin: 3.25rem !important; }
  .mt-sm-3q1,
  .my-sm-3q1 {
    margin-top: 3.25rem !important; }
  .mr-sm-3q1,
  .mx-sm-3q1 {
    margin-right: 3.25rem !important; }
  .mb-sm-3q1,
  .my-sm-3q1 {
    margin-bottom: 3.25rem !important; }
  .ml-sm-3q1,
  .mx-sm-3q1 {
    margin-left: 3.25rem !important; }
  .m-sm-3q2 {
    margin: 3.5rem !important; }
  .mt-sm-3q2,
  .my-sm-3q2 {
    margin-top: 3.5rem !important; }
  .mr-sm-3q2,
  .mx-sm-3q2 {
    margin-right: 3.5rem !important; }
  .mb-sm-3q2,
  .my-sm-3q2 {
    margin-bottom: 3.5rem !important; }
  .ml-sm-3q2,
  .mx-sm-3q2 {
    margin-left: 3.5rem !important; }
  .m-sm-3q3 {
    margin: 3.75rem !important; }
  .mt-sm-3q3,
  .my-sm-3q3 {
    margin-top: 3.75rem !important; }
  .mr-sm-3q3,
  .mx-sm-3q3 {
    margin-right: 3.75rem !important; }
  .mb-sm-3q3,
  .my-sm-3q3 {
    margin-bottom: 3.75rem !important; }
  .ml-sm-3q3,
  .mx-sm-3q3 {
    margin-left: 3.75rem !important; }
  .m-sm-4 {
    margin: 4rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 4rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 4rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 4rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 4rem !important; }
  .m-sm-4q1 {
    margin: 4.25rem !important; }
  .mt-sm-4q1,
  .my-sm-4q1 {
    margin-top: 4.25rem !important; }
  .mr-sm-4q1,
  .mx-sm-4q1 {
    margin-right: 4.25rem !important; }
  .mb-sm-4q1,
  .my-sm-4q1 {
    margin-bottom: 4.25rem !important; }
  .ml-sm-4q1,
  .mx-sm-4q1 {
    margin-left: 4.25rem !important; }
  .m-sm-4q2 {
    margin: 4.5rem !important; }
  .mt-sm-4q2,
  .my-sm-4q2 {
    margin-top: 4.5rem !important; }
  .mr-sm-4q2,
  .mx-sm-4q2 {
    margin-right: 4.5rem !important; }
  .mb-sm-4q2,
  .my-sm-4q2 {
    margin-bottom: 4.5rem !important; }
  .ml-sm-4q2,
  .mx-sm-4q2 {
    margin-left: 4.5rem !important; }
  .m-sm-4q3 {
    margin: 4.75rem !important; }
  .mt-sm-4q3,
  .my-sm-4q3 {
    margin-top: 4.75rem !important; }
  .mr-sm-4q3,
  .mx-sm-4q3 {
    margin-right: 4.75rem !important; }
  .mb-sm-4q3,
  .my-sm-4q3 {
    margin-bottom: 4.75rem !important; }
  .ml-sm-4q3,
  .mx-sm-4q3 {
    margin-left: 4.75rem !important; }
  .m-sm-5 {
    margin: 5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5rem !important; }
  .m-sm-5q1 {
    margin: 5.25rem !important; }
  .mt-sm-5q1,
  .my-sm-5q1 {
    margin-top: 5.25rem !important; }
  .mr-sm-5q1,
  .mx-sm-5q1 {
    margin-right: 5.25rem !important; }
  .mb-sm-5q1,
  .my-sm-5q1 {
    margin-bottom: 5.25rem !important; }
  .ml-sm-5q1,
  .mx-sm-5q1 {
    margin-left: 5.25rem !important; }
  .m-sm-5q2 {
    margin: 5.5rem !important; }
  .mt-sm-5q2,
  .my-sm-5q2 {
    margin-top: 5.5rem !important; }
  .mr-sm-5q2,
  .mx-sm-5q2 {
    margin-right: 5.5rem !important; }
  .mb-sm-5q2,
  .my-sm-5q2 {
    margin-bottom: 5.5rem !important; }
  .ml-sm-5q2,
  .mx-sm-5q2 {
    margin-left: 5.5rem !important; }
  .m-sm-5q3 {
    margin: 5.75rem !important; }
  .mt-sm-5q3,
  .my-sm-5q3 {
    margin-top: 5.75rem !important; }
  .mr-sm-5q3,
  .mx-sm-5q3 {
    margin-right: 5.75rem !important; }
  .mb-sm-5q3,
  .my-sm-5q3 {
    margin-bottom: 5.75rem !important; }
  .ml-sm-5q3,
  .mx-sm-5q3 {
    margin-left: 5.75rem !important; }
  .m-sm-6 {
    margin: 6rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 6rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 6rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 6rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 6rem !important; }
  .m-sm-6q1 {
    margin: 6.25rem !important; }
  .mt-sm-6q1,
  .my-sm-6q1 {
    margin-top: 6.25rem !important; }
  .mr-sm-6q1,
  .mx-sm-6q1 {
    margin-right: 6.25rem !important; }
  .mb-sm-6q1,
  .my-sm-6q1 {
    margin-bottom: 6.25rem !important; }
  .ml-sm-6q1,
  .mx-sm-6q1 {
    margin-left: 6.25rem !important; }
  .m-sm-6q2 {
    margin: 6.5rem !important; }
  .mt-sm-6q2,
  .my-sm-6q2 {
    margin-top: 6.5rem !important; }
  .mr-sm-6q2,
  .mx-sm-6q2 {
    margin-right: 6.5rem !important; }
  .mb-sm-6q2,
  .my-sm-6q2 {
    margin-bottom: 6.5rem !important; }
  .ml-sm-6q2,
  .mx-sm-6q2 {
    margin-left: 6.5rem !important; }
  .m-sm-6q3 {
    margin: 6.75rem !important; }
  .mt-sm-6q3,
  .my-sm-6q3 {
    margin-top: 6.75rem !important; }
  .mr-sm-6q3,
  .mx-sm-6q3 {
    margin-right: 6.75rem !important; }
  .mb-sm-6q3,
  .my-sm-6q3 {
    margin-bottom: 6.75rem !important; }
  .ml-sm-6q3,
  .mx-sm-6q3 {
    margin-left: 6.75rem !important; }
  .m-sm-7 {
    margin: 7rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 7rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 7rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 7rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 7rem !important; }
  .m-sm-7q1 {
    margin: 7.25rem !important; }
  .mt-sm-7q1,
  .my-sm-7q1 {
    margin-top: 7.25rem !important; }
  .mr-sm-7q1,
  .mx-sm-7q1 {
    margin-right: 7.25rem !important; }
  .mb-sm-7q1,
  .my-sm-7q1 {
    margin-bottom: 7.25rem !important; }
  .ml-sm-7q1,
  .mx-sm-7q1 {
    margin-left: 7.25rem !important; }
  .m-sm-7q2 {
    margin: 7.5rem !important; }
  .mt-sm-7q2,
  .my-sm-7q2 {
    margin-top: 7.5rem !important; }
  .mr-sm-7q2,
  .mx-sm-7q2 {
    margin-right: 7.5rem !important; }
  .mb-sm-7q2,
  .my-sm-7q2 {
    margin-bottom: 7.5rem !important; }
  .ml-sm-7q2,
  .mx-sm-7q2 {
    margin-left: 7.5rem !important; }
  .m-sm-7q3 {
    margin: 7.75rem !important; }
  .mt-sm-7q3,
  .my-sm-7q3 {
    margin-top: 7.75rem !important; }
  .mr-sm-7q3,
  .mx-sm-7q3 {
    margin-right: 7.75rem !important; }
  .mb-sm-7q3,
  .my-sm-7q3 {
    margin-bottom: 7.75rem !important; }
  .ml-sm-7q3,
  .mx-sm-7q3 {
    margin-left: 7.75rem !important; }
  .m-sm-8 {
    margin: 8rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 8rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 8rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 8rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 8rem !important; }
  .m-sm-8q1 {
    margin: 8.25rem !important; }
  .mt-sm-8q1,
  .my-sm-8q1 {
    margin-top: 8.25rem !important; }
  .mr-sm-8q1,
  .mx-sm-8q1 {
    margin-right: 8.25rem !important; }
  .mb-sm-8q1,
  .my-sm-8q1 {
    margin-bottom: 8.25rem !important; }
  .ml-sm-8q1,
  .mx-sm-8q1 {
    margin-left: 8.25rem !important; }
  .m-sm-8q2 {
    margin: 8.5rem !important; }
  .mt-sm-8q2,
  .my-sm-8q2 {
    margin-top: 8.5rem !important; }
  .mr-sm-8q2,
  .mx-sm-8q2 {
    margin-right: 8.5rem !important; }
  .mb-sm-8q2,
  .my-sm-8q2 {
    margin-bottom: 8.5rem !important; }
  .ml-sm-8q2,
  .mx-sm-8q2 {
    margin-left: 8.5rem !important; }
  .m-sm-8q3 {
    margin: 8.75rem !important; }
  .mt-sm-8q3,
  .my-sm-8q3 {
    margin-top: 8.75rem !important; }
  .mr-sm-8q3,
  .mx-sm-8q3 {
    margin-right: 8.75rem !important; }
  .mb-sm-8q3,
  .my-sm-8q3 {
    margin-bottom: 8.75rem !important; }
  .ml-sm-8q3,
  .mx-sm-8q3 {
    margin-left: 8.75rem !important; }
  .m-sm-9 {
    margin: 9rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 9rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 9rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 9rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 9rem !important; }
  .m-sm-9q1 {
    margin: 9.25rem !important; }
  .mt-sm-9q1,
  .my-sm-9q1 {
    margin-top: 9.25rem !important; }
  .mr-sm-9q1,
  .mx-sm-9q1 {
    margin-right: 9.25rem !important; }
  .mb-sm-9q1,
  .my-sm-9q1 {
    margin-bottom: 9.25rem !important; }
  .ml-sm-9q1,
  .mx-sm-9q1 {
    margin-left: 9.25rem !important; }
  .m-sm-9q2 {
    margin: 9.5rem !important; }
  .mt-sm-9q2,
  .my-sm-9q2 {
    margin-top: 9.5rem !important; }
  .mr-sm-9q2,
  .mx-sm-9q2 {
    margin-right: 9.5rem !important; }
  .mb-sm-9q2,
  .my-sm-9q2 {
    margin-bottom: 9.5rem !important; }
  .ml-sm-9q2,
  .mx-sm-9q2 {
    margin-left: 9.5rem !important; }
  .m-sm-9q3 {
    margin: 9.75rem !important; }
  .mt-sm-9q3,
  .my-sm-9q3 {
    margin-top: 9.75rem !important; }
  .mr-sm-9q3,
  .mx-sm-9q3 {
    margin-right: 9.75rem !important; }
  .mb-sm-9q3,
  .my-sm-9q3 {
    margin-bottom: 9.75rem !important; }
  .ml-sm-9q3,
  .mx-sm-9q3 {
    margin-left: 9.75rem !important; }
  .m-sm-10 {
    margin: 10rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10rem !important; }
  .m-sm-10q1 {
    margin: 10.25rem !important; }
  .mt-sm-10q1,
  .my-sm-10q1 {
    margin-top: 10.25rem !important; }
  .mr-sm-10q1,
  .mx-sm-10q1 {
    margin-right: 10.25rem !important; }
  .mb-sm-10q1,
  .my-sm-10q1 {
    margin-bottom: 10.25rem !important; }
  .ml-sm-10q1,
  .mx-sm-10q1 {
    margin-left: 10.25rem !important; }
  .m-sm-10q2 {
    margin: 10.5rem !important; }
  .mt-sm-10q2,
  .my-sm-10q2 {
    margin-top: 10.5rem !important; }
  .mr-sm-10q2,
  .mx-sm-10q2 {
    margin-right: 10.5rem !important; }
  .mb-sm-10q2,
  .my-sm-10q2 {
    margin-bottom: 10.5rem !important; }
  .ml-sm-10q2,
  .mx-sm-10q2 {
    margin-left: 10.5rem !important; }
  .m-sm-10q3 {
    margin: 10.75rem !important; }
  .mt-sm-10q3,
  .my-sm-10q3 {
    margin-top: 10.75rem !important; }
  .mr-sm-10q3,
  .mx-sm-10q3 {
    margin-right: 10.75rem !important; }
  .mb-sm-10q3,
  .my-sm-10q3 {
    margin-bottom: 10.75rem !important; }
  .ml-sm-10q3,
  .mx-sm-10q3 {
    margin-left: 10.75rem !important; }
  .m-sm-11 {
    margin: 11rem !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 11rem !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 11rem !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 11rem !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 11rem !important; }
  .m-sm-11q1 {
    margin: 11.25rem !important; }
  .mt-sm-11q1,
  .my-sm-11q1 {
    margin-top: 11.25rem !important; }
  .mr-sm-11q1,
  .mx-sm-11q1 {
    margin-right: 11.25rem !important; }
  .mb-sm-11q1,
  .my-sm-11q1 {
    margin-bottom: 11.25rem !important; }
  .ml-sm-11q1,
  .mx-sm-11q1 {
    margin-left: 11.25rem !important; }
  .m-sm-11 q2 {
    margin: 11.5rem !important; }
  .mt-sm-11 q2,
  .my-sm-11 q2 {
    margin-top: 11.5rem !important; }
  .mr-sm-11 q2,
  .mx-sm-11 q2 {
    margin-right: 11.5rem !important; }
  .mb-sm-11 q2,
  .my-sm-11 q2 {
    margin-bottom: 11.5rem !important; }
  .ml-sm-11 q2,
  .mx-sm-11 q2 {
    margin-left: 11.5rem !important; }
  .m-sm-11 q3 {
    margin: 11.75rem !important; }
  .mt-sm-11 q3,
  .my-sm-11 q3 {
    margin-top: 11.75rem !important; }
  .mr-sm-11 q3,
  .mx-sm-11 q3 {
    margin-right: 11.75rem !important; }
  .mb-sm-11 q3,
  .my-sm-11 q3 {
    margin-bottom: 11.75rem !important; }
  .ml-sm-11 q3,
  .mx-sm-11 q3 {
    margin-left: 11.75rem !important; }
  .m-sm-12 {
    margin: 12rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 12rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 12rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 12rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 12rem !important; }
  .m-sm-12 q1 {
    margin: 12.25rem !important; }
  .mt-sm-12 q1,
  .my-sm-12 q1 {
    margin-top: 12.25rem !important; }
  .mr-sm-12 q1,
  .mx-sm-12 q1 {
    margin-right: 12.25rem !important; }
  .mb-sm-12 q1,
  .my-sm-12 q1 {
    margin-bottom: 12.25rem !important; }
  .ml-sm-12 q1,
  .mx-sm-12 q1 {
    margin-left: 12.25rem !important; }
  .m-sm-12 q2 {
    margin: 12.5rem !important; }
  .mt-sm-12 q2,
  .my-sm-12 q2 {
    margin-top: 12.5rem !important; }
  .mr-sm-12 q2,
  .mx-sm-12 q2 {
    margin-right: 12.5rem !important; }
  .mb-sm-12 q2,
  .my-sm-12 q2 {
    margin-bottom: 12.5rem !important; }
  .ml-sm-12 q2,
  .mx-sm-12 q2 {
    margin-left: 12.5rem !important; }
  .m-sm-12 q3 {
    margin: 12.75rem !important; }
  .mt-sm-12 q3,
  .my-sm-12 q3 {
    margin-top: 12.75rem !important; }
  .mr-sm-12 q3,
  .mx-sm-12 q3 {
    margin-right: 12.75rem !important; }
  .mb-sm-12 q3,
  .my-sm-12 q3 {
    margin-bottom: 12.75rem !important; }
  .ml-sm-12 q3,
  .mx-sm-12 q3 {
    margin-left: 12.75rem !important; }
  .p-sm-q1 {
    padding: 0.25rem !important; }
  .pt-sm-q1,
  .py-sm-q1 {
    padding-top: 0.25rem !important; }
  .pr-sm-q1,
  .px-sm-q1 {
    padding-right: 0.25rem !important; }
  .pb-sm-q1,
  .py-sm-q1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-q1,
  .px-sm-q1 {
    padding-left: 0.25rem !important; }
  .p-sm-q2 {
    padding: 0.5rem !important; }
  .pt-sm-q2,
  .py-sm-q2 {
    padding-top: 0.5rem !important; }
  .pr-sm-q2,
  .px-sm-q2 {
    padding-right: 0.5rem !important; }
  .pb-sm-q2,
  .py-sm-q2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-q2,
  .px-sm-q2 {
    padding-left: 0.5rem !important; }
  .p-sm-q3 {
    padding: 0.75rem !important; }
  .pt-sm-q3,
  .py-sm-q3 {
    padding-top: 0.75rem !important; }
  .pr-sm-q3,
  .px-sm-q3 {
    padding-right: 0.75rem !important; }
  .pb-sm-q3,
  .py-sm-q3 {
    padding-bottom: 0.75rem !important; }
  .pl-sm-q3,
  .px-sm-q3 {
    padding-left: 0.75rem !important; }
  .p-sm-0 {
    padding: 0rem !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0rem !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0rem !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0rem !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0rem !important; }
  .p-sm-1 {
    padding: 1rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 1rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 1rem !important; }
  .p-sm-1q1 {
    padding: 1.25rem !important; }
  .pt-sm-1q1,
  .py-sm-1q1 {
    padding-top: 1.25rem !important; }
  .pr-sm-1q1,
  .px-sm-1q1 {
    padding-right: 1.25rem !important; }
  .pb-sm-1q1,
  .py-sm-1q1 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-1q1,
  .px-sm-1q1 {
    padding-left: 1.25rem !important; }
  .p-sm-1q2 {
    padding: 1.5rem !important; }
  .pt-sm-1q2,
  .py-sm-1q2 {
    padding-top: 1.5rem !important; }
  .pr-sm-1q2,
  .px-sm-1q2 {
    padding-right: 1.5rem !important; }
  .pb-sm-1q2,
  .py-sm-1q2 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-1q2,
  .px-sm-1q2 {
    padding-left: 1.5rem !important; }
  .p-sm-1q3 {
    padding: 1.75rem !important; }
  .pt-sm-1q3,
  .py-sm-1q3 {
    padding-top: 1.75rem !important; }
  .pr-sm-1q3,
  .px-sm-1q3 {
    padding-right: 1.75rem !important; }
  .pb-sm-1q3,
  .py-sm-1q3 {
    padding-bottom: 1.75rem !important; }
  .pl-sm-1q3,
  .px-sm-1q3 {
    padding-left: 1.75rem !important; }
  .p-sm-2 {
    padding: 2rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 2rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 2rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 2rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 2rem !important; }
  .p-sm-2q1 {
    padding: 2.25rem !important; }
  .pt-sm-2q1,
  .py-sm-2q1 {
    padding-top: 2.25rem !important; }
  .pr-sm-2q1,
  .px-sm-2q1 {
    padding-right: 2.25rem !important; }
  .pb-sm-2q1,
  .py-sm-2q1 {
    padding-bottom: 2.25rem !important; }
  .pl-sm-2q1,
  .px-sm-2q1 {
    padding-left: 2.25rem !important; }
  .p-sm-2q2 {
    padding: 2.5rem !important; }
  .pt-sm-2q2,
  .py-sm-2q2 {
    padding-top: 2.5rem !important; }
  .pr-sm-2q2,
  .px-sm-2q2 {
    padding-right: 2.5rem !important; }
  .pb-sm-2q2,
  .py-sm-2q2 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-2q2,
  .px-sm-2q2 {
    padding-left: 2.5rem !important; }
  .p-sm-2q3 {
    padding: 2.75rem !important; }
  .pt-sm-2q3,
  .py-sm-2q3 {
    padding-top: 2.75rem !important; }
  .pr-sm-2q3,
  .px-sm-2q3 {
    padding-right: 2.75rem !important; }
  .pb-sm-2q3,
  .py-sm-2q3 {
    padding-bottom: 2.75rem !important; }
  .pl-sm-2q3,
  .px-sm-2q3 {
    padding-left: 2.75rem !important; }
  .p-sm-3 {
    padding: 3rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 3rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 3rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 3rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 3rem !important; }
  .p-sm-3q1 {
    padding: 3.25rem !important; }
  .pt-sm-3q1,
  .py-sm-3q1 {
    padding-top: 3.25rem !important; }
  .pr-sm-3q1,
  .px-sm-3q1 {
    padding-right: 3.25rem !important; }
  .pb-sm-3q1,
  .py-sm-3q1 {
    padding-bottom: 3.25rem !important; }
  .pl-sm-3q1,
  .px-sm-3q1 {
    padding-left: 3.25rem !important; }
  .p-sm-3q2 {
    padding: 3.5rem !important; }
  .pt-sm-3q2,
  .py-sm-3q2 {
    padding-top: 3.5rem !important; }
  .pr-sm-3q2,
  .px-sm-3q2 {
    padding-right: 3.5rem !important; }
  .pb-sm-3q2,
  .py-sm-3q2 {
    padding-bottom: 3.5rem !important; }
  .pl-sm-3q2,
  .px-sm-3q2 {
    padding-left: 3.5rem !important; }
  .p-sm-3q3 {
    padding: 3.75rem !important; }
  .pt-sm-3q3,
  .py-sm-3q3 {
    padding-top: 3.75rem !important; }
  .pr-sm-3q3,
  .px-sm-3q3 {
    padding-right: 3.75rem !important; }
  .pb-sm-3q3,
  .py-sm-3q3 {
    padding-bottom: 3.75rem !important; }
  .pl-sm-3q3,
  .px-sm-3q3 {
    padding-left: 3.75rem !important; }
  .p-sm-4 {
    padding: 4rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 4rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 4rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 4rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 4rem !important; }
  .p-sm-4q1 {
    padding: 4.25rem !important; }
  .pt-sm-4q1,
  .py-sm-4q1 {
    padding-top: 4.25rem !important; }
  .pr-sm-4q1,
  .px-sm-4q1 {
    padding-right: 4.25rem !important; }
  .pb-sm-4q1,
  .py-sm-4q1 {
    padding-bottom: 4.25rem !important; }
  .pl-sm-4q1,
  .px-sm-4q1 {
    padding-left: 4.25rem !important; }
  .p-sm-4q2 {
    padding: 4.5rem !important; }
  .pt-sm-4q2,
  .py-sm-4q2 {
    padding-top: 4.5rem !important; }
  .pr-sm-4q2,
  .px-sm-4q2 {
    padding-right: 4.5rem !important; }
  .pb-sm-4q2,
  .py-sm-4q2 {
    padding-bottom: 4.5rem !important; }
  .pl-sm-4q2,
  .px-sm-4q2 {
    padding-left: 4.5rem !important; }
  .p-sm-4q3 {
    padding: 4.75rem !important; }
  .pt-sm-4q3,
  .py-sm-4q3 {
    padding-top: 4.75rem !important; }
  .pr-sm-4q3,
  .px-sm-4q3 {
    padding-right: 4.75rem !important; }
  .pb-sm-4q3,
  .py-sm-4q3 {
    padding-bottom: 4.75rem !important; }
  .pl-sm-4q3,
  .px-sm-4q3 {
    padding-left: 4.75rem !important; }
  .p-sm-5 {
    padding: 5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5rem !important; }
  .p-sm-5q1 {
    padding: 5.25rem !important; }
  .pt-sm-5q1,
  .py-sm-5q1 {
    padding-top: 5.25rem !important; }
  .pr-sm-5q1,
  .px-sm-5q1 {
    padding-right: 5.25rem !important; }
  .pb-sm-5q1,
  .py-sm-5q1 {
    padding-bottom: 5.25rem !important; }
  .pl-sm-5q1,
  .px-sm-5q1 {
    padding-left: 5.25rem !important; }
  .p-sm-5q2 {
    padding: 5.5rem !important; }
  .pt-sm-5q2,
  .py-sm-5q2 {
    padding-top: 5.5rem !important; }
  .pr-sm-5q2,
  .px-sm-5q2 {
    padding-right: 5.5rem !important; }
  .pb-sm-5q2,
  .py-sm-5q2 {
    padding-bottom: 5.5rem !important; }
  .pl-sm-5q2,
  .px-sm-5q2 {
    padding-left: 5.5rem !important; }
  .p-sm-5q3 {
    padding: 5.75rem !important; }
  .pt-sm-5q3,
  .py-sm-5q3 {
    padding-top: 5.75rem !important; }
  .pr-sm-5q3,
  .px-sm-5q3 {
    padding-right: 5.75rem !important; }
  .pb-sm-5q3,
  .py-sm-5q3 {
    padding-bottom: 5.75rem !important; }
  .pl-sm-5q3,
  .px-sm-5q3 {
    padding-left: 5.75rem !important; }
  .p-sm-6 {
    padding: 6rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 6rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 6rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 6rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 6rem !important; }
  .p-sm-6q1 {
    padding: 6.25rem !important; }
  .pt-sm-6q1,
  .py-sm-6q1 {
    padding-top: 6.25rem !important; }
  .pr-sm-6q1,
  .px-sm-6q1 {
    padding-right: 6.25rem !important; }
  .pb-sm-6q1,
  .py-sm-6q1 {
    padding-bottom: 6.25rem !important; }
  .pl-sm-6q1,
  .px-sm-6q1 {
    padding-left: 6.25rem !important; }
  .p-sm-6q2 {
    padding: 6.5rem !important; }
  .pt-sm-6q2,
  .py-sm-6q2 {
    padding-top: 6.5rem !important; }
  .pr-sm-6q2,
  .px-sm-6q2 {
    padding-right: 6.5rem !important; }
  .pb-sm-6q2,
  .py-sm-6q2 {
    padding-bottom: 6.5rem !important; }
  .pl-sm-6q2,
  .px-sm-6q2 {
    padding-left: 6.5rem !important; }
  .p-sm-6q3 {
    padding: 6.75rem !important; }
  .pt-sm-6q3,
  .py-sm-6q3 {
    padding-top: 6.75rem !important; }
  .pr-sm-6q3,
  .px-sm-6q3 {
    padding-right: 6.75rem !important; }
  .pb-sm-6q3,
  .py-sm-6q3 {
    padding-bottom: 6.75rem !important; }
  .pl-sm-6q3,
  .px-sm-6q3 {
    padding-left: 6.75rem !important; }
  .p-sm-7 {
    padding: 7rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 7rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 7rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 7rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 7rem !important; }
  .p-sm-7q1 {
    padding: 7.25rem !important; }
  .pt-sm-7q1,
  .py-sm-7q1 {
    padding-top: 7.25rem !important; }
  .pr-sm-7q1,
  .px-sm-7q1 {
    padding-right: 7.25rem !important; }
  .pb-sm-7q1,
  .py-sm-7q1 {
    padding-bottom: 7.25rem !important; }
  .pl-sm-7q1,
  .px-sm-7q1 {
    padding-left: 7.25rem !important; }
  .p-sm-7q2 {
    padding: 7.5rem !important; }
  .pt-sm-7q2,
  .py-sm-7q2 {
    padding-top: 7.5rem !important; }
  .pr-sm-7q2,
  .px-sm-7q2 {
    padding-right: 7.5rem !important; }
  .pb-sm-7q2,
  .py-sm-7q2 {
    padding-bottom: 7.5rem !important; }
  .pl-sm-7q2,
  .px-sm-7q2 {
    padding-left: 7.5rem !important; }
  .p-sm-7q3 {
    padding: 7.75rem !important; }
  .pt-sm-7q3,
  .py-sm-7q3 {
    padding-top: 7.75rem !important; }
  .pr-sm-7q3,
  .px-sm-7q3 {
    padding-right: 7.75rem !important; }
  .pb-sm-7q3,
  .py-sm-7q3 {
    padding-bottom: 7.75rem !important; }
  .pl-sm-7q3,
  .px-sm-7q3 {
    padding-left: 7.75rem !important; }
  .p-sm-8 {
    padding: 8rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 8rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 8rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 8rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 8rem !important; }
  .p-sm-8q1 {
    padding: 8.25rem !important; }
  .pt-sm-8q1,
  .py-sm-8q1 {
    padding-top: 8.25rem !important; }
  .pr-sm-8q1,
  .px-sm-8q1 {
    padding-right: 8.25rem !important; }
  .pb-sm-8q1,
  .py-sm-8q1 {
    padding-bottom: 8.25rem !important; }
  .pl-sm-8q1,
  .px-sm-8q1 {
    padding-left: 8.25rem !important; }
  .p-sm-8q2 {
    padding: 8.5rem !important; }
  .pt-sm-8q2,
  .py-sm-8q2 {
    padding-top: 8.5rem !important; }
  .pr-sm-8q2,
  .px-sm-8q2 {
    padding-right: 8.5rem !important; }
  .pb-sm-8q2,
  .py-sm-8q2 {
    padding-bottom: 8.5rem !important; }
  .pl-sm-8q2,
  .px-sm-8q2 {
    padding-left: 8.5rem !important; }
  .p-sm-8q3 {
    padding: 8.75rem !important; }
  .pt-sm-8q3,
  .py-sm-8q3 {
    padding-top: 8.75rem !important; }
  .pr-sm-8q3,
  .px-sm-8q3 {
    padding-right: 8.75rem !important; }
  .pb-sm-8q3,
  .py-sm-8q3 {
    padding-bottom: 8.75rem !important; }
  .pl-sm-8q3,
  .px-sm-8q3 {
    padding-left: 8.75rem !important; }
  .p-sm-9 {
    padding: 9rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 9rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 9rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 9rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 9rem !important; }
  .p-sm-9q1 {
    padding: 9.25rem !important; }
  .pt-sm-9q1,
  .py-sm-9q1 {
    padding-top: 9.25rem !important; }
  .pr-sm-9q1,
  .px-sm-9q1 {
    padding-right: 9.25rem !important; }
  .pb-sm-9q1,
  .py-sm-9q1 {
    padding-bottom: 9.25rem !important; }
  .pl-sm-9q1,
  .px-sm-9q1 {
    padding-left: 9.25rem !important; }
  .p-sm-9q2 {
    padding: 9.5rem !important; }
  .pt-sm-9q2,
  .py-sm-9q2 {
    padding-top: 9.5rem !important; }
  .pr-sm-9q2,
  .px-sm-9q2 {
    padding-right: 9.5rem !important; }
  .pb-sm-9q2,
  .py-sm-9q2 {
    padding-bottom: 9.5rem !important; }
  .pl-sm-9q2,
  .px-sm-9q2 {
    padding-left: 9.5rem !important; }
  .p-sm-9q3 {
    padding: 9.75rem !important; }
  .pt-sm-9q3,
  .py-sm-9q3 {
    padding-top: 9.75rem !important; }
  .pr-sm-9q3,
  .px-sm-9q3 {
    padding-right: 9.75rem !important; }
  .pb-sm-9q3,
  .py-sm-9q3 {
    padding-bottom: 9.75rem !important; }
  .pl-sm-9q3,
  .px-sm-9q3 {
    padding-left: 9.75rem !important; }
  .p-sm-10 {
    padding: 10rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10rem !important; }
  .p-sm-10q1 {
    padding: 10.25rem !important; }
  .pt-sm-10q1,
  .py-sm-10q1 {
    padding-top: 10.25rem !important; }
  .pr-sm-10q1,
  .px-sm-10q1 {
    padding-right: 10.25rem !important; }
  .pb-sm-10q1,
  .py-sm-10q1 {
    padding-bottom: 10.25rem !important; }
  .pl-sm-10q1,
  .px-sm-10q1 {
    padding-left: 10.25rem !important; }
  .p-sm-10q2 {
    padding: 10.5rem !important; }
  .pt-sm-10q2,
  .py-sm-10q2 {
    padding-top: 10.5rem !important; }
  .pr-sm-10q2,
  .px-sm-10q2 {
    padding-right: 10.5rem !important; }
  .pb-sm-10q2,
  .py-sm-10q2 {
    padding-bottom: 10.5rem !important; }
  .pl-sm-10q2,
  .px-sm-10q2 {
    padding-left: 10.5rem !important; }
  .p-sm-10q3 {
    padding: 10.75rem !important; }
  .pt-sm-10q3,
  .py-sm-10q3 {
    padding-top: 10.75rem !important; }
  .pr-sm-10q3,
  .px-sm-10q3 {
    padding-right: 10.75rem !important; }
  .pb-sm-10q3,
  .py-sm-10q3 {
    padding-bottom: 10.75rem !important; }
  .pl-sm-10q3,
  .px-sm-10q3 {
    padding-left: 10.75rem !important; }
  .p-sm-11 {
    padding: 11rem !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 11rem !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 11rem !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 11rem !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 11rem !important; }
  .p-sm-11q1 {
    padding: 11.25rem !important; }
  .pt-sm-11q1,
  .py-sm-11q1 {
    padding-top: 11.25rem !important; }
  .pr-sm-11q1,
  .px-sm-11q1 {
    padding-right: 11.25rem !important; }
  .pb-sm-11q1,
  .py-sm-11q1 {
    padding-bottom: 11.25rem !important; }
  .pl-sm-11q1,
  .px-sm-11q1 {
    padding-left: 11.25rem !important; }
  .p-sm-11 q2 {
    padding: 11.5rem !important; }
  .pt-sm-11 q2,
  .py-sm-11 q2 {
    padding-top: 11.5rem !important; }
  .pr-sm-11 q2,
  .px-sm-11 q2 {
    padding-right: 11.5rem !important; }
  .pb-sm-11 q2,
  .py-sm-11 q2 {
    padding-bottom: 11.5rem !important; }
  .pl-sm-11 q2,
  .px-sm-11 q2 {
    padding-left: 11.5rem !important; }
  .p-sm-11 q3 {
    padding: 11.75rem !important; }
  .pt-sm-11 q3,
  .py-sm-11 q3 {
    padding-top: 11.75rem !important; }
  .pr-sm-11 q3,
  .px-sm-11 q3 {
    padding-right: 11.75rem !important; }
  .pb-sm-11 q3,
  .py-sm-11 q3 {
    padding-bottom: 11.75rem !important; }
  .pl-sm-11 q3,
  .px-sm-11 q3 {
    padding-left: 11.75rem !important; }
  .p-sm-12 {
    padding: 12rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 12rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 12rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 12rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 12rem !important; }
  .p-sm-12 q1 {
    padding: 12.25rem !important; }
  .pt-sm-12 q1,
  .py-sm-12 q1 {
    padding-top: 12.25rem !important; }
  .pr-sm-12 q1,
  .px-sm-12 q1 {
    padding-right: 12.25rem !important; }
  .pb-sm-12 q1,
  .py-sm-12 q1 {
    padding-bottom: 12.25rem !important; }
  .pl-sm-12 q1,
  .px-sm-12 q1 {
    padding-left: 12.25rem !important; }
  .p-sm-12 q2 {
    padding: 12.5rem !important; }
  .pt-sm-12 q2,
  .py-sm-12 q2 {
    padding-top: 12.5rem !important; }
  .pr-sm-12 q2,
  .px-sm-12 q2 {
    padding-right: 12.5rem !important; }
  .pb-sm-12 q2,
  .py-sm-12 q2 {
    padding-bottom: 12.5rem !important; }
  .pl-sm-12 q2,
  .px-sm-12 q2 {
    padding-left: 12.5rem !important; }
  .p-sm-12 q3 {
    padding: 12.75rem !important; }
  .pt-sm-12 q3,
  .py-sm-12 q3 {
    padding-top: 12.75rem !important; }
  .pr-sm-12 q3,
  .px-sm-12 q3 {
    padding-right: 12.75rem !important; }
  .pb-sm-12 q3,
  .py-sm-12 q3 {
    padding-bottom: 12.75rem !important; }
  .pl-sm-12 q3,
  .px-sm-12 q3 {
    padding-left: 12.75rem !important; }
  .m-sm-nq1 {
    margin: -0.25rem !important; }
  .mt-sm-nq1,
  .my-sm-nq1 {
    margin-top: -0.25rem !important; }
  .mr-sm-nq1,
  .mx-sm-nq1 {
    margin-right: -0.25rem !important; }
  .mb-sm-nq1,
  .my-sm-nq1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-nq1,
  .mx-sm-nq1 {
    margin-left: -0.25rem !important; }
  .m-sm-nq2 {
    margin: -0.5rem !important; }
  .mt-sm-nq2,
  .my-sm-nq2 {
    margin-top: -0.5rem !important; }
  .mr-sm-nq2,
  .mx-sm-nq2 {
    margin-right: -0.5rem !important; }
  .mb-sm-nq2,
  .my-sm-nq2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-nq2,
  .mx-sm-nq2 {
    margin-left: -0.5rem !important; }
  .m-sm-nq3 {
    margin: -0.75rem !important; }
  .mt-sm-nq3,
  .my-sm-nq3 {
    margin-top: -0.75rem !important; }
  .mr-sm-nq3,
  .mx-sm-nq3 {
    margin-right: -0.75rem !important; }
  .mb-sm-nq3,
  .my-sm-nq3 {
    margin-bottom: -0.75rem !important; }
  .ml-sm-nq3,
  .mx-sm-nq3 {
    margin-left: -0.75rem !important; }
  .m-sm-n1 {
    margin: -1rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -1rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -1rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -1rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -1rem !important; }
  .m-sm-n1q1 {
    margin: -1.25rem !important; }
  .mt-sm-n1q1,
  .my-sm-n1q1 {
    margin-top: -1.25rem !important; }
  .mr-sm-n1q1,
  .mx-sm-n1q1 {
    margin-right: -1.25rem !important; }
  .mb-sm-n1q1,
  .my-sm-n1q1 {
    margin-bottom: -1.25rem !important; }
  .ml-sm-n1q1,
  .mx-sm-n1q1 {
    margin-left: -1.25rem !important; }
  .m-sm-n1q2 {
    margin: -1.5rem !important; }
  .mt-sm-n1q2,
  .my-sm-n1q2 {
    margin-top: -1.5rem !important; }
  .mr-sm-n1q2,
  .mx-sm-n1q2 {
    margin-right: -1.5rem !important; }
  .mb-sm-n1q2,
  .my-sm-n1q2 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n1q2,
  .mx-sm-n1q2 {
    margin-left: -1.5rem !important; }
  .m-sm-n1q3 {
    margin: -1.75rem !important; }
  .mt-sm-n1q3,
  .my-sm-n1q3 {
    margin-top: -1.75rem !important; }
  .mr-sm-n1q3,
  .mx-sm-n1q3 {
    margin-right: -1.75rem !important; }
  .mb-sm-n1q3,
  .my-sm-n1q3 {
    margin-bottom: -1.75rem !important; }
  .ml-sm-n1q3,
  .mx-sm-n1q3 {
    margin-left: -1.75rem !important; }
  .m-sm-n2 {
    margin: -2rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -2rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -2rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -2rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -2rem !important; }
  .m-sm-n2q1 {
    margin: -2.25rem !important; }
  .mt-sm-n2q1,
  .my-sm-n2q1 {
    margin-top: -2.25rem !important; }
  .mr-sm-n2q1,
  .mx-sm-n2q1 {
    margin-right: -2.25rem !important; }
  .mb-sm-n2q1,
  .my-sm-n2q1 {
    margin-bottom: -2.25rem !important; }
  .ml-sm-n2q1,
  .mx-sm-n2q1 {
    margin-left: -2.25rem !important; }
  .m-sm-n2q2 {
    margin: -2.5rem !important; }
  .mt-sm-n2q2,
  .my-sm-n2q2 {
    margin-top: -2.5rem !important; }
  .mr-sm-n2q2,
  .mx-sm-n2q2 {
    margin-right: -2.5rem !important; }
  .mb-sm-n2q2,
  .my-sm-n2q2 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n2q2,
  .mx-sm-n2q2 {
    margin-left: -2.5rem !important; }
  .m-sm-n2q3 {
    margin: -2.75rem !important; }
  .mt-sm-n2q3,
  .my-sm-n2q3 {
    margin-top: -2.75rem !important; }
  .mr-sm-n2q3,
  .mx-sm-n2q3 {
    margin-right: -2.75rem !important; }
  .mb-sm-n2q3,
  .my-sm-n2q3 {
    margin-bottom: -2.75rem !important; }
  .ml-sm-n2q3,
  .mx-sm-n2q3 {
    margin-left: -2.75rem !important; }
  .m-sm-n3 {
    margin: -3rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -3rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -3rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -3rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -3rem !important; }
  .m-sm-n3q1 {
    margin: -3.25rem !important; }
  .mt-sm-n3q1,
  .my-sm-n3q1 {
    margin-top: -3.25rem !important; }
  .mr-sm-n3q1,
  .mx-sm-n3q1 {
    margin-right: -3.25rem !important; }
  .mb-sm-n3q1,
  .my-sm-n3q1 {
    margin-bottom: -3.25rem !important; }
  .ml-sm-n3q1,
  .mx-sm-n3q1 {
    margin-left: -3.25rem !important; }
  .m-sm-n3q2 {
    margin: -3.5rem !important; }
  .mt-sm-n3q2,
  .my-sm-n3q2 {
    margin-top: -3.5rem !important; }
  .mr-sm-n3q2,
  .mx-sm-n3q2 {
    margin-right: -3.5rem !important; }
  .mb-sm-n3q2,
  .my-sm-n3q2 {
    margin-bottom: -3.5rem !important; }
  .ml-sm-n3q2,
  .mx-sm-n3q2 {
    margin-left: -3.5rem !important; }
  .m-sm-n3q3 {
    margin: -3.75rem !important; }
  .mt-sm-n3q3,
  .my-sm-n3q3 {
    margin-top: -3.75rem !important; }
  .mr-sm-n3q3,
  .mx-sm-n3q3 {
    margin-right: -3.75rem !important; }
  .mb-sm-n3q3,
  .my-sm-n3q3 {
    margin-bottom: -3.75rem !important; }
  .ml-sm-n3q3,
  .mx-sm-n3q3 {
    margin-left: -3.75rem !important; }
  .m-sm-n4 {
    margin: -4rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -4rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -4rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -4rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -4rem !important; }
  .m-sm-n4q1 {
    margin: -4.25rem !important; }
  .mt-sm-n4q1,
  .my-sm-n4q1 {
    margin-top: -4.25rem !important; }
  .mr-sm-n4q1,
  .mx-sm-n4q1 {
    margin-right: -4.25rem !important; }
  .mb-sm-n4q1,
  .my-sm-n4q1 {
    margin-bottom: -4.25rem !important; }
  .ml-sm-n4q1,
  .mx-sm-n4q1 {
    margin-left: -4.25rem !important; }
  .m-sm-n4q2 {
    margin: -4.5rem !important; }
  .mt-sm-n4q2,
  .my-sm-n4q2 {
    margin-top: -4.5rem !important; }
  .mr-sm-n4q2,
  .mx-sm-n4q2 {
    margin-right: -4.5rem !important; }
  .mb-sm-n4q2,
  .my-sm-n4q2 {
    margin-bottom: -4.5rem !important; }
  .ml-sm-n4q2,
  .mx-sm-n4q2 {
    margin-left: -4.5rem !important; }
  .m-sm-n4q3 {
    margin: -4.75rem !important; }
  .mt-sm-n4q3,
  .my-sm-n4q3 {
    margin-top: -4.75rem !important; }
  .mr-sm-n4q3,
  .mx-sm-n4q3 {
    margin-right: -4.75rem !important; }
  .mb-sm-n4q3,
  .my-sm-n4q3 {
    margin-bottom: -4.75rem !important; }
  .ml-sm-n4q3,
  .mx-sm-n4q3 {
    margin-left: -4.75rem !important; }
  .m-sm-n5 {
    margin: -5rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -5rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -5rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -5rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -5rem !important; }
  .m-sm-n5q1 {
    margin: -5.25rem !important; }
  .mt-sm-n5q1,
  .my-sm-n5q1 {
    margin-top: -5.25rem !important; }
  .mr-sm-n5q1,
  .mx-sm-n5q1 {
    margin-right: -5.25rem !important; }
  .mb-sm-n5q1,
  .my-sm-n5q1 {
    margin-bottom: -5.25rem !important; }
  .ml-sm-n5q1,
  .mx-sm-n5q1 {
    margin-left: -5.25rem !important; }
  .m-sm-n5q2 {
    margin: -5.5rem !important; }
  .mt-sm-n5q2,
  .my-sm-n5q2 {
    margin-top: -5.5rem !important; }
  .mr-sm-n5q2,
  .mx-sm-n5q2 {
    margin-right: -5.5rem !important; }
  .mb-sm-n5q2,
  .my-sm-n5q2 {
    margin-bottom: -5.5rem !important; }
  .ml-sm-n5q2,
  .mx-sm-n5q2 {
    margin-left: -5.5rem !important; }
  .m-sm-n5q3 {
    margin: -5.75rem !important; }
  .mt-sm-n5q3,
  .my-sm-n5q3 {
    margin-top: -5.75rem !important; }
  .mr-sm-n5q3,
  .mx-sm-n5q3 {
    margin-right: -5.75rem !important; }
  .mb-sm-n5q3,
  .my-sm-n5q3 {
    margin-bottom: -5.75rem !important; }
  .ml-sm-n5q3,
  .mx-sm-n5q3 {
    margin-left: -5.75rem !important; }
  .m-sm-n6 {
    margin: -6rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -6rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -6rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -6rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -6rem !important; }
  .m-sm-n6q1 {
    margin: -6.25rem !important; }
  .mt-sm-n6q1,
  .my-sm-n6q1 {
    margin-top: -6.25rem !important; }
  .mr-sm-n6q1,
  .mx-sm-n6q1 {
    margin-right: -6.25rem !important; }
  .mb-sm-n6q1,
  .my-sm-n6q1 {
    margin-bottom: -6.25rem !important; }
  .ml-sm-n6q1,
  .mx-sm-n6q1 {
    margin-left: -6.25rem !important; }
  .m-sm-n6q2 {
    margin: -6.5rem !important; }
  .mt-sm-n6q2,
  .my-sm-n6q2 {
    margin-top: -6.5rem !important; }
  .mr-sm-n6q2,
  .mx-sm-n6q2 {
    margin-right: -6.5rem !important; }
  .mb-sm-n6q2,
  .my-sm-n6q2 {
    margin-bottom: -6.5rem !important; }
  .ml-sm-n6q2,
  .mx-sm-n6q2 {
    margin-left: -6.5rem !important; }
  .m-sm-n6q3 {
    margin: -6.75rem !important; }
  .mt-sm-n6q3,
  .my-sm-n6q3 {
    margin-top: -6.75rem !important; }
  .mr-sm-n6q3,
  .mx-sm-n6q3 {
    margin-right: -6.75rem !important; }
  .mb-sm-n6q3,
  .my-sm-n6q3 {
    margin-bottom: -6.75rem !important; }
  .ml-sm-n6q3,
  .mx-sm-n6q3 {
    margin-left: -6.75rem !important; }
  .m-sm-n7 {
    margin: -7rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -7rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -7rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -7rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -7rem !important; }
  .m-sm-n7q1 {
    margin: -7.25rem !important; }
  .mt-sm-n7q1,
  .my-sm-n7q1 {
    margin-top: -7.25rem !important; }
  .mr-sm-n7q1,
  .mx-sm-n7q1 {
    margin-right: -7.25rem !important; }
  .mb-sm-n7q1,
  .my-sm-n7q1 {
    margin-bottom: -7.25rem !important; }
  .ml-sm-n7q1,
  .mx-sm-n7q1 {
    margin-left: -7.25rem !important; }
  .m-sm-n7q2 {
    margin: -7.5rem !important; }
  .mt-sm-n7q2,
  .my-sm-n7q2 {
    margin-top: -7.5rem !important; }
  .mr-sm-n7q2,
  .mx-sm-n7q2 {
    margin-right: -7.5rem !important; }
  .mb-sm-n7q2,
  .my-sm-n7q2 {
    margin-bottom: -7.5rem !important; }
  .ml-sm-n7q2,
  .mx-sm-n7q2 {
    margin-left: -7.5rem !important; }
  .m-sm-n7q3 {
    margin: -7.75rem !important; }
  .mt-sm-n7q3,
  .my-sm-n7q3 {
    margin-top: -7.75rem !important; }
  .mr-sm-n7q3,
  .mx-sm-n7q3 {
    margin-right: -7.75rem !important; }
  .mb-sm-n7q3,
  .my-sm-n7q3 {
    margin-bottom: -7.75rem !important; }
  .ml-sm-n7q3,
  .mx-sm-n7q3 {
    margin-left: -7.75rem !important; }
  .m-sm-n8 {
    margin: -8rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -8rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -8rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -8rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -8rem !important; }
  .m-sm-n8q1 {
    margin: -8.25rem !important; }
  .mt-sm-n8q1,
  .my-sm-n8q1 {
    margin-top: -8.25rem !important; }
  .mr-sm-n8q1,
  .mx-sm-n8q1 {
    margin-right: -8.25rem !important; }
  .mb-sm-n8q1,
  .my-sm-n8q1 {
    margin-bottom: -8.25rem !important; }
  .ml-sm-n8q1,
  .mx-sm-n8q1 {
    margin-left: -8.25rem !important; }
  .m-sm-n8q2 {
    margin: -8.5rem !important; }
  .mt-sm-n8q2,
  .my-sm-n8q2 {
    margin-top: -8.5rem !important; }
  .mr-sm-n8q2,
  .mx-sm-n8q2 {
    margin-right: -8.5rem !important; }
  .mb-sm-n8q2,
  .my-sm-n8q2 {
    margin-bottom: -8.5rem !important; }
  .ml-sm-n8q2,
  .mx-sm-n8q2 {
    margin-left: -8.5rem !important; }
  .m-sm-n8q3 {
    margin: -8.75rem !important; }
  .mt-sm-n8q3,
  .my-sm-n8q3 {
    margin-top: -8.75rem !important; }
  .mr-sm-n8q3,
  .mx-sm-n8q3 {
    margin-right: -8.75rem !important; }
  .mb-sm-n8q3,
  .my-sm-n8q3 {
    margin-bottom: -8.75rem !important; }
  .ml-sm-n8q3,
  .mx-sm-n8q3 {
    margin-left: -8.75rem !important; }
  .m-sm-n9 {
    margin: -9rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -9rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -9rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -9rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -9rem !important; }
  .m-sm-n9q1 {
    margin: -9.25rem !important; }
  .mt-sm-n9q1,
  .my-sm-n9q1 {
    margin-top: -9.25rem !important; }
  .mr-sm-n9q1,
  .mx-sm-n9q1 {
    margin-right: -9.25rem !important; }
  .mb-sm-n9q1,
  .my-sm-n9q1 {
    margin-bottom: -9.25rem !important; }
  .ml-sm-n9q1,
  .mx-sm-n9q1 {
    margin-left: -9.25rem !important; }
  .m-sm-n9q2 {
    margin: -9.5rem !important; }
  .mt-sm-n9q2,
  .my-sm-n9q2 {
    margin-top: -9.5rem !important; }
  .mr-sm-n9q2,
  .mx-sm-n9q2 {
    margin-right: -9.5rem !important; }
  .mb-sm-n9q2,
  .my-sm-n9q2 {
    margin-bottom: -9.5rem !important; }
  .ml-sm-n9q2,
  .mx-sm-n9q2 {
    margin-left: -9.5rem !important; }
  .m-sm-n9q3 {
    margin: -9.75rem !important; }
  .mt-sm-n9q3,
  .my-sm-n9q3 {
    margin-top: -9.75rem !important; }
  .mr-sm-n9q3,
  .mx-sm-n9q3 {
    margin-right: -9.75rem !important; }
  .mb-sm-n9q3,
  .my-sm-n9q3 {
    margin-bottom: -9.75rem !important; }
  .ml-sm-n9q3,
  .mx-sm-n9q3 {
    margin-left: -9.75rem !important; }
  .m-sm-n10 {
    margin: -10rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -10rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -10rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -10rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -10rem !important; }
  .m-sm-n10q1 {
    margin: -10.25rem !important; }
  .mt-sm-n10q1,
  .my-sm-n10q1 {
    margin-top: -10.25rem !important; }
  .mr-sm-n10q1,
  .mx-sm-n10q1 {
    margin-right: -10.25rem !important; }
  .mb-sm-n10q1,
  .my-sm-n10q1 {
    margin-bottom: -10.25rem !important; }
  .ml-sm-n10q1,
  .mx-sm-n10q1 {
    margin-left: -10.25rem !important; }
  .m-sm-n10q2 {
    margin: -10.5rem !important; }
  .mt-sm-n10q2,
  .my-sm-n10q2 {
    margin-top: -10.5rem !important; }
  .mr-sm-n10q2,
  .mx-sm-n10q2 {
    margin-right: -10.5rem !important; }
  .mb-sm-n10q2,
  .my-sm-n10q2 {
    margin-bottom: -10.5rem !important; }
  .ml-sm-n10q2,
  .mx-sm-n10q2 {
    margin-left: -10.5rem !important; }
  .m-sm-n10q3 {
    margin: -10.75rem !important; }
  .mt-sm-n10q3,
  .my-sm-n10q3 {
    margin-top: -10.75rem !important; }
  .mr-sm-n10q3,
  .mx-sm-n10q3 {
    margin-right: -10.75rem !important; }
  .mb-sm-n10q3,
  .my-sm-n10q3 {
    margin-bottom: -10.75rem !important; }
  .ml-sm-n10q3,
  .mx-sm-n10q3 {
    margin-left: -10.75rem !important; }
  .m-sm-n11 {
    margin: -11rem !important; }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -11rem !important; }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -11rem !important; }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -11rem !important; }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -11rem !important; }
  .m-sm-n11q1 {
    margin: -11.25rem !important; }
  .mt-sm-n11q1,
  .my-sm-n11q1 {
    margin-top: -11.25rem !important; }
  .mr-sm-n11q1,
  .mx-sm-n11q1 {
    margin-right: -11.25rem !important; }
  .mb-sm-n11q1,
  .my-sm-n11q1 {
    margin-bottom: -11.25rem !important; }
  .ml-sm-n11q1,
  .mx-sm-n11q1 {
    margin-left: -11.25rem !important; }
  .m-sm-n11 q2 {
    margin: -11.5rem !important; }
  .mt-sm-n11 q2,
  .my-sm-n11 q2 {
    margin-top: -11.5rem !important; }
  .mr-sm-n11 q2,
  .mx-sm-n11 q2 {
    margin-right: -11.5rem !important; }
  .mb-sm-n11 q2,
  .my-sm-n11 q2 {
    margin-bottom: -11.5rem !important; }
  .ml-sm-n11 q2,
  .mx-sm-n11 q2 {
    margin-left: -11.5rem !important; }
  .m-sm-n11 q3 {
    margin: -11.75rem !important; }
  .mt-sm-n11 q3,
  .my-sm-n11 q3 {
    margin-top: -11.75rem !important; }
  .mr-sm-n11 q3,
  .mx-sm-n11 q3 {
    margin-right: -11.75rem !important; }
  .mb-sm-n11 q3,
  .my-sm-n11 q3 {
    margin-bottom: -11.75rem !important; }
  .ml-sm-n11 q3,
  .mx-sm-n11 q3 {
    margin-left: -11.75rem !important; }
  .m-sm-n12 {
    margin: -12rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -12rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -12rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -12rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -12rem !important; }
  .m-sm-n12 q1 {
    margin: -12.25rem !important; }
  .mt-sm-n12 q1,
  .my-sm-n12 q1 {
    margin-top: -12.25rem !important; }
  .mr-sm-n12 q1,
  .mx-sm-n12 q1 {
    margin-right: -12.25rem !important; }
  .mb-sm-n12 q1,
  .my-sm-n12 q1 {
    margin-bottom: -12.25rem !important; }
  .ml-sm-n12 q1,
  .mx-sm-n12 q1 {
    margin-left: -12.25rem !important; }
  .m-sm-n12 q2 {
    margin: -12.5rem !important; }
  .mt-sm-n12 q2,
  .my-sm-n12 q2 {
    margin-top: -12.5rem !important; }
  .mr-sm-n12 q2,
  .mx-sm-n12 q2 {
    margin-right: -12.5rem !important; }
  .mb-sm-n12 q2,
  .my-sm-n12 q2 {
    margin-bottom: -12.5rem !important; }
  .ml-sm-n12 q2,
  .mx-sm-n12 q2 {
    margin-left: -12.5rem !important; }
  .m-sm-n12 q3 {
    margin: -12.75rem !important; }
  .mt-sm-n12 q3,
  .my-sm-n12 q3 {
    margin-top: -12.75rem !important; }
  .mr-sm-n12 q3,
  .mx-sm-n12 q3 {
    margin-right: -12.75rem !important; }
  .mb-sm-n12 q3,
  .my-sm-n12 q3 {
    margin-bottom: -12.75rem !important; }
  .ml-sm-n12 q3,
  .mx-sm-n12 q3 {
    margin-left: -12.75rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 767px) {
  .m-md-q1 {
    margin: 0.25rem !important; }
  .mt-md-q1,
  .my-md-q1 {
    margin-top: 0.25rem !important; }
  .mr-md-q1,
  .mx-md-q1 {
    margin-right: 0.25rem !important; }
  .mb-md-q1,
  .my-md-q1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-q1,
  .mx-md-q1 {
    margin-left: 0.25rem !important; }
  .m-md-q2 {
    margin: 0.5rem !important; }
  .mt-md-q2,
  .my-md-q2 {
    margin-top: 0.5rem !important; }
  .mr-md-q2,
  .mx-md-q2 {
    margin-right: 0.5rem !important; }
  .mb-md-q2,
  .my-md-q2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-q2,
  .mx-md-q2 {
    margin-left: 0.5rem !important; }
  .m-md-q3 {
    margin: 0.75rem !important; }
  .mt-md-q3,
  .my-md-q3 {
    margin-top: 0.75rem !important; }
  .mr-md-q3,
  .mx-md-q3 {
    margin-right: 0.75rem !important; }
  .mb-md-q3,
  .my-md-q3 {
    margin-bottom: 0.75rem !important; }
  .ml-md-q3,
  .mx-md-q3 {
    margin-left: 0.75rem !important; }
  .m-md-0 {
    margin: 0rem !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0rem !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0rem !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0rem !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0rem !important; }
  .m-md-1 {
    margin: 1rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 1rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 1rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 1rem !important; }
  .m-md-1q1 {
    margin: 1.25rem !important; }
  .mt-md-1q1,
  .my-md-1q1 {
    margin-top: 1.25rem !important; }
  .mr-md-1q1,
  .mx-md-1q1 {
    margin-right: 1.25rem !important; }
  .mb-md-1q1,
  .my-md-1q1 {
    margin-bottom: 1.25rem !important; }
  .ml-md-1q1,
  .mx-md-1q1 {
    margin-left: 1.25rem !important; }
  .m-md-1q2 {
    margin: 1.5rem !important; }
  .mt-md-1q2,
  .my-md-1q2 {
    margin-top: 1.5rem !important; }
  .mr-md-1q2,
  .mx-md-1q2 {
    margin-right: 1.5rem !important; }
  .mb-md-1q2,
  .my-md-1q2 {
    margin-bottom: 1.5rem !important; }
  .ml-md-1q2,
  .mx-md-1q2 {
    margin-left: 1.5rem !important; }
  .m-md-1q3 {
    margin: 1.75rem !important; }
  .mt-md-1q3,
  .my-md-1q3 {
    margin-top: 1.75rem !important; }
  .mr-md-1q3,
  .mx-md-1q3 {
    margin-right: 1.75rem !important; }
  .mb-md-1q3,
  .my-md-1q3 {
    margin-bottom: 1.75rem !important; }
  .ml-md-1q3,
  .mx-md-1q3 {
    margin-left: 1.75rem !important; }
  .m-md-2 {
    margin: 2rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 2rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 2rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 2rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 2rem !important; }
  .m-md-2q1 {
    margin: 2.25rem !important; }
  .mt-md-2q1,
  .my-md-2q1 {
    margin-top: 2.25rem !important; }
  .mr-md-2q1,
  .mx-md-2q1 {
    margin-right: 2.25rem !important; }
  .mb-md-2q1,
  .my-md-2q1 {
    margin-bottom: 2.25rem !important; }
  .ml-md-2q1,
  .mx-md-2q1 {
    margin-left: 2.25rem !important; }
  .m-md-2q2 {
    margin: 2.5rem !important; }
  .mt-md-2q2,
  .my-md-2q2 {
    margin-top: 2.5rem !important; }
  .mr-md-2q2,
  .mx-md-2q2 {
    margin-right: 2.5rem !important; }
  .mb-md-2q2,
  .my-md-2q2 {
    margin-bottom: 2.5rem !important; }
  .ml-md-2q2,
  .mx-md-2q2 {
    margin-left: 2.5rem !important; }
  .m-md-2q3 {
    margin: 2.75rem !important; }
  .mt-md-2q3,
  .my-md-2q3 {
    margin-top: 2.75rem !important; }
  .mr-md-2q3,
  .mx-md-2q3 {
    margin-right: 2.75rem !important; }
  .mb-md-2q3,
  .my-md-2q3 {
    margin-bottom: 2.75rem !important; }
  .ml-md-2q3,
  .mx-md-2q3 {
    margin-left: 2.75rem !important; }
  .m-md-3 {
    margin: 3rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 3rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 3rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 3rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 3rem !important; }
  .m-md-3q1 {
    margin: 3.25rem !important; }
  .mt-md-3q1,
  .my-md-3q1 {
    margin-top: 3.25rem !important; }
  .mr-md-3q1,
  .mx-md-3q1 {
    margin-right: 3.25rem !important; }
  .mb-md-3q1,
  .my-md-3q1 {
    margin-bottom: 3.25rem !important; }
  .ml-md-3q1,
  .mx-md-3q1 {
    margin-left: 3.25rem !important; }
  .m-md-3q2 {
    margin: 3.5rem !important; }
  .mt-md-3q2,
  .my-md-3q2 {
    margin-top: 3.5rem !important; }
  .mr-md-3q2,
  .mx-md-3q2 {
    margin-right: 3.5rem !important; }
  .mb-md-3q2,
  .my-md-3q2 {
    margin-bottom: 3.5rem !important; }
  .ml-md-3q2,
  .mx-md-3q2 {
    margin-left: 3.5rem !important; }
  .m-md-3q3 {
    margin: 3.75rem !important; }
  .mt-md-3q3,
  .my-md-3q3 {
    margin-top: 3.75rem !important; }
  .mr-md-3q3,
  .mx-md-3q3 {
    margin-right: 3.75rem !important; }
  .mb-md-3q3,
  .my-md-3q3 {
    margin-bottom: 3.75rem !important; }
  .ml-md-3q3,
  .mx-md-3q3 {
    margin-left: 3.75rem !important; }
  .m-md-4 {
    margin: 4rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 4rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 4rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 4rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 4rem !important; }
  .m-md-4q1 {
    margin: 4.25rem !important; }
  .mt-md-4q1,
  .my-md-4q1 {
    margin-top: 4.25rem !important; }
  .mr-md-4q1,
  .mx-md-4q1 {
    margin-right: 4.25rem !important; }
  .mb-md-4q1,
  .my-md-4q1 {
    margin-bottom: 4.25rem !important; }
  .ml-md-4q1,
  .mx-md-4q1 {
    margin-left: 4.25rem !important; }
  .m-md-4q2 {
    margin: 4.5rem !important; }
  .mt-md-4q2,
  .my-md-4q2 {
    margin-top: 4.5rem !important; }
  .mr-md-4q2,
  .mx-md-4q2 {
    margin-right: 4.5rem !important; }
  .mb-md-4q2,
  .my-md-4q2 {
    margin-bottom: 4.5rem !important; }
  .ml-md-4q2,
  .mx-md-4q2 {
    margin-left: 4.5rem !important; }
  .m-md-4q3 {
    margin: 4.75rem !important; }
  .mt-md-4q3,
  .my-md-4q3 {
    margin-top: 4.75rem !important; }
  .mr-md-4q3,
  .mx-md-4q3 {
    margin-right: 4.75rem !important; }
  .mb-md-4q3,
  .my-md-4q3 {
    margin-bottom: 4.75rem !important; }
  .ml-md-4q3,
  .mx-md-4q3 {
    margin-left: 4.75rem !important; }
  .m-md-5 {
    margin: 5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5rem !important; }
  .m-md-5q1 {
    margin: 5.25rem !important; }
  .mt-md-5q1,
  .my-md-5q1 {
    margin-top: 5.25rem !important; }
  .mr-md-5q1,
  .mx-md-5q1 {
    margin-right: 5.25rem !important; }
  .mb-md-5q1,
  .my-md-5q1 {
    margin-bottom: 5.25rem !important; }
  .ml-md-5q1,
  .mx-md-5q1 {
    margin-left: 5.25rem !important; }
  .m-md-5q2 {
    margin: 5.5rem !important; }
  .mt-md-5q2,
  .my-md-5q2 {
    margin-top: 5.5rem !important; }
  .mr-md-5q2,
  .mx-md-5q2 {
    margin-right: 5.5rem !important; }
  .mb-md-5q2,
  .my-md-5q2 {
    margin-bottom: 5.5rem !important; }
  .ml-md-5q2,
  .mx-md-5q2 {
    margin-left: 5.5rem !important; }
  .m-md-5q3 {
    margin: 5.75rem !important; }
  .mt-md-5q3,
  .my-md-5q3 {
    margin-top: 5.75rem !important; }
  .mr-md-5q3,
  .mx-md-5q3 {
    margin-right: 5.75rem !important; }
  .mb-md-5q3,
  .my-md-5q3 {
    margin-bottom: 5.75rem !important; }
  .ml-md-5q3,
  .mx-md-5q3 {
    margin-left: 5.75rem !important; }
  .m-md-6 {
    margin: 6rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 6rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 6rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 6rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 6rem !important; }
  .m-md-6q1 {
    margin: 6.25rem !important; }
  .mt-md-6q1,
  .my-md-6q1 {
    margin-top: 6.25rem !important; }
  .mr-md-6q1,
  .mx-md-6q1 {
    margin-right: 6.25rem !important; }
  .mb-md-6q1,
  .my-md-6q1 {
    margin-bottom: 6.25rem !important; }
  .ml-md-6q1,
  .mx-md-6q1 {
    margin-left: 6.25rem !important; }
  .m-md-6q2 {
    margin: 6.5rem !important; }
  .mt-md-6q2,
  .my-md-6q2 {
    margin-top: 6.5rem !important; }
  .mr-md-6q2,
  .mx-md-6q2 {
    margin-right: 6.5rem !important; }
  .mb-md-6q2,
  .my-md-6q2 {
    margin-bottom: 6.5rem !important; }
  .ml-md-6q2,
  .mx-md-6q2 {
    margin-left: 6.5rem !important; }
  .m-md-6q3 {
    margin: 6.75rem !important; }
  .mt-md-6q3,
  .my-md-6q3 {
    margin-top: 6.75rem !important; }
  .mr-md-6q3,
  .mx-md-6q3 {
    margin-right: 6.75rem !important; }
  .mb-md-6q3,
  .my-md-6q3 {
    margin-bottom: 6.75rem !important; }
  .ml-md-6q3,
  .mx-md-6q3 {
    margin-left: 6.75rem !important; }
  .m-md-7 {
    margin: 7rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 7rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 7rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 7rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 7rem !important; }
  .m-md-7q1 {
    margin: 7.25rem !important; }
  .mt-md-7q1,
  .my-md-7q1 {
    margin-top: 7.25rem !important; }
  .mr-md-7q1,
  .mx-md-7q1 {
    margin-right: 7.25rem !important; }
  .mb-md-7q1,
  .my-md-7q1 {
    margin-bottom: 7.25rem !important; }
  .ml-md-7q1,
  .mx-md-7q1 {
    margin-left: 7.25rem !important; }
  .m-md-7q2 {
    margin: 7.5rem !important; }
  .mt-md-7q2,
  .my-md-7q2 {
    margin-top: 7.5rem !important; }
  .mr-md-7q2,
  .mx-md-7q2 {
    margin-right: 7.5rem !important; }
  .mb-md-7q2,
  .my-md-7q2 {
    margin-bottom: 7.5rem !important; }
  .ml-md-7q2,
  .mx-md-7q2 {
    margin-left: 7.5rem !important; }
  .m-md-7q3 {
    margin: 7.75rem !important; }
  .mt-md-7q3,
  .my-md-7q3 {
    margin-top: 7.75rem !important; }
  .mr-md-7q3,
  .mx-md-7q3 {
    margin-right: 7.75rem !important; }
  .mb-md-7q3,
  .my-md-7q3 {
    margin-bottom: 7.75rem !important; }
  .ml-md-7q3,
  .mx-md-7q3 {
    margin-left: 7.75rem !important; }
  .m-md-8 {
    margin: 8rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 8rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 8rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 8rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 8rem !important; }
  .m-md-8q1 {
    margin: 8.25rem !important; }
  .mt-md-8q1,
  .my-md-8q1 {
    margin-top: 8.25rem !important; }
  .mr-md-8q1,
  .mx-md-8q1 {
    margin-right: 8.25rem !important; }
  .mb-md-8q1,
  .my-md-8q1 {
    margin-bottom: 8.25rem !important; }
  .ml-md-8q1,
  .mx-md-8q1 {
    margin-left: 8.25rem !important; }
  .m-md-8q2 {
    margin: 8.5rem !important; }
  .mt-md-8q2,
  .my-md-8q2 {
    margin-top: 8.5rem !important; }
  .mr-md-8q2,
  .mx-md-8q2 {
    margin-right: 8.5rem !important; }
  .mb-md-8q2,
  .my-md-8q2 {
    margin-bottom: 8.5rem !important; }
  .ml-md-8q2,
  .mx-md-8q2 {
    margin-left: 8.5rem !important; }
  .m-md-8q3 {
    margin: 8.75rem !important; }
  .mt-md-8q3,
  .my-md-8q3 {
    margin-top: 8.75rem !important; }
  .mr-md-8q3,
  .mx-md-8q3 {
    margin-right: 8.75rem !important; }
  .mb-md-8q3,
  .my-md-8q3 {
    margin-bottom: 8.75rem !important; }
  .ml-md-8q3,
  .mx-md-8q3 {
    margin-left: 8.75rem !important; }
  .m-md-9 {
    margin: 9rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 9rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 9rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 9rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 9rem !important; }
  .m-md-9q1 {
    margin: 9.25rem !important; }
  .mt-md-9q1,
  .my-md-9q1 {
    margin-top: 9.25rem !important; }
  .mr-md-9q1,
  .mx-md-9q1 {
    margin-right: 9.25rem !important; }
  .mb-md-9q1,
  .my-md-9q1 {
    margin-bottom: 9.25rem !important; }
  .ml-md-9q1,
  .mx-md-9q1 {
    margin-left: 9.25rem !important; }
  .m-md-9q2 {
    margin: 9.5rem !important; }
  .mt-md-9q2,
  .my-md-9q2 {
    margin-top: 9.5rem !important; }
  .mr-md-9q2,
  .mx-md-9q2 {
    margin-right: 9.5rem !important; }
  .mb-md-9q2,
  .my-md-9q2 {
    margin-bottom: 9.5rem !important; }
  .ml-md-9q2,
  .mx-md-9q2 {
    margin-left: 9.5rem !important; }
  .m-md-9q3 {
    margin: 9.75rem !important; }
  .mt-md-9q3,
  .my-md-9q3 {
    margin-top: 9.75rem !important; }
  .mr-md-9q3,
  .mx-md-9q3 {
    margin-right: 9.75rem !important; }
  .mb-md-9q3,
  .my-md-9q3 {
    margin-bottom: 9.75rem !important; }
  .ml-md-9q3,
  .mx-md-9q3 {
    margin-left: 9.75rem !important; }
  .m-md-10 {
    margin: 10rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10rem !important; }
  .m-md-10q1 {
    margin: 10.25rem !important; }
  .mt-md-10q1,
  .my-md-10q1 {
    margin-top: 10.25rem !important; }
  .mr-md-10q1,
  .mx-md-10q1 {
    margin-right: 10.25rem !important; }
  .mb-md-10q1,
  .my-md-10q1 {
    margin-bottom: 10.25rem !important; }
  .ml-md-10q1,
  .mx-md-10q1 {
    margin-left: 10.25rem !important; }
  .m-md-10q2 {
    margin: 10.5rem !important; }
  .mt-md-10q2,
  .my-md-10q2 {
    margin-top: 10.5rem !important; }
  .mr-md-10q2,
  .mx-md-10q2 {
    margin-right: 10.5rem !important; }
  .mb-md-10q2,
  .my-md-10q2 {
    margin-bottom: 10.5rem !important; }
  .ml-md-10q2,
  .mx-md-10q2 {
    margin-left: 10.5rem !important; }
  .m-md-10q3 {
    margin: 10.75rem !important; }
  .mt-md-10q3,
  .my-md-10q3 {
    margin-top: 10.75rem !important; }
  .mr-md-10q3,
  .mx-md-10q3 {
    margin-right: 10.75rem !important; }
  .mb-md-10q3,
  .my-md-10q3 {
    margin-bottom: 10.75rem !important; }
  .ml-md-10q3,
  .mx-md-10q3 {
    margin-left: 10.75rem !important; }
  .m-md-11 {
    margin: 11rem !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 11rem !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 11rem !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 11rem !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 11rem !important; }
  .m-md-11q1 {
    margin: 11.25rem !important; }
  .mt-md-11q1,
  .my-md-11q1 {
    margin-top: 11.25rem !important; }
  .mr-md-11q1,
  .mx-md-11q1 {
    margin-right: 11.25rem !important; }
  .mb-md-11q1,
  .my-md-11q1 {
    margin-bottom: 11.25rem !important; }
  .ml-md-11q1,
  .mx-md-11q1 {
    margin-left: 11.25rem !important; }
  .m-md-11 q2 {
    margin: 11.5rem !important; }
  .mt-md-11 q2,
  .my-md-11 q2 {
    margin-top: 11.5rem !important; }
  .mr-md-11 q2,
  .mx-md-11 q2 {
    margin-right: 11.5rem !important; }
  .mb-md-11 q2,
  .my-md-11 q2 {
    margin-bottom: 11.5rem !important; }
  .ml-md-11 q2,
  .mx-md-11 q2 {
    margin-left: 11.5rem !important; }
  .m-md-11 q3 {
    margin: 11.75rem !important; }
  .mt-md-11 q3,
  .my-md-11 q3 {
    margin-top: 11.75rem !important; }
  .mr-md-11 q3,
  .mx-md-11 q3 {
    margin-right: 11.75rem !important; }
  .mb-md-11 q3,
  .my-md-11 q3 {
    margin-bottom: 11.75rem !important; }
  .ml-md-11 q3,
  .mx-md-11 q3 {
    margin-left: 11.75rem !important; }
  .m-md-12 {
    margin: 12rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 12rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 12rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 12rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 12rem !important; }
  .m-md-12 q1 {
    margin: 12.25rem !important; }
  .mt-md-12 q1,
  .my-md-12 q1 {
    margin-top: 12.25rem !important; }
  .mr-md-12 q1,
  .mx-md-12 q1 {
    margin-right: 12.25rem !important; }
  .mb-md-12 q1,
  .my-md-12 q1 {
    margin-bottom: 12.25rem !important; }
  .ml-md-12 q1,
  .mx-md-12 q1 {
    margin-left: 12.25rem !important; }
  .m-md-12 q2 {
    margin: 12.5rem !important; }
  .mt-md-12 q2,
  .my-md-12 q2 {
    margin-top: 12.5rem !important; }
  .mr-md-12 q2,
  .mx-md-12 q2 {
    margin-right: 12.5rem !important; }
  .mb-md-12 q2,
  .my-md-12 q2 {
    margin-bottom: 12.5rem !important; }
  .ml-md-12 q2,
  .mx-md-12 q2 {
    margin-left: 12.5rem !important; }
  .m-md-12 q3 {
    margin: 12.75rem !important; }
  .mt-md-12 q3,
  .my-md-12 q3 {
    margin-top: 12.75rem !important; }
  .mr-md-12 q3,
  .mx-md-12 q3 {
    margin-right: 12.75rem !important; }
  .mb-md-12 q3,
  .my-md-12 q3 {
    margin-bottom: 12.75rem !important; }
  .ml-md-12 q3,
  .mx-md-12 q3 {
    margin-left: 12.75rem !important; }
  .p-md-q1 {
    padding: 0.25rem !important; }
  .pt-md-q1,
  .py-md-q1 {
    padding-top: 0.25rem !important; }
  .pr-md-q1,
  .px-md-q1 {
    padding-right: 0.25rem !important; }
  .pb-md-q1,
  .py-md-q1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-q1,
  .px-md-q1 {
    padding-left: 0.25rem !important; }
  .p-md-q2 {
    padding: 0.5rem !important; }
  .pt-md-q2,
  .py-md-q2 {
    padding-top: 0.5rem !important; }
  .pr-md-q2,
  .px-md-q2 {
    padding-right: 0.5rem !important; }
  .pb-md-q2,
  .py-md-q2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-q2,
  .px-md-q2 {
    padding-left: 0.5rem !important; }
  .p-md-q3 {
    padding: 0.75rem !important; }
  .pt-md-q3,
  .py-md-q3 {
    padding-top: 0.75rem !important; }
  .pr-md-q3,
  .px-md-q3 {
    padding-right: 0.75rem !important; }
  .pb-md-q3,
  .py-md-q3 {
    padding-bottom: 0.75rem !important; }
  .pl-md-q3,
  .px-md-q3 {
    padding-left: 0.75rem !important; }
  .p-md-0 {
    padding: 0rem !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0rem !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0rem !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0rem !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0rem !important; }
  .p-md-1 {
    padding: 1rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 1rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 1rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 1rem !important; }
  .p-md-1q1 {
    padding: 1.25rem !important; }
  .pt-md-1q1,
  .py-md-1q1 {
    padding-top: 1.25rem !important; }
  .pr-md-1q1,
  .px-md-1q1 {
    padding-right: 1.25rem !important; }
  .pb-md-1q1,
  .py-md-1q1 {
    padding-bottom: 1.25rem !important; }
  .pl-md-1q1,
  .px-md-1q1 {
    padding-left: 1.25rem !important; }
  .p-md-1q2 {
    padding: 1.5rem !important; }
  .pt-md-1q2,
  .py-md-1q2 {
    padding-top: 1.5rem !important; }
  .pr-md-1q2,
  .px-md-1q2 {
    padding-right: 1.5rem !important; }
  .pb-md-1q2,
  .py-md-1q2 {
    padding-bottom: 1.5rem !important; }
  .pl-md-1q2,
  .px-md-1q2 {
    padding-left: 1.5rem !important; }
  .p-md-1q3 {
    padding: 1.75rem !important; }
  .pt-md-1q3,
  .py-md-1q3 {
    padding-top: 1.75rem !important; }
  .pr-md-1q3,
  .px-md-1q3 {
    padding-right: 1.75rem !important; }
  .pb-md-1q3,
  .py-md-1q3 {
    padding-bottom: 1.75rem !important; }
  .pl-md-1q3,
  .px-md-1q3 {
    padding-left: 1.75rem !important; }
  .p-md-2 {
    padding: 2rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 2rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 2rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 2rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 2rem !important; }
  .p-md-2q1 {
    padding: 2.25rem !important; }
  .pt-md-2q1,
  .py-md-2q1 {
    padding-top: 2.25rem !important; }
  .pr-md-2q1,
  .px-md-2q1 {
    padding-right: 2.25rem !important; }
  .pb-md-2q1,
  .py-md-2q1 {
    padding-bottom: 2.25rem !important; }
  .pl-md-2q1,
  .px-md-2q1 {
    padding-left: 2.25rem !important; }
  .p-md-2q2 {
    padding: 2.5rem !important; }
  .pt-md-2q2,
  .py-md-2q2 {
    padding-top: 2.5rem !important; }
  .pr-md-2q2,
  .px-md-2q2 {
    padding-right: 2.5rem !important; }
  .pb-md-2q2,
  .py-md-2q2 {
    padding-bottom: 2.5rem !important; }
  .pl-md-2q2,
  .px-md-2q2 {
    padding-left: 2.5rem !important; }
  .p-md-2q3 {
    padding: 2.75rem !important; }
  .pt-md-2q3,
  .py-md-2q3 {
    padding-top: 2.75rem !important; }
  .pr-md-2q3,
  .px-md-2q3 {
    padding-right: 2.75rem !important; }
  .pb-md-2q3,
  .py-md-2q3 {
    padding-bottom: 2.75rem !important; }
  .pl-md-2q3,
  .px-md-2q3 {
    padding-left: 2.75rem !important; }
  .p-md-3 {
    padding: 3rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 3rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 3rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 3rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 3rem !important; }
  .p-md-3q1 {
    padding: 3.25rem !important; }
  .pt-md-3q1,
  .py-md-3q1 {
    padding-top: 3.25rem !important; }
  .pr-md-3q1,
  .px-md-3q1 {
    padding-right: 3.25rem !important; }
  .pb-md-3q1,
  .py-md-3q1 {
    padding-bottom: 3.25rem !important; }
  .pl-md-3q1,
  .px-md-3q1 {
    padding-left: 3.25rem !important; }
  .p-md-3q2 {
    padding: 3.5rem !important; }
  .pt-md-3q2,
  .py-md-3q2 {
    padding-top: 3.5rem !important; }
  .pr-md-3q2,
  .px-md-3q2 {
    padding-right: 3.5rem !important; }
  .pb-md-3q2,
  .py-md-3q2 {
    padding-bottom: 3.5rem !important; }
  .pl-md-3q2,
  .px-md-3q2 {
    padding-left: 3.5rem !important; }
  .p-md-3q3 {
    padding: 3.75rem !important; }
  .pt-md-3q3,
  .py-md-3q3 {
    padding-top: 3.75rem !important; }
  .pr-md-3q3,
  .px-md-3q3 {
    padding-right: 3.75rem !important; }
  .pb-md-3q3,
  .py-md-3q3 {
    padding-bottom: 3.75rem !important; }
  .pl-md-3q3,
  .px-md-3q3 {
    padding-left: 3.75rem !important; }
  .p-md-4 {
    padding: 4rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 4rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 4rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 4rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 4rem !important; }
  .p-md-4q1 {
    padding: 4.25rem !important; }
  .pt-md-4q1,
  .py-md-4q1 {
    padding-top: 4.25rem !important; }
  .pr-md-4q1,
  .px-md-4q1 {
    padding-right: 4.25rem !important; }
  .pb-md-4q1,
  .py-md-4q1 {
    padding-bottom: 4.25rem !important; }
  .pl-md-4q1,
  .px-md-4q1 {
    padding-left: 4.25rem !important; }
  .p-md-4q2 {
    padding: 4.5rem !important; }
  .pt-md-4q2,
  .py-md-4q2 {
    padding-top: 4.5rem !important; }
  .pr-md-4q2,
  .px-md-4q2 {
    padding-right: 4.5rem !important; }
  .pb-md-4q2,
  .py-md-4q2 {
    padding-bottom: 4.5rem !important; }
  .pl-md-4q2,
  .px-md-4q2 {
    padding-left: 4.5rem !important; }
  .p-md-4q3 {
    padding: 4.75rem !important; }
  .pt-md-4q3,
  .py-md-4q3 {
    padding-top: 4.75rem !important; }
  .pr-md-4q3,
  .px-md-4q3 {
    padding-right: 4.75rem !important; }
  .pb-md-4q3,
  .py-md-4q3 {
    padding-bottom: 4.75rem !important; }
  .pl-md-4q3,
  .px-md-4q3 {
    padding-left: 4.75rem !important; }
  .p-md-5 {
    padding: 5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5rem !important; }
  .p-md-5q1 {
    padding: 5.25rem !important; }
  .pt-md-5q1,
  .py-md-5q1 {
    padding-top: 5.25rem !important; }
  .pr-md-5q1,
  .px-md-5q1 {
    padding-right: 5.25rem !important; }
  .pb-md-5q1,
  .py-md-5q1 {
    padding-bottom: 5.25rem !important; }
  .pl-md-5q1,
  .px-md-5q1 {
    padding-left: 5.25rem !important; }
  .p-md-5q2 {
    padding: 5.5rem !important; }
  .pt-md-5q2,
  .py-md-5q2 {
    padding-top: 5.5rem !important; }
  .pr-md-5q2,
  .px-md-5q2 {
    padding-right: 5.5rem !important; }
  .pb-md-5q2,
  .py-md-5q2 {
    padding-bottom: 5.5rem !important; }
  .pl-md-5q2,
  .px-md-5q2 {
    padding-left: 5.5rem !important; }
  .p-md-5q3 {
    padding: 5.75rem !important; }
  .pt-md-5q3,
  .py-md-5q3 {
    padding-top: 5.75rem !important; }
  .pr-md-5q3,
  .px-md-5q3 {
    padding-right: 5.75rem !important; }
  .pb-md-5q3,
  .py-md-5q3 {
    padding-bottom: 5.75rem !important; }
  .pl-md-5q3,
  .px-md-5q3 {
    padding-left: 5.75rem !important; }
  .p-md-6 {
    padding: 6rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 6rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 6rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 6rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 6rem !important; }
  .p-md-6q1 {
    padding: 6.25rem !important; }
  .pt-md-6q1,
  .py-md-6q1 {
    padding-top: 6.25rem !important; }
  .pr-md-6q1,
  .px-md-6q1 {
    padding-right: 6.25rem !important; }
  .pb-md-6q1,
  .py-md-6q1 {
    padding-bottom: 6.25rem !important; }
  .pl-md-6q1,
  .px-md-6q1 {
    padding-left: 6.25rem !important; }
  .p-md-6q2 {
    padding: 6.5rem !important; }
  .pt-md-6q2,
  .py-md-6q2 {
    padding-top: 6.5rem !important; }
  .pr-md-6q2,
  .px-md-6q2 {
    padding-right: 6.5rem !important; }
  .pb-md-6q2,
  .py-md-6q2 {
    padding-bottom: 6.5rem !important; }
  .pl-md-6q2,
  .px-md-6q2 {
    padding-left: 6.5rem !important; }
  .p-md-6q3 {
    padding: 6.75rem !important; }
  .pt-md-6q3,
  .py-md-6q3 {
    padding-top: 6.75rem !important; }
  .pr-md-6q3,
  .px-md-6q3 {
    padding-right: 6.75rem !important; }
  .pb-md-6q3,
  .py-md-6q3 {
    padding-bottom: 6.75rem !important; }
  .pl-md-6q3,
  .px-md-6q3 {
    padding-left: 6.75rem !important; }
  .p-md-7 {
    padding: 7rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 7rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 7rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 7rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 7rem !important; }
  .p-md-7q1 {
    padding: 7.25rem !important; }
  .pt-md-7q1,
  .py-md-7q1 {
    padding-top: 7.25rem !important; }
  .pr-md-7q1,
  .px-md-7q1 {
    padding-right: 7.25rem !important; }
  .pb-md-7q1,
  .py-md-7q1 {
    padding-bottom: 7.25rem !important; }
  .pl-md-7q1,
  .px-md-7q1 {
    padding-left: 7.25rem !important; }
  .p-md-7q2 {
    padding: 7.5rem !important; }
  .pt-md-7q2,
  .py-md-7q2 {
    padding-top: 7.5rem !important; }
  .pr-md-7q2,
  .px-md-7q2 {
    padding-right: 7.5rem !important; }
  .pb-md-7q2,
  .py-md-7q2 {
    padding-bottom: 7.5rem !important; }
  .pl-md-7q2,
  .px-md-7q2 {
    padding-left: 7.5rem !important; }
  .p-md-7q3 {
    padding: 7.75rem !important; }
  .pt-md-7q3,
  .py-md-7q3 {
    padding-top: 7.75rem !important; }
  .pr-md-7q3,
  .px-md-7q3 {
    padding-right: 7.75rem !important; }
  .pb-md-7q3,
  .py-md-7q3 {
    padding-bottom: 7.75rem !important; }
  .pl-md-7q3,
  .px-md-7q3 {
    padding-left: 7.75rem !important; }
  .p-md-8 {
    padding: 8rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 8rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 8rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 8rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 8rem !important; }
  .p-md-8q1 {
    padding: 8.25rem !important; }
  .pt-md-8q1,
  .py-md-8q1 {
    padding-top: 8.25rem !important; }
  .pr-md-8q1,
  .px-md-8q1 {
    padding-right: 8.25rem !important; }
  .pb-md-8q1,
  .py-md-8q1 {
    padding-bottom: 8.25rem !important; }
  .pl-md-8q1,
  .px-md-8q1 {
    padding-left: 8.25rem !important; }
  .p-md-8q2 {
    padding: 8.5rem !important; }
  .pt-md-8q2,
  .py-md-8q2 {
    padding-top: 8.5rem !important; }
  .pr-md-8q2,
  .px-md-8q2 {
    padding-right: 8.5rem !important; }
  .pb-md-8q2,
  .py-md-8q2 {
    padding-bottom: 8.5rem !important; }
  .pl-md-8q2,
  .px-md-8q2 {
    padding-left: 8.5rem !important; }
  .p-md-8q3 {
    padding: 8.75rem !important; }
  .pt-md-8q3,
  .py-md-8q3 {
    padding-top: 8.75rem !important; }
  .pr-md-8q3,
  .px-md-8q3 {
    padding-right: 8.75rem !important; }
  .pb-md-8q3,
  .py-md-8q3 {
    padding-bottom: 8.75rem !important; }
  .pl-md-8q3,
  .px-md-8q3 {
    padding-left: 8.75rem !important; }
  .p-md-9 {
    padding: 9rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 9rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 9rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 9rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 9rem !important; }
  .p-md-9q1 {
    padding: 9.25rem !important; }
  .pt-md-9q1,
  .py-md-9q1 {
    padding-top: 9.25rem !important; }
  .pr-md-9q1,
  .px-md-9q1 {
    padding-right: 9.25rem !important; }
  .pb-md-9q1,
  .py-md-9q1 {
    padding-bottom: 9.25rem !important; }
  .pl-md-9q1,
  .px-md-9q1 {
    padding-left: 9.25rem !important; }
  .p-md-9q2 {
    padding: 9.5rem !important; }
  .pt-md-9q2,
  .py-md-9q2 {
    padding-top: 9.5rem !important; }
  .pr-md-9q2,
  .px-md-9q2 {
    padding-right: 9.5rem !important; }
  .pb-md-9q2,
  .py-md-9q2 {
    padding-bottom: 9.5rem !important; }
  .pl-md-9q2,
  .px-md-9q2 {
    padding-left: 9.5rem !important; }
  .p-md-9q3 {
    padding: 9.75rem !important; }
  .pt-md-9q3,
  .py-md-9q3 {
    padding-top: 9.75rem !important; }
  .pr-md-9q3,
  .px-md-9q3 {
    padding-right: 9.75rem !important; }
  .pb-md-9q3,
  .py-md-9q3 {
    padding-bottom: 9.75rem !important; }
  .pl-md-9q3,
  .px-md-9q3 {
    padding-left: 9.75rem !important; }
  .p-md-10 {
    padding: 10rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10rem !important; }
  .p-md-10q1 {
    padding: 10.25rem !important; }
  .pt-md-10q1,
  .py-md-10q1 {
    padding-top: 10.25rem !important; }
  .pr-md-10q1,
  .px-md-10q1 {
    padding-right: 10.25rem !important; }
  .pb-md-10q1,
  .py-md-10q1 {
    padding-bottom: 10.25rem !important; }
  .pl-md-10q1,
  .px-md-10q1 {
    padding-left: 10.25rem !important; }
  .p-md-10q2 {
    padding: 10.5rem !important; }
  .pt-md-10q2,
  .py-md-10q2 {
    padding-top: 10.5rem !important; }
  .pr-md-10q2,
  .px-md-10q2 {
    padding-right: 10.5rem !important; }
  .pb-md-10q2,
  .py-md-10q2 {
    padding-bottom: 10.5rem !important; }
  .pl-md-10q2,
  .px-md-10q2 {
    padding-left: 10.5rem !important; }
  .p-md-10q3 {
    padding: 10.75rem !important; }
  .pt-md-10q3,
  .py-md-10q3 {
    padding-top: 10.75rem !important; }
  .pr-md-10q3,
  .px-md-10q3 {
    padding-right: 10.75rem !important; }
  .pb-md-10q3,
  .py-md-10q3 {
    padding-bottom: 10.75rem !important; }
  .pl-md-10q3,
  .px-md-10q3 {
    padding-left: 10.75rem !important; }
  .p-md-11 {
    padding: 11rem !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 11rem !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 11rem !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 11rem !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 11rem !important; }
  .p-md-11q1 {
    padding: 11.25rem !important; }
  .pt-md-11q1,
  .py-md-11q1 {
    padding-top: 11.25rem !important; }
  .pr-md-11q1,
  .px-md-11q1 {
    padding-right: 11.25rem !important; }
  .pb-md-11q1,
  .py-md-11q1 {
    padding-bottom: 11.25rem !important; }
  .pl-md-11q1,
  .px-md-11q1 {
    padding-left: 11.25rem !important; }
  .p-md-11 q2 {
    padding: 11.5rem !important; }
  .pt-md-11 q2,
  .py-md-11 q2 {
    padding-top: 11.5rem !important; }
  .pr-md-11 q2,
  .px-md-11 q2 {
    padding-right: 11.5rem !important; }
  .pb-md-11 q2,
  .py-md-11 q2 {
    padding-bottom: 11.5rem !important; }
  .pl-md-11 q2,
  .px-md-11 q2 {
    padding-left: 11.5rem !important; }
  .p-md-11 q3 {
    padding: 11.75rem !important; }
  .pt-md-11 q3,
  .py-md-11 q3 {
    padding-top: 11.75rem !important; }
  .pr-md-11 q3,
  .px-md-11 q3 {
    padding-right: 11.75rem !important; }
  .pb-md-11 q3,
  .py-md-11 q3 {
    padding-bottom: 11.75rem !important; }
  .pl-md-11 q3,
  .px-md-11 q3 {
    padding-left: 11.75rem !important; }
  .p-md-12 {
    padding: 12rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 12rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 12rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 12rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 12rem !important; }
  .p-md-12 q1 {
    padding: 12.25rem !important; }
  .pt-md-12 q1,
  .py-md-12 q1 {
    padding-top: 12.25rem !important; }
  .pr-md-12 q1,
  .px-md-12 q1 {
    padding-right: 12.25rem !important; }
  .pb-md-12 q1,
  .py-md-12 q1 {
    padding-bottom: 12.25rem !important; }
  .pl-md-12 q1,
  .px-md-12 q1 {
    padding-left: 12.25rem !important; }
  .p-md-12 q2 {
    padding: 12.5rem !important; }
  .pt-md-12 q2,
  .py-md-12 q2 {
    padding-top: 12.5rem !important; }
  .pr-md-12 q2,
  .px-md-12 q2 {
    padding-right: 12.5rem !important; }
  .pb-md-12 q2,
  .py-md-12 q2 {
    padding-bottom: 12.5rem !important; }
  .pl-md-12 q2,
  .px-md-12 q2 {
    padding-left: 12.5rem !important; }
  .p-md-12 q3 {
    padding: 12.75rem !important; }
  .pt-md-12 q3,
  .py-md-12 q3 {
    padding-top: 12.75rem !important; }
  .pr-md-12 q3,
  .px-md-12 q3 {
    padding-right: 12.75rem !important; }
  .pb-md-12 q3,
  .py-md-12 q3 {
    padding-bottom: 12.75rem !important; }
  .pl-md-12 q3,
  .px-md-12 q3 {
    padding-left: 12.75rem !important; }
  .m-md-nq1 {
    margin: -0.25rem !important; }
  .mt-md-nq1,
  .my-md-nq1 {
    margin-top: -0.25rem !important; }
  .mr-md-nq1,
  .mx-md-nq1 {
    margin-right: -0.25rem !important; }
  .mb-md-nq1,
  .my-md-nq1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-nq1,
  .mx-md-nq1 {
    margin-left: -0.25rem !important; }
  .m-md-nq2 {
    margin: -0.5rem !important; }
  .mt-md-nq2,
  .my-md-nq2 {
    margin-top: -0.5rem !important; }
  .mr-md-nq2,
  .mx-md-nq2 {
    margin-right: -0.5rem !important; }
  .mb-md-nq2,
  .my-md-nq2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-nq2,
  .mx-md-nq2 {
    margin-left: -0.5rem !important; }
  .m-md-nq3 {
    margin: -0.75rem !important; }
  .mt-md-nq3,
  .my-md-nq3 {
    margin-top: -0.75rem !important; }
  .mr-md-nq3,
  .mx-md-nq3 {
    margin-right: -0.75rem !important; }
  .mb-md-nq3,
  .my-md-nq3 {
    margin-bottom: -0.75rem !important; }
  .ml-md-nq3,
  .mx-md-nq3 {
    margin-left: -0.75rem !important; }
  .m-md-n1 {
    margin: -1rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -1rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -1rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -1rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -1rem !important; }
  .m-md-n1q1 {
    margin: -1.25rem !important; }
  .mt-md-n1q1,
  .my-md-n1q1 {
    margin-top: -1.25rem !important; }
  .mr-md-n1q1,
  .mx-md-n1q1 {
    margin-right: -1.25rem !important; }
  .mb-md-n1q1,
  .my-md-n1q1 {
    margin-bottom: -1.25rem !important; }
  .ml-md-n1q1,
  .mx-md-n1q1 {
    margin-left: -1.25rem !important; }
  .m-md-n1q2 {
    margin: -1.5rem !important; }
  .mt-md-n1q2,
  .my-md-n1q2 {
    margin-top: -1.5rem !important; }
  .mr-md-n1q2,
  .mx-md-n1q2 {
    margin-right: -1.5rem !important; }
  .mb-md-n1q2,
  .my-md-n1q2 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n1q2,
  .mx-md-n1q2 {
    margin-left: -1.5rem !important; }
  .m-md-n1q3 {
    margin: -1.75rem !important; }
  .mt-md-n1q3,
  .my-md-n1q3 {
    margin-top: -1.75rem !important; }
  .mr-md-n1q3,
  .mx-md-n1q3 {
    margin-right: -1.75rem !important; }
  .mb-md-n1q3,
  .my-md-n1q3 {
    margin-bottom: -1.75rem !important; }
  .ml-md-n1q3,
  .mx-md-n1q3 {
    margin-left: -1.75rem !important; }
  .m-md-n2 {
    margin: -2rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -2rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -2rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -2rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -2rem !important; }
  .m-md-n2q1 {
    margin: -2.25rem !important; }
  .mt-md-n2q1,
  .my-md-n2q1 {
    margin-top: -2.25rem !important; }
  .mr-md-n2q1,
  .mx-md-n2q1 {
    margin-right: -2.25rem !important; }
  .mb-md-n2q1,
  .my-md-n2q1 {
    margin-bottom: -2.25rem !important; }
  .ml-md-n2q1,
  .mx-md-n2q1 {
    margin-left: -2.25rem !important; }
  .m-md-n2q2 {
    margin: -2.5rem !important; }
  .mt-md-n2q2,
  .my-md-n2q2 {
    margin-top: -2.5rem !important; }
  .mr-md-n2q2,
  .mx-md-n2q2 {
    margin-right: -2.5rem !important; }
  .mb-md-n2q2,
  .my-md-n2q2 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n2q2,
  .mx-md-n2q2 {
    margin-left: -2.5rem !important; }
  .m-md-n2q3 {
    margin: -2.75rem !important; }
  .mt-md-n2q3,
  .my-md-n2q3 {
    margin-top: -2.75rem !important; }
  .mr-md-n2q3,
  .mx-md-n2q3 {
    margin-right: -2.75rem !important; }
  .mb-md-n2q3,
  .my-md-n2q3 {
    margin-bottom: -2.75rem !important; }
  .ml-md-n2q3,
  .mx-md-n2q3 {
    margin-left: -2.75rem !important; }
  .m-md-n3 {
    margin: -3rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -3rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -3rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -3rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -3rem !important; }
  .m-md-n3q1 {
    margin: -3.25rem !important; }
  .mt-md-n3q1,
  .my-md-n3q1 {
    margin-top: -3.25rem !important; }
  .mr-md-n3q1,
  .mx-md-n3q1 {
    margin-right: -3.25rem !important; }
  .mb-md-n3q1,
  .my-md-n3q1 {
    margin-bottom: -3.25rem !important; }
  .ml-md-n3q1,
  .mx-md-n3q1 {
    margin-left: -3.25rem !important; }
  .m-md-n3q2 {
    margin: -3.5rem !important; }
  .mt-md-n3q2,
  .my-md-n3q2 {
    margin-top: -3.5rem !important; }
  .mr-md-n3q2,
  .mx-md-n3q2 {
    margin-right: -3.5rem !important; }
  .mb-md-n3q2,
  .my-md-n3q2 {
    margin-bottom: -3.5rem !important; }
  .ml-md-n3q2,
  .mx-md-n3q2 {
    margin-left: -3.5rem !important; }
  .m-md-n3q3 {
    margin: -3.75rem !important; }
  .mt-md-n3q3,
  .my-md-n3q3 {
    margin-top: -3.75rem !important; }
  .mr-md-n3q3,
  .mx-md-n3q3 {
    margin-right: -3.75rem !important; }
  .mb-md-n3q3,
  .my-md-n3q3 {
    margin-bottom: -3.75rem !important; }
  .ml-md-n3q3,
  .mx-md-n3q3 {
    margin-left: -3.75rem !important; }
  .m-md-n4 {
    margin: -4rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -4rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -4rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -4rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -4rem !important; }
  .m-md-n4q1 {
    margin: -4.25rem !important; }
  .mt-md-n4q1,
  .my-md-n4q1 {
    margin-top: -4.25rem !important; }
  .mr-md-n4q1,
  .mx-md-n4q1 {
    margin-right: -4.25rem !important; }
  .mb-md-n4q1,
  .my-md-n4q1 {
    margin-bottom: -4.25rem !important; }
  .ml-md-n4q1,
  .mx-md-n4q1 {
    margin-left: -4.25rem !important; }
  .m-md-n4q2 {
    margin: -4.5rem !important; }
  .mt-md-n4q2,
  .my-md-n4q2 {
    margin-top: -4.5rem !important; }
  .mr-md-n4q2,
  .mx-md-n4q2 {
    margin-right: -4.5rem !important; }
  .mb-md-n4q2,
  .my-md-n4q2 {
    margin-bottom: -4.5rem !important; }
  .ml-md-n4q2,
  .mx-md-n4q2 {
    margin-left: -4.5rem !important; }
  .m-md-n4q3 {
    margin: -4.75rem !important; }
  .mt-md-n4q3,
  .my-md-n4q3 {
    margin-top: -4.75rem !important; }
  .mr-md-n4q3,
  .mx-md-n4q3 {
    margin-right: -4.75rem !important; }
  .mb-md-n4q3,
  .my-md-n4q3 {
    margin-bottom: -4.75rem !important; }
  .ml-md-n4q3,
  .mx-md-n4q3 {
    margin-left: -4.75rem !important; }
  .m-md-n5 {
    margin: -5rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -5rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -5rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -5rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -5rem !important; }
  .m-md-n5q1 {
    margin: -5.25rem !important; }
  .mt-md-n5q1,
  .my-md-n5q1 {
    margin-top: -5.25rem !important; }
  .mr-md-n5q1,
  .mx-md-n5q1 {
    margin-right: -5.25rem !important; }
  .mb-md-n5q1,
  .my-md-n5q1 {
    margin-bottom: -5.25rem !important; }
  .ml-md-n5q1,
  .mx-md-n5q1 {
    margin-left: -5.25rem !important; }
  .m-md-n5q2 {
    margin: -5.5rem !important; }
  .mt-md-n5q2,
  .my-md-n5q2 {
    margin-top: -5.5rem !important; }
  .mr-md-n5q2,
  .mx-md-n5q2 {
    margin-right: -5.5rem !important; }
  .mb-md-n5q2,
  .my-md-n5q2 {
    margin-bottom: -5.5rem !important; }
  .ml-md-n5q2,
  .mx-md-n5q2 {
    margin-left: -5.5rem !important; }
  .m-md-n5q3 {
    margin: -5.75rem !important; }
  .mt-md-n5q3,
  .my-md-n5q3 {
    margin-top: -5.75rem !important; }
  .mr-md-n5q3,
  .mx-md-n5q3 {
    margin-right: -5.75rem !important; }
  .mb-md-n5q3,
  .my-md-n5q3 {
    margin-bottom: -5.75rem !important; }
  .ml-md-n5q3,
  .mx-md-n5q3 {
    margin-left: -5.75rem !important; }
  .m-md-n6 {
    margin: -6rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -6rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -6rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -6rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -6rem !important; }
  .m-md-n6q1 {
    margin: -6.25rem !important; }
  .mt-md-n6q1,
  .my-md-n6q1 {
    margin-top: -6.25rem !important; }
  .mr-md-n6q1,
  .mx-md-n6q1 {
    margin-right: -6.25rem !important; }
  .mb-md-n6q1,
  .my-md-n6q1 {
    margin-bottom: -6.25rem !important; }
  .ml-md-n6q1,
  .mx-md-n6q1 {
    margin-left: -6.25rem !important; }
  .m-md-n6q2 {
    margin: -6.5rem !important; }
  .mt-md-n6q2,
  .my-md-n6q2 {
    margin-top: -6.5rem !important; }
  .mr-md-n6q2,
  .mx-md-n6q2 {
    margin-right: -6.5rem !important; }
  .mb-md-n6q2,
  .my-md-n6q2 {
    margin-bottom: -6.5rem !important; }
  .ml-md-n6q2,
  .mx-md-n6q2 {
    margin-left: -6.5rem !important; }
  .m-md-n6q3 {
    margin: -6.75rem !important; }
  .mt-md-n6q3,
  .my-md-n6q3 {
    margin-top: -6.75rem !important; }
  .mr-md-n6q3,
  .mx-md-n6q3 {
    margin-right: -6.75rem !important; }
  .mb-md-n6q3,
  .my-md-n6q3 {
    margin-bottom: -6.75rem !important; }
  .ml-md-n6q3,
  .mx-md-n6q3 {
    margin-left: -6.75rem !important; }
  .m-md-n7 {
    margin: -7rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -7rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -7rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -7rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -7rem !important; }
  .m-md-n7q1 {
    margin: -7.25rem !important; }
  .mt-md-n7q1,
  .my-md-n7q1 {
    margin-top: -7.25rem !important; }
  .mr-md-n7q1,
  .mx-md-n7q1 {
    margin-right: -7.25rem !important; }
  .mb-md-n7q1,
  .my-md-n7q1 {
    margin-bottom: -7.25rem !important; }
  .ml-md-n7q1,
  .mx-md-n7q1 {
    margin-left: -7.25rem !important; }
  .m-md-n7q2 {
    margin: -7.5rem !important; }
  .mt-md-n7q2,
  .my-md-n7q2 {
    margin-top: -7.5rem !important; }
  .mr-md-n7q2,
  .mx-md-n7q2 {
    margin-right: -7.5rem !important; }
  .mb-md-n7q2,
  .my-md-n7q2 {
    margin-bottom: -7.5rem !important; }
  .ml-md-n7q2,
  .mx-md-n7q2 {
    margin-left: -7.5rem !important; }
  .m-md-n7q3 {
    margin: -7.75rem !important; }
  .mt-md-n7q3,
  .my-md-n7q3 {
    margin-top: -7.75rem !important; }
  .mr-md-n7q3,
  .mx-md-n7q3 {
    margin-right: -7.75rem !important; }
  .mb-md-n7q3,
  .my-md-n7q3 {
    margin-bottom: -7.75rem !important; }
  .ml-md-n7q3,
  .mx-md-n7q3 {
    margin-left: -7.75rem !important; }
  .m-md-n8 {
    margin: -8rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -8rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -8rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -8rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -8rem !important; }
  .m-md-n8q1 {
    margin: -8.25rem !important; }
  .mt-md-n8q1,
  .my-md-n8q1 {
    margin-top: -8.25rem !important; }
  .mr-md-n8q1,
  .mx-md-n8q1 {
    margin-right: -8.25rem !important; }
  .mb-md-n8q1,
  .my-md-n8q1 {
    margin-bottom: -8.25rem !important; }
  .ml-md-n8q1,
  .mx-md-n8q1 {
    margin-left: -8.25rem !important; }
  .m-md-n8q2 {
    margin: -8.5rem !important; }
  .mt-md-n8q2,
  .my-md-n8q2 {
    margin-top: -8.5rem !important; }
  .mr-md-n8q2,
  .mx-md-n8q2 {
    margin-right: -8.5rem !important; }
  .mb-md-n8q2,
  .my-md-n8q2 {
    margin-bottom: -8.5rem !important; }
  .ml-md-n8q2,
  .mx-md-n8q2 {
    margin-left: -8.5rem !important; }
  .m-md-n8q3 {
    margin: -8.75rem !important; }
  .mt-md-n8q3,
  .my-md-n8q3 {
    margin-top: -8.75rem !important; }
  .mr-md-n8q3,
  .mx-md-n8q3 {
    margin-right: -8.75rem !important; }
  .mb-md-n8q3,
  .my-md-n8q3 {
    margin-bottom: -8.75rem !important; }
  .ml-md-n8q3,
  .mx-md-n8q3 {
    margin-left: -8.75rem !important; }
  .m-md-n9 {
    margin: -9rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -9rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -9rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -9rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -9rem !important; }
  .m-md-n9q1 {
    margin: -9.25rem !important; }
  .mt-md-n9q1,
  .my-md-n9q1 {
    margin-top: -9.25rem !important; }
  .mr-md-n9q1,
  .mx-md-n9q1 {
    margin-right: -9.25rem !important; }
  .mb-md-n9q1,
  .my-md-n9q1 {
    margin-bottom: -9.25rem !important; }
  .ml-md-n9q1,
  .mx-md-n9q1 {
    margin-left: -9.25rem !important; }
  .m-md-n9q2 {
    margin: -9.5rem !important; }
  .mt-md-n9q2,
  .my-md-n9q2 {
    margin-top: -9.5rem !important; }
  .mr-md-n9q2,
  .mx-md-n9q2 {
    margin-right: -9.5rem !important; }
  .mb-md-n9q2,
  .my-md-n9q2 {
    margin-bottom: -9.5rem !important; }
  .ml-md-n9q2,
  .mx-md-n9q2 {
    margin-left: -9.5rem !important; }
  .m-md-n9q3 {
    margin: -9.75rem !important; }
  .mt-md-n9q3,
  .my-md-n9q3 {
    margin-top: -9.75rem !important; }
  .mr-md-n9q3,
  .mx-md-n9q3 {
    margin-right: -9.75rem !important; }
  .mb-md-n9q3,
  .my-md-n9q3 {
    margin-bottom: -9.75rem !important; }
  .ml-md-n9q3,
  .mx-md-n9q3 {
    margin-left: -9.75rem !important; }
  .m-md-n10 {
    margin: -10rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -10rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -10rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -10rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -10rem !important; }
  .m-md-n10q1 {
    margin: -10.25rem !important; }
  .mt-md-n10q1,
  .my-md-n10q1 {
    margin-top: -10.25rem !important; }
  .mr-md-n10q1,
  .mx-md-n10q1 {
    margin-right: -10.25rem !important; }
  .mb-md-n10q1,
  .my-md-n10q1 {
    margin-bottom: -10.25rem !important; }
  .ml-md-n10q1,
  .mx-md-n10q1 {
    margin-left: -10.25rem !important; }
  .m-md-n10q2 {
    margin: -10.5rem !important; }
  .mt-md-n10q2,
  .my-md-n10q2 {
    margin-top: -10.5rem !important; }
  .mr-md-n10q2,
  .mx-md-n10q2 {
    margin-right: -10.5rem !important; }
  .mb-md-n10q2,
  .my-md-n10q2 {
    margin-bottom: -10.5rem !important; }
  .ml-md-n10q2,
  .mx-md-n10q2 {
    margin-left: -10.5rem !important; }
  .m-md-n10q3 {
    margin: -10.75rem !important; }
  .mt-md-n10q3,
  .my-md-n10q3 {
    margin-top: -10.75rem !important; }
  .mr-md-n10q3,
  .mx-md-n10q3 {
    margin-right: -10.75rem !important; }
  .mb-md-n10q3,
  .my-md-n10q3 {
    margin-bottom: -10.75rem !important; }
  .ml-md-n10q3,
  .mx-md-n10q3 {
    margin-left: -10.75rem !important; }
  .m-md-n11 {
    margin: -11rem !important; }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -11rem !important; }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -11rem !important; }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -11rem !important; }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -11rem !important; }
  .m-md-n11q1 {
    margin: -11.25rem !important; }
  .mt-md-n11q1,
  .my-md-n11q1 {
    margin-top: -11.25rem !important; }
  .mr-md-n11q1,
  .mx-md-n11q1 {
    margin-right: -11.25rem !important; }
  .mb-md-n11q1,
  .my-md-n11q1 {
    margin-bottom: -11.25rem !important; }
  .ml-md-n11q1,
  .mx-md-n11q1 {
    margin-left: -11.25rem !important; }
  .m-md-n11 q2 {
    margin: -11.5rem !important; }
  .mt-md-n11 q2,
  .my-md-n11 q2 {
    margin-top: -11.5rem !important; }
  .mr-md-n11 q2,
  .mx-md-n11 q2 {
    margin-right: -11.5rem !important; }
  .mb-md-n11 q2,
  .my-md-n11 q2 {
    margin-bottom: -11.5rem !important; }
  .ml-md-n11 q2,
  .mx-md-n11 q2 {
    margin-left: -11.5rem !important; }
  .m-md-n11 q3 {
    margin: -11.75rem !important; }
  .mt-md-n11 q3,
  .my-md-n11 q3 {
    margin-top: -11.75rem !important; }
  .mr-md-n11 q3,
  .mx-md-n11 q3 {
    margin-right: -11.75rem !important; }
  .mb-md-n11 q3,
  .my-md-n11 q3 {
    margin-bottom: -11.75rem !important; }
  .ml-md-n11 q3,
  .mx-md-n11 q3 {
    margin-left: -11.75rem !important; }
  .m-md-n12 {
    margin: -12rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -12rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -12rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -12rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -12rem !important; }
  .m-md-n12 q1 {
    margin: -12.25rem !important; }
  .mt-md-n12 q1,
  .my-md-n12 q1 {
    margin-top: -12.25rem !important; }
  .mr-md-n12 q1,
  .mx-md-n12 q1 {
    margin-right: -12.25rem !important; }
  .mb-md-n12 q1,
  .my-md-n12 q1 {
    margin-bottom: -12.25rem !important; }
  .ml-md-n12 q1,
  .mx-md-n12 q1 {
    margin-left: -12.25rem !important; }
  .m-md-n12 q2 {
    margin: -12.5rem !important; }
  .mt-md-n12 q2,
  .my-md-n12 q2 {
    margin-top: -12.5rem !important; }
  .mr-md-n12 q2,
  .mx-md-n12 q2 {
    margin-right: -12.5rem !important; }
  .mb-md-n12 q2,
  .my-md-n12 q2 {
    margin-bottom: -12.5rem !important; }
  .ml-md-n12 q2,
  .mx-md-n12 q2 {
    margin-left: -12.5rem !important; }
  .m-md-n12 q3 {
    margin: -12.75rem !important; }
  .mt-md-n12 q3,
  .my-md-n12 q3 {
    margin-top: -12.75rem !important; }
  .mr-md-n12 q3,
  .mx-md-n12 q3 {
    margin-right: -12.75rem !important; }
  .mb-md-n12 q3,
  .my-md-n12 q3 {
    margin-bottom: -12.75rem !important; }
  .ml-md-n12 q3,
  .mx-md-n12 q3 {
    margin-left: -12.75rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-q1 {
    margin: 0.25rem !important; }
  .mt-lg-q1,
  .my-lg-q1 {
    margin-top: 0.25rem !important; }
  .mr-lg-q1,
  .mx-lg-q1 {
    margin-right: 0.25rem !important; }
  .mb-lg-q1,
  .my-lg-q1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-q1,
  .mx-lg-q1 {
    margin-left: 0.25rem !important; }
  .m-lg-q2 {
    margin: 0.5rem !important; }
  .mt-lg-q2,
  .my-lg-q2 {
    margin-top: 0.5rem !important; }
  .mr-lg-q2,
  .mx-lg-q2 {
    margin-right: 0.5rem !important; }
  .mb-lg-q2,
  .my-lg-q2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-q2,
  .mx-lg-q2 {
    margin-left: 0.5rem !important; }
  .m-lg-q3 {
    margin: 0.75rem !important; }
  .mt-lg-q3,
  .my-lg-q3 {
    margin-top: 0.75rem !important; }
  .mr-lg-q3,
  .mx-lg-q3 {
    margin-right: 0.75rem !important; }
  .mb-lg-q3,
  .my-lg-q3 {
    margin-bottom: 0.75rem !important; }
  .ml-lg-q3,
  .mx-lg-q3 {
    margin-left: 0.75rem !important; }
  .m-lg-0 {
    margin: 0rem !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0rem !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0rem !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0rem !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0rem !important; }
  .m-lg-1 {
    margin: 1rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 1rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 1rem !important; }
  .m-lg-1q1 {
    margin: 1.25rem !important; }
  .mt-lg-1q1,
  .my-lg-1q1 {
    margin-top: 1.25rem !important; }
  .mr-lg-1q1,
  .mx-lg-1q1 {
    margin-right: 1.25rem !important; }
  .mb-lg-1q1,
  .my-lg-1q1 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-1q1,
  .mx-lg-1q1 {
    margin-left: 1.25rem !important; }
  .m-lg-1q2 {
    margin: 1.5rem !important; }
  .mt-lg-1q2,
  .my-lg-1q2 {
    margin-top: 1.5rem !important; }
  .mr-lg-1q2,
  .mx-lg-1q2 {
    margin-right: 1.5rem !important; }
  .mb-lg-1q2,
  .my-lg-1q2 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-1q2,
  .mx-lg-1q2 {
    margin-left: 1.5rem !important; }
  .m-lg-1q3 {
    margin: 1.75rem !important; }
  .mt-lg-1q3,
  .my-lg-1q3 {
    margin-top: 1.75rem !important; }
  .mr-lg-1q3,
  .mx-lg-1q3 {
    margin-right: 1.75rem !important; }
  .mb-lg-1q3,
  .my-lg-1q3 {
    margin-bottom: 1.75rem !important; }
  .ml-lg-1q3,
  .mx-lg-1q3 {
    margin-left: 1.75rem !important; }
  .m-lg-2 {
    margin: 2rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 2rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 2rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 2rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 2rem !important; }
  .m-lg-2q1 {
    margin: 2.25rem !important; }
  .mt-lg-2q1,
  .my-lg-2q1 {
    margin-top: 2.25rem !important; }
  .mr-lg-2q1,
  .mx-lg-2q1 {
    margin-right: 2.25rem !important; }
  .mb-lg-2q1,
  .my-lg-2q1 {
    margin-bottom: 2.25rem !important; }
  .ml-lg-2q1,
  .mx-lg-2q1 {
    margin-left: 2.25rem !important; }
  .m-lg-2q2 {
    margin: 2.5rem !important; }
  .mt-lg-2q2,
  .my-lg-2q2 {
    margin-top: 2.5rem !important; }
  .mr-lg-2q2,
  .mx-lg-2q2 {
    margin-right: 2.5rem !important; }
  .mb-lg-2q2,
  .my-lg-2q2 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-2q2,
  .mx-lg-2q2 {
    margin-left: 2.5rem !important; }
  .m-lg-2q3 {
    margin: 2.75rem !important; }
  .mt-lg-2q3,
  .my-lg-2q3 {
    margin-top: 2.75rem !important; }
  .mr-lg-2q3,
  .mx-lg-2q3 {
    margin-right: 2.75rem !important; }
  .mb-lg-2q3,
  .my-lg-2q3 {
    margin-bottom: 2.75rem !important; }
  .ml-lg-2q3,
  .mx-lg-2q3 {
    margin-left: 2.75rem !important; }
  .m-lg-3 {
    margin: 3rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 3rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 3rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 3rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 3rem !important; }
  .m-lg-3q1 {
    margin: 3.25rem !important; }
  .mt-lg-3q1,
  .my-lg-3q1 {
    margin-top: 3.25rem !important; }
  .mr-lg-3q1,
  .mx-lg-3q1 {
    margin-right: 3.25rem !important; }
  .mb-lg-3q1,
  .my-lg-3q1 {
    margin-bottom: 3.25rem !important; }
  .ml-lg-3q1,
  .mx-lg-3q1 {
    margin-left: 3.25rem !important; }
  .m-lg-3q2 {
    margin: 3.5rem !important; }
  .mt-lg-3q2,
  .my-lg-3q2 {
    margin-top: 3.5rem !important; }
  .mr-lg-3q2,
  .mx-lg-3q2 {
    margin-right: 3.5rem !important; }
  .mb-lg-3q2,
  .my-lg-3q2 {
    margin-bottom: 3.5rem !important; }
  .ml-lg-3q2,
  .mx-lg-3q2 {
    margin-left: 3.5rem !important; }
  .m-lg-3q3 {
    margin: 3.75rem !important; }
  .mt-lg-3q3,
  .my-lg-3q3 {
    margin-top: 3.75rem !important; }
  .mr-lg-3q3,
  .mx-lg-3q3 {
    margin-right: 3.75rem !important; }
  .mb-lg-3q3,
  .my-lg-3q3 {
    margin-bottom: 3.75rem !important; }
  .ml-lg-3q3,
  .mx-lg-3q3 {
    margin-left: 3.75rem !important; }
  .m-lg-4 {
    margin: 4rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 4rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 4rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 4rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 4rem !important; }
  .m-lg-4q1 {
    margin: 4.25rem !important; }
  .mt-lg-4q1,
  .my-lg-4q1 {
    margin-top: 4.25rem !important; }
  .mr-lg-4q1,
  .mx-lg-4q1 {
    margin-right: 4.25rem !important; }
  .mb-lg-4q1,
  .my-lg-4q1 {
    margin-bottom: 4.25rem !important; }
  .ml-lg-4q1,
  .mx-lg-4q1 {
    margin-left: 4.25rem !important; }
  .m-lg-4q2 {
    margin: 4.5rem !important; }
  .mt-lg-4q2,
  .my-lg-4q2 {
    margin-top: 4.5rem !important; }
  .mr-lg-4q2,
  .mx-lg-4q2 {
    margin-right: 4.5rem !important; }
  .mb-lg-4q2,
  .my-lg-4q2 {
    margin-bottom: 4.5rem !important; }
  .ml-lg-4q2,
  .mx-lg-4q2 {
    margin-left: 4.5rem !important; }
  .m-lg-4q3 {
    margin: 4.75rem !important; }
  .mt-lg-4q3,
  .my-lg-4q3 {
    margin-top: 4.75rem !important; }
  .mr-lg-4q3,
  .mx-lg-4q3 {
    margin-right: 4.75rem !important; }
  .mb-lg-4q3,
  .my-lg-4q3 {
    margin-bottom: 4.75rem !important; }
  .ml-lg-4q3,
  .mx-lg-4q3 {
    margin-left: 4.75rem !important; }
  .m-lg-5 {
    margin: 5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5rem !important; }
  .m-lg-5q1 {
    margin: 5.25rem !important; }
  .mt-lg-5q1,
  .my-lg-5q1 {
    margin-top: 5.25rem !important; }
  .mr-lg-5q1,
  .mx-lg-5q1 {
    margin-right: 5.25rem !important; }
  .mb-lg-5q1,
  .my-lg-5q1 {
    margin-bottom: 5.25rem !important; }
  .ml-lg-5q1,
  .mx-lg-5q1 {
    margin-left: 5.25rem !important; }
  .m-lg-5q2 {
    margin: 5.5rem !important; }
  .mt-lg-5q2,
  .my-lg-5q2 {
    margin-top: 5.5rem !important; }
  .mr-lg-5q2,
  .mx-lg-5q2 {
    margin-right: 5.5rem !important; }
  .mb-lg-5q2,
  .my-lg-5q2 {
    margin-bottom: 5.5rem !important; }
  .ml-lg-5q2,
  .mx-lg-5q2 {
    margin-left: 5.5rem !important; }
  .m-lg-5q3 {
    margin: 5.75rem !important; }
  .mt-lg-5q3,
  .my-lg-5q3 {
    margin-top: 5.75rem !important; }
  .mr-lg-5q3,
  .mx-lg-5q3 {
    margin-right: 5.75rem !important; }
  .mb-lg-5q3,
  .my-lg-5q3 {
    margin-bottom: 5.75rem !important; }
  .ml-lg-5q3,
  .mx-lg-5q3 {
    margin-left: 5.75rem !important; }
  .m-lg-6 {
    margin: 6rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 6rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 6rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 6rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 6rem !important; }
  .m-lg-6q1 {
    margin: 6.25rem !important; }
  .mt-lg-6q1,
  .my-lg-6q1 {
    margin-top: 6.25rem !important; }
  .mr-lg-6q1,
  .mx-lg-6q1 {
    margin-right: 6.25rem !important; }
  .mb-lg-6q1,
  .my-lg-6q1 {
    margin-bottom: 6.25rem !important; }
  .ml-lg-6q1,
  .mx-lg-6q1 {
    margin-left: 6.25rem !important; }
  .m-lg-6q2 {
    margin: 6.5rem !important; }
  .mt-lg-6q2,
  .my-lg-6q2 {
    margin-top: 6.5rem !important; }
  .mr-lg-6q2,
  .mx-lg-6q2 {
    margin-right: 6.5rem !important; }
  .mb-lg-6q2,
  .my-lg-6q2 {
    margin-bottom: 6.5rem !important; }
  .ml-lg-6q2,
  .mx-lg-6q2 {
    margin-left: 6.5rem !important; }
  .m-lg-6q3 {
    margin: 6.75rem !important; }
  .mt-lg-6q3,
  .my-lg-6q3 {
    margin-top: 6.75rem !important; }
  .mr-lg-6q3,
  .mx-lg-6q3 {
    margin-right: 6.75rem !important; }
  .mb-lg-6q3,
  .my-lg-6q3 {
    margin-bottom: 6.75rem !important; }
  .ml-lg-6q3,
  .mx-lg-6q3 {
    margin-left: 6.75rem !important; }
  .m-lg-7 {
    margin: 7rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 7rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 7rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 7rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 7rem !important; }
  .m-lg-7q1 {
    margin: 7.25rem !important; }
  .mt-lg-7q1,
  .my-lg-7q1 {
    margin-top: 7.25rem !important; }
  .mr-lg-7q1,
  .mx-lg-7q1 {
    margin-right: 7.25rem !important; }
  .mb-lg-7q1,
  .my-lg-7q1 {
    margin-bottom: 7.25rem !important; }
  .ml-lg-7q1,
  .mx-lg-7q1 {
    margin-left: 7.25rem !important; }
  .m-lg-7q2 {
    margin: 7.5rem !important; }
  .mt-lg-7q2,
  .my-lg-7q2 {
    margin-top: 7.5rem !important; }
  .mr-lg-7q2,
  .mx-lg-7q2 {
    margin-right: 7.5rem !important; }
  .mb-lg-7q2,
  .my-lg-7q2 {
    margin-bottom: 7.5rem !important; }
  .ml-lg-7q2,
  .mx-lg-7q2 {
    margin-left: 7.5rem !important; }
  .m-lg-7q3 {
    margin: 7.75rem !important; }
  .mt-lg-7q3,
  .my-lg-7q3 {
    margin-top: 7.75rem !important; }
  .mr-lg-7q3,
  .mx-lg-7q3 {
    margin-right: 7.75rem !important; }
  .mb-lg-7q3,
  .my-lg-7q3 {
    margin-bottom: 7.75rem !important; }
  .ml-lg-7q3,
  .mx-lg-7q3 {
    margin-left: 7.75rem !important; }
  .m-lg-8 {
    margin: 8rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 8rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 8rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 8rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 8rem !important; }
  .m-lg-8q1 {
    margin: 8.25rem !important; }
  .mt-lg-8q1,
  .my-lg-8q1 {
    margin-top: 8.25rem !important; }
  .mr-lg-8q1,
  .mx-lg-8q1 {
    margin-right: 8.25rem !important; }
  .mb-lg-8q1,
  .my-lg-8q1 {
    margin-bottom: 8.25rem !important; }
  .ml-lg-8q1,
  .mx-lg-8q1 {
    margin-left: 8.25rem !important; }
  .m-lg-8q2 {
    margin: 8.5rem !important; }
  .mt-lg-8q2,
  .my-lg-8q2 {
    margin-top: 8.5rem !important; }
  .mr-lg-8q2,
  .mx-lg-8q2 {
    margin-right: 8.5rem !important; }
  .mb-lg-8q2,
  .my-lg-8q2 {
    margin-bottom: 8.5rem !important; }
  .ml-lg-8q2,
  .mx-lg-8q2 {
    margin-left: 8.5rem !important; }
  .m-lg-8q3 {
    margin: 8.75rem !important; }
  .mt-lg-8q3,
  .my-lg-8q3 {
    margin-top: 8.75rem !important; }
  .mr-lg-8q3,
  .mx-lg-8q3 {
    margin-right: 8.75rem !important; }
  .mb-lg-8q3,
  .my-lg-8q3 {
    margin-bottom: 8.75rem !important; }
  .ml-lg-8q3,
  .mx-lg-8q3 {
    margin-left: 8.75rem !important; }
  .m-lg-9 {
    margin: 9rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 9rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 9rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 9rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 9rem !important; }
  .m-lg-9q1 {
    margin: 9.25rem !important; }
  .mt-lg-9q1,
  .my-lg-9q1 {
    margin-top: 9.25rem !important; }
  .mr-lg-9q1,
  .mx-lg-9q1 {
    margin-right: 9.25rem !important; }
  .mb-lg-9q1,
  .my-lg-9q1 {
    margin-bottom: 9.25rem !important; }
  .ml-lg-9q1,
  .mx-lg-9q1 {
    margin-left: 9.25rem !important; }
  .m-lg-9q2 {
    margin: 9.5rem !important; }
  .mt-lg-9q2,
  .my-lg-9q2 {
    margin-top: 9.5rem !important; }
  .mr-lg-9q2,
  .mx-lg-9q2 {
    margin-right: 9.5rem !important; }
  .mb-lg-9q2,
  .my-lg-9q2 {
    margin-bottom: 9.5rem !important; }
  .ml-lg-9q2,
  .mx-lg-9q2 {
    margin-left: 9.5rem !important; }
  .m-lg-9q3 {
    margin: 9.75rem !important; }
  .mt-lg-9q3,
  .my-lg-9q3 {
    margin-top: 9.75rem !important; }
  .mr-lg-9q3,
  .mx-lg-9q3 {
    margin-right: 9.75rem !important; }
  .mb-lg-9q3,
  .my-lg-9q3 {
    margin-bottom: 9.75rem !important; }
  .ml-lg-9q3,
  .mx-lg-9q3 {
    margin-left: 9.75rem !important; }
  .m-lg-10 {
    margin: 10rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10rem !important; }
  .m-lg-10q1 {
    margin: 10.25rem !important; }
  .mt-lg-10q1,
  .my-lg-10q1 {
    margin-top: 10.25rem !important; }
  .mr-lg-10q1,
  .mx-lg-10q1 {
    margin-right: 10.25rem !important; }
  .mb-lg-10q1,
  .my-lg-10q1 {
    margin-bottom: 10.25rem !important; }
  .ml-lg-10q1,
  .mx-lg-10q1 {
    margin-left: 10.25rem !important; }
  .m-lg-10q2 {
    margin: 10.5rem !important; }
  .mt-lg-10q2,
  .my-lg-10q2 {
    margin-top: 10.5rem !important; }
  .mr-lg-10q2,
  .mx-lg-10q2 {
    margin-right: 10.5rem !important; }
  .mb-lg-10q2,
  .my-lg-10q2 {
    margin-bottom: 10.5rem !important; }
  .ml-lg-10q2,
  .mx-lg-10q2 {
    margin-left: 10.5rem !important; }
  .m-lg-10q3 {
    margin: 10.75rem !important; }
  .mt-lg-10q3,
  .my-lg-10q3 {
    margin-top: 10.75rem !important; }
  .mr-lg-10q3,
  .mx-lg-10q3 {
    margin-right: 10.75rem !important; }
  .mb-lg-10q3,
  .my-lg-10q3 {
    margin-bottom: 10.75rem !important; }
  .ml-lg-10q3,
  .mx-lg-10q3 {
    margin-left: 10.75rem !important; }
  .m-lg-11 {
    margin: 11rem !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 11rem !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 11rem !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 11rem !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 11rem !important; }
  .m-lg-11q1 {
    margin: 11.25rem !important; }
  .mt-lg-11q1,
  .my-lg-11q1 {
    margin-top: 11.25rem !important; }
  .mr-lg-11q1,
  .mx-lg-11q1 {
    margin-right: 11.25rem !important; }
  .mb-lg-11q1,
  .my-lg-11q1 {
    margin-bottom: 11.25rem !important; }
  .ml-lg-11q1,
  .mx-lg-11q1 {
    margin-left: 11.25rem !important; }
  .m-lg-11 q2 {
    margin: 11.5rem !important; }
  .mt-lg-11 q2,
  .my-lg-11 q2 {
    margin-top: 11.5rem !important; }
  .mr-lg-11 q2,
  .mx-lg-11 q2 {
    margin-right: 11.5rem !important; }
  .mb-lg-11 q2,
  .my-lg-11 q2 {
    margin-bottom: 11.5rem !important; }
  .ml-lg-11 q2,
  .mx-lg-11 q2 {
    margin-left: 11.5rem !important; }
  .m-lg-11 q3 {
    margin: 11.75rem !important; }
  .mt-lg-11 q3,
  .my-lg-11 q3 {
    margin-top: 11.75rem !important; }
  .mr-lg-11 q3,
  .mx-lg-11 q3 {
    margin-right: 11.75rem !important; }
  .mb-lg-11 q3,
  .my-lg-11 q3 {
    margin-bottom: 11.75rem !important; }
  .ml-lg-11 q3,
  .mx-lg-11 q3 {
    margin-left: 11.75rem !important; }
  .m-lg-12 {
    margin: 12rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 12rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 12rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 12rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 12rem !important; }
  .m-lg-12 q1 {
    margin: 12.25rem !important; }
  .mt-lg-12 q1,
  .my-lg-12 q1 {
    margin-top: 12.25rem !important; }
  .mr-lg-12 q1,
  .mx-lg-12 q1 {
    margin-right: 12.25rem !important; }
  .mb-lg-12 q1,
  .my-lg-12 q1 {
    margin-bottom: 12.25rem !important; }
  .ml-lg-12 q1,
  .mx-lg-12 q1 {
    margin-left: 12.25rem !important; }
  .m-lg-12 q2 {
    margin: 12.5rem !important; }
  .mt-lg-12 q2,
  .my-lg-12 q2 {
    margin-top: 12.5rem !important; }
  .mr-lg-12 q2,
  .mx-lg-12 q2 {
    margin-right: 12.5rem !important; }
  .mb-lg-12 q2,
  .my-lg-12 q2 {
    margin-bottom: 12.5rem !important; }
  .ml-lg-12 q2,
  .mx-lg-12 q2 {
    margin-left: 12.5rem !important; }
  .m-lg-12 q3 {
    margin: 12.75rem !important; }
  .mt-lg-12 q3,
  .my-lg-12 q3 {
    margin-top: 12.75rem !important; }
  .mr-lg-12 q3,
  .mx-lg-12 q3 {
    margin-right: 12.75rem !important; }
  .mb-lg-12 q3,
  .my-lg-12 q3 {
    margin-bottom: 12.75rem !important; }
  .ml-lg-12 q3,
  .mx-lg-12 q3 {
    margin-left: 12.75rem !important; }
  .p-lg-q1 {
    padding: 0.25rem !important; }
  .pt-lg-q1,
  .py-lg-q1 {
    padding-top: 0.25rem !important; }
  .pr-lg-q1,
  .px-lg-q1 {
    padding-right: 0.25rem !important; }
  .pb-lg-q1,
  .py-lg-q1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-q1,
  .px-lg-q1 {
    padding-left: 0.25rem !important; }
  .p-lg-q2 {
    padding: 0.5rem !important; }
  .pt-lg-q2,
  .py-lg-q2 {
    padding-top: 0.5rem !important; }
  .pr-lg-q2,
  .px-lg-q2 {
    padding-right: 0.5rem !important; }
  .pb-lg-q2,
  .py-lg-q2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-q2,
  .px-lg-q2 {
    padding-left: 0.5rem !important; }
  .p-lg-q3 {
    padding: 0.75rem !important; }
  .pt-lg-q3,
  .py-lg-q3 {
    padding-top: 0.75rem !important; }
  .pr-lg-q3,
  .px-lg-q3 {
    padding-right: 0.75rem !important; }
  .pb-lg-q3,
  .py-lg-q3 {
    padding-bottom: 0.75rem !important; }
  .pl-lg-q3,
  .px-lg-q3 {
    padding-left: 0.75rem !important; }
  .p-lg-0 {
    padding: 0rem !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0rem !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0rem !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0rem !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0rem !important; }
  .p-lg-1 {
    padding: 1rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 1rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 1rem !important; }
  .p-lg-1q1 {
    padding: 1.25rem !important; }
  .pt-lg-1q1,
  .py-lg-1q1 {
    padding-top: 1.25rem !important; }
  .pr-lg-1q1,
  .px-lg-1q1 {
    padding-right: 1.25rem !important; }
  .pb-lg-1q1,
  .py-lg-1q1 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-1q1,
  .px-lg-1q1 {
    padding-left: 1.25rem !important; }
  .p-lg-1q2 {
    padding: 1.5rem !important; }
  .pt-lg-1q2,
  .py-lg-1q2 {
    padding-top: 1.5rem !important; }
  .pr-lg-1q2,
  .px-lg-1q2 {
    padding-right: 1.5rem !important; }
  .pb-lg-1q2,
  .py-lg-1q2 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-1q2,
  .px-lg-1q2 {
    padding-left: 1.5rem !important; }
  .p-lg-1q3 {
    padding: 1.75rem !important; }
  .pt-lg-1q3,
  .py-lg-1q3 {
    padding-top: 1.75rem !important; }
  .pr-lg-1q3,
  .px-lg-1q3 {
    padding-right: 1.75rem !important; }
  .pb-lg-1q3,
  .py-lg-1q3 {
    padding-bottom: 1.75rem !important; }
  .pl-lg-1q3,
  .px-lg-1q3 {
    padding-left: 1.75rem !important; }
  .p-lg-2 {
    padding: 2rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 2rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 2rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 2rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 2rem !important; }
  .p-lg-2q1 {
    padding: 2.25rem !important; }
  .pt-lg-2q1,
  .py-lg-2q1 {
    padding-top: 2.25rem !important; }
  .pr-lg-2q1,
  .px-lg-2q1 {
    padding-right: 2.25rem !important; }
  .pb-lg-2q1,
  .py-lg-2q1 {
    padding-bottom: 2.25rem !important; }
  .pl-lg-2q1,
  .px-lg-2q1 {
    padding-left: 2.25rem !important; }
  .p-lg-2q2 {
    padding: 2.5rem !important; }
  .pt-lg-2q2,
  .py-lg-2q2 {
    padding-top: 2.5rem !important; }
  .pr-lg-2q2,
  .px-lg-2q2 {
    padding-right: 2.5rem !important; }
  .pb-lg-2q2,
  .py-lg-2q2 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-2q2,
  .px-lg-2q2 {
    padding-left: 2.5rem !important; }
  .p-lg-2q3 {
    padding: 2.75rem !important; }
  .pt-lg-2q3,
  .py-lg-2q3 {
    padding-top: 2.75rem !important; }
  .pr-lg-2q3,
  .px-lg-2q3 {
    padding-right: 2.75rem !important; }
  .pb-lg-2q3,
  .py-lg-2q3 {
    padding-bottom: 2.75rem !important; }
  .pl-lg-2q3,
  .px-lg-2q3 {
    padding-left: 2.75rem !important; }
  .p-lg-3 {
    padding: 3rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 3rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 3rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 3rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 3rem !important; }
  .p-lg-3q1 {
    padding: 3.25rem !important; }
  .pt-lg-3q1,
  .py-lg-3q1 {
    padding-top: 3.25rem !important; }
  .pr-lg-3q1,
  .px-lg-3q1 {
    padding-right: 3.25rem !important; }
  .pb-lg-3q1,
  .py-lg-3q1 {
    padding-bottom: 3.25rem !important; }
  .pl-lg-3q1,
  .px-lg-3q1 {
    padding-left: 3.25rem !important; }
  .p-lg-3q2 {
    padding: 3.5rem !important; }
  .pt-lg-3q2,
  .py-lg-3q2 {
    padding-top: 3.5rem !important; }
  .pr-lg-3q2,
  .px-lg-3q2 {
    padding-right: 3.5rem !important; }
  .pb-lg-3q2,
  .py-lg-3q2 {
    padding-bottom: 3.5rem !important; }
  .pl-lg-3q2,
  .px-lg-3q2 {
    padding-left: 3.5rem !important; }
  .p-lg-3q3 {
    padding: 3.75rem !important; }
  .pt-lg-3q3,
  .py-lg-3q3 {
    padding-top: 3.75rem !important; }
  .pr-lg-3q3,
  .px-lg-3q3 {
    padding-right: 3.75rem !important; }
  .pb-lg-3q3,
  .py-lg-3q3 {
    padding-bottom: 3.75rem !important; }
  .pl-lg-3q3,
  .px-lg-3q3 {
    padding-left: 3.75rem !important; }
  .p-lg-4 {
    padding: 4rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 4rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 4rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 4rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 4rem !important; }
  .p-lg-4q1 {
    padding: 4.25rem !important; }
  .pt-lg-4q1,
  .py-lg-4q1 {
    padding-top: 4.25rem !important; }
  .pr-lg-4q1,
  .px-lg-4q1 {
    padding-right: 4.25rem !important; }
  .pb-lg-4q1,
  .py-lg-4q1 {
    padding-bottom: 4.25rem !important; }
  .pl-lg-4q1,
  .px-lg-4q1 {
    padding-left: 4.25rem !important; }
  .p-lg-4q2 {
    padding: 4.5rem !important; }
  .pt-lg-4q2,
  .py-lg-4q2 {
    padding-top: 4.5rem !important; }
  .pr-lg-4q2,
  .px-lg-4q2 {
    padding-right: 4.5rem !important; }
  .pb-lg-4q2,
  .py-lg-4q2 {
    padding-bottom: 4.5rem !important; }
  .pl-lg-4q2,
  .px-lg-4q2 {
    padding-left: 4.5rem !important; }
  .p-lg-4q3 {
    padding: 4.75rem !important; }
  .pt-lg-4q3,
  .py-lg-4q3 {
    padding-top: 4.75rem !important; }
  .pr-lg-4q3,
  .px-lg-4q3 {
    padding-right: 4.75rem !important; }
  .pb-lg-4q3,
  .py-lg-4q3 {
    padding-bottom: 4.75rem !important; }
  .pl-lg-4q3,
  .px-lg-4q3 {
    padding-left: 4.75rem !important; }
  .p-lg-5 {
    padding: 5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5rem !important; }
  .p-lg-5q1 {
    padding: 5.25rem !important; }
  .pt-lg-5q1,
  .py-lg-5q1 {
    padding-top: 5.25rem !important; }
  .pr-lg-5q1,
  .px-lg-5q1 {
    padding-right: 5.25rem !important; }
  .pb-lg-5q1,
  .py-lg-5q1 {
    padding-bottom: 5.25rem !important; }
  .pl-lg-5q1,
  .px-lg-5q1 {
    padding-left: 5.25rem !important; }
  .p-lg-5q2 {
    padding: 5.5rem !important; }
  .pt-lg-5q2,
  .py-lg-5q2 {
    padding-top: 5.5rem !important; }
  .pr-lg-5q2,
  .px-lg-5q2 {
    padding-right: 5.5rem !important; }
  .pb-lg-5q2,
  .py-lg-5q2 {
    padding-bottom: 5.5rem !important; }
  .pl-lg-5q2,
  .px-lg-5q2 {
    padding-left: 5.5rem !important; }
  .p-lg-5q3 {
    padding: 5.75rem !important; }
  .pt-lg-5q3,
  .py-lg-5q3 {
    padding-top: 5.75rem !important; }
  .pr-lg-5q3,
  .px-lg-5q3 {
    padding-right: 5.75rem !important; }
  .pb-lg-5q3,
  .py-lg-5q3 {
    padding-bottom: 5.75rem !important; }
  .pl-lg-5q3,
  .px-lg-5q3 {
    padding-left: 5.75rem !important; }
  .p-lg-6 {
    padding: 6rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 6rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 6rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 6rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 6rem !important; }
  .p-lg-6q1 {
    padding: 6.25rem !important; }
  .pt-lg-6q1,
  .py-lg-6q1 {
    padding-top: 6.25rem !important; }
  .pr-lg-6q1,
  .px-lg-6q1 {
    padding-right: 6.25rem !important; }
  .pb-lg-6q1,
  .py-lg-6q1 {
    padding-bottom: 6.25rem !important; }
  .pl-lg-6q1,
  .px-lg-6q1 {
    padding-left: 6.25rem !important; }
  .p-lg-6q2 {
    padding: 6.5rem !important; }
  .pt-lg-6q2,
  .py-lg-6q2 {
    padding-top: 6.5rem !important; }
  .pr-lg-6q2,
  .px-lg-6q2 {
    padding-right: 6.5rem !important; }
  .pb-lg-6q2,
  .py-lg-6q2 {
    padding-bottom: 6.5rem !important; }
  .pl-lg-6q2,
  .px-lg-6q2 {
    padding-left: 6.5rem !important; }
  .p-lg-6q3 {
    padding: 6.75rem !important; }
  .pt-lg-6q3,
  .py-lg-6q3 {
    padding-top: 6.75rem !important; }
  .pr-lg-6q3,
  .px-lg-6q3 {
    padding-right: 6.75rem !important; }
  .pb-lg-6q3,
  .py-lg-6q3 {
    padding-bottom: 6.75rem !important; }
  .pl-lg-6q3,
  .px-lg-6q3 {
    padding-left: 6.75rem !important; }
  .p-lg-7 {
    padding: 7rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 7rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 7rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 7rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 7rem !important; }
  .p-lg-7q1 {
    padding: 7.25rem !important; }
  .pt-lg-7q1,
  .py-lg-7q1 {
    padding-top: 7.25rem !important; }
  .pr-lg-7q1,
  .px-lg-7q1 {
    padding-right: 7.25rem !important; }
  .pb-lg-7q1,
  .py-lg-7q1 {
    padding-bottom: 7.25rem !important; }
  .pl-lg-7q1,
  .px-lg-7q1 {
    padding-left: 7.25rem !important; }
  .p-lg-7q2 {
    padding: 7.5rem !important; }
  .pt-lg-7q2,
  .py-lg-7q2 {
    padding-top: 7.5rem !important; }
  .pr-lg-7q2,
  .px-lg-7q2 {
    padding-right: 7.5rem !important; }
  .pb-lg-7q2,
  .py-lg-7q2 {
    padding-bottom: 7.5rem !important; }
  .pl-lg-7q2,
  .px-lg-7q2 {
    padding-left: 7.5rem !important; }
  .p-lg-7q3 {
    padding: 7.75rem !important; }
  .pt-lg-7q3,
  .py-lg-7q3 {
    padding-top: 7.75rem !important; }
  .pr-lg-7q3,
  .px-lg-7q3 {
    padding-right: 7.75rem !important; }
  .pb-lg-7q3,
  .py-lg-7q3 {
    padding-bottom: 7.75rem !important; }
  .pl-lg-7q3,
  .px-lg-7q3 {
    padding-left: 7.75rem !important; }
  .p-lg-8 {
    padding: 8rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 8rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 8rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 8rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 8rem !important; }
  .p-lg-8q1 {
    padding: 8.25rem !important; }
  .pt-lg-8q1,
  .py-lg-8q1 {
    padding-top: 8.25rem !important; }
  .pr-lg-8q1,
  .px-lg-8q1 {
    padding-right: 8.25rem !important; }
  .pb-lg-8q1,
  .py-lg-8q1 {
    padding-bottom: 8.25rem !important; }
  .pl-lg-8q1,
  .px-lg-8q1 {
    padding-left: 8.25rem !important; }
  .p-lg-8q2 {
    padding: 8.5rem !important; }
  .pt-lg-8q2,
  .py-lg-8q2 {
    padding-top: 8.5rem !important; }
  .pr-lg-8q2,
  .px-lg-8q2 {
    padding-right: 8.5rem !important; }
  .pb-lg-8q2,
  .py-lg-8q2 {
    padding-bottom: 8.5rem !important; }
  .pl-lg-8q2,
  .px-lg-8q2 {
    padding-left: 8.5rem !important; }
  .p-lg-8q3 {
    padding: 8.75rem !important; }
  .pt-lg-8q3,
  .py-lg-8q3 {
    padding-top: 8.75rem !important; }
  .pr-lg-8q3,
  .px-lg-8q3 {
    padding-right: 8.75rem !important; }
  .pb-lg-8q3,
  .py-lg-8q3 {
    padding-bottom: 8.75rem !important; }
  .pl-lg-8q3,
  .px-lg-8q3 {
    padding-left: 8.75rem !important; }
  .p-lg-9 {
    padding: 9rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 9rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 9rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 9rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 9rem !important; }
  .p-lg-9q1 {
    padding: 9.25rem !important; }
  .pt-lg-9q1,
  .py-lg-9q1 {
    padding-top: 9.25rem !important; }
  .pr-lg-9q1,
  .px-lg-9q1 {
    padding-right: 9.25rem !important; }
  .pb-lg-9q1,
  .py-lg-9q1 {
    padding-bottom: 9.25rem !important; }
  .pl-lg-9q1,
  .px-lg-9q1 {
    padding-left: 9.25rem !important; }
  .p-lg-9q2 {
    padding: 9.5rem !important; }
  .pt-lg-9q2,
  .py-lg-9q2 {
    padding-top: 9.5rem !important; }
  .pr-lg-9q2,
  .px-lg-9q2 {
    padding-right: 9.5rem !important; }
  .pb-lg-9q2,
  .py-lg-9q2 {
    padding-bottom: 9.5rem !important; }
  .pl-lg-9q2,
  .px-lg-9q2 {
    padding-left: 9.5rem !important; }
  .p-lg-9q3 {
    padding: 9.75rem !important; }
  .pt-lg-9q3,
  .py-lg-9q3 {
    padding-top: 9.75rem !important; }
  .pr-lg-9q3,
  .px-lg-9q3 {
    padding-right: 9.75rem !important; }
  .pb-lg-9q3,
  .py-lg-9q3 {
    padding-bottom: 9.75rem !important; }
  .pl-lg-9q3,
  .px-lg-9q3 {
    padding-left: 9.75rem !important; }
  .p-lg-10 {
    padding: 10rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10rem !important; }
  .p-lg-10q1 {
    padding: 10.25rem !important; }
  .pt-lg-10q1,
  .py-lg-10q1 {
    padding-top: 10.25rem !important; }
  .pr-lg-10q1,
  .px-lg-10q1 {
    padding-right: 10.25rem !important; }
  .pb-lg-10q1,
  .py-lg-10q1 {
    padding-bottom: 10.25rem !important; }
  .pl-lg-10q1,
  .px-lg-10q1 {
    padding-left: 10.25rem !important; }
  .p-lg-10q2 {
    padding: 10.5rem !important; }
  .pt-lg-10q2,
  .py-lg-10q2 {
    padding-top: 10.5rem !important; }
  .pr-lg-10q2,
  .px-lg-10q2 {
    padding-right: 10.5rem !important; }
  .pb-lg-10q2,
  .py-lg-10q2 {
    padding-bottom: 10.5rem !important; }
  .pl-lg-10q2,
  .px-lg-10q2 {
    padding-left: 10.5rem !important; }
  .p-lg-10q3 {
    padding: 10.75rem !important; }
  .pt-lg-10q3,
  .py-lg-10q3 {
    padding-top: 10.75rem !important; }
  .pr-lg-10q3,
  .px-lg-10q3 {
    padding-right: 10.75rem !important; }
  .pb-lg-10q3,
  .py-lg-10q3 {
    padding-bottom: 10.75rem !important; }
  .pl-lg-10q3,
  .px-lg-10q3 {
    padding-left: 10.75rem !important; }
  .p-lg-11 {
    padding: 11rem !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 11rem !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 11rem !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 11rem !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 11rem !important; }
  .p-lg-11q1 {
    padding: 11.25rem !important; }
  .pt-lg-11q1,
  .py-lg-11q1 {
    padding-top: 11.25rem !important; }
  .pr-lg-11q1,
  .px-lg-11q1 {
    padding-right: 11.25rem !important; }
  .pb-lg-11q1,
  .py-lg-11q1 {
    padding-bottom: 11.25rem !important; }
  .pl-lg-11q1,
  .px-lg-11q1 {
    padding-left: 11.25rem !important; }
  .p-lg-11 q2 {
    padding: 11.5rem !important; }
  .pt-lg-11 q2,
  .py-lg-11 q2 {
    padding-top: 11.5rem !important; }
  .pr-lg-11 q2,
  .px-lg-11 q2 {
    padding-right: 11.5rem !important; }
  .pb-lg-11 q2,
  .py-lg-11 q2 {
    padding-bottom: 11.5rem !important; }
  .pl-lg-11 q2,
  .px-lg-11 q2 {
    padding-left: 11.5rem !important; }
  .p-lg-11 q3 {
    padding: 11.75rem !important; }
  .pt-lg-11 q3,
  .py-lg-11 q3 {
    padding-top: 11.75rem !important; }
  .pr-lg-11 q3,
  .px-lg-11 q3 {
    padding-right: 11.75rem !important; }
  .pb-lg-11 q3,
  .py-lg-11 q3 {
    padding-bottom: 11.75rem !important; }
  .pl-lg-11 q3,
  .px-lg-11 q3 {
    padding-left: 11.75rem !important; }
  .p-lg-12 {
    padding: 12rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 12rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 12rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 12rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 12rem !important; }
  .p-lg-12 q1 {
    padding: 12.25rem !important; }
  .pt-lg-12 q1,
  .py-lg-12 q1 {
    padding-top: 12.25rem !important; }
  .pr-lg-12 q1,
  .px-lg-12 q1 {
    padding-right: 12.25rem !important; }
  .pb-lg-12 q1,
  .py-lg-12 q1 {
    padding-bottom: 12.25rem !important; }
  .pl-lg-12 q1,
  .px-lg-12 q1 {
    padding-left: 12.25rem !important; }
  .p-lg-12 q2 {
    padding: 12.5rem !important; }
  .pt-lg-12 q2,
  .py-lg-12 q2 {
    padding-top: 12.5rem !important; }
  .pr-lg-12 q2,
  .px-lg-12 q2 {
    padding-right: 12.5rem !important; }
  .pb-lg-12 q2,
  .py-lg-12 q2 {
    padding-bottom: 12.5rem !important; }
  .pl-lg-12 q2,
  .px-lg-12 q2 {
    padding-left: 12.5rem !important; }
  .p-lg-12 q3 {
    padding: 12.75rem !important; }
  .pt-lg-12 q3,
  .py-lg-12 q3 {
    padding-top: 12.75rem !important; }
  .pr-lg-12 q3,
  .px-lg-12 q3 {
    padding-right: 12.75rem !important; }
  .pb-lg-12 q3,
  .py-lg-12 q3 {
    padding-bottom: 12.75rem !important; }
  .pl-lg-12 q3,
  .px-lg-12 q3 {
    padding-left: 12.75rem !important; }
  .m-lg-nq1 {
    margin: -0.25rem !important; }
  .mt-lg-nq1,
  .my-lg-nq1 {
    margin-top: -0.25rem !important; }
  .mr-lg-nq1,
  .mx-lg-nq1 {
    margin-right: -0.25rem !important; }
  .mb-lg-nq1,
  .my-lg-nq1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-nq1,
  .mx-lg-nq1 {
    margin-left: -0.25rem !important; }
  .m-lg-nq2 {
    margin: -0.5rem !important; }
  .mt-lg-nq2,
  .my-lg-nq2 {
    margin-top: -0.5rem !important; }
  .mr-lg-nq2,
  .mx-lg-nq2 {
    margin-right: -0.5rem !important; }
  .mb-lg-nq2,
  .my-lg-nq2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-nq2,
  .mx-lg-nq2 {
    margin-left: -0.5rem !important; }
  .m-lg-nq3 {
    margin: -0.75rem !important; }
  .mt-lg-nq3,
  .my-lg-nq3 {
    margin-top: -0.75rem !important; }
  .mr-lg-nq3,
  .mx-lg-nq3 {
    margin-right: -0.75rem !important; }
  .mb-lg-nq3,
  .my-lg-nq3 {
    margin-bottom: -0.75rem !important; }
  .ml-lg-nq3,
  .mx-lg-nq3 {
    margin-left: -0.75rem !important; }
  .m-lg-n1 {
    margin: -1rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -1rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -1rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -1rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -1rem !important; }
  .m-lg-n1q1 {
    margin: -1.25rem !important; }
  .mt-lg-n1q1,
  .my-lg-n1q1 {
    margin-top: -1.25rem !important; }
  .mr-lg-n1q1,
  .mx-lg-n1q1 {
    margin-right: -1.25rem !important; }
  .mb-lg-n1q1,
  .my-lg-n1q1 {
    margin-bottom: -1.25rem !important; }
  .ml-lg-n1q1,
  .mx-lg-n1q1 {
    margin-left: -1.25rem !important; }
  .m-lg-n1q2 {
    margin: -1.5rem !important; }
  .mt-lg-n1q2,
  .my-lg-n1q2 {
    margin-top: -1.5rem !important; }
  .mr-lg-n1q2,
  .mx-lg-n1q2 {
    margin-right: -1.5rem !important; }
  .mb-lg-n1q2,
  .my-lg-n1q2 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n1q2,
  .mx-lg-n1q2 {
    margin-left: -1.5rem !important; }
  .m-lg-n1q3 {
    margin: -1.75rem !important; }
  .mt-lg-n1q3,
  .my-lg-n1q3 {
    margin-top: -1.75rem !important; }
  .mr-lg-n1q3,
  .mx-lg-n1q3 {
    margin-right: -1.75rem !important; }
  .mb-lg-n1q3,
  .my-lg-n1q3 {
    margin-bottom: -1.75rem !important; }
  .ml-lg-n1q3,
  .mx-lg-n1q3 {
    margin-left: -1.75rem !important; }
  .m-lg-n2 {
    margin: -2rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -2rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -2rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -2rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -2rem !important; }
  .m-lg-n2q1 {
    margin: -2.25rem !important; }
  .mt-lg-n2q1,
  .my-lg-n2q1 {
    margin-top: -2.25rem !important; }
  .mr-lg-n2q1,
  .mx-lg-n2q1 {
    margin-right: -2.25rem !important; }
  .mb-lg-n2q1,
  .my-lg-n2q1 {
    margin-bottom: -2.25rem !important; }
  .ml-lg-n2q1,
  .mx-lg-n2q1 {
    margin-left: -2.25rem !important; }
  .m-lg-n2q2 {
    margin: -2.5rem !important; }
  .mt-lg-n2q2,
  .my-lg-n2q2 {
    margin-top: -2.5rem !important; }
  .mr-lg-n2q2,
  .mx-lg-n2q2 {
    margin-right: -2.5rem !important; }
  .mb-lg-n2q2,
  .my-lg-n2q2 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n2q2,
  .mx-lg-n2q2 {
    margin-left: -2.5rem !important; }
  .m-lg-n2q3 {
    margin: -2.75rem !important; }
  .mt-lg-n2q3,
  .my-lg-n2q3 {
    margin-top: -2.75rem !important; }
  .mr-lg-n2q3,
  .mx-lg-n2q3 {
    margin-right: -2.75rem !important; }
  .mb-lg-n2q3,
  .my-lg-n2q3 {
    margin-bottom: -2.75rem !important; }
  .ml-lg-n2q3,
  .mx-lg-n2q3 {
    margin-left: -2.75rem !important; }
  .m-lg-n3 {
    margin: -3rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -3rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -3rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -3rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -3rem !important; }
  .m-lg-n3q1 {
    margin: -3.25rem !important; }
  .mt-lg-n3q1,
  .my-lg-n3q1 {
    margin-top: -3.25rem !important; }
  .mr-lg-n3q1,
  .mx-lg-n3q1 {
    margin-right: -3.25rem !important; }
  .mb-lg-n3q1,
  .my-lg-n3q1 {
    margin-bottom: -3.25rem !important; }
  .ml-lg-n3q1,
  .mx-lg-n3q1 {
    margin-left: -3.25rem !important; }
  .m-lg-n3q2 {
    margin: -3.5rem !important; }
  .mt-lg-n3q2,
  .my-lg-n3q2 {
    margin-top: -3.5rem !important; }
  .mr-lg-n3q2,
  .mx-lg-n3q2 {
    margin-right: -3.5rem !important; }
  .mb-lg-n3q2,
  .my-lg-n3q2 {
    margin-bottom: -3.5rem !important; }
  .ml-lg-n3q2,
  .mx-lg-n3q2 {
    margin-left: -3.5rem !important; }
  .m-lg-n3q3 {
    margin: -3.75rem !important; }
  .mt-lg-n3q3,
  .my-lg-n3q3 {
    margin-top: -3.75rem !important; }
  .mr-lg-n3q3,
  .mx-lg-n3q3 {
    margin-right: -3.75rem !important; }
  .mb-lg-n3q3,
  .my-lg-n3q3 {
    margin-bottom: -3.75rem !important; }
  .ml-lg-n3q3,
  .mx-lg-n3q3 {
    margin-left: -3.75rem !important; }
  .m-lg-n4 {
    margin: -4rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -4rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -4rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -4rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -4rem !important; }
  .m-lg-n4q1 {
    margin: -4.25rem !important; }
  .mt-lg-n4q1,
  .my-lg-n4q1 {
    margin-top: -4.25rem !important; }
  .mr-lg-n4q1,
  .mx-lg-n4q1 {
    margin-right: -4.25rem !important; }
  .mb-lg-n4q1,
  .my-lg-n4q1 {
    margin-bottom: -4.25rem !important; }
  .ml-lg-n4q1,
  .mx-lg-n4q1 {
    margin-left: -4.25rem !important; }
  .m-lg-n4q2 {
    margin: -4.5rem !important; }
  .mt-lg-n4q2,
  .my-lg-n4q2 {
    margin-top: -4.5rem !important; }
  .mr-lg-n4q2,
  .mx-lg-n4q2 {
    margin-right: -4.5rem !important; }
  .mb-lg-n4q2,
  .my-lg-n4q2 {
    margin-bottom: -4.5rem !important; }
  .ml-lg-n4q2,
  .mx-lg-n4q2 {
    margin-left: -4.5rem !important; }
  .m-lg-n4q3 {
    margin: -4.75rem !important; }
  .mt-lg-n4q3,
  .my-lg-n4q3 {
    margin-top: -4.75rem !important; }
  .mr-lg-n4q3,
  .mx-lg-n4q3 {
    margin-right: -4.75rem !important; }
  .mb-lg-n4q3,
  .my-lg-n4q3 {
    margin-bottom: -4.75rem !important; }
  .ml-lg-n4q3,
  .mx-lg-n4q3 {
    margin-left: -4.75rem !important; }
  .m-lg-n5 {
    margin: -5rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -5rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -5rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -5rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -5rem !important; }
  .m-lg-n5q1 {
    margin: -5.25rem !important; }
  .mt-lg-n5q1,
  .my-lg-n5q1 {
    margin-top: -5.25rem !important; }
  .mr-lg-n5q1,
  .mx-lg-n5q1 {
    margin-right: -5.25rem !important; }
  .mb-lg-n5q1,
  .my-lg-n5q1 {
    margin-bottom: -5.25rem !important; }
  .ml-lg-n5q1,
  .mx-lg-n5q1 {
    margin-left: -5.25rem !important; }
  .m-lg-n5q2 {
    margin: -5.5rem !important; }
  .mt-lg-n5q2,
  .my-lg-n5q2 {
    margin-top: -5.5rem !important; }
  .mr-lg-n5q2,
  .mx-lg-n5q2 {
    margin-right: -5.5rem !important; }
  .mb-lg-n5q2,
  .my-lg-n5q2 {
    margin-bottom: -5.5rem !important; }
  .ml-lg-n5q2,
  .mx-lg-n5q2 {
    margin-left: -5.5rem !important; }
  .m-lg-n5q3 {
    margin: -5.75rem !important; }
  .mt-lg-n5q3,
  .my-lg-n5q3 {
    margin-top: -5.75rem !important; }
  .mr-lg-n5q3,
  .mx-lg-n5q3 {
    margin-right: -5.75rem !important; }
  .mb-lg-n5q3,
  .my-lg-n5q3 {
    margin-bottom: -5.75rem !important; }
  .ml-lg-n5q3,
  .mx-lg-n5q3 {
    margin-left: -5.75rem !important; }
  .m-lg-n6 {
    margin: -6rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -6rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -6rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -6rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -6rem !important; }
  .m-lg-n6q1 {
    margin: -6.25rem !important; }
  .mt-lg-n6q1,
  .my-lg-n6q1 {
    margin-top: -6.25rem !important; }
  .mr-lg-n6q1,
  .mx-lg-n6q1 {
    margin-right: -6.25rem !important; }
  .mb-lg-n6q1,
  .my-lg-n6q1 {
    margin-bottom: -6.25rem !important; }
  .ml-lg-n6q1,
  .mx-lg-n6q1 {
    margin-left: -6.25rem !important; }
  .m-lg-n6q2 {
    margin: -6.5rem !important; }
  .mt-lg-n6q2,
  .my-lg-n6q2 {
    margin-top: -6.5rem !important; }
  .mr-lg-n6q2,
  .mx-lg-n6q2 {
    margin-right: -6.5rem !important; }
  .mb-lg-n6q2,
  .my-lg-n6q2 {
    margin-bottom: -6.5rem !important; }
  .ml-lg-n6q2,
  .mx-lg-n6q2 {
    margin-left: -6.5rem !important; }
  .m-lg-n6q3 {
    margin: -6.75rem !important; }
  .mt-lg-n6q3,
  .my-lg-n6q3 {
    margin-top: -6.75rem !important; }
  .mr-lg-n6q3,
  .mx-lg-n6q3 {
    margin-right: -6.75rem !important; }
  .mb-lg-n6q3,
  .my-lg-n6q3 {
    margin-bottom: -6.75rem !important; }
  .ml-lg-n6q3,
  .mx-lg-n6q3 {
    margin-left: -6.75rem !important; }
  .m-lg-n7 {
    margin: -7rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -7rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -7rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -7rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -7rem !important; }
  .m-lg-n7q1 {
    margin: -7.25rem !important; }
  .mt-lg-n7q1,
  .my-lg-n7q1 {
    margin-top: -7.25rem !important; }
  .mr-lg-n7q1,
  .mx-lg-n7q1 {
    margin-right: -7.25rem !important; }
  .mb-lg-n7q1,
  .my-lg-n7q1 {
    margin-bottom: -7.25rem !important; }
  .ml-lg-n7q1,
  .mx-lg-n7q1 {
    margin-left: -7.25rem !important; }
  .m-lg-n7q2 {
    margin: -7.5rem !important; }
  .mt-lg-n7q2,
  .my-lg-n7q2 {
    margin-top: -7.5rem !important; }
  .mr-lg-n7q2,
  .mx-lg-n7q2 {
    margin-right: -7.5rem !important; }
  .mb-lg-n7q2,
  .my-lg-n7q2 {
    margin-bottom: -7.5rem !important; }
  .ml-lg-n7q2,
  .mx-lg-n7q2 {
    margin-left: -7.5rem !important; }
  .m-lg-n7q3 {
    margin: -7.75rem !important; }
  .mt-lg-n7q3,
  .my-lg-n7q3 {
    margin-top: -7.75rem !important; }
  .mr-lg-n7q3,
  .mx-lg-n7q3 {
    margin-right: -7.75rem !important; }
  .mb-lg-n7q3,
  .my-lg-n7q3 {
    margin-bottom: -7.75rem !important; }
  .ml-lg-n7q3,
  .mx-lg-n7q3 {
    margin-left: -7.75rem !important; }
  .m-lg-n8 {
    margin: -8rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -8rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -8rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -8rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -8rem !important; }
  .m-lg-n8q1 {
    margin: -8.25rem !important; }
  .mt-lg-n8q1,
  .my-lg-n8q1 {
    margin-top: -8.25rem !important; }
  .mr-lg-n8q1,
  .mx-lg-n8q1 {
    margin-right: -8.25rem !important; }
  .mb-lg-n8q1,
  .my-lg-n8q1 {
    margin-bottom: -8.25rem !important; }
  .ml-lg-n8q1,
  .mx-lg-n8q1 {
    margin-left: -8.25rem !important; }
  .m-lg-n8q2 {
    margin: -8.5rem !important; }
  .mt-lg-n8q2,
  .my-lg-n8q2 {
    margin-top: -8.5rem !important; }
  .mr-lg-n8q2,
  .mx-lg-n8q2 {
    margin-right: -8.5rem !important; }
  .mb-lg-n8q2,
  .my-lg-n8q2 {
    margin-bottom: -8.5rem !important; }
  .ml-lg-n8q2,
  .mx-lg-n8q2 {
    margin-left: -8.5rem !important; }
  .m-lg-n8q3 {
    margin: -8.75rem !important; }
  .mt-lg-n8q3,
  .my-lg-n8q3 {
    margin-top: -8.75rem !important; }
  .mr-lg-n8q3,
  .mx-lg-n8q3 {
    margin-right: -8.75rem !important; }
  .mb-lg-n8q3,
  .my-lg-n8q3 {
    margin-bottom: -8.75rem !important; }
  .ml-lg-n8q3,
  .mx-lg-n8q3 {
    margin-left: -8.75rem !important; }
  .m-lg-n9 {
    margin: -9rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -9rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -9rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -9rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -9rem !important; }
  .m-lg-n9q1 {
    margin: -9.25rem !important; }
  .mt-lg-n9q1,
  .my-lg-n9q1 {
    margin-top: -9.25rem !important; }
  .mr-lg-n9q1,
  .mx-lg-n9q1 {
    margin-right: -9.25rem !important; }
  .mb-lg-n9q1,
  .my-lg-n9q1 {
    margin-bottom: -9.25rem !important; }
  .ml-lg-n9q1,
  .mx-lg-n9q1 {
    margin-left: -9.25rem !important; }
  .m-lg-n9q2 {
    margin: -9.5rem !important; }
  .mt-lg-n9q2,
  .my-lg-n9q2 {
    margin-top: -9.5rem !important; }
  .mr-lg-n9q2,
  .mx-lg-n9q2 {
    margin-right: -9.5rem !important; }
  .mb-lg-n9q2,
  .my-lg-n9q2 {
    margin-bottom: -9.5rem !important; }
  .ml-lg-n9q2,
  .mx-lg-n9q2 {
    margin-left: -9.5rem !important; }
  .m-lg-n9q3 {
    margin: -9.75rem !important; }
  .mt-lg-n9q3,
  .my-lg-n9q3 {
    margin-top: -9.75rem !important; }
  .mr-lg-n9q3,
  .mx-lg-n9q3 {
    margin-right: -9.75rem !important; }
  .mb-lg-n9q3,
  .my-lg-n9q3 {
    margin-bottom: -9.75rem !important; }
  .ml-lg-n9q3,
  .mx-lg-n9q3 {
    margin-left: -9.75rem !important; }
  .m-lg-n10 {
    margin: -10rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -10rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -10rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -10rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -10rem !important; }
  .m-lg-n10q1 {
    margin: -10.25rem !important; }
  .mt-lg-n10q1,
  .my-lg-n10q1 {
    margin-top: -10.25rem !important; }
  .mr-lg-n10q1,
  .mx-lg-n10q1 {
    margin-right: -10.25rem !important; }
  .mb-lg-n10q1,
  .my-lg-n10q1 {
    margin-bottom: -10.25rem !important; }
  .ml-lg-n10q1,
  .mx-lg-n10q1 {
    margin-left: -10.25rem !important; }
  .m-lg-n10q2 {
    margin: -10.5rem !important; }
  .mt-lg-n10q2,
  .my-lg-n10q2 {
    margin-top: -10.5rem !important; }
  .mr-lg-n10q2,
  .mx-lg-n10q2 {
    margin-right: -10.5rem !important; }
  .mb-lg-n10q2,
  .my-lg-n10q2 {
    margin-bottom: -10.5rem !important; }
  .ml-lg-n10q2,
  .mx-lg-n10q2 {
    margin-left: -10.5rem !important; }
  .m-lg-n10q3 {
    margin: -10.75rem !important; }
  .mt-lg-n10q3,
  .my-lg-n10q3 {
    margin-top: -10.75rem !important; }
  .mr-lg-n10q3,
  .mx-lg-n10q3 {
    margin-right: -10.75rem !important; }
  .mb-lg-n10q3,
  .my-lg-n10q3 {
    margin-bottom: -10.75rem !important; }
  .ml-lg-n10q3,
  .mx-lg-n10q3 {
    margin-left: -10.75rem !important; }
  .m-lg-n11 {
    margin: -11rem !important; }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -11rem !important; }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -11rem !important; }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -11rem !important; }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -11rem !important; }
  .m-lg-n11q1 {
    margin: -11.25rem !important; }
  .mt-lg-n11q1,
  .my-lg-n11q1 {
    margin-top: -11.25rem !important; }
  .mr-lg-n11q1,
  .mx-lg-n11q1 {
    margin-right: -11.25rem !important; }
  .mb-lg-n11q1,
  .my-lg-n11q1 {
    margin-bottom: -11.25rem !important; }
  .ml-lg-n11q1,
  .mx-lg-n11q1 {
    margin-left: -11.25rem !important; }
  .m-lg-n11 q2 {
    margin: -11.5rem !important; }
  .mt-lg-n11 q2,
  .my-lg-n11 q2 {
    margin-top: -11.5rem !important; }
  .mr-lg-n11 q2,
  .mx-lg-n11 q2 {
    margin-right: -11.5rem !important; }
  .mb-lg-n11 q2,
  .my-lg-n11 q2 {
    margin-bottom: -11.5rem !important; }
  .ml-lg-n11 q2,
  .mx-lg-n11 q2 {
    margin-left: -11.5rem !important; }
  .m-lg-n11 q3 {
    margin: -11.75rem !important; }
  .mt-lg-n11 q3,
  .my-lg-n11 q3 {
    margin-top: -11.75rem !important; }
  .mr-lg-n11 q3,
  .mx-lg-n11 q3 {
    margin-right: -11.75rem !important; }
  .mb-lg-n11 q3,
  .my-lg-n11 q3 {
    margin-bottom: -11.75rem !important; }
  .ml-lg-n11 q3,
  .mx-lg-n11 q3 {
    margin-left: -11.75rem !important; }
  .m-lg-n12 {
    margin: -12rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -12rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -12rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -12rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -12rem !important; }
  .m-lg-n12 q1 {
    margin: -12.25rem !important; }
  .mt-lg-n12 q1,
  .my-lg-n12 q1 {
    margin-top: -12.25rem !important; }
  .mr-lg-n12 q1,
  .mx-lg-n12 q1 {
    margin-right: -12.25rem !important; }
  .mb-lg-n12 q1,
  .my-lg-n12 q1 {
    margin-bottom: -12.25rem !important; }
  .ml-lg-n12 q1,
  .mx-lg-n12 q1 {
    margin-left: -12.25rem !important; }
  .m-lg-n12 q2 {
    margin: -12.5rem !important; }
  .mt-lg-n12 q2,
  .my-lg-n12 q2 {
    margin-top: -12.5rem !important; }
  .mr-lg-n12 q2,
  .mx-lg-n12 q2 {
    margin-right: -12.5rem !important; }
  .mb-lg-n12 q2,
  .my-lg-n12 q2 {
    margin-bottom: -12.5rem !important; }
  .ml-lg-n12 q2,
  .mx-lg-n12 q2 {
    margin-left: -12.5rem !important; }
  .m-lg-n12 q3 {
    margin: -12.75rem !important; }
  .mt-lg-n12 q3,
  .my-lg-n12 q3 {
    margin-top: -12.75rem !important; }
  .mr-lg-n12 q3,
  .mx-lg-n12 q3 {
    margin-right: -12.75rem !important; }
  .mb-lg-n12 q3,
  .my-lg-n12 q3 {
    margin-bottom: -12.75rem !important; }
  .ml-lg-n12 q3,
  .mx-lg-n12 q3 {
    margin-left: -12.75rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-q1 {
    margin: 0.25rem !important; }
  .mt-xl-q1,
  .my-xl-q1 {
    margin-top: 0.25rem !important; }
  .mr-xl-q1,
  .mx-xl-q1 {
    margin-right: 0.25rem !important; }
  .mb-xl-q1,
  .my-xl-q1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-q1,
  .mx-xl-q1 {
    margin-left: 0.25rem !important; }
  .m-xl-q2 {
    margin: 0.5rem !important; }
  .mt-xl-q2,
  .my-xl-q2 {
    margin-top: 0.5rem !important; }
  .mr-xl-q2,
  .mx-xl-q2 {
    margin-right: 0.5rem !important; }
  .mb-xl-q2,
  .my-xl-q2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-q2,
  .mx-xl-q2 {
    margin-left: 0.5rem !important; }
  .m-xl-q3 {
    margin: 0.75rem !important; }
  .mt-xl-q3,
  .my-xl-q3 {
    margin-top: 0.75rem !important; }
  .mr-xl-q3,
  .mx-xl-q3 {
    margin-right: 0.75rem !important; }
  .mb-xl-q3,
  .my-xl-q3 {
    margin-bottom: 0.75rem !important; }
  .ml-xl-q3,
  .mx-xl-q3 {
    margin-left: 0.75rem !important; }
  .m-xl-0 {
    margin: 0rem !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0rem !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0rem !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0rem !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0rem !important; }
  .m-xl-1 {
    margin: 1rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 1rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 1rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 1rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 1rem !important; }
  .m-xl-1q1 {
    margin: 1.25rem !important; }
  .mt-xl-1q1,
  .my-xl-1q1 {
    margin-top: 1.25rem !important; }
  .mr-xl-1q1,
  .mx-xl-1q1 {
    margin-right: 1.25rem !important; }
  .mb-xl-1q1,
  .my-xl-1q1 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-1q1,
  .mx-xl-1q1 {
    margin-left: 1.25rem !important; }
  .m-xl-1q2 {
    margin: 1.5rem !important; }
  .mt-xl-1q2,
  .my-xl-1q2 {
    margin-top: 1.5rem !important; }
  .mr-xl-1q2,
  .mx-xl-1q2 {
    margin-right: 1.5rem !important; }
  .mb-xl-1q2,
  .my-xl-1q2 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-1q2,
  .mx-xl-1q2 {
    margin-left: 1.5rem !important; }
  .m-xl-1q3 {
    margin: 1.75rem !important; }
  .mt-xl-1q3,
  .my-xl-1q3 {
    margin-top: 1.75rem !important; }
  .mr-xl-1q3,
  .mx-xl-1q3 {
    margin-right: 1.75rem !important; }
  .mb-xl-1q3,
  .my-xl-1q3 {
    margin-bottom: 1.75rem !important; }
  .ml-xl-1q3,
  .mx-xl-1q3 {
    margin-left: 1.75rem !important; }
  .m-xl-2 {
    margin: 2rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 2rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 2rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 2rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 2rem !important; }
  .m-xl-2q1 {
    margin: 2.25rem !important; }
  .mt-xl-2q1,
  .my-xl-2q1 {
    margin-top: 2.25rem !important; }
  .mr-xl-2q1,
  .mx-xl-2q1 {
    margin-right: 2.25rem !important; }
  .mb-xl-2q1,
  .my-xl-2q1 {
    margin-bottom: 2.25rem !important; }
  .ml-xl-2q1,
  .mx-xl-2q1 {
    margin-left: 2.25rem !important; }
  .m-xl-2q2 {
    margin: 2.5rem !important; }
  .mt-xl-2q2,
  .my-xl-2q2 {
    margin-top: 2.5rem !important; }
  .mr-xl-2q2,
  .mx-xl-2q2 {
    margin-right: 2.5rem !important; }
  .mb-xl-2q2,
  .my-xl-2q2 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-2q2,
  .mx-xl-2q2 {
    margin-left: 2.5rem !important; }
  .m-xl-2q3 {
    margin: 2.75rem !important; }
  .mt-xl-2q3,
  .my-xl-2q3 {
    margin-top: 2.75rem !important; }
  .mr-xl-2q3,
  .mx-xl-2q3 {
    margin-right: 2.75rem !important; }
  .mb-xl-2q3,
  .my-xl-2q3 {
    margin-bottom: 2.75rem !important; }
  .ml-xl-2q3,
  .mx-xl-2q3 {
    margin-left: 2.75rem !important; }
  .m-xl-3 {
    margin: 3rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 3rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 3rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 3rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 3rem !important; }
  .m-xl-3q1 {
    margin: 3.25rem !important; }
  .mt-xl-3q1,
  .my-xl-3q1 {
    margin-top: 3.25rem !important; }
  .mr-xl-3q1,
  .mx-xl-3q1 {
    margin-right: 3.25rem !important; }
  .mb-xl-3q1,
  .my-xl-3q1 {
    margin-bottom: 3.25rem !important; }
  .ml-xl-3q1,
  .mx-xl-3q1 {
    margin-left: 3.25rem !important; }
  .m-xl-3q2 {
    margin: 3.5rem !important; }
  .mt-xl-3q2,
  .my-xl-3q2 {
    margin-top: 3.5rem !important; }
  .mr-xl-3q2,
  .mx-xl-3q2 {
    margin-right: 3.5rem !important; }
  .mb-xl-3q2,
  .my-xl-3q2 {
    margin-bottom: 3.5rem !important; }
  .ml-xl-3q2,
  .mx-xl-3q2 {
    margin-left: 3.5rem !important; }
  .m-xl-3q3 {
    margin: 3.75rem !important; }
  .mt-xl-3q3,
  .my-xl-3q3 {
    margin-top: 3.75rem !important; }
  .mr-xl-3q3,
  .mx-xl-3q3 {
    margin-right: 3.75rem !important; }
  .mb-xl-3q3,
  .my-xl-3q3 {
    margin-bottom: 3.75rem !important; }
  .ml-xl-3q3,
  .mx-xl-3q3 {
    margin-left: 3.75rem !important; }
  .m-xl-4 {
    margin: 4rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 4rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 4rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 4rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 4rem !important; }
  .m-xl-4q1 {
    margin: 4.25rem !important; }
  .mt-xl-4q1,
  .my-xl-4q1 {
    margin-top: 4.25rem !important; }
  .mr-xl-4q1,
  .mx-xl-4q1 {
    margin-right: 4.25rem !important; }
  .mb-xl-4q1,
  .my-xl-4q1 {
    margin-bottom: 4.25rem !important; }
  .ml-xl-4q1,
  .mx-xl-4q1 {
    margin-left: 4.25rem !important; }
  .m-xl-4q2 {
    margin: 4.5rem !important; }
  .mt-xl-4q2,
  .my-xl-4q2 {
    margin-top: 4.5rem !important; }
  .mr-xl-4q2,
  .mx-xl-4q2 {
    margin-right: 4.5rem !important; }
  .mb-xl-4q2,
  .my-xl-4q2 {
    margin-bottom: 4.5rem !important; }
  .ml-xl-4q2,
  .mx-xl-4q2 {
    margin-left: 4.5rem !important; }
  .m-xl-4q3 {
    margin: 4.75rem !important; }
  .mt-xl-4q3,
  .my-xl-4q3 {
    margin-top: 4.75rem !important; }
  .mr-xl-4q3,
  .mx-xl-4q3 {
    margin-right: 4.75rem !important; }
  .mb-xl-4q3,
  .my-xl-4q3 {
    margin-bottom: 4.75rem !important; }
  .ml-xl-4q3,
  .mx-xl-4q3 {
    margin-left: 4.75rem !important; }
  .m-xl-5 {
    margin: 5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 5rem !important; }
  .m-xl-5q1 {
    margin: 5.25rem !important; }
  .mt-xl-5q1,
  .my-xl-5q1 {
    margin-top: 5.25rem !important; }
  .mr-xl-5q1,
  .mx-xl-5q1 {
    margin-right: 5.25rem !important; }
  .mb-xl-5q1,
  .my-xl-5q1 {
    margin-bottom: 5.25rem !important; }
  .ml-xl-5q1,
  .mx-xl-5q1 {
    margin-left: 5.25rem !important; }
  .m-xl-5q2 {
    margin: 5.5rem !important; }
  .mt-xl-5q2,
  .my-xl-5q2 {
    margin-top: 5.5rem !important; }
  .mr-xl-5q2,
  .mx-xl-5q2 {
    margin-right: 5.5rem !important; }
  .mb-xl-5q2,
  .my-xl-5q2 {
    margin-bottom: 5.5rem !important; }
  .ml-xl-5q2,
  .mx-xl-5q2 {
    margin-left: 5.5rem !important; }
  .m-xl-5q3 {
    margin: 5.75rem !important; }
  .mt-xl-5q3,
  .my-xl-5q3 {
    margin-top: 5.75rem !important; }
  .mr-xl-5q3,
  .mx-xl-5q3 {
    margin-right: 5.75rem !important; }
  .mb-xl-5q3,
  .my-xl-5q3 {
    margin-bottom: 5.75rem !important; }
  .ml-xl-5q3,
  .mx-xl-5q3 {
    margin-left: 5.75rem !important; }
  .m-xl-6 {
    margin: 6rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 6rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 6rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 6rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 6rem !important; }
  .m-xl-6q1 {
    margin: 6.25rem !important; }
  .mt-xl-6q1,
  .my-xl-6q1 {
    margin-top: 6.25rem !important; }
  .mr-xl-6q1,
  .mx-xl-6q1 {
    margin-right: 6.25rem !important; }
  .mb-xl-6q1,
  .my-xl-6q1 {
    margin-bottom: 6.25rem !important; }
  .ml-xl-6q1,
  .mx-xl-6q1 {
    margin-left: 6.25rem !important; }
  .m-xl-6q2 {
    margin: 6.5rem !important; }
  .mt-xl-6q2,
  .my-xl-6q2 {
    margin-top: 6.5rem !important; }
  .mr-xl-6q2,
  .mx-xl-6q2 {
    margin-right: 6.5rem !important; }
  .mb-xl-6q2,
  .my-xl-6q2 {
    margin-bottom: 6.5rem !important; }
  .ml-xl-6q2,
  .mx-xl-6q2 {
    margin-left: 6.5rem !important; }
  .m-xl-6q3 {
    margin: 6.75rem !important; }
  .mt-xl-6q3,
  .my-xl-6q3 {
    margin-top: 6.75rem !important; }
  .mr-xl-6q3,
  .mx-xl-6q3 {
    margin-right: 6.75rem !important; }
  .mb-xl-6q3,
  .my-xl-6q3 {
    margin-bottom: 6.75rem !important; }
  .ml-xl-6q3,
  .mx-xl-6q3 {
    margin-left: 6.75rem !important; }
  .m-xl-7 {
    margin: 7rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 7rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 7rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 7rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 7rem !important; }
  .m-xl-7q1 {
    margin: 7.25rem !important; }
  .mt-xl-7q1,
  .my-xl-7q1 {
    margin-top: 7.25rem !important; }
  .mr-xl-7q1,
  .mx-xl-7q1 {
    margin-right: 7.25rem !important; }
  .mb-xl-7q1,
  .my-xl-7q1 {
    margin-bottom: 7.25rem !important; }
  .ml-xl-7q1,
  .mx-xl-7q1 {
    margin-left: 7.25rem !important; }
  .m-xl-7q2 {
    margin: 7.5rem !important; }
  .mt-xl-7q2,
  .my-xl-7q2 {
    margin-top: 7.5rem !important; }
  .mr-xl-7q2,
  .mx-xl-7q2 {
    margin-right: 7.5rem !important; }
  .mb-xl-7q2,
  .my-xl-7q2 {
    margin-bottom: 7.5rem !important; }
  .ml-xl-7q2,
  .mx-xl-7q2 {
    margin-left: 7.5rem !important; }
  .m-xl-7q3 {
    margin: 7.75rem !important; }
  .mt-xl-7q3,
  .my-xl-7q3 {
    margin-top: 7.75rem !important; }
  .mr-xl-7q3,
  .mx-xl-7q3 {
    margin-right: 7.75rem !important; }
  .mb-xl-7q3,
  .my-xl-7q3 {
    margin-bottom: 7.75rem !important; }
  .ml-xl-7q3,
  .mx-xl-7q3 {
    margin-left: 7.75rem !important; }
  .m-xl-8 {
    margin: 8rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 8rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 8rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 8rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 8rem !important; }
  .m-xl-8q1 {
    margin: 8.25rem !important; }
  .mt-xl-8q1,
  .my-xl-8q1 {
    margin-top: 8.25rem !important; }
  .mr-xl-8q1,
  .mx-xl-8q1 {
    margin-right: 8.25rem !important; }
  .mb-xl-8q1,
  .my-xl-8q1 {
    margin-bottom: 8.25rem !important; }
  .ml-xl-8q1,
  .mx-xl-8q1 {
    margin-left: 8.25rem !important; }
  .m-xl-8q2 {
    margin: 8.5rem !important; }
  .mt-xl-8q2,
  .my-xl-8q2 {
    margin-top: 8.5rem !important; }
  .mr-xl-8q2,
  .mx-xl-8q2 {
    margin-right: 8.5rem !important; }
  .mb-xl-8q2,
  .my-xl-8q2 {
    margin-bottom: 8.5rem !important; }
  .ml-xl-8q2,
  .mx-xl-8q2 {
    margin-left: 8.5rem !important; }
  .m-xl-8q3 {
    margin: 8.75rem !important; }
  .mt-xl-8q3,
  .my-xl-8q3 {
    margin-top: 8.75rem !important; }
  .mr-xl-8q3,
  .mx-xl-8q3 {
    margin-right: 8.75rem !important; }
  .mb-xl-8q3,
  .my-xl-8q3 {
    margin-bottom: 8.75rem !important; }
  .ml-xl-8q3,
  .mx-xl-8q3 {
    margin-left: 8.75rem !important; }
  .m-xl-9 {
    margin: 9rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 9rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 9rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 9rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 9rem !important; }
  .m-xl-9q1 {
    margin: 9.25rem !important; }
  .mt-xl-9q1,
  .my-xl-9q1 {
    margin-top: 9.25rem !important; }
  .mr-xl-9q1,
  .mx-xl-9q1 {
    margin-right: 9.25rem !important; }
  .mb-xl-9q1,
  .my-xl-9q1 {
    margin-bottom: 9.25rem !important; }
  .ml-xl-9q1,
  .mx-xl-9q1 {
    margin-left: 9.25rem !important; }
  .m-xl-9q2 {
    margin: 9.5rem !important; }
  .mt-xl-9q2,
  .my-xl-9q2 {
    margin-top: 9.5rem !important; }
  .mr-xl-9q2,
  .mx-xl-9q2 {
    margin-right: 9.5rem !important; }
  .mb-xl-9q2,
  .my-xl-9q2 {
    margin-bottom: 9.5rem !important; }
  .ml-xl-9q2,
  .mx-xl-9q2 {
    margin-left: 9.5rem !important; }
  .m-xl-9q3 {
    margin: 9.75rem !important; }
  .mt-xl-9q3,
  .my-xl-9q3 {
    margin-top: 9.75rem !important; }
  .mr-xl-9q3,
  .mx-xl-9q3 {
    margin-right: 9.75rem !important; }
  .mb-xl-9q3,
  .my-xl-9q3 {
    margin-bottom: 9.75rem !important; }
  .ml-xl-9q3,
  .mx-xl-9q3 {
    margin-left: 9.75rem !important; }
  .m-xl-10 {
    margin: 10rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10rem !important; }
  .m-xl-10q1 {
    margin: 10.25rem !important; }
  .mt-xl-10q1,
  .my-xl-10q1 {
    margin-top: 10.25rem !important; }
  .mr-xl-10q1,
  .mx-xl-10q1 {
    margin-right: 10.25rem !important; }
  .mb-xl-10q1,
  .my-xl-10q1 {
    margin-bottom: 10.25rem !important; }
  .ml-xl-10q1,
  .mx-xl-10q1 {
    margin-left: 10.25rem !important; }
  .m-xl-10q2 {
    margin: 10.5rem !important; }
  .mt-xl-10q2,
  .my-xl-10q2 {
    margin-top: 10.5rem !important; }
  .mr-xl-10q2,
  .mx-xl-10q2 {
    margin-right: 10.5rem !important; }
  .mb-xl-10q2,
  .my-xl-10q2 {
    margin-bottom: 10.5rem !important; }
  .ml-xl-10q2,
  .mx-xl-10q2 {
    margin-left: 10.5rem !important; }
  .m-xl-10q3 {
    margin: 10.75rem !important; }
  .mt-xl-10q3,
  .my-xl-10q3 {
    margin-top: 10.75rem !important; }
  .mr-xl-10q3,
  .mx-xl-10q3 {
    margin-right: 10.75rem !important; }
  .mb-xl-10q3,
  .my-xl-10q3 {
    margin-bottom: 10.75rem !important; }
  .ml-xl-10q3,
  .mx-xl-10q3 {
    margin-left: 10.75rem !important; }
  .m-xl-11 {
    margin: 11rem !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 11rem !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 11rem !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 11rem !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 11rem !important; }
  .m-xl-11q1 {
    margin: 11.25rem !important; }
  .mt-xl-11q1,
  .my-xl-11q1 {
    margin-top: 11.25rem !important; }
  .mr-xl-11q1,
  .mx-xl-11q1 {
    margin-right: 11.25rem !important; }
  .mb-xl-11q1,
  .my-xl-11q1 {
    margin-bottom: 11.25rem !important; }
  .ml-xl-11q1,
  .mx-xl-11q1 {
    margin-left: 11.25rem !important; }
  .m-xl-11 q2 {
    margin: 11.5rem !important; }
  .mt-xl-11 q2,
  .my-xl-11 q2 {
    margin-top: 11.5rem !important; }
  .mr-xl-11 q2,
  .mx-xl-11 q2 {
    margin-right: 11.5rem !important; }
  .mb-xl-11 q2,
  .my-xl-11 q2 {
    margin-bottom: 11.5rem !important; }
  .ml-xl-11 q2,
  .mx-xl-11 q2 {
    margin-left: 11.5rem !important; }
  .m-xl-11 q3 {
    margin: 11.75rem !important; }
  .mt-xl-11 q3,
  .my-xl-11 q3 {
    margin-top: 11.75rem !important; }
  .mr-xl-11 q3,
  .mx-xl-11 q3 {
    margin-right: 11.75rem !important; }
  .mb-xl-11 q3,
  .my-xl-11 q3 {
    margin-bottom: 11.75rem !important; }
  .ml-xl-11 q3,
  .mx-xl-11 q3 {
    margin-left: 11.75rem !important; }
  .m-xl-12 {
    margin: 12rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 12rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 12rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 12rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 12rem !important; }
  .m-xl-12 q1 {
    margin: 12.25rem !important; }
  .mt-xl-12 q1,
  .my-xl-12 q1 {
    margin-top: 12.25rem !important; }
  .mr-xl-12 q1,
  .mx-xl-12 q1 {
    margin-right: 12.25rem !important; }
  .mb-xl-12 q1,
  .my-xl-12 q1 {
    margin-bottom: 12.25rem !important; }
  .ml-xl-12 q1,
  .mx-xl-12 q1 {
    margin-left: 12.25rem !important; }
  .m-xl-12 q2 {
    margin: 12.5rem !important; }
  .mt-xl-12 q2,
  .my-xl-12 q2 {
    margin-top: 12.5rem !important; }
  .mr-xl-12 q2,
  .mx-xl-12 q2 {
    margin-right: 12.5rem !important; }
  .mb-xl-12 q2,
  .my-xl-12 q2 {
    margin-bottom: 12.5rem !important; }
  .ml-xl-12 q2,
  .mx-xl-12 q2 {
    margin-left: 12.5rem !important; }
  .m-xl-12 q3 {
    margin: 12.75rem !important; }
  .mt-xl-12 q3,
  .my-xl-12 q3 {
    margin-top: 12.75rem !important; }
  .mr-xl-12 q3,
  .mx-xl-12 q3 {
    margin-right: 12.75rem !important; }
  .mb-xl-12 q3,
  .my-xl-12 q3 {
    margin-bottom: 12.75rem !important; }
  .ml-xl-12 q3,
  .mx-xl-12 q3 {
    margin-left: 12.75rem !important; }
  .p-xl-q1 {
    padding: 0.25rem !important; }
  .pt-xl-q1,
  .py-xl-q1 {
    padding-top: 0.25rem !important; }
  .pr-xl-q1,
  .px-xl-q1 {
    padding-right: 0.25rem !important; }
  .pb-xl-q1,
  .py-xl-q1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-q1,
  .px-xl-q1 {
    padding-left: 0.25rem !important; }
  .p-xl-q2 {
    padding: 0.5rem !important; }
  .pt-xl-q2,
  .py-xl-q2 {
    padding-top: 0.5rem !important; }
  .pr-xl-q2,
  .px-xl-q2 {
    padding-right: 0.5rem !important; }
  .pb-xl-q2,
  .py-xl-q2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-q2,
  .px-xl-q2 {
    padding-left: 0.5rem !important; }
  .p-xl-q3 {
    padding: 0.75rem !important; }
  .pt-xl-q3,
  .py-xl-q3 {
    padding-top: 0.75rem !important; }
  .pr-xl-q3,
  .px-xl-q3 {
    padding-right: 0.75rem !important; }
  .pb-xl-q3,
  .py-xl-q3 {
    padding-bottom: 0.75rem !important; }
  .pl-xl-q3,
  .px-xl-q3 {
    padding-left: 0.75rem !important; }
  .p-xl-0 {
    padding: 0rem !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0rem !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0rem !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0rem !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0rem !important; }
  .p-xl-1 {
    padding: 1rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 1rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 1rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 1rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 1rem !important; }
  .p-xl-1q1 {
    padding: 1.25rem !important; }
  .pt-xl-1q1,
  .py-xl-1q1 {
    padding-top: 1.25rem !important; }
  .pr-xl-1q1,
  .px-xl-1q1 {
    padding-right: 1.25rem !important; }
  .pb-xl-1q1,
  .py-xl-1q1 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-1q1,
  .px-xl-1q1 {
    padding-left: 1.25rem !important; }
  .p-xl-1q2 {
    padding: 1.5rem !important; }
  .pt-xl-1q2,
  .py-xl-1q2 {
    padding-top: 1.5rem !important; }
  .pr-xl-1q2,
  .px-xl-1q2 {
    padding-right: 1.5rem !important; }
  .pb-xl-1q2,
  .py-xl-1q2 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-1q2,
  .px-xl-1q2 {
    padding-left: 1.5rem !important; }
  .p-xl-1q3 {
    padding: 1.75rem !important; }
  .pt-xl-1q3,
  .py-xl-1q3 {
    padding-top: 1.75rem !important; }
  .pr-xl-1q3,
  .px-xl-1q3 {
    padding-right: 1.75rem !important; }
  .pb-xl-1q3,
  .py-xl-1q3 {
    padding-bottom: 1.75rem !important; }
  .pl-xl-1q3,
  .px-xl-1q3 {
    padding-left: 1.75rem !important; }
  .p-xl-2 {
    padding: 2rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 2rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 2rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 2rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 2rem !important; }
  .p-xl-2q1 {
    padding: 2.25rem !important; }
  .pt-xl-2q1,
  .py-xl-2q1 {
    padding-top: 2.25rem !important; }
  .pr-xl-2q1,
  .px-xl-2q1 {
    padding-right: 2.25rem !important; }
  .pb-xl-2q1,
  .py-xl-2q1 {
    padding-bottom: 2.25rem !important; }
  .pl-xl-2q1,
  .px-xl-2q1 {
    padding-left: 2.25rem !important; }
  .p-xl-2q2 {
    padding: 2.5rem !important; }
  .pt-xl-2q2,
  .py-xl-2q2 {
    padding-top: 2.5rem !important; }
  .pr-xl-2q2,
  .px-xl-2q2 {
    padding-right: 2.5rem !important; }
  .pb-xl-2q2,
  .py-xl-2q2 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-2q2,
  .px-xl-2q2 {
    padding-left: 2.5rem !important; }
  .p-xl-2q3 {
    padding: 2.75rem !important; }
  .pt-xl-2q3,
  .py-xl-2q3 {
    padding-top: 2.75rem !important; }
  .pr-xl-2q3,
  .px-xl-2q3 {
    padding-right: 2.75rem !important; }
  .pb-xl-2q3,
  .py-xl-2q3 {
    padding-bottom: 2.75rem !important; }
  .pl-xl-2q3,
  .px-xl-2q3 {
    padding-left: 2.75rem !important; }
  .p-xl-3 {
    padding: 3rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 3rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 3rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 3rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 3rem !important; }
  .p-xl-3q1 {
    padding: 3.25rem !important; }
  .pt-xl-3q1,
  .py-xl-3q1 {
    padding-top: 3.25rem !important; }
  .pr-xl-3q1,
  .px-xl-3q1 {
    padding-right: 3.25rem !important; }
  .pb-xl-3q1,
  .py-xl-3q1 {
    padding-bottom: 3.25rem !important; }
  .pl-xl-3q1,
  .px-xl-3q1 {
    padding-left: 3.25rem !important; }
  .p-xl-3q2 {
    padding: 3.5rem !important; }
  .pt-xl-3q2,
  .py-xl-3q2 {
    padding-top: 3.5rem !important; }
  .pr-xl-3q2,
  .px-xl-3q2 {
    padding-right: 3.5rem !important; }
  .pb-xl-3q2,
  .py-xl-3q2 {
    padding-bottom: 3.5rem !important; }
  .pl-xl-3q2,
  .px-xl-3q2 {
    padding-left: 3.5rem !important; }
  .p-xl-3q3 {
    padding: 3.75rem !important; }
  .pt-xl-3q3,
  .py-xl-3q3 {
    padding-top: 3.75rem !important; }
  .pr-xl-3q3,
  .px-xl-3q3 {
    padding-right: 3.75rem !important; }
  .pb-xl-3q3,
  .py-xl-3q3 {
    padding-bottom: 3.75rem !important; }
  .pl-xl-3q3,
  .px-xl-3q3 {
    padding-left: 3.75rem !important; }
  .p-xl-4 {
    padding: 4rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 4rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 4rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 4rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 4rem !important; }
  .p-xl-4q1 {
    padding: 4.25rem !important; }
  .pt-xl-4q1,
  .py-xl-4q1 {
    padding-top: 4.25rem !important; }
  .pr-xl-4q1,
  .px-xl-4q1 {
    padding-right: 4.25rem !important; }
  .pb-xl-4q1,
  .py-xl-4q1 {
    padding-bottom: 4.25rem !important; }
  .pl-xl-4q1,
  .px-xl-4q1 {
    padding-left: 4.25rem !important; }
  .p-xl-4q2 {
    padding: 4.5rem !important; }
  .pt-xl-4q2,
  .py-xl-4q2 {
    padding-top: 4.5rem !important; }
  .pr-xl-4q2,
  .px-xl-4q2 {
    padding-right: 4.5rem !important; }
  .pb-xl-4q2,
  .py-xl-4q2 {
    padding-bottom: 4.5rem !important; }
  .pl-xl-4q2,
  .px-xl-4q2 {
    padding-left: 4.5rem !important; }
  .p-xl-4q3 {
    padding: 4.75rem !important; }
  .pt-xl-4q3,
  .py-xl-4q3 {
    padding-top: 4.75rem !important; }
  .pr-xl-4q3,
  .px-xl-4q3 {
    padding-right: 4.75rem !important; }
  .pb-xl-4q3,
  .py-xl-4q3 {
    padding-bottom: 4.75rem !important; }
  .pl-xl-4q3,
  .px-xl-4q3 {
    padding-left: 4.75rem !important; }
  .p-xl-5 {
    padding: 5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 5rem !important; }
  .p-xl-5q1 {
    padding: 5.25rem !important; }
  .pt-xl-5q1,
  .py-xl-5q1 {
    padding-top: 5.25rem !important; }
  .pr-xl-5q1,
  .px-xl-5q1 {
    padding-right: 5.25rem !important; }
  .pb-xl-5q1,
  .py-xl-5q1 {
    padding-bottom: 5.25rem !important; }
  .pl-xl-5q1,
  .px-xl-5q1 {
    padding-left: 5.25rem !important; }
  .p-xl-5q2 {
    padding: 5.5rem !important; }
  .pt-xl-5q2,
  .py-xl-5q2 {
    padding-top: 5.5rem !important; }
  .pr-xl-5q2,
  .px-xl-5q2 {
    padding-right: 5.5rem !important; }
  .pb-xl-5q2,
  .py-xl-5q2 {
    padding-bottom: 5.5rem !important; }
  .pl-xl-5q2,
  .px-xl-5q2 {
    padding-left: 5.5rem !important; }
  .p-xl-5q3 {
    padding: 5.75rem !important; }
  .pt-xl-5q3,
  .py-xl-5q3 {
    padding-top: 5.75rem !important; }
  .pr-xl-5q3,
  .px-xl-5q3 {
    padding-right: 5.75rem !important; }
  .pb-xl-5q3,
  .py-xl-5q3 {
    padding-bottom: 5.75rem !important; }
  .pl-xl-5q3,
  .px-xl-5q3 {
    padding-left: 5.75rem !important; }
  .p-xl-6 {
    padding: 6rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 6rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 6rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 6rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 6rem !important; }
  .p-xl-6q1 {
    padding: 6.25rem !important; }
  .pt-xl-6q1,
  .py-xl-6q1 {
    padding-top: 6.25rem !important; }
  .pr-xl-6q1,
  .px-xl-6q1 {
    padding-right: 6.25rem !important; }
  .pb-xl-6q1,
  .py-xl-6q1 {
    padding-bottom: 6.25rem !important; }
  .pl-xl-6q1,
  .px-xl-6q1 {
    padding-left: 6.25rem !important; }
  .p-xl-6q2 {
    padding: 6.5rem !important; }
  .pt-xl-6q2,
  .py-xl-6q2 {
    padding-top: 6.5rem !important; }
  .pr-xl-6q2,
  .px-xl-6q2 {
    padding-right: 6.5rem !important; }
  .pb-xl-6q2,
  .py-xl-6q2 {
    padding-bottom: 6.5rem !important; }
  .pl-xl-6q2,
  .px-xl-6q2 {
    padding-left: 6.5rem !important; }
  .p-xl-6q3 {
    padding: 6.75rem !important; }
  .pt-xl-6q3,
  .py-xl-6q3 {
    padding-top: 6.75rem !important; }
  .pr-xl-6q3,
  .px-xl-6q3 {
    padding-right: 6.75rem !important; }
  .pb-xl-6q3,
  .py-xl-6q3 {
    padding-bottom: 6.75rem !important; }
  .pl-xl-6q3,
  .px-xl-6q3 {
    padding-left: 6.75rem !important; }
  .p-xl-7 {
    padding: 7rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 7rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 7rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 7rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 7rem !important; }
  .p-xl-7q1 {
    padding: 7.25rem !important; }
  .pt-xl-7q1,
  .py-xl-7q1 {
    padding-top: 7.25rem !important; }
  .pr-xl-7q1,
  .px-xl-7q1 {
    padding-right: 7.25rem !important; }
  .pb-xl-7q1,
  .py-xl-7q1 {
    padding-bottom: 7.25rem !important; }
  .pl-xl-7q1,
  .px-xl-7q1 {
    padding-left: 7.25rem !important; }
  .p-xl-7q2 {
    padding: 7.5rem !important; }
  .pt-xl-7q2,
  .py-xl-7q2 {
    padding-top: 7.5rem !important; }
  .pr-xl-7q2,
  .px-xl-7q2 {
    padding-right: 7.5rem !important; }
  .pb-xl-7q2,
  .py-xl-7q2 {
    padding-bottom: 7.5rem !important; }
  .pl-xl-7q2,
  .px-xl-7q2 {
    padding-left: 7.5rem !important; }
  .p-xl-7q3 {
    padding: 7.75rem !important; }
  .pt-xl-7q3,
  .py-xl-7q3 {
    padding-top: 7.75rem !important; }
  .pr-xl-7q3,
  .px-xl-7q3 {
    padding-right: 7.75rem !important; }
  .pb-xl-7q3,
  .py-xl-7q3 {
    padding-bottom: 7.75rem !important; }
  .pl-xl-7q3,
  .px-xl-7q3 {
    padding-left: 7.75rem !important; }
  .p-xl-8 {
    padding: 8rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 8rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 8rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 8rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 8rem !important; }
  .p-xl-8q1 {
    padding: 8.25rem !important; }
  .pt-xl-8q1,
  .py-xl-8q1 {
    padding-top: 8.25rem !important; }
  .pr-xl-8q1,
  .px-xl-8q1 {
    padding-right: 8.25rem !important; }
  .pb-xl-8q1,
  .py-xl-8q1 {
    padding-bottom: 8.25rem !important; }
  .pl-xl-8q1,
  .px-xl-8q1 {
    padding-left: 8.25rem !important; }
  .p-xl-8q2 {
    padding: 8.5rem !important; }
  .pt-xl-8q2,
  .py-xl-8q2 {
    padding-top: 8.5rem !important; }
  .pr-xl-8q2,
  .px-xl-8q2 {
    padding-right: 8.5rem !important; }
  .pb-xl-8q2,
  .py-xl-8q2 {
    padding-bottom: 8.5rem !important; }
  .pl-xl-8q2,
  .px-xl-8q2 {
    padding-left: 8.5rem !important; }
  .p-xl-8q3 {
    padding: 8.75rem !important; }
  .pt-xl-8q3,
  .py-xl-8q3 {
    padding-top: 8.75rem !important; }
  .pr-xl-8q3,
  .px-xl-8q3 {
    padding-right: 8.75rem !important; }
  .pb-xl-8q3,
  .py-xl-8q3 {
    padding-bottom: 8.75rem !important; }
  .pl-xl-8q3,
  .px-xl-8q3 {
    padding-left: 8.75rem !important; }
  .p-xl-9 {
    padding: 9rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 9rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 9rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 9rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 9rem !important; }
  .p-xl-9q1 {
    padding: 9.25rem !important; }
  .pt-xl-9q1,
  .py-xl-9q1 {
    padding-top: 9.25rem !important; }
  .pr-xl-9q1,
  .px-xl-9q1 {
    padding-right: 9.25rem !important; }
  .pb-xl-9q1,
  .py-xl-9q1 {
    padding-bottom: 9.25rem !important; }
  .pl-xl-9q1,
  .px-xl-9q1 {
    padding-left: 9.25rem !important; }
  .p-xl-9q2 {
    padding: 9.5rem !important; }
  .pt-xl-9q2,
  .py-xl-9q2 {
    padding-top: 9.5rem !important; }
  .pr-xl-9q2,
  .px-xl-9q2 {
    padding-right: 9.5rem !important; }
  .pb-xl-9q2,
  .py-xl-9q2 {
    padding-bottom: 9.5rem !important; }
  .pl-xl-9q2,
  .px-xl-9q2 {
    padding-left: 9.5rem !important; }
  .p-xl-9q3 {
    padding: 9.75rem !important; }
  .pt-xl-9q3,
  .py-xl-9q3 {
    padding-top: 9.75rem !important; }
  .pr-xl-9q3,
  .px-xl-9q3 {
    padding-right: 9.75rem !important; }
  .pb-xl-9q3,
  .py-xl-9q3 {
    padding-bottom: 9.75rem !important; }
  .pl-xl-9q3,
  .px-xl-9q3 {
    padding-left: 9.75rem !important; }
  .p-xl-10 {
    padding: 10rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10rem !important; }
  .p-xl-10q1 {
    padding: 10.25rem !important; }
  .pt-xl-10q1,
  .py-xl-10q1 {
    padding-top: 10.25rem !important; }
  .pr-xl-10q1,
  .px-xl-10q1 {
    padding-right: 10.25rem !important; }
  .pb-xl-10q1,
  .py-xl-10q1 {
    padding-bottom: 10.25rem !important; }
  .pl-xl-10q1,
  .px-xl-10q1 {
    padding-left: 10.25rem !important; }
  .p-xl-10q2 {
    padding: 10.5rem !important; }
  .pt-xl-10q2,
  .py-xl-10q2 {
    padding-top: 10.5rem !important; }
  .pr-xl-10q2,
  .px-xl-10q2 {
    padding-right: 10.5rem !important; }
  .pb-xl-10q2,
  .py-xl-10q2 {
    padding-bottom: 10.5rem !important; }
  .pl-xl-10q2,
  .px-xl-10q2 {
    padding-left: 10.5rem !important; }
  .p-xl-10q3 {
    padding: 10.75rem !important; }
  .pt-xl-10q3,
  .py-xl-10q3 {
    padding-top: 10.75rem !important; }
  .pr-xl-10q3,
  .px-xl-10q3 {
    padding-right: 10.75rem !important; }
  .pb-xl-10q3,
  .py-xl-10q3 {
    padding-bottom: 10.75rem !important; }
  .pl-xl-10q3,
  .px-xl-10q3 {
    padding-left: 10.75rem !important; }
  .p-xl-11 {
    padding: 11rem !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 11rem !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 11rem !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 11rem !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 11rem !important; }
  .p-xl-11q1 {
    padding: 11.25rem !important; }
  .pt-xl-11q1,
  .py-xl-11q1 {
    padding-top: 11.25rem !important; }
  .pr-xl-11q1,
  .px-xl-11q1 {
    padding-right: 11.25rem !important; }
  .pb-xl-11q1,
  .py-xl-11q1 {
    padding-bottom: 11.25rem !important; }
  .pl-xl-11q1,
  .px-xl-11q1 {
    padding-left: 11.25rem !important; }
  .p-xl-11 q2 {
    padding: 11.5rem !important; }
  .pt-xl-11 q2,
  .py-xl-11 q2 {
    padding-top: 11.5rem !important; }
  .pr-xl-11 q2,
  .px-xl-11 q2 {
    padding-right: 11.5rem !important; }
  .pb-xl-11 q2,
  .py-xl-11 q2 {
    padding-bottom: 11.5rem !important; }
  .pl-xl-11 q2,
  .px-xl-11 q2 {
    padding-left: 11.5rem !important; }
  .p-xl-11 q3 {
    padding: 11.75rem !important; }
  .pt-xl-11 q3,
  .py-xl-11 q3 {
    padding-top: 11.75rem !important; }
  .pr-xl-11 q3,
  .px-xl-11 q3 {
    padding-right: 11.75rem !important; }
  .pb-xl-11 q3,
  .py-xl-11 q3 {
    padding-bottom: 11.75rem !important; }
  .pl-xl-11 q3,
  .px-xl-11 q3 {
    padding-left: 11.75rem !important; }
  .p-xl-12 {
    padding: 12rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 12rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 12rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 12rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 12rem !important; }
  .p-xl-12 q1 {
    padding: 12.25rem !important; }
  .pt-xl-12 q1,
  .py-xl-12 q1 {
    padding-top: 12.25rem !important; }
  .pr-xl-12 q1,
  .px-xl-12 q1 {
    padding-right: 12.25rem !important; }
  .pb-xl-12 q1,
  .py-xl-12 q1 {
    padding-bottom: 12.25rem !important; }
  .pl-xl-12 q1,
  .px-xl-12 q1 {
    padding-left: 12.25rem !important; }
  .p-xl-12 q2 {
    padding: 12.5rem !important; }
  .pt-xl-12 q2,
  .py-xl-12 q2 {
    padding-top: 12.5rem !important; }
  .pr-xl-12 q2,
  .px-xl-12 q2 {
    padding-right: 12.5rem !important; }
  .pb-xl-12 q2,
  .py-xl-12 q2 {
    padding-bottom: 12.5rem !important; }
  .pl-xl-12 q2,
  .px-xl-12 q2 {
    padding-left: 12.5rem !important; }
  .p-xl-12 q3 {
    padding: 12.75rem !important; }
  .pt-xl-12 q3,
  .py-xl-12 q3 {
    padding-top: 12.75rem !important; }
  .pr-xl-12 q3,
  .px-xl-12 q3 {
    padding-right: 12.75rem !important; }
  .pb-xl-12 q3,
  .py-xl-12 q3 {
    padding-bottom: 12.75rem !important; }
  .pl-xl-12 q3,
  .px-xl-12 q3 {
    padding-left: 12.75rem !important; }
  .m-xl-nq1 {
    margin: -0.25rem !important; }
  .mt-xl-nq1,
  .my-xl-nq1 {
    margin-top: -0.25rem !important; }
  .mr-xl-nq1,
  .mx-xl-nq1 {
    margin-right: -0.25rem !important; }
  .mb-xl-nq1,
  .my-xl-nq1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-nq1,
  .mx-xl-nq1 {
    margin-left: -0.25rem !important; }
  .m-xl-nq2 {
    margin: -0.5rem !important; }
  .mt-xl-nq2,
  .my-xl-nq2 {
    margin-top: -0.5rem !important; }
  .mr-xl-nq2,
  .mx-xl-nq2 {
    margin-right: -0.5rem !important; }
  .mb-xl-nq2,
  .my-xl-nq2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-nq2,
  .mx-xl-nq2 {
    margin-left: -0.5rem !important; }
  .m-xl-nq3 {
    margin: -0.75rem !important; }
  .mt-xl-nq3,
  .my-xl-nq3 {
    margin-top: -0.75rem !important; }
  .mr-xl-nq3,
  .mx-xl-nq3 {
    margin-right: -0.75rem !important; }
  .mb-xl-nq3,
  .my-xl-nq3 {
    margin-bottom: -0.75rem !important; }
  .ml-xl-nq3,
  .mx-xl-nq3 {
    margin-left: -0.75rem !important; }
  .m-xl-n1 {
    margin: -1rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -1rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -1rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -1rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -1rem !important; }
  .m-xl-n1q1 {
    margin: -1.25rem !important; }
  .mt-xl-n1q1,
  .my-xl-n1q1 {
    margin-top: -1.25rem !important; }
  .mr-xl-n1q1,
  .mx-xl-n1q1 {
    margin-right: -1.25rem !important; }
  .mb-xl-n1q1,
  .my-xl-n1q1 {
    margin-bottom: -1.25rem !important; }
  .ml-xl-n1q1,
  .mx-xl-n1q1 {
    margin-left: -1.25rem !important; }
  .m-xl-n1q2 {
    margin: -1.5rem !important; }
  .mt-xl-n1q2,
  .my-xl-n1q2 {
    margin-top: -1.5rem !important; }
  .mr-xl-n1q2,
  .mx-xl-n1q2 {
    margin-right: -1.5rem !important; }
  .mb-xl-n1q2,
  .my-xl-n1q2 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n1q2,
  .mx-xl-n1q2 {
    margin-left: -1.5rem !important; }
  .m-xl-n1q3 {
    margin: -1.75rem !important; }
  .mt-xl-n1q3,
  .my-xl-n1q3 {
    margin-top: -1.75rem !important; }
  .mr-xl-n1q3,
  .mx-xl-n1q3 {
    margin-right: -1.75rem !important; }
  .mb-xl-n1q3,
  .my-xl-n1q3 {
    margin-bottom: -1.75rem !important; }
  .ml-xl-n1q3,
  .mx-xl-n1q3 {
    margin-left: -1.75rem !important; }
  .m-xl-n2 {
    margin: -2rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -2rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -2rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -2rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -2rem !important; }
  .m-xl-n2q1 {
    margin: -2.25rem !important; }
  .mt-xl-n2q1,
  .my-xl-n2q1 {
    margin-top: -2.25rem !important; }
  .mr-xl-n2q1,
  .mx-xl-n2q1 {
    margin-right: -2.25rem !important; }
  .mb-xl-n2q1,
  .my-xl-n2q1 {
    margin-bottom: -2.25rem !important; }
  .ml-xl-n2q1,
  .mx-xl-n2q1 {
    margin-left: -2.25rem !important; }
  .m-xl-n2q2 {
    margin: -2.5rem !important; }
  .mt-xl-n2q2,
  .my-xl-n2q2 {
    margin-top: -2.5rem !important; }
  .mr-xl-n2q2,
  .mx-xl-n2q2 {
    margin-right: -2.5rem !important; }
  .mb-xl-n2q2,
  .my-xl-n2q2 {
    margin-bottom: -2.5rem !important; }
  .ml-xl-n2q2,
  .mx-xl-n2q2 {
    margin-left: -2.5rem !important; }
  .m-xl-n2q3 {
    margin: -2.75rem !important; }
  .mt-xl-n2q3,
  .my-xl-n2q3 {
    margin-top: -2.75rem !important; }
  .mr-xl-n2q3,
  .mx-xl-n2q3 {
    margin-right: -2.75rem !important; }
  .mb-xl-n2q3,
  .my-xl-n2q3 {
    margin-bottom: -2.75rem !important; }
  .ml-xl-n2q3,
  .mx-xl-n2q3 {
    margin-left: -2.75rem !important; }
  .m-xl-n3 {
    margin: -3rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -3rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -3rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -3rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -3rem !important; }
  .m-xl-n3q1 {
    margin: -3.25rem !important; }
  .mt-xl-n3q1,
  .my-xl-n3q1 {
    margin-top: -3.25rem !important; }
  .mr-xl-n3q1,
  .mx-xl-n3q1 {
    margin-right: -3.25rem !important; }
  .mb-xl-n3q1,
  .my-xl-n3q1 {
    margin-bottom: -3.25rem !important; }
  .ml-xl-n3q1,
  .mx-xl-n3q1 {
    margin-left: -3.25rem !important; }
  .m-xl-n3q2 {
    margin: -3.5rem !important; }
  .mt-xl-n3q2,
  .my-xl-n3q2 {
    margin-top: -3.5rem !important; }
  .mr-xl-n3q2,
  .mx-xl-n3q2 {
    margin-right: -3.5rem !important; }
  .mb-xl-n3q2,
  .my-xl-n3q2 {
    margin-bottom: -3.5rem !important; }
  .ml-xl-n3q2,
  .mx-xl-n3q2 {
    margin-left: -3.5rem !important; }
  .m-xl-n3q3 {
    margin: -3.75rem !important; }
  .mt-xl-n3q3,
  .my-xl-n3q3 {
    margin-top: -3.75rem !important; }
  .mr-xl-n3q3,
  .mx-xl-n3q3 {
    margin-right: -3.75rem !important; }
  .mb-xl-n3q3,
  .my-xl-n3q3 {
    margin-bottom: -3.75rem !important; }
  .ml-xl-n3q3,
  .mx-xl-n3q3 {
    margin-left: -3.75rem !important; }
  .m-xl-n4 {
    margin: -4rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -4rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -4rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -4rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -4rem !important; }
  .m-xl-n4q1 {
    margin: -4.25rem !important; }
  .mt-xl-n4q1,
  .my-xl-n4q1 {
    margin-top: -4.25rem !important; }
  .mr-xl-n4q1,
  .mx-xl-n4q1 {
    margin-right: -4.25rem !important; }
  .mb-xl-n4q1,
  .my-xl-n4q1 {
    margin-bottom: -4.25rem !important; }
  .ml-xl-n4q1,
  .mx-xl-n4q1 {
    margin-left: -4.25rem !important; }
  .m-xl-n4q2 {
    margin: -4.5rem !important; }
  .mt-xl-n4q2,
  .my-xl-n4q2 {
    margin-top: -4.5rem !important; }
  .mr-xl-n4q2,
  .mx-xl-n4q2 {
    margin-right: -4.5rem !important; }
  .mb-xl-n4q2,
  .my-xl-n4q2 {
    margin-bottom: -4.5rem !important; }
  .ml-xl-n4q2,
  .mx-xl-n4q2 {
    margin-left: -4.5rem !important; }
  .m-xl-n4q3 {
    margin: -4.75rem !important; }
  .mt-xl-n4q3,
  .my-xl-n4q3 {
    margin-top: -4.75rem !important; }
  .mr-xl-n4q3,
  .mx-xl-n4q3 {
    margin-right: -4.75rem !important; }
  .mb-xl-n4q3,
  .my-xl-n4q3 {
    margin-bottom: -4.75rem !important; }
  .ml-xl-n4q3,
  .mx-xl-n4q3 {
    margin-left: -4.75rem !important; }
  .m-xl-n5 {
    margin: -5rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -5rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -5rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -5rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -5rem !important; }
  .m-xl-n5q1 {
    margin: -5.25rem !important; }
  .mt-xl-n5q1,
  .my-xl-n5q1 {
    margin-top: -5.25rem !important; }
  .mr-xl-n5q1,
  .mx-xl-n5q1 {
    margin-right: -5.25rem !important; }
  .mb-xl-n5q1,
  .my-xl-n5q1 {
    margin-bottom: -5.25rem !important; }
  .ml-xl-n5q1,
  .mx-xl-n5q1 {
    margin-left: -5.25rem !important; }
  .m-xl-n5q2 {
    margin: -5.5rem !important; }
  .mt-xl-n5q2,
  .my-xl-n5q2 {
    margin-top: -5.5rem !important; }
  .mr-xl-n5q2,
  .mx-xl-n5q2 {
    margin-right: -5.5rem !important; }
  .mb-xl-n5q2,
  .my-xl-n5q2 {
    margin-bottom: -5.5rem !important; }
  .ml-xl-n5q2,
  .mx-xl-n5q2 {
    margin-left: -5.5rem !important; }
  .m-xl-n5q3 {
    margin: -5.75rem !important; }
  .mt-xl-n5q3,
  .my-xl-n5q3 {
    margin-top: -5.75rem !important; }
  .mr-xl-n5q3,
  .mx-xl-n5q3 {
    margin-right: -5.75rem !important; }
  .mb-xl-n5q3,
  .my-xl-n5q3 {
    margin-bottom: -5.75rem !important; }
  .ml-xl-n5q3,
  .mx-xl-n5q3 {
    margin-left: -5.75rem !important; }
  .m-xl-n6 {
    margin: -6rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -6rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -6rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -6rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -6rem !important; }
  .m-xl-n6q1 {
    margin: -6.25rem !important; }
  .mt-xl-n6q1,
  .my-xl-n6q1 {
    margin-top: -6.25rem !important; }
  .mr-xl-n6q1,
  .mx-xl-n6q1 {
    margin-right: -6.25rem !important; }
  .mb-xl-n6q1,
  .my-xl-n6q1 {
    margin-bottom: -6.25rem !important; }
  .ml-xl-n6q1,
  .mx-xl-n6q1 {
    margin-left: -6.25rem !important; }
  .m-xl-n6q2 {
    margin: -6.5rem !important; }
  .mt-xl-n6q2,
  .my-xl-n6q2 {
    margin-top: -6.5rem !important; }
  .mr-xl-n6q2,
  .mx-xl-n6q2 {
    margin-right: -6.5rem !important; }
  .mb-xl-n6q2,
  .my-xl-n6q2 {
    margin-bottom: -6.5rem !important; }
  .ml-xl-n6q2,
  .mx-xl-n6q2 {
    margin-left: -6.5rem !important; }
  .m-xl-n6q3 {
    margin: -6.75rem !important; }
  .mt-xl-n6q3,
  .my-xl-n6q3 {
    margin-top: -6.75rem !important; }
  .mr-xl-n6q3,
  .mx-xl-n6q3 {
    margin-right: -6.75rem !important; }
  .mb-xl-n6q3,
  .my-xl-n6q3 {
    margin-bottom: -6.75rem !important; }
  .ml-xl-n6q3,
  .mx-xl-n6q3 {
    margin-left: -6.75rem !important; }
  .m-xl-n7 {
    margin: -7rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -7rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -7rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -7rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -7rem !important; }
  .m-xl-n7q1 {
    margin: -7.25rem !important; }
  .mt-xl-n7q1,
  .my-xl-n7q1 {
    margin-top: -7.25rem !important; }
  .mr-xl-n7q1,
  .mx-xl-n7q1 {
    margin-right: -7.25rem !important; }
  .mb-xl-n7q1,
  .my-xl-n7q1 {
    margin-bottom: -7.25rem !important; }
  .ml-xl-n7q1,
  .mx-xl-n7q1 {
    margin-left: -7.25rem !important; }
  .m-xl-n7q2 {
    margin: -7.5rem !important; }
  .mt-xl-n7q2,
  .my-xl-n7q2 {
    margin-top: -7.5rem !important; }
  .mr-xl-n7q2,
  .mx-xl-n7q2 {
    margin-right: -7.5rem !important; }
  .mb-xl-n7q2,
  .my-xl-n7q2 {
    margin-bottom: -7.5rem !important; }
  .ml-xl-n7q2,
  .mx-xl-n7q2 {
    margin-left: -7.5rem !important; }
  .m-xl-n7q3 {
    margin: -7.75rem !important; }
  .mt-xl-n7q3,
  .my-xl-n7q3 {
    margin-top: -7.75rem !important; }
  .mr-xl-n7q3,
  .mx-xl-n7q3 {
    margin-right: -7.75rem !important; }
  .mb-xl-n7q3,
  .my-xl-n7q3 {
    margin-bottom: -7.75rem !important; }
  .ml-xl-n7q3,
  .mx-xl-n7q3 {
    margin-left: -7.75rem !important; }
  .m-xl-n8 {
    margin: -8rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -8rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -8rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -8rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -8rem !important; }
  .m-xl-n8q1 {
    margin: -8.25rem !important; }
  .mt-xl-n8q1,
  .my-xl-n8q1 {
    margin-top: -8.25rem !important; }
  .mr-xl-n8q1,
  .mx-xl-n8q1 {
    margin-right: -8.25rem !important; }
  .mb-xl-n8q1,
  .my-xl-n8q1 {
    margin-bottom: -8.25rem !important; }
  .ml-xl-n8q1,
  .mx-xl-n8q1 {
    margin-left: -8.25rem !important; }
  .m-xl-n8q2 {
    margin: -8.5rem !important; }
  .mt-xl-n8q2,
  .my-xl-n8q2 {
    margin-top: -8.5rem !important; }
  .mr-xl-n8q2,
  .mx-xl-n8q2 {
    margin-right: -8.5rem !important; }
  .mb-xl-n8q2,
  .my-xl-n8q2 {
    margin-bottom: -8.5rem !important; }
  .ml-xl-n8q2,
  .mx-xl-n8q2 {
    margin-left: -8.5rem !important; }
  .m-xl-n8q3 {
    margin: -8.75rem !important; }
  .mt-xl-n8q3,
  .my-xl-n8q3 {
    margin-top: -8.75rem !important; }
  .mr-xl-n8q3,
  .mx-xl-n8q3 {
    margin-right: -8.75rem !important; }
  .mb-xl-n8q3,
  .my-xl-n8q3 {
    margin-bottom: -8.75rem !important; }
  .ml-xl-n8q3,
  .mx-xl-n8q3 {
    margin-left: -8.75rem !important; }
  .m-xl-n9 {
    margin: -9rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -9rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -9rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -9rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -9rem !important; }
  .m-xl-n9q1 {
    margin: -9.25rem !important; }
  .mt-xl-n9q1,
  .my-xl-n9q1 {
    margin-top: -9.25rem !important; }
  .mr-xl-n9q1,
  .mx-xl-n9q1 {
    margin-right: -9.25rem !important; }
  .mb-xl-n9q1,
  .my-xl-n9q1 {
    margin-bottom: -9.25rem !important; }
  .ml-xl-n9q1,
  .mx-xl-n9q1 {
    margin-left: -9.25rem !important; }
  .m-xl-n9q2 {
    margin: -9.5rem !important; }
  .mt-xl-n9q2,
  .my-xl-n9q2 {
    margin-top: -9.5rem !important; }
  .mr-xl-n9q2,
  .mx-xl-n9q2 {
    margin-right: -9.5rem !important; }
  .mb-xl-n9q2,
  .my-xl-n9q2 {
    margin-bottom: -9.5rem !important; }
  .ml-xl-n9q2,
  .mx-xl-n9q2 {
    margin-left: -9.5rem !important; }
  .m-xl-n9q3 {
    margin: -9.75rem !important; }
  .mt-xl-n9q3,
  .my-xl-n9q3 {
    margin-top: -9.75rem !important; }
  .mr-xl-n9q3,
  .mx-xl-n9q3 {
    margin-right: -9.75rem !important; }
  .mb-xl-n9q3,
  .my-xl-n9q3 {
    margin-bottom: -9.75rem !important; }
  .ml-xl-n9q3,
  .mx-xl-n9q3 {
    margin-left: -9.75rem !important; }
  .m-xl-n10 {
    margin: -10rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -10rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -10rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -10rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -10rem !important; }
  .m-xl-n10q1 {
    margin: -10.25rem !important; }
  .mt-xl-n10q1,
  .my-xl-n10q1 {
    margin-top: -10.25rem !important; }
  .mr-xl-n10q1,
  .mx-xl-n10q1 {
    margin-right: -10.25rem !important; }
  .mb-xl-n10q1,
  .my-xl-n10q1 {
    margin-bottom: -10.25rem !important; }
  .ml-xl-n10q1,
  .mx-xl-n10q1 {
    margin-left: -10.25rem !important; }
  .m-xl-n10q2 {
    margin: -10.5rem !important; }
  .mt-xl-n10q2,
  .my-xl-n10q2 {
    margin-top: -10.5rem !important; }
  .mr-xl-n10q2,
  .mx-xl-n10q2 {
    margin-right: -10.5rem !important; }
  .mb-xl-n10q2,
  .my-xl-n10q2 {
    margin-bottom: -10.5rem !important; }
  .ml-xl-n10q2,
  .mx-xl-n10q2 {
    margin-left: -10.5rem !important; }
  .m-xl-n10q3 {
    margin: -10.75rem !important; }
  .mt-xl-n10q3,
  .my-xl-n10q3 {
    margin-top: -10.75rem !important; }
  .mr-xl-n10q3,
  .mx-xl-n10q3 {
    margin-right: -10.75rem !important; }
  .mb-xl-n10q3,
  .my-xl-n10q3 {
    margin-bottom: -10.75rem !important; }
  .ml-xl-n10q3,
  .mx-xl-n10q3 {
    margin-left: -10.75rem !important; }
  .m-xl-n11 {
    margin: -11rem !important; }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -11rem !important; }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -11rem !important; }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -11rem !important; }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -11rem !important; }
  .m-xl-n11q1 {
    margin: -11.25rem !important; }
  .mt-xl-n11q1,
  .my-xl-n11q1 {
    margin-top: -11.25rem !important; }
  .mr-xl-n11q1,
  .mx-xl-n11q1 {
    margin-right: -11.25rem !important; }
  .mb-xl-n11q1,
  .my-xl-n11q1 {
    margin-bottom: -11.25rem !important; }
  .ml-xl-n11q1,
  .mx-xl-n11q1 {
    margin-left: -11.25rem !important; }
  .m-xl-n11 q2 {
    margin: -11.5rem !important; }
  .mt-xl-n11 q2,
  .my-xl-n11 q2 {
    margin-top: -11.5rem !important; }
  .mr-xl-n11 q2,
  .mx-xl-n11 q2 {
    margin-right: -11.5rem !important; }
  .mb-xl-n11 q2,
  .my-xl-n11 q2 {
    margin-bottom: -11.5rem !important; }
  .ml-xl-n11 q2,
  .mx-xl-n11 q2 {
    margin-left: -11.5rem !important; }
  .m-xl-n11 q3 {
    margin: -11.75rem !important; }
  .mt-xl-n11 q3,
  .my-xl-n11 q3 {
    margin-top: -11.75rem !important; }
  .mr-xl-n11 q3,
  .mx-xl-n11 q3 {
    margin-right: -11.75rem !important; }
  .mb-xl-n11 q3,
  .my-xl-n11 q3 {
    margin-bottom: -11.75rem !important; }
  .ml-xl-n11 q3,
  .mx-xl-n11 q3 {
    margin-left: -11.75rem !important; }
  .m-xl-n12 {
    margin: -12rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -12rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -12rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -12rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -12rem !important; }
  .m-xl-n12 q1 {
    margin: -12.25rem !important; }
  .mt-xl-n12 q1,
  .my-xl-n12 q1 {
    margin-top: -12.25rem !important; }
  .mr-xl-n12 q1,
  .mx-xl-n12 q1 {
    margin-right: -12.25rem !important; }
  .mb-xl-n12 q1,
  .my-xl-n12 q1 {
    margin-bottom: -12.25rem !important; }
  .ml-xl-n12 q1,
  .mx-xl-n12 q1 {
    margin-left: -12.25rem !important; }
  .m-xl-n12 q2 {
    margin: -12.5rem !important; }
  .mt-xl-n12 q2,
  .my-xl-n12 q2 {
    margin-top: -12.5rem !important; }
  .mr-xl-n12 q2,
  .mx-xl-n12 q2 {
    margin-right: -12.5rem !important; }
  .mb-xl-n12 q2,
  .my-xl-n12 q2 {
    margin-bottom: -12.5rem !important; }
  .ml-xl-n12 q2,
  .mx-xl-n12 q2 {
    margin-left: -12.5rem !important; }
  .m-xl-n12 q3 {
    margin: -12.75rem !important; }
  .mt-xl-n12 q3,
  .my-xl-n12 q3 {
    margin-top: -12.75rem !important; }
  .mr-xl-n12 q3,
  .mx-xl-n12 q3 {
    margin-right: -12.75rem !important; }
  .mb-xl-n12 q3,
  .my-xl-n12 q3 {
    margin-bottom: -12.75rem !important; }
  .ml-xl-n12 q3,
  .mx-xl-n12 q3 {
    margin-left: -12.75rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 767px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 767px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 767px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.row.gutter-0 {
  margin-left: 0;
  margin-right: 0; }

.row.gutter-0 > .col,
.row.gutter-0 > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.row.gutter-1 {
  margin-left: -5px;
  margin-right: -5px; }

.row.gutter-1 > .col,
.row.gutter-1 > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px; }

.row.gutter-2 {
  margin-left: -30px;
  margin-right: -30px; }

.row.gutter-2 > .col,
.row.gutter-2 > [class*="col-"] {
  padding-right: 30px;
  padding-left: 30px; }

.row.gutter-3 {
  margin-left: -40px;
  margin-right: -40px; }

.row.gutter-3 > .col,
.row.gutter-3 > [class*="col-"] {
  padding-right: 40px;
  padding-left: 40px; }

button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0; }

h1, .h1 {
  font-weight: 800;
  font-size: clamp(36px, 7vw, 70px);
  line-height: 130%;
  font-family: 'Exo 2', sans-serif; }

h2, .h2 {
  font-size: clamp(36px, 4vw, 50px);
  line-height: 150%;
  font-weight: 800;
  font-family: 'Exo 2', sans-serif; }

h3, .h3 {
  font-size: clamp(18px, 4vw, 22px);
  line-height: 125%;
  font-weight: 800;
  font-family: 'Exo 2', sans-serif; }

p, .text {
  font-size: clamp(14px, 3vw, 16px);
  line-height: 130%;
  font-style: normal;
  font-weight: 400; }

@keyframes shake {
  0% {
    transform: translateX(0); }
  25% {
    transform: translateX(-2px); }
  75% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.5); }
  to {
    opacity: 1;
    transform: scale(1); } }

@keyframes appear-lt {
  from {
    opacity: 0;
    transform: translateX(-100px) translateY(-100px); }
  to {
    opacity: 1;
    transform: translateX(0) translateY(0); } }

@keyframes appear-rt {
  from {
    opacity: 0;
    transform: translateX(100px) translateY(-100px); }
  to {
    opacity: 1;
    transform: translateX(0) translateY(0); } }

@keyframes appear-lb {
  from {
    opacity: 0;
    transform: translateX(-100px) translateY(100px); }
  to {
    opacity: 1;
    transform: translateX(0) translateY(0); } }

@keyframes appear-rb {
  from {
    opacity: 0;
    transform: translateX(100px) translateY(100px); }
  to {
    opacity: 1;
    transform: translateX(0) translateY(0); } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 767px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 767px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 767px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-q1 {
  margin: 0.25rem !important; }

.mt-q1,
.my-q1 {
  margin-top: 0.25rem !important; }

.mr-q1,
.mx-q1 {
  margin-right: 0.25rem !important; }

.mb-q1,
.my-q1 {
  margin-bottom: 0.25rem !important; }

.ml-q1,
.mx-q1 {
  margin-left: 0.25rem !important; }

.m-q2 {
  margin: 0.5rem !important; }

.mt-q2,
.my-q2 {
  margin-top: 0.5rem !important; }

.mr-q2,
.mx-q2 {
  margin-right: 0.5rem !important; }

.mb-q2,
.my-q2 {
  margin-bottom: 0.5rem !important; }

.ml-q2,
.mx-q2 {
  margin-left: 0.5rem !important; }

.m-q3 {
  margin: 0.75rem !important; }

.mt-q3,
.my-q3 {
  margin-top: 0.75rem !important; }

.mr-q3,
.mx-q3 {
  margin-right: 0.75rem !important; }

.mb-q3,
.my-q3 {
  margin-bottom: 0.75rem !important; }

.ml-q3,
.mx-q3 {
  margin-left: 0.75rem !important; }

.m-0 {
  margin: 0rem !important; }

.mt-0,
.my-0 {
  margin-top: 0rem !important; }

.mr-0,
.mx-0 {
  margin-right: 0rem !important; }

.mb-0,
.my-0 {
  margin-bottom: 0rem !important; }

.ml-0,
.mx-0 {
  margin-left: 0rem !important; }

.m-1 {
  margin: 1rem !important; }

.mt-1,
.my-1 {
  margin-top: 1rem !important; }

.mr-1,
.mx-1 {
  margin-right: 1rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 1rem !important; }

.ml-1,
.mx-1 {
  margin-left: 1rem !important; }

.m-1q1 {
  margin: 1.25rem !important; }

.mt-1q1,
.my-1q1 {
  margin-top: 1.25rem !important; }

.mr-1q1,
.mx-1q1 {
  margin-right: 1.25rem !important; }

.mb-1q1,
.my-1q1 {
  margin-bottom: 1.25rem !important; }

.ml-1q1,
.mx-1q1 {
  margin-left: 1.25rem !important; }

.m-1q2 {
  margin: 1.5rem !important; }

.mt-1q2,
.my-1q2 {
  margin-top: 1.5rem !important; }

.mr-1q2,
.mx-1q2 {
  margin-right: 1.5rem !important; }

.mb-1q2,
.my-1q2 {
  margin-bottom: 1.5rem !important; }

.ml-1q2,
.mx-1q2 {
  margin-left: 1.5rem !important; }

.m-1q3 {
  margin: 1.75rem !important; }

.mt-1q3,
.my-1q3 {
  margin-top: 1.75rem !important; }

.mr-1q3,
.mx-1q3 {
  margin-right: 1.75rem !important; }

.mb-1q3,
.my-1q3 {
  margin-bottom: 1.75rem !important; }

.ml-1q3,
.mx-1q3 {
  margin-left: 1.75rem !important; }

.m-2 {
  margin: 2rem !important; }

.mt-2,
.my-2 {
  margin-top: 2rem !important; }

.mr-2,
.mx-2 {
  margin-right: 2rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 2rem !important; }

.ml-2,
.mx-2 {
  margin-left: 2rem !important; }

.m-2q1 {
  margin: 2.25rem !important; }

.mt-2q1,
.my-2q1 {
  margin-top: 2.25rem !important; }

.mr-2q1,
.mx-2q1 {
  margin-right: 2.25rem !important; }

.mb-2q1,
.my-2q1 {
  margin-bottom: 2.25rem !important; }

.ml-2q1,
.mx-2q1 {
  margin-left: 2.25rem !important; }

.m-2q2 {
  margin: 2.5rem !important; }

.mt-2q2,
.my-2q2 {
  margin-top: 2.5rem !important; }

.mr-2q2,
.mx-2q2 {
  margin-right: 2.5rem !important; }

.mb-2q2,
.my-2q2 {
  margin-bottom: 2.5rem !important; }

.ml-2q2,
.mx-2q2 {
  margin-left: 2.5rem !important; }

.m-2q3 {
  margin: 2.75rem !important; }

.mt-2q3,
.my-2q3 {
  margin-top: 2.75rem !important; }

.mr-2q3,
.mx-2q3 {
  margin-right: 2.75rem !important; }

.mb-2q3,
.my-2q3 {
  margin-bottom: 2.75rem !important; }

.ml-2q3,
.mx-2q3 {
  margin-left: 2.75rem !important; }

.m-3 {
  margin: 3rem !important; }

.mt-3,
.my-3 {
  margin-top: 3rem !important; }

.mr-3,
.mx-3 {
  margin-right: 3rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 3rem !important; }

.ml-3,
.mx-3 {
  margin-left: 3rem !important; }

.m-3q1 {
  margin: 3.25rem !important; }

.mt-3q1,
.my-3q1 {
  margin-top: 3.25rem !important; }

.mr-3q1,
.mx-3q1 {
  margin-right: 3.25rem !important; }

.mb-3q1,
.my-3q1 {
  margin-bottom: 3.25rem !important; }

.ml-3q1,
.mx-3q1 {
  margin-left: 3.25rem !important; }

.m-3q2 {
  margin: 3.5rem !important; }

.mt-3q2,
.my-3q2 {
  margin-top: 3.5rem !important; }

.mr-3q2,
.mx-3q2 {
  margin-right: 3.5rem !important; }

.mb-3q2,
.my-3q2 {
  margin-bottom: 3.5rem !important; }

.ml-3q2,
.mx-3q2 {
  margin-left: 3.5rem !important; }

.m-3q3 {
  margin: 3.75rem !important; }

.mt-3q3,
.my-3q3 {
  margin-top: 3.75rem !important; }

.mr-3q3,
.mx-3q3 {
  margin-right: 3.75rem !important; }

.mb-3q3,
.my-3q3 {
  margin-bottom: 3.75rem !important; }

.ml-3q3,
.mx-3q3 {
  margin-left: 3.75rem !important; }

.m-4 {
  margin: 4rem !important; }

.mt-4,
.my-4 {
  margin-top: 4rem !important; }

.mr-4,
.mx-4 {
  margin-right: 4rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 4rem !important; }

.ml-4,
.mx-4 {
  margin-left: 4rem !important; }

.m-4q1 {
  margin: 4.25rem !important; }

.mt-4q1,
.my-4q1 {
  margin-top: 4.25rem !important; }

.mr-4q1,
.mx-4q1 {
  margin-right: 4.25rem !important; }

.mb-4q1,
.my-4q1 {
  margin-bottom: 4.25rem !important; }

.ml-4q1,
.mx-4q1 {
  margin-left: 4.25rem !important; }

.m-4q2 {
  margin: 4.5rem !important; }

.mt-4q2,
.my-4q2 {
  margin-top: 4.5rem !important; }

.mr-4q2,
.mx-4q2 {
  margin-right: 4.5rem !important; }

.mb-4q2,
.my-4q2 {
  margin-bottom: 4.5rem !important; }

.ml-4q2,
.mx-4q2 {
  margin-left: 4.5rem !important; }

.m-4q3 {
  margin: 4.75rem !important; }

.mt-4q3,
.my-4q3 {
  margin-top: 4.75rem !important; }

.mr-4q3,
.mx-4q3 {
  margin-right: 4.75rem !important; }

.mb-4q3,
.my-4q3 {
  margin-bottom: 4.75rem !important; }

.ml-4q3,
.mx-4q3 {
  margin-left: 4.75rem !important; }

.m-5 {
  margin: 5rem !important; }

.mt-5,
.my-5 {
  margin-top: 5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 5rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 5rem !important; }

.m-5q1 {
  margin: 5.25rem !important; }

.mt-5q1,
.my-5q1 {
  margin-top: 5.25rem !important; }

.mr-5q1,
.mx-5q1 {
  margin-right: 5.25rem !important; }

.mb-5q1,
.my-5q1 {
  margin-bottom: 5.25rem !important; }

.ml-5q1,
.mx-5q1 {
  margin-left: 5.25rem !important; }

.m-5q2 {
  margin: 5.5rem !important; }

.mt-5q2,
.my-5q2 {
  margin-top: 5.5rem !important; }

.mr-5q2,
.mx-5q2 {
  margin-right: 5.5rem !important; }

.mb-5q2,
.my-5q2 {
  margin-bottom: 5.5rem !important; }

.ml-5q2,
.mx-5q2 {
  margin-left: 5.5rem !important; }

.m-5q3 {
  margin: 5.75rem !important; }

.mt-5q3,
.my-5q3 {
  margin-top: 5.75rem !important; }

.mr-5q3,
.mx-5q3 {
  margin-right: 5.75rem !important; }

.mb-5q3,
.my-5q3 {
  margin-bottom: 5.75rem !important; }

.ml-5q3,
.mx-5q3 {
  margin-left: 5.75rem !important; }

.m-6 {
  margin: 6rem !important; }

.mt-6,
.my-6 {
  margin-top: 6rem !important; }

.mr-6,
.mx-6 {
  margin-right: 6rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 6rem !important; }

.ml-6,
.mx-6 {
  margin-left: 6rem !important; }

.m-6q1 {
  margin: 6.25rem !important; }

.mt-6q1,
.my-6q1 {
  margin-top: 6.25rem !important; }

.mr-6q1,
.mx-6q1 {
  margin-right: 6.25rem !important; }

.mb-6q1,
.my-6q1 {
  margin-bottom: 6.25rem !important; }

.ml-6q1,
.mx-6q1 {
  margin-left: 6.25rem !important; }

.m-6q2 {
  margin: 6.5rem !important; }

.mt-6q2,
.my-6q2 {
  margin-top: 6.5rem !important; }

.mr-6q2,
.mx-6q2 {
  margin-right: 6.5rem !important; }

.mb-6q2,
.my-6q2 {
  margin-bottom: 6.5rem !important; }

.ml-6q2,
.mx-6q2 {
  margin-left: 6.5rem !important; }

.m-6q3 {
  margin: 6.75rem !important; }

.mt-6q3,
.my-6q3 {
  margin-top: 6.75rem !important; }

.mr-6q3,
.mx-6q3 {
  margin-right: 6.75rem !important; }

.mb-6q3,
.my-6q3 {
  margin-bottom: 6.75rem !important; }

.ml-6q3,
.mx-6q3 {
  margin-left: 6.75rem !important; }

.m-7 {
  margin: 7rem !important; }

.mt-7,
.my-7 {
  margin-top: 7rem !important; }

.mr-7,
.mx-7 {
  margin-right: 7rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 7rem !important; }

.ml-7,
.mx-7 {
  margin-left: 7rem !important; }

.m-7q1 {
  margin: 7.25rem !important; }

.mt-7q1,
.my-7q1 {
  margin-top: 7.25rem !important; }

.mr-7q1,
.mx-7q1 {
  margin-right: 7.25rem !important; }

.mb-7q1,
.my-7q1 {
  margin-bottom: 7.25rem !important; }

.ml-7q1,
.mx-7q1 {
  margin-left: 7.25rem !important; }

.m-7q2 {
  margin: 7.5rem !important; }

.mt-7q2,
.my-7q2 {
  margin-top: 7.5rem !important; }

.mr-7q2,
.mx-7q2 {
  margin-right: 7.5rem !important; }

.mb-7q2,
.my-7q2 {
  margin-bottom: 7.5rem !important; }

.ml-7q2,
.mx-7q2 {
  margin-left: 7.5rem !important; }

.m-7q3 {
  margin: 7.75rem !important; }

.mt-7q3,
.my-7q3 {
  margin-top: 7.75rem !important; }

.mr-7q3,
.mx-7q3 {
  margin-right: 7.75rem !important; }

.mb-7q3,
.my-7q3 {
  margin-bottom: 7.75rem !important; }

.ml-7q3,
.mx-7q3 {
  margin-left: 7.75rem !important; }

.m-8 {
  margin: 8rem !important; }

.mt-8,
.my-8 {
  margin-top: 8rem !important; }

.mr-8,
.mx-8 {
  margin-right: 8rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 8rem !important; }

.ml-8,
.mx-8 {
  margin-left: 8rem !important; }

.m-8q1 {
  margin: 8.25rem !important; }

.mt-8q1,
.my-8q1 {
  margin-top: 8.25rem !important; }

.mr-8q1,
.mx-8q1 {
  margin-right: 8.25rem !important; }

.mb-8q1,
.my-8q1 {
  margin-bottom: 8.25rem !important; }

.ml-8q1,
.mx-8q1 {
  margin-left: 8.25rem !important; }

.m-8q2 {
  margin: 8.5rem !important; }

.mt-8q2,
.my-8q2 {
  margin-top: 8.5rem !important; }

.mr-8q2,
.mx-8q2 {
  margin-right: 8.5rem !important; }

.mb-8q2,
.my-8q2 {
  margin-bottom: 8.5rem !important; }

.ml-8q2,
.mx-8q2 {
  margin-left: 8.5rem !important; }

.m-8q3 {
  margin: 8.75rem !important; }

.mt-8q3,
.my-8q3 {
  margin-top: 8.75rem !important; }

.mr-8q3,
.mx-8q3 {
  margin-right: 8.75rem !important; }

.mb-8q3,
.my-8q3 {
  margin-bottom: 8.75rem !important; }

.ml-8q3,
.mx-8q3 {
  margin-left: 8.75rem !important; }

.m-9 {
  margin: 9rem !important; }

.mt-9,
.my-9 {
  margin-top: 9rem !important; }

.mr-9,
.mx-9 {
  margin-right: 9rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 9rem !important; }

.ml-9,
.mx-9 {
  margin-left: 9rem !important; }

.m-9q1 {
  margin: 9.25rem !important; }

.mt-9q1,
.my-9q1 {
  margin-top: 9.25rem !important; }

.mr-9q1,
.mx-9q1 {
  margin-right: 9.25rem !important; }

.mb-9q1,
.my-9q1 {
  margin-bottom: 9.25rem !important; }

.ml-9q1,
.mx-9q1 {
  margin-left: 9.25rem !important; }

.m-9q2 {
  margin: 9.5rem !important; }

.mt-9q2,
.my-9q2 {
  margin-top: 9.5rem !important; }

.mr-9q2,
.mx-9q2 {
  margin-right: 9.5rem !important; }

.mb-9q2,
.my-9q2 {
  margin-bottom: 9.5rem !important; }

.ml-9q2,
.mx-9q2 {
  margin-left: 9.5rem !important; }

.m-9q3 {
  margin: 9.75rem !important; }

.mt-9q3,
.my-9q3 {
  margin-top: 9.75rem !important; }

.mr-9q3,
.mx-9q3 {
  margin-right: 9.75rem !important; }

.mb-9q3,
.my-9q3 {
  margin-bottom: 9.75rem !important; }

.ml-9q3,
.mx-9q3 {
  margin-left: 9.75rem !important; }

.m-10 {
  margin: 10rem !important; }

.mt-10,
.my-10 {
  margin-top: 10rem !important; }

.mr-10,
.mx-10 {
  margin-right: 10rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 10rem !important; }

.ml-10,
.mx-10 {
  margin-left: 10rem !important; }

.m-10q1 {
  margin: 10.25rem !important; }

.mt-10q1,
.my-10q1 {
  margin-top: 10.25rem !important; }

.mr-10q1,
.mx-10q1 {
  margin-right: 10.25rem !important; }

.mb-10q1,
.my-10q1 {
  margin-bottom: 10.25rem !important; }

.ml-10q1,
.mx-10q1 {
  margin-left: 10.25rem !important; }

.m-10q2 {
  margin: 10.5rem !important; }

.mt-10q2,
.my-10q2 {
  margin-top: 10.5rem !important; }

.mr-10q2,
.mx-10q2 {
  margin-right: 10.5rem !important; }

.mb-10q2,
.my-10q2 {
  margin-bottom: 10.5rem !important; }

.ml-10q2,
.mx-10q2 {
  margin-left: 10.5rem !important; }

.m-10q3 {
  margin: 10.75rem !important; }

.mt-10q3,
.my-10q3 {
  margin-top: 10.75rem !important; }

.mr-10q3,
.mx-10q3 {
  margin-right: 10.75rem !important; }

.mb-10q3,
.my-10q3 {
  margin-bottom: 10.75rem !important; }

.ml-10q3,
.mx-10q3 {
  margin-left: 10.75rem !important; }

.m-11 {
  margin: 11rem !important; }

.mt-11,
.my-11 {
  margin-top: 11rem !important; }

.mr-11,
.mx-11 {
  margin-right: 11rem !important; }

.mb-11,
.my-11 {
  margin-bottom: 11rem !important; }

.ml-11,
.mx-11 {
  margin-left: 11rem !important; }

.m-11q1 {
  margin: 11.25rem !important; }

.mt-11q1,
.my-11q1 {
  margin-top: 11.25rem !important; }

.mr-11q1,
.mx-11q1 {
  margin-right: 11.25rem !important; }

.mb-11q1,
.my-11q1 {
  margin-bottom: 11.25rem !important; }

.ml-11q1,
.mx-11q1 {
  margin-left: 11.25rem !important; }

.m-11 q2 {
  margin: 11.5rem !important; }

.mt-11 q2,
.my-11 q2 {
  margin-top: 11.5rem !important; }

.mr-11 q2,
.mx-11 q2 {
  margin-right: 11.5rem !important; }

.mb-11 q2,
.my-11 q2 {
  margin-bottom: 11.5rem !important; }

.ml-11 q2,
.mx-11 q2 {
  margin-left: 11.5rem !important; }

.m-11 q3 {
  margin: 11.75rem !important; }

.mt-11 q3,
.my-11 q3 {
  margin-top: 11.75rem !important; }

.mr-11 q3,
.mx-11 q3 {
  margin-right: 11.75rem !important; }

.mb-11 q3,
.my-11 q3 {
  margin-bottom: 11.75rem !important; }

.ml-11 q3,
.mx-11 q3 {
  margin-left: 11.75rem !important; }

.m-12 {
  margin: 12rem !important; }

.mt-12,
.my-12 {
  margin-top: 12rem !important; }

.mr-12,
.mx-12 {
  margin-right: 12rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 12rem !important; }

.ml-12,
.mx-12 {
  margin-left: 12rem !important; }

.m-12 q1 {
  margin: 12.25rem !important; }

.mt-12 q1,
.my-12 q1 {
  margin-top: 12.25rem !important; }

.mr-12 q1,
.mx-12 q1 {
  margin-right: 12.25rem !important; }

.mb-12 q1,
.my-12 q1 {
  margin-bottom: 12.25rem !important; }

.ml-12 q1,
.mx-12 q1 {
  margin-left: 12.25rem !important; }

.m-12 q2 {
  margin: 12.5rem !important; }

.mt-12 q2,
.my-12 q2 {
  margin-top: 12.5rem !important; }

.mr-12 q2,
.mx-12 q2 {
  margin-right: 12.5rem !important; }

.mb-12 q2,
.my-12 q2 {
  margin-bottom: 12.5rem !important; }

.ml-12 q2,
.mx-12 q2 {
  margin-left: 12.5rem !important; }

.m-12 q3 {
  margin: 12.75rem !important; }

.mt-12 q3,
.my-12 q3 {
  margin-top: 12.75rem !important; }

.mr-12 q3,
.mx-12 q3 {
  margin-right: 12.75rem !important; }

.mb-12 q3,
.my-12 q3 {
  margin-bottom: 12.75rem !important; }

.ml-12 q3,
.mx-12 q3 {
  margin-left: 12.75rem !important; }

.p-q1 {
  padding: 0.25rem !important; }

.pt-q1,
.py-q1 {
  padding-top: 0.25rem !important; }

.pr-q1,
.px-q1 {
  padding-right: 0.25rem !important; }

.pb-q1,
.py-q1 {
  padding-bottom: 0.25rem !important; }

.pl-q1,
.px-q1 {
  padding-left: 0.25rem !important; }

.p-q2 {
  padding: 0.5rem !important; }

.pt-q2,
.py-q2 {
  padding-top: 0.5rem !important; }

.pr-q2,
.px-q2 {
  padding-right: 0.5rem !important; }

.pb-q2,
.py-q2 {
  padding-bottom: 0.5rem !important; }

.pl-q2,
.px-q2 {
  padding-left: 0.5rem !important; }

.p-q3 {
  padding: 0.75rem !important; }

.pt-q3,
.py-q3 {
  padding-top: 0.75rem !important; }

.pr-q3,
.px-q3 {
  padding-right: 0.75rem !important; }

.pb-q3,
.py-q3 {
  padding-bottom: 0.75rem !important; }

.pl-q3,
.px-q3 {
  padding-left: 0.75rem !important; }

.p-0 {
  padding: 0rem !important; }

.pt-0,
.py-0 {
  padding-top: 0rem !important; }

.pr-0,
.px-0 {
  padding-right: 0rem !important; }

.pb-0,
.py-0 {
  padding-bottom: 0rem !important; }

.pl-0,
.px-0 {
  padding-left: 0rem !important; }

.p-1 {
  padding: 1rem !important; }

.pt-1,
.py-1 {
  padding-top: 1rem !important; }

.pr-1,
.px-1 {
  padding-right: 1rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 1rem !important; }

.pl-1,
.px-1 {
  padding-left: 1rem !important; }

.p-1q1 {
  padding: 1.25rem !important; }

.pt-1q1,
.py-1q1 {
  padding-top: 1.25rem !important; }

.pr-1q1,
.px-1q1 {
  padding-right: 1.25rem !important; }

.pb-1q1,
.py-1q1 {
  padding-bottom: 1.25rem !important; }

.pl-1q1,
.px-1q1 {
  padding-left: 1.25rem !important; }

.p-1q2 {
  padding: 1.5rem !important; }

.pt-1q2,
.py-1q2 {
  padding-top: 1.5rem !important; }

.pr-1q2,
.px-1q2 {
  padding-right: 1.5rem !important; }

.pb-1q2,
.py-1q2 {
  padding-bottom: 1.5rem !important; }

.pl-1q2,
.px-1q2 {
  padding-left: 1.5rem !important; }

.p-1q3 {
  padding: 1.75rem !important; }

.pt-1q3,
.py-1q3 {
  padding-top: 1.75rem !important; }

.pr-1q3,
.px-1q3 {
  padding-right: 1.75rem !important; }

.pb-1q3,
.py-1q3 {
  padding-bottom: 1.75rem !important; }

.pl-1q3,
.px-1q3 {
  padding-left: 1.75rem !important; }

.p-2 {
  padding: 2rem !important; }

.pt-2,
.py-2 {
  padding-top: 2rem !important; }

.pr-2,
.px-2 {
  padding-right: 2rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 2rem !important; }

.pl-2,
.px-2 {
  padding-left: 2rem !important; }

.p-2q1 {
  padding: 2.25rem !important; }

.pt-2q1,
.py-2q1 {
  padding-top: 2.25rem !important; }

.pr-2q1,
.px-2q1 {
  padding-right: 2.25rem !important; }

.pb-2q1,
.py-2q1 {
  padding-bottom: 2.25rem !important; }

.pl-2q1,
.px-2q1 {
  padding-left: 2.25rem !important; }

.p-2q2 {
  padding: 2.5rem !important; }

.pt-2q2,
.py-2q2 {
  padding-top: 2.5rem !important; }

.pr-2q2,
.px-2q2 {
  padding-right: 2.5rem !important; }

.pb-2q2,
.py-2q2 {
  padding-bottom: 2.5rem !important; }

.pl-2q2,
.px-2q2 {
  padding-left: 2.5rem !important; }

.p-2q3 {
  padding: 2.75rem !important; }

.pt-2q3,
.py-2q3 {
  padding-top: 2.75rem !important; }

.pr-2q3,
.px-2q3 {
  padding-right: 2.75rem !important; }

.pb-2q3,
.py-2q3 {
  padding-bottom: 2.75rem !important; }

.pl-2q3,
.px-2q3 {
  padding-left: 2.75rem !important; }

.p-3 {
  padding: 3rem !important; }

.pt-3,
.py-3 {
  padding-top: 3rem !important; }

.pr-3,
.px-3 {
  padding-right: 3rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 3rem !important; }

.pl-3,
.px-3 {
  padding-left: 3rem !important; }

.p-3q1 {
  padding: 3.25rem !important; }

.pt-3q1,
.py-3q1 {
  padding-top: 3.25rem !important; }

.pr-3q1,
.px-3q1 {
  padding-right: 3.25rem !important; }

.pb-3q1,
.py-3q1 {
  padding-bottom: 3.25rem !important; }

.pl-3q1,
.px-3q1 {
  padding-left: 3.25rem !important; }

.p-3q2 {
  padding: 3.5rem !important; }

.pt-3q2,
.py-3q2 {
  padding-top: 3.5rem !important; }

.pr-3q2,
.px-3q2 {
  padding-right: 3.5rem !important; }

.pb-3q2,
.py-3q2 {
  padding-bottom: 3.5rem !important; }

.pl-3q2,
.px-3q2 {
  padding-left: 3.5rem !important; }

.p-3q3 {
  padding: 3.75rem !important; }

.pt-3q3,
.py-3q3 {
  padding-top: 3.75rem !important; }

.pr-3q3,
.px-3q3 {
  padding-right: 3.75rem !important; }

.pb-3q3,
.py-3q3 {
  padding-bottom: 3.75rem !important; }

.pl-3q3,
.px-3q3 {
  padding-left: 3.75rem !important; }

.p-4 {
  padding: 4rem !important; }

.pt-4,
.py-4 {
  padding-top: 4rem !important; }

.pr-4,
.px-4 {
  padding-right: 4rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 4rem !important; }

.pl-4,
.px-4 {
  padding-left: 4rem !important; }

.p-4q1 {
  padding: 4.25rem !important; }

.pt-4q1,
.py-4q1 {
  padding-top: 4.25rem !important; }

.pr-4q1,
.px-4q1 {
  padding-right: 4.25rem !important; }

.pb-4q1,
.py-4q1 {
  padding-bottom: 4.25rem !important; }

.pl-4q1,
.px-4q1 {
  padding-left: 4.25rem !important; }

.p-4q2 {
  padding: 4.5rem !important; }

.pt-4q2,
.py-4q2 {
  padding-top: 4.5rem !important; }

.pr-4q2,
.px-4q2 {
  padding-right: 4.5rem !important; }

.pb-4q2,
.py-4q2 {
  padding-bottom: 4.5rem !important; }

.pl-4q2,
.px-4q2 {
  padding-left: 4.5rem !important; }

.p-4q3 {
  padding: 4.75rem !important; }

.pt-4q3,
.py-4q3 {
  padding-top: 4.75rem !important; }

.pr-4q3,
.px-4q3 {
  padding-right: 4.75rem !important; }

.pb-4q3,
.py-4q3 {
  padding-bottom: 4.75rem !important; }

.pl-4q3,
.px-4q3 {
  padding-left: 4.75rem !important; }

.p-5 {
  padding: 5rem !important; }

.pt-5,
.py-5 {
  padding-top: 5rem !important; }

.pr-5,
.px-5 {
  padding-right: 5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 5rem !important; }

.pl-5,
.px-5 {
  padding-left: 5rem !important; }

.p-5q1 {
  padding: 5.25rem !important; }

.pt-5q1,
.py-5q1 {
  padding-top: 5.25rem !important; }

.pr-5q1,
.px-5q1 {
  padding-right: 5.25rem !important; }

.pb-5q1,
.py-5q1 {
  padding-bottom: 5.25rem !important; }

.pl-5q1,
.px-5q1 {
  padding-left: 5.25rem !important; }

.p-5q2 {
  padding: 5.5rem !important; }

.pt-5q2,
.py-5q2 {
  padding-top: 5.5rem !important; }

.pr-5q2,
.px-5q2 {
  padding-right: 5.5rem !important; }

.pb-5q2,
.py-5q2 {
  padding-bottom: 5.5rem !important; }

.pl-5q2,
.px-5q2 {
  padding-left: 5.5rem !important; }

.p-5q3 {
  padding: 5.75rem !important; }

.pt-5q3,
.py-5q3 {
  padding-top: 5.75rem !important; }

.pr-5q3,
.px-5q3 {
  padding-right: 5.75rem !important; }

.pb-5q3,
.py-5q3 {
  padding-bottom: 5.75rem !important; }

.pl-5q3,
.px-5q3 {
  padding-left: 5.75rem !important; }

.p-6 {
  padding: 6rem !important; }

.pt-6,
.py-6 {
  padding-top: 6rem !important; }

.pr-6,
.px-6 {
  padding-right: 6rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 6rem !important; }

.pl-6,
.px-6 {
  padding-left: 6rem !important; }

.p-6q1 {
  padding: 6.25rem !important; }

.pt-6q1,
.py-6q1 {
  padding-top: 6.25rem !important; }

.pr-6q1,
.px-6q1 {
  padding-right: 6.25rem !important; }

.pb-6q1,
.py-6q1 {
  padding-bottom: 6.25rem !important; }

.pl-6q1,
.px-6q1 {
  padding-left: 6.25rem !important; }

.p-6q2 {
  padding: 6.5rem !important; }

.pt-6q2,
.py-6q2 {
  padding-top: 6.5rem !important; }

.pr-6q2,
.px-6q2 {
  padding-right: 6.5rem !important; }

.pb-6q2,
.py-6q2 {
  padding-bottom: 6.5rem !important; }

.pl-6q2,
.px-6q2 {
  padding-left: 6.5rem !important; }

.p-6q3 {
  padding: 6.75rem !important; }

.pt-6q3,
.py-6q3 {
  padding-top: 6.75rem !important; }

.pr-6q3,
.px-6q3 {
  padding-right: 6.75rem !important; }

.pb-6q3,
.py-6q3 {
  padding-bottom: 6.75rem !important; }

.pl-6q3,
.px-6q3 {
  padding-left: 6.75rem !important; }

.p-7 {
  padding: 7rem !important; }

.pt-7,
.py-7 {
  padding-top: 7rem !important; }

.pr-7,
.px-7 {
  padding-right: 7rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 7rem !important; }

.pl-7,
.px-7 {
  padding-left: 7rem !important; }

.p-7q1 {
  padding: 7.25rem !important; }

.pt-7q1,
.py-7q1 {
  padding-top: 7.25rem !important; }

.pr-7q1,
.px-7q1 {
  padding-right: 7.25rem !important; }

.pb-7q1,
.py-7q1 {
  padding-bottom: 7.25rem !important; }

.pl-7q1,
.px-7q1 {
  padding-left: 7.25rem !important; }

.p-7q2 {
  padding: 7.5rem !important; }

.pt-7q2,
.py-7q2 {
  padding-top: 7.5rem !important; }

.pr-7q2,
.px-7q2 {
  padding-right: 7.5rem !important; }

.pb-7q2,
.py-7q2 {
  padding-bottom: 7.5rem !important; }

.pl-7q2,
.px-7q2 {
  padding-left: 7.5rem !important; }

.p-7q3 {
  padding: 7.75rem !important; }

.pt-7q3,
.py-7q3 {
  padding-top: 7.75rem !important; }

.pr-7q3,
.px-7q3 {
  padding-right: 7.75rem !important; }

.pb-7q3,
.py-7q3 {
  padding-bottom: 7.75rem !important; }

.pl-7q3,
.px-7q3 {
  padding-left: 7.75rem !important; }

.p-8 {
  padding: 8rem !important; }

.pt-8,
.py-8 {
  padding-top: 8rem !important; }

.pr-8,
.px-8 {
  padding-right: 8rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 8rem !important; }

.pl-8,
.px-8 {
  padding-left: 8rem !important; }

.p-8q1 {
  padding: 8.25rem !important; }

.pt-8q1,
.py-8q1 {
  padding-top: 8.25rem !important; }

.pr-8q1,
.px-8q1 {
  padding-right: 8.25rem !important; }

.pb-8q1,
.py-8q1 {
  padding-bottom: 8.25rem !important; }

.pl-8q1,
.px-8q1 {
  padding-left: 8.25rem !important; }

.p-8q2 {
  padding: 8.5rem !important; }

.pt-8q2,
.py-8q2 {
  padding-top: 8.5rem !important; }

.pr-8q2,
.px-8q2 {
  padding-right: 8.5rem !important; }

.pb-8q2,
.py-8q2 {
  padding-bottom: 8.5rem !important; }

.pl-8q2,
.px-8q2 {
  padding-left: 8.5rem !important; }

.p-8q3 {
  padding: 8.75rem !important; }

.pt-8q3,
.py-8q3 {
  padding-top: 8.75rem !important; }

.pr-8q3,
.px-8q3 {
  padding-right: 8.75rem !important; }

.pb-8q3,
.py-8q3 {
  padding-bottom: 8.75rem !important; }

.pl-8q3,
.px-8q3 {
  padding-left: 8.75rem !important; }

.p-9 {
  padding: 9rem !important; }

.pt-9,
.py-9 {
  padding-top: 9rem !important; }

.pr-9,
.px-9 {
  padding-right: 9rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 9rem !important; }

.pl-9,
.px-9 {
  padding-left: 9rem !important; }

.p-9q1 {
  padding: 9.25rem !important; }

.pt-9q1,
.py-9q1 {
  padding-top: 9.25rem !important; }

.pr-9q1,
.px-9q1 {
  padding-right: 9.25rem !important; }

.pb-9q1,
.py-9q1 {
  padding-bottom: 9.25rem !important; }

.pl-9q1,
.px-9q1 {
  padding-left: 9.25rem !important; }

.p-9q2 {
  padding: 9.5rem !important; }

.pt-9q2,
.py-9q2 {
  padding-top: 9.5rem !important; }

.pr-9q2,
.px-9q2 {
  padding-right: 9.5rem !important; }

.pb-9q2,
.py-9q2 {
  padding-bottom: 9.5rem !important; }

.pl-9q2,
.px-9q2 {
  padding-left: 9.5rem !important; }

.p-9q3 {
  padding: 9.75rem !important; }

.pt-9q3,
.py-9q3 {
  padding-top: 9.75rem !important; }

.pr-9q3,
.px-9q3 {
  padding-right: 9.75rem !important; }

.pb-9q3,
.py-9q3 {
  padding-bottom: 9.75rem !important; }

.pl-9q3,
.px-9q3 {
  padding-left: 9.75rem !important; }

.p-10 {
  padding: 10rem !important; }

.pt-10,
.py-10 {
  padding-top: 10rem !important; }

.pr-10,
.px-10 {
  padding-right: 10rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 10rem !important; }

.pl-10,
.px-10 {
  padding-left: 10rem !important; }

.p-10q1 {
  padding: 10.25rem !important; }

.pt-10q1,
.py-10q1 {
  padding-top: 10.25rem !important; }

.pr-10q1,
.px-10q1 {
  padding-right: 10.25rem !important; }

.pb-10q1,
.py-10q1 {
  padding-bottom: 10.25rem !important; }

.pl-10q1,
.px-10q1 {
  padding-left: 10.25rem !important; }

.p-10q2 {
  padding: 10.5rem !important; }

.pt-10q2,
.py-10q2 {
  padding-top: 10.5rem !important; }

.pr-10q2,
.px-10q2 {
  padding-right: 10.5rem !important; }

.pb-10q2,
.py-10q2 {
  padding-bottom: 10.5rem !important; }

.pl-10q2,
.px-10q2 {
  padding-left: 10.5rem !important; }

.p-10q3 {
  padding: 10.75rem !important; }

.pt-10q3,
.py-10q3 {
  padding-top: 10.75rem !important; }

.pr-10q3,
.px-10q3 {
  padding-right: 10.75rem !important; }

.pb-10q3,
.py-10q3 {
  padding-bottom: 10.75rem !important; }

.pl-10q3,
.px-10q3 {
  padding-left: 10.75rem !important; }

.p-11 {
  padding: 11rem !important; }

.pt-11,
.py-11 {
  padding-top: 11rem !important; }

.pr-11,
.px-11 {
  padding-right: 11rem !important; }

.pb-11,
.py-11 {
  padding-bottom: 11rem !important; }

.pl-11,
.px-11 {
  padding-left: 11rem !important; }

.p-11q1 {
  padding: 11.25rem !important; }

.pt-11q1,
.py-11q1 {
  padding-top: 11.25rem !important; }

.pr-11q1,
.px-11q1 {
  padding-right: 11.25rem !important; }

.pb-11q1,
.py-11q1 {
  padding-bottom: 11.25rem !important; }

.pl-11q1,
.px-11q1 {
  padding-left: 11.25rem !important; }

.p-11 q2 {
  padding: 11.5rem !important; }

.pt-11 q2,
.py-11 q2 {
  padding-top: 11.5rem !important; }

.pr-11 q2,
.px-11 q2 {
  padding-right: 11.5rem !important; }

.pb-11 q2,
.py-11 q2 {
  padding-bottom: 11.5rem !important; }

.pl-11 q2,
.px-11 q2 {
  padding-left: 11.5rem !important; }

.p-11 q3 {
  padding: 11.75rem !important; }

.pt-11 q3,
.py-11 q3 {
  padding-top: 11.75rem !important; }

.pr-11 q3,
.px-11 q3 {
  padding-right: 11.75rem !important; }

.pb-11 q3,
.py-11 q3 {
  padding-bottom: 11.75rem !important; }

.pl-11 q3,
.px-11 q3 {
  padding-left: 11.75rem !important; }

.p-12 {
  padding: 12rem !important; }

.pt-12,
.py-12 {
  padding-top: 12rem !important; }

.pr-12,
.px-12 {
  padding-right: 12rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 12rem !important; }

.pl-12,
.px-12 {
  padding-left: 12rem !important; }

.p-12 q1 {
  padding: 12.25rem !important; }

.pt-12 q1,
.py-12 q1 {
  padding-top: 12.25rem !important; }

.pr-12 q1,
.px-12 q1 {
  padding-right: 12.25rem !important; }

.pb-12 q1,
.py-12 q1 {
  padding-bottom: 12.25rem !important; }

.pl-12 q1,
.px-12 q1 {
  padding-left: 12.25rem !important; }

.p-12 q2 {
  padding: 12.5rem !important; }

.pt-12 q2,
.py-12 q2 {
  padding-top: 12.5rem !important; }

.pr-12 q2,
.px-12 q2 {
  padding-right: 12.5rem !important; }

.pb-12 q2,
.py-12 q2 {
  padding-bottom: 12.5rem !important; }

.pl-12 q2,
.px-12 q2 {
  padding-left: 12.5rem !important; }

.p-12 q3 {
  padding: 12.75rem !important; }

.pt-12 q3,
.py-12 q3 {
  padding-top: 12.75rem !important; }

.pr-12 q3,
.px-12 q3 {
  padding-right: 12.75rem !important; }

.pb-12 q3,
.py-12 q3 {
  padding-bottom: 12.75rem !important; }

.pl-12 q3,
.px-12 q3 {
  padding-left: 12.75rem !important; }

.m-nq1 {
  margin: -0.25rem !important; }

.mt-nq1,
.my-nq1 {
  margin-top: -0.25rem !important; }

.mr-nq1,
.mx-nq1 {
  margin-right: -0.25rem !important; }

.mb-nq1,
.my-nq1 {
  margin-bottom: -0.25rem !important; }

.ml-nq1,
.mx-nq1 {
  margin-left: -0.25rem !important; }

.m-nq2 {
  margin: -0.5rem !important; }

.mt-nq2,
.my-nq2 {
  margin-top: -0.5rem !important; }

.mr-nq2,
.mx-nq2 {
  margin-right: -0.5rem !important; }

.mb-nq2,
.my-nq2 {
  margin-bottom: -0.5rem !important; }

.ml-nq2,
.mx-nq2 {
  margin-left: -0.5rem !important; }

.m-nq3 {
  margin: -0.75rem !important; }

.mt-nq3,
.my-nq3 {
  margin-top: -0.75rem !important; }

.mr-nq3,
.mx-nq3 {
  margin-right: -0.75rem !important; }

.mb-nq3,
.my-nq3 {
  margin-bottom: -0.75rem !important; }

.ml-nq3,
.mx-nq3 {
  margin-left: -0.75rem !important; }

.m-n1 {
  margin: -1rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -1rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -1rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -1rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -1rem !important; }

.m-n1q1 {
  margin: -1.25rem !important; }

.mt-n1q1,
.my-n1q1 {
  margin-top: -1.25rem !important; }

.mr-n1q1,
.mx-n1q1 {
  margin-right: -1.25rem !important; }

.mb-n1q1,
.my-n1q1 {
  margin-bottom: -1.25rem !important; }

.ml-n1q1,
.mx-n1q1 {
  margin-left: -1.25rem !important; }

.m-n1q2 {
  margin: -1.5rem !important; }

.mt-n1q2,
.my-n1q2 {
  margin-top: -1.5rem !important; }

.mr-n1q2,
.mx-n1q2 {
  margin-right: -1.5rem !important; }

.mb-n1q2,
.my-n1q2 {
  margin-bottom: -1.5rem !important; }

.ml-n1q2,
.mx-n1q2 {
  margin-left: -1.5rem !important; }

.m-n1q3 {
  margin: -1.75rem !important; }

.mt-n1q3,
.my-n1q3 {
  margin-top: -1.75rem !important; }

.mr-n1q3,
.mx-n1q3 {
  margin-right: -1.75rem !important; }

.mb-n1q3,
.my-n1q3 {
  margin-bottom: -1.75rem !important; }

.ml-n1q3,
.mx-n1q3 {
  margin-left: -1.75rem !important; }

.m-n2 {
  margin: -2rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -2rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -2rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -2rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -2rem !important; }

.m-n2q1 {
  margin: -2.25rem !important; }

.mt-n2q1,
.my-n2q1 {
  margin-top: -2.25rem !important; }

.mr-n2q1,
.mx-n2q1 {
  margin-right: -2.25rem !important; }

.mb-n2q1,
.my-n2q1 {
  margin-bottom: -2.25rem !important; }

.ml-n2q1,
.mx-n2q1 {
  margin-left: -2.25rem !important; }

.m-n2q2 {
  margin: -2.5rem !important; }

.mt-n2q2,
.my-n2q2 {
  margin-top: -2.5rem !important; }

.mr-n2q2,
.mx-n2q2 {
  margin-right: -2.5rem !important; }

.mb-n2q2,
.my-n2q2 {
  margin-bottom: -2.5rem !important; }

.ml-n2q2,
.mx-n2q2 {
  margin-left: -2.5rem !important; }

.m-n2q3 {
  margin: -2.75rem !important; }

.mt-n2q3,
.my-n2q3 {
  margin-top: -2.75rem !important; }

.mr-n2q3,
.mx-n2q3 {
  margin-right: -2.75rem !important; }

.mb-n2q3,
.my-n2q3 {
  margin-bottom: -2.75rem !important; }

.ml-n2q3,
.mx-n2q3 {
  margin-left: -2.75rem !important; }

.m-n3 {
  margin: -3rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -3rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -3rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -3rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -3rem !important; }

.m-n3q1 {
  margin: -3.25rem !important; }

.mt-n3q1,
.my-n3q1 {
  margin-top: -3.25rem !important; }

.mr-n3q1,
.mx-n3q1 {
  margin-right: -3.25rem !important; }

.mb-n3q1,
.my-n3q1 {
  margin-bottom: -3.25rem !important; }

.ml-n3q1,
.mx-n3q1 {
  margin-left: -3.25rem !important; }

.m-n3q2 {
  margin: -3.5rem !important; }

.mt-n3q2,
.my-n3q2 {
  margin-top: -3.5rem !important; }

.mr-n3q2,
.mx-n3q2 {
  margin-right: -3.5rem !important; }

.mb-n3q2,
.my-n3q2 {
  margin-bottom: -3.5rem !important; }

.ml-n3q2,
.mx-n3q2 {
  margin-left: -3.5rem !important; }

.m-n3q3 {
  margin: -3.75rem !important; }

.mt-n3q3,
.my-n3q3 {
  margin-top: -3.75rem !important; }

.mr-n3q3,
.mx-n3q3 {
  margin-right: -3.75rem !important; }

.mb-n3q3,
.my-n3q3 {
  margin-bottom: -3.75rem !important; }

.ml-n3q3,
.mx-n3q3 {
  margin-left: -3.75rem !important; }

.m-n4 {
  margin: -4rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -4rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -4rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -4rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -4rem !important; }

.m-n4q1 {
  margin: -4.25rem !important; }

.mt-n4q1,
.my-n4q1 {
  margin-top: -4.25rem !important; }

.mr-n4q1,
.mx-n4q1 {
  margin-right: -4.25rem !important; }

.mb-n4q1,
.my-n4q1 {
  margin-bottom: -4.25rem !important; }

.ml-n4q1,
.mx-n4q1 {
  margin-left: -4.25rem !important; }

.m-n4q2 {
  margin: -4.5rem !important; }

.mt-n4q2,
.my-n4q2 {
  margin-top: -4.5rem !important; }

.mr-n4q2,
.mx-n4q2 {
  margin-right: -4.5rem !important; }

.mb-n4q2,
.my-n4q2 {
  margin-bottom: -4.5rem !important; }

.ml-n4q2,
.mx-n4q2 {
  margin-left: -4.5rem !important; }

.m-n4q3 {
  margin: -4.75rem !important; }

.mt-n4q3,
.my-n4q3 {
  margin-top: -4.75rem !important; }

.mr-n4q3,
.mx-n4q3 {
  margin-right: -4.75rem !important; }

.mb-n4q3,
.my-n4q3 {
  margin-bottom: -4.75rem !important; }

.ml-n4q3,
.mx-n4q3 {
  margin-left: -4.75rem !important; }

.m-n5 {
  margin: -5rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -5rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -5rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -5rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -5rem !important; }

.m-n5q1 {
  margin: -5.25rem !important; }

.mt-n5q1,
.my-n5q1 {
  margin-top: -5.25rem !important; }

.mr-n5q1,
.mx-n5q1 {
  margin-right: -5.25rem !important; }

.mb-n5q1,
.my-n5q1 {
  margin-bottom: -5.25rem !important; }

.ml-n5q1,
.mx-n5q1 {
  margin-left: -5.25rem !important; }

.m-n5q2 {
  margin: -5.5rem !important; }

.mt-n5q2,
.my-n5q2 {
  margin-top: -5.5rem !important; }

.mr-n5q2,
.mx-n5q2 {
  margin-right: -5.5rem !important; }

.mb-n5q2,
.my-n5q2 {
  margin-bottom: -5.5rem !important; }

.ml-n5q2,
.mx-n5q2 {
  margin-left: -5.5rem !important; }

.m-n5q3 {
  margin: -5.75rem !important; }

.mt-n5q3,
.my-n5q3 {
  margin-top: -5.75rem !important; }

.mr-n5q3,
.mx-n5q3 {
  margin-right: -5.75rem !important; }

.mb-n5q3,
.my-n5q3 {
  margin-bottom: -5.75rem !important; }

.ml-n5q3,
.mx-n5q3 {
  margin-left: -5.75rem !important; }

.m-n6 {
  margin: -6rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -6rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -6rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -6rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -6rem !important; }

.m-n6q1 {
  margin: -6.25rem !important; }

.mt-n6q1,
.my-n6q1 {
  margin-top: -6.25rem !important; }

.mr-n6q1,
.mx-n6q1 {
  margin-right: -6.25rem !important; }

.mb-n6q1,
.my-n6q1 {
  margin-bottom: -6.25rem !important; }

.ml-n6q1,
.mx-n6q1 {
  margin-left: -6.25rem !important; }

.m-n6q2 {
  margin: -6.5rem !important; }

.mt-n6q2,
.my-n6q2 {
  margin-top: -6.5rem !important; }

.mr-n6q2,
.mx-n6q2 {
  margin-right: -6.5rem !important; }

.mb-n6q2,
.my-n6q2 {
  margin-bottom: -6.5rem !important; }

.ml-n6q2,
.mx-n6q2 {
  margin-left: -6.5rem !important; }

.m-n6q3 {
  margin: -6.75rem !important; }

.mt-n6q3,
.my-n6q3 {
  margin-top: -6.75rem !important; }

.mr-n6q3,
.mx-n6q3 {
  margin-right: -6.75rem !important; }

.mb-n6q3,
.my-n6q3 {
  margin-bottom: -6.75rem !important; }

.ml-n6q3,
.mx-n6q3 {
  margin-left: -6.75rem !important; }

.m-n7 {
  margin: -7rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -7rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -7rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -7rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -7rem !important; }

.m-n7q1 {
  margin: -7.25rem !important; }

.mt-n7q1,
.my-n7q1 {
  margin-top: -7.25rem !important; }

.mr-n7q1,
.mx-n7q1 {
  margin-right: -7.25rem !important; }

.mb-n7q1,
.my-n7q1 {
  margin-bottom: -7.25rem !important; }

.ml-n7q1,
.mx-n7q1 {
  margin-left: -7.25rem !important; }

.m-n7q2 {
  margin: -7.5rem !important; }

.mt-n7q2,
.my-n7q2 {
  margin-top: -7.5rem !important; }

.mr-n7q2,
.mx-n7q2 {
  margin-right: -7.5rem !important; }

.mb-n7q2,
.my-n7q2 {
  margin-bottom: -7.5rem !important; }

.ml-n7q2,
.mx-n7q2 {
  margin-left: -7.5rem !important; }

.m-n7q3 {
  margin: -7.75rem !important; }

.mt-n7q3,
.my-n7q3 {
  margin-top: -7.75rem !important; }

.mr-n7q3,
.mx-n7q3 {
  margin-right: -7.75rem !important; }

.mb-n7q3,
.my-n7q3 {
  margin-bottom: -7.75rem !important; }

.ml-n7q3,
.mx-n7q3 {
  margin-left: -7.75rem !important; }

.m-n8 {
  margin: -8rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -8rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -8rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -8rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -8rem !important; }

.m-n8q1 {
  margin: -8.25rem !important; }

.mt-n8q1,
.my-n8q1 {
  margin-top: -8.25rem !important; }

.mr-n8q1,
.mx-n8q1 {
  margin-right: -8.25rem !important; }

.mb-n8q1,
.my-n8q1 {
  margin-bottom: -8.25rem !important; }

.ml-n8q1,
.mx-n8q1 {
  margin-left: -8.25rem !important; }

.m-n8q2 {
  margin: -8.5rem !important; }

.mt-n8q2,
.my-n8q2 {
  margin-top: -8.5rem !important; }

.mr-n8q2,
.mx-n8q2 {
  margin-right: -8.5rem !important; }

.mb-n8q2,
.my-n8q2 {
  margin-bottom: -8.5rem !important; }

.ml-n8q2,
.mx-n8q2 {
  margin-left: -8.5rem !important; }

.m-n8q3 {
  margin: -8.75rem !important; }

.mt-n8q3,
.my-n8q3 {
  margin-top: -8.75rem !important; }

.mr-n8q3,
.mx-n8q3 {
  margin-right: -8.75rem !important; }

.mb-n8q3,
.my-n8q3 {
  margin-bottom: -8.75rem !important; }

.ml-n8q3,
.mx-n8q3 {
  margin-left: -8.75rem !important; }

.m-n9 {
  margin: -9rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -9rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -9rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -9rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -9rem !important; }

.m-n9q1 {
  margin: -9.25rem !important; }

.mt-n9q1,
.my-n9q1 {
  margin-top: -9.25rem !important; }

.mr-n9q1,
.mx-n9q1 {
  margin-right: -9.25rem !important; }

.mb-n9q1,
.my-n9q1 {
  margin-bottom: -9.25rem !important; }

.ml-n9q1,
.mx-n9q1 {
  margin-left: -9.25rem !important; }

.m-n9q2 {
  margin: -9.5rem !important; }

.mt-n9q2,
.my-n9q2 {
  margin-top: -9.5rem !important; }

.mr-n9q2,
.mx-n9q2 {
  margin-right: -9.5rem !important; }

.mb-n9q2,
.my-n9q2 {
  margin-bottom: -9.5rem !important; }

.ml-n9q2,
.mx-n9q2 {
  margin-left: -9.5rem !important; }

.m-n9q3 {
  margin: -9.75rem !important; }

.mt-n9q3,
.my-n9q3 {
  margin-top: -9.75rem !important; }

.mr-n9q3,
.mx-n9q3 {
  margin-right: -9.75rem !important; }

.mb-n9q3,
.my-n9q3 {
  margin-bottom: -9.75rem !important; }

.ml-n9q3,
.mx-n9q3 {
  margin-left: -9.75rem !important; }

.m-n10 {
  margin: -10rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -10rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -10rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -10rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -10rem !important; }

.m-n10q1 {
  margin: -10.25rem !important; }

.mt-n10q1,
.my-n10q1 {
  margin-top: -10.25rem !important; }

.mr-n10q1,
.mx-n10q1 {
  margin-right: -10.25rem !important; }

.mb-n10q1,
.my-n10q1 {
  margin-bottom: -10.25rem !important; }

.ml-n10q1,
.mx-n10q1 {
  margin-left: -10.25rem !important; }

.m-n10q2 {
  margin: -10.5rem !important; }

.mt-n10q2,
.my-n10q2 {
  margin-top: -10.5rem !important; }

.mr-n10q2,
.mx-n10q2 {
  margin-right: -10.5rem !important; }

.mb-n10q2,
.my-n10q2 {
  margin-bottom: -10.5rem !important; }

.ml-n10q2,
.mx-n10q2 {
  margin-left: -10.5rem !important; }

.m-n10q3 {
  margin: -10.75rem !important; }

.mt-n10q3,
.my-n10q3 {
  margin-top: -10.75rem !important; }

.mr-n10q3,
.mx-n10q3 {
  margin-right: -10.75rem !important; }

.mb-n10q3,
.my-n10q3 {
  margin-bottom: -10.75rem !important; }

.ml-n10q3,
.mx-n10q3 {
  margin-left: -10.75rem !important; }

.m-n11 {
  margin: -11rem !important; }

.mt-n11,
.my-n11 {
  margin-top: -11rem !important; }

.mr-n11,
.mx-n11 {
  margin-right: -11rem !important; }

.mb-n11,
.my-n11 {
  margin-bottom: -11rem !important; }

.ml-n11,
.mx-n11 {
  margin-left: -11rem !important; }

.m-n11q1 {
  margin: -11.25rem !important; }

.mt-n11q1,
.my-n11q1 {
  margin-top: -11.25rem !important; }

.mr-n11q1,
.mx-n11q1 {
  margin-right: -11.25rem !important; }

.mb-n11q1,
.my-n11q1 {
  margin-bottom: -11.25rem !important; }

.ml-n11q1,
.mx-n11q1 {
  margin-left: -11.25rem !important; }

.m-n11 q2 {
  margin: -11.5rem !important; }

.mt-n11 q2,
.my-n11 q2 {
  margin-top: -11.5rem !important; }

.mr-n11 q2,
.mx-n11 q2 {
  margin-right: -11.5rem !important; }

.mb-n11 q2,
.my-n11 q2 {
  margin-bottom: -11.5rem !important; }

.ml-n11 q2,
.mx-n11 q2 {
  margin-left: -11.5rem !important; }

.m-n11 q3 {
  margin: -11.75rem !important; }

.mt-n11 q3,
.my-n11 q3 {
  margin-top: -11.75rem !important; }

.mr-n11 q3,
.mx-n11 q3 {
  margin-right: -11.75rem !important; }

.mb-n11 q3,
.my-n11 q3 {
  margin-bottom: -11.75rem !important; }

.ml-n11 q3,
.mx-n11 q3 {
  margin-left: -11.75rem !important; }

.m-n12 {
  margin: -12rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -12rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -12rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -12rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -12rem !important; }

.m-n12 q1 {
  margin: -12.25rem !important; }

.mt-n12 q1,
.my-n12 q1 {
  margin-top: -12.25rem !important; }

.mr-n12 q1,
.mx-n12 q1 {
  margin-right: -12.25rem !important; }

.mb-n12 q1,
.my-n12 q1 {
  margin-bottom: -12.25rem !important; }

.ml-n12 q1,
.mx-n12 q1 {
  margin-left: -12.25rem !important; }

.m-n12 q2 {
  margin: -12.5rem !important; }

.mt-n12 q2,
.my-n12 q2 {
  margin-top: -12.5rem !important; }

.mr-n12 q2,
.mx-n12 q2 {
  margin-right: -12.5rem !important; }

.mb-n12 q2,
.my-n12 q2 {
  margin-bottom: -12.5rem !important; }

.ml-n12 q2,
.mx-n12 q2 {
  margin-left: -12.5rem !important; }

.m-n12 q3 {
  margin: -12.75rem !important; }

.mt-n12 q3,
.my-n12 q3 {
  margin-top: -12.75rem !important; }

.mr-n12 q3,
.mx-n12 q3 {
  margin-right: -12.75rem !important; }

.mb-n12 q3,
.my-n12 q3 {
  margin-bottom: -12.75rem !important; }

.ml-n12 q3,
.mx-n12 q3 {
  margin-left: -12.75rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-q1 {
    margin: 0.25rem !important; }
  .mt-sm-q1,
  .my-sm-q1 {
    margin-top: 0.25rem !important; }
  .mr-sm-q1,
  .mx-sm-q1 {
    margin-right: 0.25rem !important; }
  .mb-sm-q1,
  .my-sm-q1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-q1,
  .mx-sm-q1 {
    margin-left: 0.25rem !important; }
  .m-sm-q2 {
    margin: 0.5rem !important; }
  .mt-sm-q2,
  .my-sm-q2 {
    margin-top: 0.5rem !important; }
  .mr-sm-q2,
  .mx-sm-q2 {
    margin-right: 0.5rem !important; }
  .mb-sm-q2,
  .my-sm-q2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-q2,
  .mx-sm-q2 {
    margin-left: 0.5rem !important; }
  .m-sm-q3 {
    margin: 0.75rem !important; }
  .mt-sm-q3,
  .my-sm-q3 {
    margin-top: 0.75rem !important; }
  .mr-sm-q3,
  .mx-sm-q3 {
    margin-right: 0.75rem !important; }
  .mb-sm-q3,
  .my-sm-q3 {
    margin-bottom: 0.75rem !important; }
  .ml-sm-q3,
  .mx-sm-q3 {
    margin-left: 0.75rem !important; }
  .m-sm-0 {
    margin: 0rem !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0rem !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0rem !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0rem !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0rem !important; }
  .m-sm-1 {
    margin: 1rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 1rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 1rem !important; }
  .m-sm-1q1 {
    margin: 1.25rem !important; }
  .mt-sm-1q1,
  .my-sm-1q1 {
    margin-top: 1.25rem !important; }
  .mr-sm-1q1,
  .mx-sm-1q1 {
    margin-right: 1.25rem !important; }
  .mb-sm-1q1,
  .my-sm-1q1 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-1q1,
  .mx-sm-1q1 {
    margin-left: 1.25rem !important; }
  .m-sm-1q2 {
    margin: 1.5rem !important; }
  .mt-sm-1q2,
  .my-sm-1q2 {
    margin-top: 1.5rem !important; }
  .mr-sm-1q2,
  .mx-sm-1q2 {
    margin-right: 1.5rem !important; }
  .mb-sm-1q2,
  .my-sm-1q2 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-1q2,
  .mx-sm-1q2 {
    margin-left: 1.5rem !important; }
  .m-sm-1q3 {
    margin: 1.75rem !important; }
  .mt-sm-1q3,
  .my-sm-1q3 {
    margin-top: 1.75rem !important; }
  .mr-sm-1q3,
  .mx-sm-1q3 {
    margin-right: 1.75rem !important; }
  .mb-sm-1q3,
  .my-sm-1q3 {
    margin-bottom: 1.75rem !important; }
  .ml-sm-1q3,
  .mx-sm-1q3 {
    margin-left: 1.75rem !important; }
  .m-sm-2 {
    margin: 2rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 2rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 2rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 2rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 2rem !important; }
  .m-sm-2q1 {
    margin: 2.25rem !important; }
  .mt-sm-2q1,
  .my-sm-2q1 {
    margin-top: 2.25rem !important; }
  .mr-sm-2q1,
  .mx-sm-2q1 {
    margin-right: 2.25rem !important; }
  .mb-sm-2q1,
  .my-sm-2q1 {
    margin-bottom: 2.25rem !important; }
  .ml-sm-2q1,
  .mx-sm-2q1 {
    margin-left: 2.25rem !important; }
  .m-sm-2q2 {
    margin: 2.5rem !important; }
  .mt-sm-2q2,
  .my-sm-2q2 {
    margin-top: 2.5rem !important; }
  .mr-sm-2q2,
  .mx-sm-2q2 {
    margin-right: 2.5rem !important; }
  .mb-sm-2q2,
  .my-sm-2q2 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-2q2,
  .mx-sm-2q2 {
    margin-left: 2.5rem !important; }
  .m-sm-2q3 {
    margin: 2.75rem !important; }
  .mt-sm-2q3,
  .my-sm-2q3 {
    margin-top: 2.75rem !important; }
  .mr-sm-2q3,
  .mx-sm-2q3 {
    margin-right: 2.75rem !important; }
  .mb-sm-2q3,
  .my-sm-2q3 {
    margin-bottom: 2.75rem !important; }
  .ml-sm-2q3,
  .mx-sm-2q3 {
    margin-left: 2.75rem !important; }
  .m-sm-3 {
    margin: 3rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 3rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 3rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 3rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 3rem !important; }
  .m-sm-3q1 {
    margin: 3.25rem !important; }
  .mt-sm-3q1,
  .my-sm-3q1 {
    margin-top: 3.25rem !important; }
  .mr-sm-3q1,
  .mx-sm-3q1 {
    margin-right: 3.25rem !important; }
  .mb-sm-3q1,
  .my-sm-3q1 {
    margin-bottom: 3.25rem !important; }
  .ml-sm-3q1,
  .mx-sm-3q1 {
    margin-left: 3.25rem !important; }
  .m-sm-3q2 {
    margin: 3.5rem !important; }
  .mt-sm-3q2,
  .my-sm-3q2 {
    margin-top: 3.5rem !important; }
  .mr-sm-3q2,
  .mx-sm-3q2 {
    margin-right: 3.5rem !important; }
  .mb-sm-3q2,
  .my-sm-3q2 {
    margin-bottom: 3.5rem !important; }
  .ml-sm-3q2,
  .mx-sm-3q2 {
    margin-left: 3.5rem !important; }
  .m-sm-3q3 {
    margin: 3.75rem !important; }
  .mt-sm-3q3,
  .my-sm-3q3 {
    margin-top: 3.75rem !important; }
  .mr-sm-3q3,
  .mx-sm-3q3 {
    margin-right: 3.75rem !important; }
  .mb-sm-3q3,
  .my-sm-3q3 {
    margin-bottom: 3.75rem !important; }
  .ml-sm-3q3,
  .mx-sm-3q3 {
    margin-left: 3.75rem !important; }
  .m-sm-4 {
    margin: 4rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 4rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 4rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 4rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 4rem !important; }
  .m-sm-4q1 {
    margin: 4.25rem !important; }
  .mt-sm-4q1,
  .my-sm-4q1 {
    margin-top: 4.25rem !important; }
  .mr-sm-4q1,
  .mx-sm-4q1 {
    margin-right: 4.25rem !important; }
  .mb-sm-4q1,
  .my-sm-4q1 {
    margin-bottom: 4.25rem !important; }
  .ml-sm-4q1,
  .mx-sm-4q1 {
    margin-left: 4.25rem !important; }
  .m-sm-4q2 {
    margin: 4.5rem !important; }
  .mt-sm-4q2,
  .my-sm-4q2 {
    margin-top: 4.5rem !important; }
  .mr-sm-4q2,
  .mx-sm-4q2 {
    margin-right: 4.5rem !important; }
  .mb-sm-4q2,
  .my-sm-4q2 {
    margin-bottom: 4.5rem !important; }
  .ml-sm-4q2,
  .mx-sm-4q2 {
    margin-left: 4.5rem !important; }
  .m-sm-4q3 {
    margin: 4.75rem !important; }
  .mt-sm-4q3,
  .my-sm-4q3 {
    margin-top: 4.75rem !important; }
  .mr-sm-4q3,
  .mx-sm-4q3 {
    margin-right: 4.75rem !important; }
  .mb-sm-4q3,
  .my-sm-4q3 {
    margin-bottom: 4.75rem !important; }
  .ml-sm-4q3,
  .mx-sm-4q3 {
    margin-left: 4.75rem !important; }
  .m-sm-5 {
    margin: 5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5rem !important; }
  .m-sm-5q1 {
    margin: 5.25rem !important; }
  .mt-sm-5q1,
  .my-sm-5q1 {
    margin-top: 5.25rem !important; }
  .mr-sm-5q1,
  .mx-sm-5q1 {
    margin-right: 5.25rem !important; }
  .mb-sm-5q1,
  .my-sm-5q1 {
    margin-bottom: 5.25rem !important; }
  .ml-sm-5q1,
  .mx-sm-5q1 {
    margin-left: 5.25rem !important; }
  .m-sm-5q2 {
    margin: 5.5rem !important; }
  .mt-sm-5q2,
  .my-sm-5q2 {
    margin-top: 5.5rem !important; }
  .mr-sm-5q2,
  .mx-sm-5q2 {
    margin-right: 5.5rem !important; }
  .mb-sm-5q2,
  .my-sm-5q2 {
    margin-bottom: 5.5rem !important; }
  .ml-sm-5q2,
  .mx-sm-5q2 {
    margin-left: 5.5rem !important; }
  .m-sm-5q3 {
    margin: 5.75rem !important; }
  .mt-sm-5q3,
  .my-sm-5q3 {
    margin-top: 5.75rem !important; }
  .mr-sm-5q3,
  .mx-sm-5q3 {
    margin-right: 5.75rem !important; }
  .mb-sm-5q3,
  .my-sm-5q3 {
    margin-bottom: 5.75rem !important; }
  .ml-sm-5q3,
  .mx-sm-5q3 {
    margin-left: 5.75rem !important; }
  .m-sm-6 {
    margin: 6rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 6rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 6rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 6rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 6rem !important; }
  .m-sm-6q1 {
    margin: 6.25rem !important; }
  .mt-sm-6q1,
  .my-sm-6q1 {
    margin-top: 6.25rem !important; }
  .mr-sm-6q1,
  .mx-sm-6q1 {
    margin-right: 6.25rem !important; }
  .mb-sm-6q1,
  .my-sm-6q1 {
    margin-bottom: 6.25rem !important; }
  .ml-sm-6q1,
  .mx-sm-6q1 {
    margin-left: 6.25rem !important; }
  .m-sm-6q2 {
    margin: 6.5rem !important; }
  .mt-sm-6q2,
  .my-sm-6q2 {
    margin-top: 6.5rem !important; }
  .mr-sm-6q2,
  .mx-sm-6q2 {
    margin-right: 6.5rem !important; }
  .mb-sm-6q2,
  .my-sm-6q2 {
    margin-bottom: 6.5rem !important; }
  .ml-sm-6q2,
  .mx-sm-6q2 {
    margin-left: 6.5rem !important; }
  .m-sm-6q3 {
    margin: 6.75rem !important; }
  .mt-sm-6q3,
  .my-sm-6q3 {
    margin-top: 6.75rem !important; }
  .mr-sm-6q3,
  .mx-sm-6q3 {
    margin-right: 6.75rem !important; }
  .mb-sm-6q3,
  .my-sm-6q3 {
    margin-bottom: 6.75rem !important; }
  .ml-sm-6q3,
  .mx-sm-6q3 {
    margin-left: 6.75rem !important; }
  .m-sm-7 {
    margin: 7rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 7rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 7rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 7rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 7rem !important; }
  .m-sm-7q1 {
    margin: 7.25rem !important; }
  .mt-sm-7q1,
  .my-sm-7q1 {
    margin-top: 7.25rem !important; }
  .mr-sm-7q1,
  .mx-sm-7q1 {
    margin-right: 7.25rem !important; }
  .mb-sm-7q1,
  .my-sm-7q1 {
    margin-bottom: 7.25rem !important; }
  .ml-sm-7q1,
  .mx-sm-7q1 {
    margin-left: 7.25rem !important; }
  .m-sm-7q2 {
    margin: 7.5rem !important; }
  .mt-sm-7q2,
  .my-sm-7q2 {
    margin-top: 7.5rem !important; }
  .mr-sm-7q2,
  .mx-sm-7q2 {
    margin-right: 7.5rem !important; }
  .mb-sm-7q2,
  .my-sm-7q2 {
    margin-bottom: 7.5rem !important; }
  .ml-sm-7q2,
  .mx-sm-7q2 {
    margin-left: 7.5rem !important; }
  .m-sm-7q3 {
    margin: 7.75rem !important; }
  .mt-sm-7q3,
  .my-sm-7q3 {
    margin-top: 7.75rem !important; }
  .mr-sm-7q3,
  .mx-sm-7q3 {
    margin-right: 7.75rem !important; }
  .mb-sm-7q3,
  .my-sm-7q3 {
    margin-bottom: 7.75rem !important; }
  .ml-sm-7q3,
  .mx-sm-7q3 {
    margin-left: 7.75rem !important; }
  .m-sm-8 {
    margin: 8rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 8rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 8rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 8rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 8rem !important; }
  .m-sm-8q1 {
    margin: 8.25rem !important; }
  .mt-sm-8q1,
  .my-sm-8q1 {
    margin-top: 8.25rem !important; }
  .mr-sm-8q1,
  .mx-sm-8q1 {
    margin-right: 8.25rem !important; }
  .mb-sm-8q1,
  .my-sm-8q1 {
    margin-bottom: 8.25rem !important; }
  .ml-sm-8q1,
  .mx-sm-8q1 {
    margin-left: 8.25rem !important; }
  .m-sm-8q2 {
    margin: 8.5rem !important; }
  .mt-sm-8q2,
  .my-sm-8q2 {
    margin-top: 8.5rem !important; }
  .mr-sm-8q2,
  .mx-sm-8q2 {
    margin-right: 8.5rem !important; }
  .mb-sm-8q2,
  .my-sm-8q2 {
    margin-bottom: 8.5rem !important; }
  .ml-sm-8q2,
  .mx-sm-8q2 {
    margin-left: 8.5rem !important; }
  .m-sm-8q3 {
    margin: 8.75rem !important; }
  .mt-sm-8q3,
  .my-sm-8q3 {
    margin-top: 8.75rem !important; }
  .mr-sm-8q3,
  .mx-sm-8q3 {
    margin-right: 8.75rem !important; }
  .mb-sm-8q3,
  .my-sm-8q3 {
    margin-bottom: 8.75rem !important; }
  .ml-sm-8q3,
  .mx-sm-8q3 {
    margin-left: 8.75rem !important; }
  .m-sm-9 {
    margin: 9rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 9rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 9rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 9rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 9rem !important; }
  .m-sm-9q1 {
    margin: 9.25rem !important; }
  .mt-sm-9q1,
  .my-sm-9q1 {
    margin-top: 9.25rem !important; }
  .mr-sm-9q1,
  .mx-sm-9q1 {
    margin-right: 9.25rem !important; }
  .mb-sm-9q1,
  .my-sm-9q1 {
    margin-bottom: 9.25rem !important; }
  .ml-sm-9q1,
  .mx-sm-9q1 {
    margin-left: 9.25rem !important; }
  .m-sm-9q2 {
    margin: 9.5rem !important; }
  .mt-sm-9q2,
  .my-sm-9q2 {
    margin-top: 9.5rem !important; }
  .mr-sm-9q2,
  .mx-sm-9q2 {
    margin-right: 9.5rem !important; }
  .mb-sm-9q2,
  .my-sm-9q2 {
    margin-bottom: 9.5rem !important; }
  .ml-sm-9q2,
  .mx-sm-9q2 {
    margin-left: 9.5rem !important; }
  .m-sm-9q3 {
    margin: 9.75rem !important; }
  .mt-sm-9q3,
  .my-sm-9q3 {
    margin-top: 9.75rem !important; }
  .mr-sm-9q3,
  .mx-sm-9q3 {
    margin-right: 9.75rem !important; }
  .mb-sm-9q3,
  .my-sm-9q3 {
    margin-bottom: 9.75rem !important; }
  .ml-sm-9q3,
  .mx-sm-9q3 {
    margin-left: 9.75rem !important; }
  .m-sm-10 {
    margin: 10rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10rem !important; }
  .m-sm-10q1 {
    margin: 10.25rem !important; }
  .mt-sm-10q1,
  .my-sm-10q1 {
    margin-top: 10.25rem !important; }
  .mr-sm-10q1,
  .mx-sm-10q1 {
    margin-right: 10.25rem !important; }
  .mb-sm-10q1,
  .my-sm-10q1 {
    margin-bottom: 10.25rem !important; }
  .ml-sm-10q1,
  .mx-sm-10q1 {
    margin-left: 10.25rem !important; }
  .m-sm-10q2 {
    margin: 10.5rem !important; }
  .mt-sm-10q2,
  .my-sm-10q2 {
    margin-top: 10.5rem !important; }
  .mr-sm-10q2,
  .mx-sm-10q2 {
    margin-right: 10.5rem !important; }
  .mb-sm-10q2,
  .my-sm-10q2 {
    margin-bottom: 10.5rem !important; }
  .ml-sm-10q2,
  .mx-sm-10q2 {
    margin-left: 10.5rem !important; }
  .m-sm-10q3 {
    margin: 10.75rem !important; }
  .mt-sm-10q3,
  .my-sm-10q3 {
    margin-top: 10.75rem !important; }
  .mr-sm-10q3,
  .mx-sm-10q3 {
    margin-right: 10.75rem !important; }
  .mb-sm-10q3,
  .my-sm-10q3 {
    margin-bottom: 10.75rem !important; }
  .ml-sm-10q3,
  .mx-sm-10q3 {
    margin-left: 10.75rem !important; }
  .m-sm-11 {
    margin: 11rem !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 11rem !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 11rem !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 11rem !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 11rem !important; }
  .m-sm-11q1 {
    margin: 11.25rem !important; }
  .mt-sm-11q1,
  .my-sm-11q1 {
    margin-top: 11.25rem !important; }
  .mr-sm-11q1,
  .mx-sm-11q1 {
    margin-right: 11.25rem !important; }
  .mb-sm-11q1,
  .my-sm-11q1 {
    margin-bottom: 11.25rem !important; }
  .ml-sm-11q1,
  .mx-sm-11q1 {
    margin-left: 11.25rem !important; }
  .m-sm-11 q2 {
    margin: 11.5rem !important; }
  .mt-sm-11 q2,
  .my-sm-11 q2 {
    margin-top: 11.5rem !important; }
  .mr-sm-11 q2,
  .mx-sm-11 q2 {
    margin-right: 11.5rem !important; }
  .mb-sm-11 q2,
  .my-sm-11 q2 {
    margin-bottom: 11.5rem !important; }
  .ml-sm-11 q2,
  .mx-sm-11 q2 {
    margin-left: 11.5rem !important; }
  .m-sm-11 q3 {
    margin: 11.75rem !important; }
  .mt-sm-11 q3,
  .my-sm-11 q3 {
    margin-top: 11.75rem !important; }
  .mr-sm-11 q3,
  .mx-sm-11 q3 {
    margin-right: 11.75rem !important; }
  .mb-sm-11 q3,
  .my-sm-11 q3 {
    margin-bottom: 11.75rem !important; }
  .ml-sm-11 q3,
  .mx-sm-11 q3 {
    margin-left: 11.75rem !important; }
  .m-sm-12 {
    margin: 12rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 12rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 12rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 12rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 12rem !important; }
  .m-sm-12 q1 {
    margin: 12.25rem !important; }
  .mt-sm-12 q1,
  .my-sm-12 q1 {
    margin-top: 12.25rem !important; }
  .mr-sm-12 q1,
  .mx-sm-12 q1 {
    margin-right: 12.25rem !important; }
  .mb-sm-12 q1,
  .my-sm-12 q1 {
    margin-bottom: 12.25rem !important; }
  .ml-sm-12 q1,
  .mx-sm-12 q1 {
    margin-left: 12.25rem !important; }
  .m-sm-12 q2 {
    margin: 12.5rem !important; }
  .mt-sm-12 q2,
  .my-sm-12 q2 {
    margin-top: 12.5rem !important; }
  .mr-sm-12 q2,
  .mx-sm-12 q2 {
    margin-right: 12.5rem !important; }
  .mb-sm-12 q2,
  .my-sm-12 q2 {
    margin-bottom: 12.5rem !important; }
  .ml-sm-12 q2,
  .mx-sm-12 q2 {
    margin-left: 12.5rem !important; }
  .m-sm-12 q3 {
    margin: 12.75rem !important; }
  .mt-sm-12 q3,
  .my-sm-12 q3 {
    margin-top: 12.75rem !important; }
  .mr-sm-12 q3,
  .mx-sm-12 q3 {
    margin-right: 12.75rem !important; }
  .mb-sm-12 q3,
  .my-sm-12 q3 {
    margin-bottom: 12.75rem !important; }
  .ml-sm-12 q3,
  .mx-sm-12 q3 {
    margin-left: 12.75rem !important; }
  .p-sm-q1 {
    padding: 0.25rem !important; }
  .pt-sm-q1,
  .py-sm-q1 {
    padding-top: 0.25rem !important; }
  .pr-sm-q1,
  .px-sm-q1 {
    padding-right: 0.25rem !important; }
  .pb-sm-q1,
  .py-sm-q1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-q1,
  .px-sm-q1 {
    padding-left: 0.25rem !important; }
  .p-sm-q2 {
    padding: 0.5rem !important; }
  .pt-sm-q2,
  .py-sm-q2 {
    padding-top: 0.5rem !important; }
  .pr-sm-q2,
  .px-sm-q2 {
    padding-right: 0.5rem !important; }
  .pb-sm-q2,
  .py-sm-q2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-q2,
  .px-sm-q2 {
    padding-left: 0.5rem !important; }
  .p-sm-q3 {
    padding: 0.75rem !important; }
  .pt-sm-q3,
  .py-sm-q3 {
    padding-top: 0.75rem !important; }
  .pr-sm-q3,
  .px-sm-q3 {
    padding-right: 0.75rem !important; }
  .pb-sm-q3,
  .py-sm-q3 {
    padding-bottom: 0.75rem !important; }
  .pl-sm-q3,
  .px-sm-q3 {
    padding-left: 0.75rem !important; }
  .p-sm-0 {
    padding: 0rem !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0rem !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0rem !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0rem !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0rem !important; }
  .p-sm-1 {
    padding: 1rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 1rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 1rem !important; }
  .p-sm-1q1 {
    padding: 1.25rem !important; }
  .pt-sm-1q1,
  .py-sm-1q1 {
    padding-top: 1.25rem !important; }
  .pr-sm-1q1,
  .px-sm-1q1 {
    padding-right: 1.25rem !important; }
  .pb-sm-1q1,
  .py-sm-1q1 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-1q1,
  .px-sm-1q1 {
    padding-left: 1.25rem !important; }
  .p-sm-1q2 {
    padding: 1.5rem !important; }
  .pt-sm-1q2,
  .py-sm-1q2 {
    padding-top: 1.5rem !important; }
  .pr-sm-1q2,
  .px-sm-1q2 {
    padding-right: 1.5rem !important; }
  .pb-sm-1q2,
  .py-sm-1q2 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-1q2,
  .px-sm-1q2 {
    padding-left: 1.5rem !important; }
  .p-sm-1q3 {
    padding: 1.75rem !important; }
  .pt-sm-1q3,
  .py-sm-1q3 {
    padding-top: 1.75rem !important; }
  .pr-sm-1q3,
  .px-sm-1q3 {
    padding-right: 1.75rem !important; }
  .pb-sm-1q3,
  .py-sm-1q3 {
    padding-bottom: 1.75rem !important; }
  .pl-sm-1q3,
  .px-sm-1q3 {
    padding-left: 1.75rem !important; }
  .p-sm-2 {
    padding: 2rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 2rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 2rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 2rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 2rem !important; }
  .p-sm-2q1 {
    padding: 2.25rem !important; }
  .pt-sm-2q1,
  .py-sm-2q1 {
    padding-top: 2.25rem !important; }
  .pr-sm-2q1,
  .px-sm-2q1 {
    padding-right: 2.25rem !important; }
  .pb-sm-2q1,
  .py-sm-2q1 {
    padding-bottom: 2.25rem !important; }
  .pl-sm-2q1,
  .px-sm-2q1 {
    padding-left: 2.25rem !important; }
  .p-sm-2q2 {
    padding: 2.5rem !important; }
  .pt-sm-2q2,
  .py-sm-2q2 {
    padding-top: 2.5rem !important; }
  .pr-sm-2q2,
  .px-sm-2q2 {
    padding-right: 2.5rem !important; }
  .pb-sm-2q2,
  .py-sm-2q2 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-2q2,
  .px-sm-2q2 {
    padding-left: 2.5rem !important; }
  .p-sm-2q3 {
    padding: 2.75rem !important; }
  .pt-sm-2q3,
  .py-sm-2q3 {
    padding-top: 2.75rem !important; }
  .pr-sm-2q3,
  .px-sm-2q3 {
    padding-right: 2.75rem !important; }
  .pb-sm-2q3,
  .py-sm-2q3 {
    padding-bottom: 2.75rem !important; }
  .pl-sm-2q3,
  .px-sm-2q3 {
    padding-left: 2.75rem !important; }
  .p-sm-3 {
    padding: 3rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 3rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 3rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 3rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 3rem !important; }
  .p-sm-3q1 {
    padding: 3.25rem !important; }
  .pt-sm-3q1,
  .py-sm-3q1 {
    padding-top: 3.25rem !important; }
  .pr-sm-3q1,
  .px-sm-3q1 {
    padding-right: 3.25rem !important; }
  .pb-sm-3q1,
  .py-sm-3q1 {
    padding-bottom: 3.25rem !important; }
  .pl-sm-3q1,
  .px-sm-3q1 {
    padding-left: 3.25rem !important; }
  .p-sm-3q2 {
    padding: 3.5rem !important; }
  .pt-sm-3q2,
  .py-sm-3q2 {
    padding-top: 3.5rem !important; }
  .pr-sm-3q2,
  .px-sm-3q2 {
    padding-right: 3.5rem !important; }
  .pb-sm-3q2,
  .py-sm-3q2 {
    padding-bottom: 3.5rem !important; }
  .pl-sm-3q2,
  .px-sm-3q2 {
    padding-left: 3.5rem !important; }
  .p-sm-3q3 {
    padding: 3.75rem !important; }
  .pt-sm-3q3,
  .py-sm-3q3 {
    padding-top: 3.75rem !important; }
  .pr-sm-3q3,
  .px-sm-3q3 {
    padding-right: 3.75rem !important; }
  .pb-sm-3q3,
  .py-sm-3q3 {
    padding-bottom: 3.75rem !important; }
  .pl-sm-3q3,
  .px-sm-3q3 {
    padding-left: 3.75rem !important; }
  .p-sm-4 {
    padding: 4rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 4rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 4rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 4rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 4rem !important; }
  .p-sm-4q1 {
    padding: 4.25rem !important; }
  .pt-sm-4q1,
  .py-sm-4q1 {
    padding-top: 4.25rem !important; }
  .pr-sm-4q1,
  .px-sm-4q1 {
    padding-right: 4.25rem !important; }
  .pb-sm-4q1,
  .py-sm-4q1 {
    padding-bottom: 4.25rem !important; }
  .pl-sm-4q1,
  .px-sm-4q1 {
    padding-left: 4.25rem !important; }
  .p-sm-4q2 {
    padding: 4.5rem !important; }
  .pt-sm-4q2,
  .py-sm-4q2 {
    padding-top: 4.5rem !important; }
  .pr-sm-4q2,
  .px-sm-4q2 {
    padding-right: 4.5rem !important; }
  .pb-sm-4q2,
  .py-sm-4q2 {
    padding-bottom: 4.5rem !important; }
  .pl-sm-4q2,
  .px-sm-4q2 {
    padding-left: 4.5rem !important; }
  .p-sm-4q3 {
    padding: 4.75rem !important; }
  .pt-sm-4q3,
  .py-sm-4q3 {
    padding-top: 4.75rem !important; }
  .pr-sm-4q3,
  .px-sm-4q3 {
    padding-right: 4.75rem !important; }
  .pb-sm-4q3,
  .py-sm-4q3 {
    padding-bottom: 4.75rem !important; }
  .pl-sm-4q3,
  .px-sm-4q3 {
    padding-left: 4.75rem !important; }
  .p-sm-5 {
    padding: 5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5rem !important; }
  .p-sm-5q1 {
    padding: 5.25rem !important; }
  .pt-sm-5q1,
  .py-sm-5q1 {
    padding-top: 5.25rem !important; }
  .pr-sm-5q1,
  .px-sm-5q1 {
    padding-right: 5.25rem !important; }
  .pb-sm-5q1,
  .py-sm-5q1 {
    padding-bottom: 5.25rem !important; }
  .pl-sm-5q1,
  .px-sm-5q1 {
    padding-left: 5.25rem !important; }
  .p-sm-5q2 {
    padding: 5.5rem !important; }
  .pt-sm-5q2,
  .py-sm-5q2 {
    padding-top: 5.5rem !important; }
  .pr-sm-5q2,
  .px-sm-5q2 {
    padding-right: 5.5rem !important; }
  .pb-sm-5q2,
  .py-sm-5q2 {
    padding-bottom: 5.5rem !important; }
  .pl-sm-5q2,
  .px-sm-5q2 {
    padding-left: 5.5rem !important; }
  .p-sm-5q3 {
    padding: 5.75rem !important; }
  .pt-sm-5q3,
  .py-sm-5q3 {
    padding-top: 5.75rem !important; }
  .pr-sm-5q3,
  .px-sm-5q3 {
    padding-right: 5.75rem !important; }
  .pb-sm-5q3,
  .py-sm-5q3 {
    padding-bottom: 5.75rem !important; }
  .pl-sm-5q3,
  .px-sm-5q3 {
    padding-left: 5.75rem !important; }
  .p-sm-6 {
    padding: 6rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 6rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 6rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 6rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 6rem !important; }
  .p-sm-6q1 {
    padding: 6.25rem !important; }
  .pt-sm-6q1,
  .py-sm-6q1 {
    padding-top: 6.25rem !important; }
  .pr-sm-6q1,
  .px-sm-6q1 {
    padding-right: 6.25rem !important; }
  .pb-sm-6q1,
  .py-sm-6q1 {
    padding-bottom: 6.25rem !important; }
  .pl-sm-6q1,
  .px-sm-6q1 {
    padding-left: 6.25rem !important; }
  .p-sm-6q2 {
    padding: 6.5rem !important; }
  .pt-sm-6q2,
  .py-sm-6q2 {
    padding-top: 6.5rem !important; }
  .pr-sm-6q2,
  .px-sm-6q2 {
    padding-right: 6.5rem !important; }
  .pb-sm-6q2,
  .py-sm-6q2 {
    padding-bottom: 6.5rem !important; }
  .pl-sm-6q2,
  .px-sm-6q2 {
    padding-left: 6.5rem !important; }
  .p-sm-6q3 {
    padding: 6.75rem !important; }
  .pt-sm-6q3,
  .py-sm-6q3 {
    padding-top: 6.75rem !important; }
  .pr-sm-6q3,
  .px-sm-6q3 {
    padding-right: 6.75rem !important; }
  .pb-sm-6q3,
  .py-sm-6q3 {
    padding-bottom: 6.75rem !important; }
  .pl-sm-6q3,
  .px-sm-6q3 {
    padding-left: 6.75rem !important; }
  .p-sm-7 {
    padding: 7rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 7rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 7rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 7rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 7rem !important; }
  .p-sm-7q1 {
    padding: 7.25rem !important; }
  .pt-sm-7q1,
  .py-sm-7q1 {
    padding-top: 7.25rem !important; }
  .pr-sm-7q1,
  .px-sm-7q1 {
    padding-right: 7.25rem !important; }
  .pb-sm-7q1,
  .py-sm-7q1 {
    padding-bottom: 7.25rem !important; }
  .pl-sm-7q1,
  .px-sm-7q1 {
    padding-left: 7.25rem !important; }
  .p-sm-7q2 {
    padding: 7.5rem !important; }
  .pt-sm-7q2,
  .py-sm-7q2 {
    padding-top: 7.5rem !important; }
  .pr-sm-7q2,
  .px-sm-7q2 {
    padding-right: 7.5rem !important; }
  .pb-sm-7q2,
  .py-sm-7q2 {
    padding-bottom: 7.5rem !important; }
  .pl-sm-7q2,
  .px-sm-7q2 {
    padding-left: 7.5rem !important; }
  .p-sm-7q3 {
    padding: 7.75rem !important; }
  .pt-sm-7q3,
  .py-sm-7q3 {
    padding-top: 7.75rem !important; }
  .pr-sm-7q3,
  .px-sm-7q3 {
    padding-right: 7.75rem !important; }
  .pb-sm-7q3,
  .py-sm-7q3 {
    padding-bottom: 7.75rem !important; }
  .pl-sm-7q3,
  .px-sm-7q3 {
    padding-left: 7.75rem !important; }
  .p-sm-8 {
    padding: 8rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 8rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 8rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 8rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 8rem !important; }
  .p-sm-8q1 {
    padding: 8.25rem !important; }
  .pt-sm-8q1,
  .py-sm-8q1 {
    padding-top: 8.25rem !important; }
  .pr-sm-8q1,
  .px-sm-8q1 {
    padding-right: 8.25rem !important; }
  .pb-sm-8q1,
  .py-sm-8q1 {
    padding-bottom: 8.25rem !important; }
  .pl-sm-8q1,
  .px-sm-8q1 {
    padding-left: 8.25rem !important; }
  .p-sm-8q2 {
    padding: 8.5rem !important; }
  .pt-sm-8q2,
  .py-sm-8q2 {
    padding-top: 8.5rem !important; }
  .pr-sm-8q2,
  .px-sm-8q2 {
    padding-right: 8.5rem !important; }
  .pb-sm-8q2,
  .py-sm-8q2 {
    padding-bottom: 8.5rem !important; }
  .pl-sm-8q2,
  .px-sm-8q2 {
    padding-left: 8.5rem !important; }
  .p-sm-8q3 {
    padding: 8.75rem !important; }
  .pt-sm-8q3,
  .py-sm-8q3 {
    padding-top: 8.75rem !important; }
  .pr-sm-8q3,
  .px-sm-8q3 {
    padding-right: 8.75rem !important; }
  .pb-sm-8q3,
  .py-sm-8q3 {
    padding-bottom: 8.75rem !important; }
  .pl-sm-8q3,
  .px-sm-8q3 {
    padding-left: 8.75rem !important; }
  .p-sm-9 {
    padding: 9rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 9rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 9rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 9rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 9rem !important; }
  .p-sm-9q1 {
    padding: 9.25rem !important; }
  .pt-sm-9q1,
  .py-sm-9q1 {
    padding-top: 9.25rem !important; }
  .pr-sm-9q1,
  .px-sm-9q1 {
    padding-right: 9.25rem !important; }
  .pb-sm-9q1,
  .py-sm-9q1 {
    padding-bottom: 9.25rem !important; }
  .pl-sm-9q1,
  .px-sm-9q1 {
    padding-left: 9.25rem !important; }
  .p-sm-9q2 {
    padding: 9.5rem !important; }
  .pt-sm-9q2,
  .py-sm-9q2 {
    padding-top: 9.5rem !important; }
  .pr-sm-9q2,
  .px-sm-9q2 {
    padding-right: 9.5rem !important; }
  .pb-sm-9q2,
  .py-sm-9q2 {
    padding-bottom: 9.5rem !important; }
  .pl-sm-9q2,
  .px-sm-9q2 {
    padding-left: 9.5rem !important; }
  .p-sm-9q3 {
    padding: 9.75rem !important; }
  .pt-sm-9q3,
  .py-sm-9q3 {
    padding-top: 9.75rem !important; }
  .pr-sm-9q3,
  .px-sm-9q3 {
    padding-right: 9.75rem !important; }
  .pb-sm-9q3,
  .py-sm-9q3 {
    padding-bottom: 9.75rem !important; }
  .pl-sm-9q3,
  .px-sm-9q3 {
    padding-left: 9.75rem !important; }
  .p-sm-10 {
    padding: 10rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10rem !important; }
  .p-sm-10q1 {
    padding: 10.25rem !important; }
  .pt-sm-10q1,
  .py-sm-10q1 {
    padding-top: 10.25rem !important; }
  .pr-sm-10q1,
  .px-sm-10q1 {
    padding-right: 10.25rem !important; }
  .pb-sm-10q1,
  .py-sm-10q1 {
    padding-bottom: 10.25rem !important; }
  .pl-sm-10q1,
  .px-sm-10q1 {
    padding-left: 10.25rem !important; }
  .p-sm-10q2 {
    padding: 10.5rem !important; }
  .pt-sm-10q2,
  .py-sm-10q2 {
    padding-top: 10.5rem !important; }
  .pr-sm-10q2,
  .px-sm-10q2 {
    padding-right: 10.5rem !important; }
  .pb-sm-10q2,
  .py-sm-10q2 {
    padding-bottom: 10.5rem !important; }
  .pl-sm-10q2,
  .px-sm-10q2 {
    padding-left: 10.5rem !important; }
  .p-sm-10q3 {
    padding: 10.75rem !important; }
  .pt-sm-10q3,
  .py-sm-10q3 {
    padding-top: 10.75rem !important; }
  .pr-sm-10q3,
  .px-sm-10q3 {
    padding-right: 10.75rem !important; }
  .pb-sm-10q3,
  .py-sm-10q3 {
    padding-bottom: 10.75rem !important; }
  .pl-sm-10q3,
  .px-sm-10q3 {
    padding-left: 10.75rem !important; }
  .p-sm-11 {
    padding: 11rem !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 11rem !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 11rem !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 11rem !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 11rem !important; }
  .p-sm-11q1 {
    padding: 11.25rem !important; }
  .pt-sm-11q1,
  .py-sm-11q1 {
    padding-top: 11.25rem !important; }
  .pr-sm-11q1,
  .px-sm-11q1 {
    padding-right: 11.25rem !important; }
  .pb-sm-11q1,
  .py-sm-11q1 {
    padding-bottom: 11.25rem !important; }
  .pl-sm-11q1,
  .px-sm-11q1 {
    padding-left: 11.25rem !important; }
  .p-sm-11 q2 {
    padding: 11.5rem !important; }
  .pt-sm-11 q2,
  .py-sm-11 q2 {
    padding-top: 11.5rem !important; }
  .pr-sm-11 q2,
  .px-sm-11 q2 {
    padding-right: 11.5rem !important; }
  .pb-sm-11 q2,
  .py-sm-11 q2 {
    padding-bottom: 11.5rem !important; }
  .pl-sm-11 q2,
  .px-sm-11 q2 {
    padding-left: 11.5rem !important; }
  .p-sm-11 q3 {
    padding: 11.75rem !important; }
  .pt-sm-11 q3,
  .py-sm-11 q3 {
    padding-top: 11.75rem !important; }
  .pr-sm-11 q3,
  .px-sm-11 q3 {
    padding-right: 11.75rem !important; }
  .pb-sm-11 q3,
  .py-sm-11 q3 {
    padding-bottom: 11.75rem !important; }
  .pl-sm-11 q3,
  .px-sm-11 q3 {
    padding-left: 11.75rem !important; }
  .p-sm-12 {
    padding: 12rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 12rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 12rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 12rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 12rem !important; }
  .p-sm-12 q1 {
    padding: 12.25rem !important; }
  .pt-sm-12 q1,
  .py-sm-12 q1 {
    padding-top: 12.25rem !important; }
  .pr-sm-12 q1,
  .px-sm-12 q1 {
    padding-right: 12.25rem !important; }
  .pb-sm-12 q1,
  .py-sm-12 q1 {
    padding-bottom: 12.25rem !important; }
  .pl-sm-12 q1,
  .px-sm-12 q1 {
    padding-left: 12.25rem !important; }
  .p-sm-12 q2 {
    padding: 12.5rem !important; }
  .pt-sm-12 q2,
  .py-sm-12 q2 {
    padding-top: 12.5rem !important; }
  .pr-sm-12 q2,
  .px-sm-12 q2 {
    padding-right: 12.5rem !important; }
  .pb-sm-12 q2,
  .py-sm-12 q2 {
    padding-bottom: 12.5rem !important; }
  .pl-sm-12 q2,
  .px-sm-12 q2 {
    padding-left: 12.5rem !important; }
  .p-sm-12 q3 {
    padding: 12.75rem !important; }
  .pt-sm-12 q3,
  .py-sm-12 q3 {
    padding-top: 12.75rem !important; }
  .pr-sm-12 q3,
  .px-sm-12 q3 {
    padding-right: 12.75rem !important; }
  .pb-sm-12 q3,
  .py-sm-12 q3 {
    padding-bottom: 12.75rem !important; }
  .pl-sm-12 q3,
  .px-sm-12 q3 {
    padding-left: 12.75rem !important; }
  .m-sm-nq1 {
    margin: -0.25rem !important; }
  .mt-sm-nq1,
  .my-sm-nq1 {
    margin-top: -0.25rem !important; }
  .mr-sm-nq1,
  .mx-sm-nq1 {
    margin-right: -0.25rem !important; }
  .mb-sm-nq1,
  .my-sm-nq1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-nq1,
  .mx-sm-nq1 {
    margin-left: -0.25rem !important; }
  .m-sm-nq2 {
    margin: -0.5rem !important; }
  .mt-sm-nq2,
  .my-sm-nq2 {
    margin-top: -0.5rem !important; }
  .mr-sm-nq2,
  .mx-sm-nq2 {
    margin-right: -0.5rem !important; }
  .mb-sm-nq2,
  .my-sm-nq2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-nq2,
  .mx-sm-nq2 {
    margin-left: -0.5rem !important; }
  .m-sm-nq3 {
    margin: -0.75rem !important; }
  .mt-sm-nq3,
  .my-sm-nq3 {
    margin-top: -0.75rem !important; }
  .mr-sm-nq3,
  .mx-sm-nq3 {
    margin-right: -0.75rem !important; }
  .mb-sm-nq3,
  .my-sm-nq3 {
    margin-bottom: -0.75rem !important; }
  .ml-sm-nq3,
  .mx-sm-nq3 {
    margin-left: -0.75rem !important; }
  .m-sm-n1 {
    margin: -1rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -1rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -1rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -1rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -1rem !important; }
  .m-sm-n1q1 {
    margin: -1.25rem !important; }
  .mt-sm-n1q1,
  .my-sm-n1q1 {
    margin-top: -1.25rem !important; }
  .mr-sm-n1q1,
  .mx-sm-n1q1 {
    margin-right: -1.25rem !important; }
  .mb-sm-n1q1,
  .my-sm-n1q1 {
    margin-bottom: -1.25rem !important; }
  .ml-sm-n1q1,
  .mx-sm-n1q1 {
    margin-left: -1.25rem !important; }
  .m-sm-n1q2 {
    margin: -1.5rem !important; }
  .mt-sm-n1q2,
  .my-sm-n1q2 {
    margin-top: -1.5rem !important; }
  .mr-sm-n1q2,
  .mx-sm-n1q2 {
    margin-right: -1.5rem !important; }
  .mb-sm-n1q2,
  .my-sm-n1q2 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n1q2,
  .mx-sm-n1q2 {
    margin-left: -1.5rem !important; }
  .m-sm-n1q3 {
    margin: -1.75rem !important; }
  .mt-sm-n1q3,
  .my-sm-n1q3 {
    margin-top: -1.75rem !important; }
  .mr-sm-n1q3,
  .mx-sm-n1q3 {
    margin-right: -1.75rem !important; }
  .mb-sm-n1q3,
  .my-sm-n1q3 {
    margin-bottom: -1.75rem !important; }
  .ml-sm-n1q3,
  .mx-sm-n1q3 {
    margin-left: -1.75rem !important; }
  .m-sm-n2 {
    margin: -2rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -2rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -2rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -2rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -2rem !important; }
  .m-sm-n2q1 {
    margin: -2.25rem !important; }
  .mt-sm-n2q1,
  .my-sm-n2q1 {
    margin-top: -2.25rem !important; }
  .mr-sm-n2q1,
  .mx-sm-n2q1 {
    margin-right: -2.25rem !important; }
  .mb-sm-n2q1,
  .my-sm-n2q1 {
    margin-bottom: -2.25rem !important; }
  .ml-sm-n2q1,
  .mx-sm-n2q1 {
    margin-left: -2.25rem !important; }
  .m-sm-n2q2 {
    margin: -2.5rem !important; }
  .mt-sm-n2q2,
  .my-sm-n2q2 {
    margin-top: -2.5rem !important; }
  .mr-sm-n2q2,
  .mx-sm-n2q2 {
    margin-right: -2.5rem !important; }
  .mb-sm-n2q2,
  .my-sm-n2q2 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n2q2,
  .mx-sm-n2q2 {
    margin-left: -2.5rem !important; }
  .m-sm-n2q3 {
    margin: -2.75rem !important; }
  .mt-sm-n2q3,
  .my-sm-n2q3 {
    margin-top: -2.75rem !important; }
  .mr-sm-n2q3,
  .mx-sm-n2q3 {
    margin-right: -2.75rem !important; }
  .mb-sm-n2q3,
  .my-sm-n2q3 {
    margin-bottom: -2.75rem !important; }
  .ml-sm-n2q3,
  .mx-sm-n2q3 {
    margin-left: -2.75rem !important; }
  .m-sm-n3 {
    margin: -3rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -3rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -3rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -3rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -3rem !important; }
  .m-sm-n3q1 {
    margin: -3.25rem !important; }
  .mt-sm-n3q1,
  .my-sm-n3q1 {
    margin-top: -3.25rem !important; }
  .mr-sm-n3q1,
  .mx-sm-n3q1 {
    margin-right: -3.25rem !important; }
  .mb-sm-n3q1,
  .my-sm-n3q1 {
    margin-bottom: -3.25rem !important; }
  .ml-sm-n3q1,
  .mx-sm-n3q1 {
    margin-left: -3.25rem !important; }
  .m-sm-n3q2 {
    margin: -3.5rem !important; }
  .mt-sm-n3q2,
  .my-sm-n3q2 {
    margin-top: -3.5rem !important; }
  .mr-sm-n3q2,
  .mx-sm-n3q2 {
    margin-right: -3.5rem !important; }
  .mb-sm-n3q2,
  .my-sm-n3q2 {
    margin-bottom: -3.5rem !important; }
  .ml-sm-n3q2,
  .mx-sm-n3q2 {
    margin-left: -3.5rem !important; }
  .m-sm-n3q3 {
    margin: -3.75rem !important; }
  .mt-sm-n3q3,
  .my-sm-n3q3 {
    margin-top: -3.75rem !important; }
  .mr-sm-n3q3,
  .mx-sm-n3q3 {
    margin-right: -3.75rem !important; }
  .mb-sm-n3q3,
  .my-sm-n3q3 {
    margin-bottom: -3.75rem !important; }
  .ml-sm-n3q3,
  .mx-sm-n3q3 {
    margin-left: -3.75rem !important; }
  .m-sm-n4 {
    margin: -4rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -4rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -4rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -4rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -4rem !important; }
  .m-sm-n4q1 {
    margin: -4.25rem !important; }
  .mt-sm-n4q1,
  .my-sm-n4q1 {
    margin-top: -4.25rem !important; }
  .mr-sm-n4q1,
  .mx-sm-n4q1 {
    margin-right: -4.25rem !important; }
  .mb-sm-n4q1,
  .my-sm-n4q1 {
    margin-bottom: -4.25rem !important; }
  .ml-sm-n4q1,
  .mx-sm-n4q1 {
    margin-left: -4.25rem !important; }
  .m-sm-n4q2 {
    margin: -4.5rem !important; }
  .mt-sm-n4q2,
  .my-sm-n4q2 {
    margin-top: -4.5rem !important; }
  .mr-sm-n4q2,
  .mx-sm-n4q2 {
    margin-right: -4.5rem !important; }
  .mb-sm-n4q2,
  .my-sm-n4q2 {
    margin-bottom: -4.5rem !important; }
  .ml-sm-n4q2,
  .mx-sm-n4q2 {
    margin-left: -4.5rem !important; }
  .m-sm-n4q3 {
    margin: -4.75rem !important; }
  .mt-sm-n4q3,
  .my-sm-n4q3 {
    margin-top: -4.75rem !important; }
  .mr-sm-n4q3,
  .mx-sm-n4q3 {
    margin-right: -4.75rem !important; }
  .mb-sm-n4q3,
  .my-sm-n4q3 {
    margin-bottom: -4.75rem !important; }
  .ml-sm-n4q3,
  .mx-sm-n4q3 {
    margin-left: -4.75rem !important; }
  .m-sm-n5 {
    margin: -5rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -5rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -5rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -5rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -5rem !important; }
  .m-sm-n5q1 {
    margin: -5.25rem !important; }
  .mt-sm-n5q1,
  .my-sm-n5q1 {
    margin-top: -5.25rem !important; }
  .mr-sm-n5q1,
  .mx-sm-n5q1 {
    margin-right: -5.25rem !important; }
  .mb-sm-n5q1,
  .my-sm-n5q1 {
    margin-bottom: -5.25rem !important; }
  .ml-sm-n5q1,
  .mx-sm-n5q1 {
    margin-left: -5.25rem !important; }
  .m-sm-n5q2 {
    margin: -5.5rem !important; }
  .mt-sm-n5q2,
  .my-sm-n5q2 {
    margin-top: -5.5rem !important; }
  .mr-sm-n5q2,
  .mx-sm-n5q2 {
    margin-right: -5.5rem !important; }
  .mb-sm-n5q2,
  .my-sm-n5q2 {
    margin-bottom: -5.5rem !important; }
  .ml-sm-n5q2,
  .mx-sm-n5q2 {
    margin-left: -5.5rem !important; }
  .m-sm-n5q3 {
    margin: -5.75rem !important; }
  .mt-sm-n5q3,
  .my-sm-n5q3 {
    margin-top: -5.75rem !important; }
  .mr-sm-n5q3,
  .mx-sm-n5q3 {
    margin-right: -5.75rem !important; }
  .mb-sm-n5q3,
  .my-sm-n5q3 {
    margin-bottom: -5.75rem !important; }
  .ml-sm-n5q3,
  .mx-sm-n5q3 {
    margin-left: -5.75rem !important; }
  .m-sm-n6 {
    margin: -6rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -6rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -6rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -6rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -6rem !important; }
  .m-sm-n6q1 {
    margin: -6.25rem !important; }
  .mt-sm-n6q1,
  .my-sm-n6q1 {
    margin-top: -6.25rem !important; }
  .mr-sm-n6q1,
  .mx-sm-n6q1 {
    margin-right: -6.25rem !important; }
  .mb-sm-n6q1,
  .my-sm-n6q1 {
    margin-bottom: -6.25rem !important; }
  .ml-sm-n6q1,
  .mx-sm-n6q1 {
    margin-left: -6.25rem !important; }
  .m-sm-n6q2 {
    margin: -6.5rem !important; }
  .mt-sm-n6q2,
  .my-sm-n6q2 {
    margin-top: -6.5rem !important; }
  .mr-sm-n6q2,
  .mx-sm-n6q2 {
    margin-right: -6.5rem !important; }
  .mb-sm-n6q2,
  .my-sm-n6q2 {
    margin-bottom: -6.5rem !important; }
  .ml-sm-n6q2,
  .mx-sm-n6q2 {
    margin-left: -6.5rem !important; }
  .m-sm-n6q3 {
    margin: -6.75rem !important; }
  .mt-sm-n6q3,
  .my-sm-n6q3 {
    margin-top: -6.75rem !important; }
  .mr-sm-n6q3,
  .mx-sm-n6q3 {
    margin-right: -6.75rem !important; }
  .mb-sm-n6q3,
  .my-sm-n6q3 {
    margin-bottom: -6.75rem !important; }
  .ml-sm-n6q3,
  .mx-sm-n6q3 {
    margin-left: -6.75rem !important; }
  .m-sm-n7 {
    margin: -7rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -7rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -7rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -7rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -7rem !important; }
  .m-sm-n7q1 {
    margin: -7.25rem !important; }
  .mt-sm-n7q1,
  .my-sm-n7q1 {
    margin-top: -7.25rem !important; }
  .mr-sm-n7q1,
  .mx-sm-n7q1 {
    margin-right: -7.25rem !important; }
  .mb-sm-n7q1,
  .my-sm-n7q1 {
    margin-bottom: -7.25rem !important; }
  .ml-sm-n7q1,
  .mx-sm-n7q1 {
    margin-left: -7.25rem !important; }
  .m-sm-n7q2 {
    margin: -7.5rem !important; }
  .mt-sm-n7q2,
  .my-sm-n7q2 {
    margin-top: -7.5rem !important; }
  .mr-sm-n7q2,
  .mx-sm-n7q2 {
    margin-right: -7.5rem !important; }
  .mb-sm-n7q2,
  .my-sm-n7q2 {
    margin-bottom: -7.5rem !important; }
  .ml-sm-n7q2,
  .mx-sm-n7q2 {
    margin-left: -7.5rem !important; }
  .m-sm-n7q3 {
    margin: -7.75rem !important; }
  .mt-sm-n7q3,
  .my-sm-n7q3 {
    margin-top: -7.75rem !important; }
  .mr-sm-n7q3,
  .mx-sm-n7q3 {
    margin-right: -7.75rem !important; }
  .mb-sm-n7q3,
  .my-sm-n7q3 {
    margin-bottom: -7.75rem !important; }
  .ml-sm-n7q3,
  .mx-sm-n7q3 {
    margin-left: -7.75rem !important; }
  .m-sm-n8 {
    margin: -8rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -8rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -8rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -8rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -8rem !important; }
  .m-sm-n8q1 {
    margin: -8.25rem !important; }
  .mt-sm-n8q1,
  .my-sm-n8q1 {
    margin-top: -8.25rem !important; }
  .mr-sm-n8q1,
  .mx-sm-n8q1 {
    margin-right: -8.25rem !important; }
  .mb-sm-n8q1,
  .my-sm-n8q1 {
    margin-bottom: -8.25rem !important; }
  .ml-sm-n8q1,
  .mx-sm-n8q1 {
    margin-left: -8.25rem !important; }
  .m-sm-n8q2 {
    margin: -8.5rem !important; }
  .mt-sm-n8q2,
  .my-sm-n8q2 {
    margin-top: -8.5rem !important; }
  .mr-sm-n8q2,
  .mx-sm-n8q2 {
    margin-right: -8.5rem !important; }
  .mb-sm-n8q2,
  .my-sm-n8q2 {
    margin-bottom: -8.5rem !important; }
  .ml-sm-n8q2,
  .mx-sm-n8q2 {
    margin-left: -8.5rem !important; }
  .m-sm-n8q3 {
    margin: -8.75rem !important; }
  .mt-sm-n8q3,
  .my-sm-n8q3 {
    margin-top: -8.75rem !important; }
  .mr-sm-n8q3,
  .mx-sm-n8q3 {
    margin-right: -8.75rem !important; }
  .mb-sm-n8q3,
  .my-sm-n8q3 {
    margin-bottom: -8.75rem !important; }
  .ml-sm-n8q3,
  .mx-sm-n8q3 {
    margin-left: -8.75rem !important; }
  .m-sm-n9 {
    margin: -9rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -9rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -9rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -9rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -9rem !important; }
  .m-sm-n9q1 {
    margin: -9.25rem !important; }
  .mt-sm-n9q1,
  .my-sm-n9q1 {
    margin-top: -9.25rem !important; }
  .mr-sm-n9q1,
  .mx-sm-n9q1 {
    margin-right: -9.25rem !important; }
  .mb-sm-n9q1,
  .my-sm-n9q1 {
    margin-bottom: -9.25rem !important; }
  .ml-sm-n9q1,
  .mx-sm-n9q1 {
    margin-left: -9.25rem !important; }
  .m-sm-n9q2 {
    margin: -9.5rem !important; }
  .mt-sm-n9q2,
  .my-sm-n9q2 {
    margin-top: -9.5rem !important; }
  .mr-sm-n9q2,
  .mx-sm-n9q2 {
    margin-right: -9.5rem !important; }
  .mb-sm-n9q2,
  .my-sm-n9q2 {
    margin-bottom: -9.5rem !important; }
  .ml-sm-n9q2,
  .mx-sm-n9q2 {
    margin-left: -9.5rem !important; }
  .m-sm-n9q3 {
    margin: -9.75rem !important; }
  .mt-sm-n9q3,
  .my-sm-n9q3 {
    margin-top: -9.75rem !important; }
  .mr-sm-n9q3,
  .mx-sm-n9q3 {
    margin-right: -9.75rem !important; }
  .mb-sm-n9q3,
  .my-sm-n9q3 {
    margin-bottom: -9.75rem !important; }
  .ml-sm-n9q3,
  .mx-sm-n9q3 {
    margin-left: -9.75rem !important; }
  .m-sm-n10 {
    margin: -10rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -10rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -10rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -10rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -10rem !important; }
  .m-sm-n10q1 {
    margin: -10.25rem !important; }
  .mt-sm-n10q1,
  .my-sm-n10q1 {
    margin-top: -10.25rem !important; }
  .mr-sm-n10q1,
  .mx-sm-n10q1 {
    margin-right: -10.25rem !important; }
  .mb-sm-n10q1,
  .my-sm-n10q1 {
    margin-bottom: -10.25rem !important; }
  .ml-sm-n10q1,
  .mx-sm-n10q1 {
    margin-left: -10.25rem !important; }
  .m-sm-n10q2 {
    margin: -10.5rem !important; }
  .mt-sm-n10q2,
  .my-sm-n10q2 {
    margin-top: -10.5rem !important; }
  .mr-sm-n10q2,
  .mx-sm-n10q2 {
    margin-right: -10.5rem !important; }
  .mb-sm-n10q2,
  .my-sm-n10q2 {
    margin-bottom: -10.5rem !important; }
  .ml-sm-n10q2,
  .mx-sm-n10q2 {
    margin-left: -10.5rem !important; }
  .m-sm-n10q3 {
    margin: -10.75rem !important; }
  .mt-sm-n10q3,
  .my-sm-n10q3 {
    margin-top: -10.75rem !important; }
  .mr-sm-n10q3,
  .mx-sm-n10q3 {
    margin-right: -10.75rem !important; }
  .mb-sm-n10q3,
  .my-sm-n10q3 {
    margin-bottom: -10.75rem !important; }
  .ml-sm-n10q3,
  .mx-sm-n10q3 {
    margin-left: -10.75rem !important; }
  .m-sm-n11 {
    margin: -11rem !important; }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -11rem !important; }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -11rem !important; }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -11rem !important; }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -11rem !important; }
  .m-sm-n11q1 {
    margin: -11.25rem !important; }
  .mt-sm-n11q1,
  .my-sm-n11q1 {
    margin-top: -11.25rem !important; }
  .mr-sm-n11q1,
  .mx-sm-n11q1 {
    margin-right: -11.25rem !important; }
  .mb-sm-n11q1,
  .my-sm-n11q1 {
    margin-bottom: -11.25rem !important; }
  .ml-sm-n11q1,
  .mx-sm-n11q1 {
    margin-left: -11.25rem !important; }
  .m-sm-n11 q2 {
    margin: -11.5rem !important; }
  .mt-sm-n11 q2,
  .my-sm-n11 q2 {
    margin-top: -11.5rem !important; }
  .mr-sm-n11 q2,
  .mx-sm-n11 q2 {
    margin-right: -11.5rem !important; }
  .mb-sm-n11 q2,
  .my-sm-n11 q2 {
    margin-bottom: -11.5rem !important; }
  .ml-sm-n11 q2,
  .mx-sm-n11 q2 {
    margin-left: -11.5rem !important; }
  .m-sm-n11 q3 {
    margin: -11.75rem !important; }
  .mt-sm-n11 q3,
  .my-sm-n11 q3 {
    margin-top: -11.75rem !important; }
  .mr-sm-n11 q3,
  .mx-sm-n11 q3 {
    margin-right: -11.75rem !important; }
  .mb-sm-n11 q3,
  .my-sm-n11 q3 {
    margin-bottom: -11.75rem !important; }
  .ml-sm-n11 q3,
  .mx-sm-n11 q3 {
    margin-left: -11.75rem !important; }
  .m-sm-n12 {
    margin: -12rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -12rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -12rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -12rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -12rem !important; }
  .m-sm-n12 q1 {
    margin: -12.25rem !important; }
  .mt-sm-n12 q1,
  .my-sm-n12 q1 {
    margin-top: -12.25rem !important; }
  .mr-sm-n12 q1,
  .mx-sm-n12 q1 {
    margin-right: -12.25rem !important; }
  .mb-sm-n12 q1,
  .my-sm-n12 q1 {
    margin-bottom: -12.25rem !important; }
  .ml-sm-n12 q1,
  .mx-sm-n12 q1 {
    margin-left: -12.25rem !important; }
  .m-sm-n12 q2 {
    margin: -12.5rem !important; }
  .mt-sm-n12 q2,
  .my-sm-n12 q2 {
    margin-top: -12.5rem !important; }
  .mr-sm-n12 q2,
  .mx-sm-n12 q2 {
    margin-right: -12.5rem !important; }
  .mb-sm-n12 q2,
  .my-sm-n12 q2 {
    margin-bottom: -12.5rem !important; }
  .ml-sm-n12 q2,
  .mx-sm-n12 q2 {
    margin-left: -12.5rem !important; }
  .m-sm-n12 q3 {
    margin: -12.75rem !important; }
  .mt-sm-n12 q3,
  .my-sm-n12 q3 {
    margin-top: -12.75rem !important; }
  .mr-sm-n12 q3,
  .mx-sm-n12 q3 {
    margin-right: -12.75rem !important; }
  .mb-sm-n12 q3,
  .my-sm-n12 q3 {
    margin-bottom: -12.75rem !important; }
  .ml-sm-n12 q3,
  .mx-sm-n12 q3 {
    margin-left: -12.75rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 767px) {
  .m-md-q1 {
    margin: 0.25rem !important; }
  .mt-md-q1,
  .my-md-q1 {
    margin-top: 0.25rem !important; }
  .mr-md-q1,
  .mx-md-q1 {
    margin-right: 0.25rem !important; }
  .mb-md-q1,
  .my-md-q1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-q1,
  .mx-md-q1 {
    margin-left: 0.25rem !important; }
  .m-md-q2 {
    margin: 0.5rem !important; }
  .mt-md-q2,
  .my-md-q2 {
    margin-top: 0.5rem !important; }
  .mr-md-q2,
  .mx-md-q2 {
    margin-right: 0.5rem !important; }
  .mb-md-q2,
  .my-md-q2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-q2,
  .mx-md-q2 {
    margin-left: 0.5rem !important; }
  .m-md-q3 {
    margin: 0.75rem !important; }
  .mt-md-q3,
  .my-md-q3 {
    margin-top: 0.75rem !important; }
  .mr-md-q3,
  .mx-md-q3 {
    margin-right: 0.75rem !important; }
  .mb-md-q3,
  .my-md-q3 {
    margin-bottom: 0.75rem !important; }
  .ml-md-q3,
  .mx-md-q3 {
    margin-left: 0.75rem !important; }
  .m-md-0 {
    margin: 0rem !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0rem !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0rem !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0rem !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0rem !important; }
  .m-md-1 {
    margin: 1rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 1rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 1rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 1rem !important; }
  .m-md-1q1 {
    margin: 1.25rem !important; }
  .mt-md-1q1,
  .my-md-1q1 {
    margin-top: 1.25rem !important; }
  .mr-md-1q1,
  .mx-md-1q1 {
    margin-right: 1.25rem !important; }
  .mb-md-1q1,
  .my-md-1q1 {
    margin-bottom: 1.25rem !important; }
  .ml-md-1q1,
  .mx-md-1q1 {
    margin-left: 1.25rem !important; }
  .m-md-1q2 {
    margin: 1.5rem !important; }
  .mt-md-1q2,
  .my-md-1q2 {
    margin-top: 1.5rem !important; }
  .mr-md-1q2,
  .mx-md-1q2 {
    margin-right: 1.5rem !important; }
  .mb-md-1q2,
  .my-md-1q2 {
    margin-bottom: 1.5rem !important; }
  .ml-md-1q2,
  .mx-md-1q2 {
    margin-left: 1.5rem !important; }
  .m-md-1q3 {
    margin: 1.75rem !important; }
  .mt-md-1q3,
  .my-md-1q3 {
    margin-top: 1.75rem !important; }
  .mr-md-1q3,
  .mx-md-1q3 {
    margin-right: 1.75rem !important; }
  .mb-md-1q3,
  .my-md-1q3 {
    margin-bottom: 1.75rem !important; }
  .ml-md-1q3,
  .mx-md-1q3 {
    margin-left: 1.75rem !important; }
  .m-md-2 {
    margin: 2rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 2rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 2rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 2rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 2rem !important; }
  .m-md-2q1 {
    margin: 2.25rem !important; }
  .mt-md-2q1,
  .my-md-2q1 {
    margin-top: 2.25rem !important; }
  .mr-md-2q1,
  .mx-md-2q1 {
    margin-right: 2.25rem !important; }
  .mb-md-2q1,
  .my-md-2q1 {
    margin-bottom: 2.25rem !important; }
  .ml-md-2q1,
  .mx-md-2q1 {
    margin-left: 2.25rem !important; }
  .m-md-2q2 {
    margin: 2.5rem !important; }
  .mt-md-2q2,
  .my-md-2q2 {
    margin-top: 2.5rem !important; }
  .mr-md-2q2,
  .mx-md-2q2 {
    margin-right: 2.5rem !important; }
  .mb-md-2q2,
  .my-md-2q2 {
    margin-bottom: 2.5rem !important; }
  .ml-md-2q2,
  .mx-md-2q2 {
    margin-left: 2.5rem !important; }
  .m-md-2q3 {
    margin: 2.75rem !important; }
  .mt-md-2q3,
  .my-md-2q3 {
    margin-top: 2.75rem !important; }
  .mr-md-2q3,
  .mx-md-2q3 {
    margin-right: 2.75rem !important; }
  .mb-md-2q3,
  .my-md-2q3 {
    margin-bottom: 2.75rem !important; }
  .ml-md-2q3,
  .mx-md-2q3 {
    margin-left: 2.75rem !important; }
  .m-md-3 {
    margin: 3rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 3rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 3rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 3rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 3rem !important; }
  .m-md-3q1 {
    margin: 3.25rem !important; }
  .mt-md-3q1,
  .my-md-3q1 {
    margin-top: 3.25rem !important; }
  .mr-md-3q1,
  .mx-md-3q1 {
    margin-right: 3.25rem !important; }
  .mb-md-3q1,
  .my-md-3q1 {
    margin-bottom: 3.25rem !important; }
  .ml-md-3q1,
  .mx-md-3q1 {
    margin-left: 3.25rem !important; }
  .m-md-3q2 {
    margin: 3.5rem !important; }
  .mt-md-3q2,
  .my-md-3q2 {
    margin-top: 3.5rem !important; }
  .mr-md-3q2,
  .mx-md-3q2 {
    margin-right: 3.5rem !important; }
  .mb-md-3q2,
  .my-md-3q2 {
    margin-bottom: 3.5rem !important; }
  .ml-md-3q2,
  .mx-md-3q2 {
    margin-left: 3.5rem !important; }
  .m-md-3q3 {
    margin: 3.75rem !important; }
  .mt-md-3q3,
  .my-md-3q3 {
    margin-top: 3.75rem !important; }
  .mr-md-3q3,
  .mx-md-3q3 {
    margin-right: 3.75rem !important; }
  .mb-md-3q3,
  .my-md-3q3 {
    margin-bottom: 3.75rem !important; }
  .ml-md-3q3,
  .mx-md-3q3 {
    margin-left: 3.75rem !important; }
  .m-md-4 {
    margin: 4rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 4rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 4rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 4rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 4rem !important; }
  .m-md-4q1 {
    margin: 4.25rem !important; }
  .mt-md-4q1,
  .my-md-4q1 {
    margin-top: 4.25rem !important; }
  .mr-md-4q1,
  .mx-md-4q1 {
    margin-right: 4.25rem !important; }
  .mb-md-4q1,
  .my-md-4q1 {
    margin-bottom: 4.25rem !important; }
  .ml-md-4q1,
  .mx-md-4q1 {
    margin-left: 4.25rem !important; }
  .m-md-4q2 {
    margin: 4.5rem !important; }
  .mt-md-4q2,
  .my-md-4q2 {
    margin-top: 4.5rem !important; }
  .mr-md-4q2,
  .mx-md-4q2 {
    margin-right: 4.5rem !important; }
  .mb-md-4q2,
  .my-md-4q2 {
    margin-bottom: 4.5rem !important; }
  .ml-md-4q2,
  .mx-md-4q2 {
    margin-left: 4.5rem !important; }
  .m-md-4q3 {
    margin: 4.75rem !important; }
  .mt-md-4q3,
  .my-md-4q3 {
    margin-top: 4.75rem !important; }
  .mr-md-4q3,
  .mx-md-4q3 {
    margin-right: 4.75rem !important; }
  .mb-md-4q3,
  .my-md-4q3 {
    margin-bottom: 4.75rem !important; }
  .ml-md-4q3,
  .mx-md-4q3 {
    margin-left: 4.75rem !important; }
  .m-md-5 {
    margin: 5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5rem !important; }
  .m-md-5q1 {
    margin: 5.25rem !important; }
  .mt-md-5q1,
  .my-md-5q1 {
    margin-top: 5.25rem !important; }
  .mr-md-5q1,
  .mx-md-5q1 {
    margin-right: 5.25rem !important; }
  .mb-md-5q1,
  .my-md-5q1 {
    margin-bottom: 5.25rem !important; }
  .ml-md-5q1,
  .mx-md-5q1 {
    margin-left: 5.25rem !important; }
  .m-md-5q2 {
    margin: 5.5rem !important; }
  .mt-md-5q2,
  .my-md-5q2 {
    margin-top: 5.5rem !important; }
  .mr-md-5q2,
  .mx-md-5q2 {
    margin-right: 5.5rem !important; }
  .mb-md-5q2,
  .my-md-5q2 {
    margin-bottom: 5.5rem !important; }
  .ml-md-5q2,
  .mx-md-5q2 {
    margin-left: 5.5rem !important; }
  .m-md-5q3 {
    margin: 5.75rem !important; }
  .mt-md-5q3,
  .my-md-5q3 {
    margin-top: 5.75rem !important; }
  .mr-md-5q3,
  .mx-md-5q3 {
    margin-right: 5.75rem !important; }
  .mb-md-5q3,
  .my-md-5q3 {
    margin-bottom: 5.75rem !important; }
  .ml-md-5q3,
  .mx-md-5q3 {
    margin-left: 5.75rem !important; }
  .m-md-6 {
    margin: 6rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 6rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 6rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 6rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 6rem !important; }
  .m-md-6q1 {
    margin: 6.25rem !important; }
  .mt-md-6q1,
  .my-md-6q1 {
    margin-top: 6.25rem !important; }
  .mr-md-6q1,
  .mx-md-6q1 {
    margin-right: 6.25rem !important; }
  .mb-md-6q1,
  .my-md-6q1 {
    margin-bottom: 6.25rem !important; }
  .ml-md-6q1,
  .mx-md-6q1 {
    margin-left: 6.25rem !important; }
  .m-md-6q2 {
    margin: 6.5rem !important; }
  .mt-md-6q2,
  .my-md-6q2 {
    margin-top: 6.5rem !important; }
  .mr-md-6q2,
  .mx-md-6q2 {
    margin-right: 6.5rem !important; }
  .mb-md-6q2,
  .my-md-6q2 {
    margin-bottom: 6.5rem !important; }
  .ml-md-6q2,
  .mx-md-6q2 {
    margin-left: 6.5rem !important; }
  .m-md-6q3 {
    margin: 6.75rem !important; }
  .mt-md-6q3,
  .my-md-6q3 {
    margin-top: 6.75rem !important; }
  .mr-md-6q3,
  .mx-md-6q3 {
    margin-right: 6.75rem !important; }
  .mb-md-6q3,
  .my-md-6q3 {
    margin-bottom: 6.75rem !important; }
  .ml-md-6q3,
  .mx-md-6q3 {
    margin-left: 6.75rem !important; }
  .m-md-7 {
    margin: 7rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 7rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 7rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 7rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 7rem !important; }
  .m-md-7q1 {
    margin: 7.25rem !important; }
  .mt-md-7q1,
  .my-md-7q1 {
    margin-top: 7.25rem !important; }
  .mr-md-7q1,
  .mx-md-7q1 {
    margin-right: 7.25rem !important; }
  .mb-md-7q1,
  .my-md-7q1 {
    margin-bottom: 7.25rem !important; }
  .ml-md-7q1,
  .mx-md-7q1 {
    margin-left: 7.25rem !important; }
  .m-md-7q2 {
    margin: 7.5rem !important; }
  .mt-md-7q2,
  .my-md-7q2 {
    margin-top: 7.5rem !important; }
  .mr-md-7q2,
  .mx-md-7q2 {
    margin-right: 7.5rem !important; }
  .mb-md-7q2,
  .my-md-7q2 {
    margin-bottom: 7.5rem !important; }
  .ml-md-7q2,
  .mx-md-7q2 {
    margin-left: 7.5rem !important; }
  .m-md-7q3 {
    margin: 7.75rem !important; }
  .mt-md-7q3,
  .my-md-7q3 {
    margin-top: 7.75rem !important; }
  .mr-md-7q3,
  .mx-md-7q3 {
    margin-right: 7.75rem !important; }
  .mb-md-7q3,
  .my-md-7q3 {
    margin-bottom: 7.75rem !important; }
  .ml-md-7q3,
  .mx-md-7q3 {
    margin-left: 7.75rem !important; }
  .m-md-8 {
    margin: 8rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 8rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 8rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 8rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 8rem !important; }
  .m-md-8q1 {
    margin: 8.25rem !important; }
  .mt-md-8q1,
  .my-md-8q1 {
    margin-top: 8.25rem !important; }
  .mr-md-8q1,
  .mx-md-8q1 {
    margin-right: 8.25rem !important; }
  .mb-md-8q1,
  .my-md-8q1 {
    margin-bottom: 8.25rem !important; }
  .ml-md-8q1,
  .mx-md-8q1 {
    margin-left: 8.25rem !important; }
  .m-md-8q2 {
    margin: 8.5rem !important; }
  .mt-md-8q2,
  .my-md-8q2 {
    margin-top: 8.5rem !important; }
  .mr-md-8q2,
  .mx-md-8q2 {
    margin-right: 8.5rem !important; }
  .mb-md-8q2,
  .my-md-8q2 {
    margin-bottom: 8.5rem !important; }
  .ml-md-8q2,
  .mx-md-8q2 {
    margin-left: 8.5rem !important; }
  .m-md-8q3 {
    margin: 8.75rem !important; }
  .mt-md-8q3,
  .my-md-8q3 {
    margin-top: 8.75rem !important; }
  .mr-md-8q3,
  .mx-md-8q3 {
    margin-right: 8.75rem !important; }
  .mb-md-8q3,
  .my-md-8q3 {
    margin-bottom: 8.75rem !important; }
  .ml-md-8q3,
  .mx-md-8q3 {
    margin-left: 8.75rem !important; }
  .m-md-9 {
    margin: 9rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 9rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 9rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 9rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 9rem !important; }
  .m-md-9q1 {
    margin: 9.25rem !important; }
  .mt-md-9q1,
  .my-md-9q1 {
    margin-top: 9.25rem !important; }
  .mr-md-9q1,
  .mx-md-9q1 {
    margin-right: 9.25rem !important; }
  .mb-md-9q1,
  .my-md-9q1 {
    margin-bottom: 9.25rem !important; }
  .ml-md-9q1,
  .mx-md-9q1 {
    margin-left: 9.25rem !important; }
  .m-md-9q2 {
    margin: 9.5rem !important; }
  .mt-md-9q2,
  .my-md-9q2 {
    margin-top: 9.5rem !important; }
  .mr-md-9q2,
  .mx-md-9q2 {
    margin-right: 9.5rem !important; }
  .mb-md-9q2,
  .my-md-9q2 {
    margin-bottom: 9.5rem !important; }
  .ml-md-9q2,
  .mx-md-9q2 {
    margin-left: 9.5rem !important; }
  .m-md-9q3 {
    margin: 9.75rem !important; }
  .mt-md-9q3,
  .my-md-9q3 {
    margin-top: 9.75rem !important; }
  .mr-md-9q3,
  .mx-md-9q3 {
    margin-right: 9.75rem !important; }
  .mb-md-9q3,
  .my-md-9q3 {
    margin-bottom: 9.75rem !important; }
  .ml-md-9q3,
  .mx-md-9q3 {
    margin-left: 9.75rem !important; }
  .m-md-10 {
    margin: 10rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10rem !important; }
  .m-md-10q1 {
    margin: 10.25rem !important; }
  .mt-md-10q1,
  .my-md-10q1 {
    margin-top: 10.25rem !important; }
  .mr-md-10q1,
  .mx-md-10q1 {
    margin-right: 10.25rem !important; }
  .mb-md-10q1,
  .my-md-10q1 {
    margin-bottom: 10.25rem !important; }
  .ml-md-10q1,
  .mx-md-10q1 {
    margin-left: 10.25rem !important; }
  .m-md-10q2 {
    margin: 10.5rem !important; }
  .mt-md-10q2,
  .my-md-10q2 {
    margin-top: 10.5rem !important; }
  .mr-md-10q2,
  .mx-md-10q2 {
    margin-right: 10.5rem !important; }
  .mb-md-10q2,
  .my-md-10q2 {
    margin-bottom: 10.5rem !important; }
  .ml-md-10q2,
  .mx-md-10q2 {
    margin-left: 10.5rem !important; }
  .m-md-10q3 {
    margin: 10.75rem !important; }
  .mt-md-10q3,
  .my-md-10q3 {
    margin-top: 10.75rem !important; }
  .mr-md-10q3,
  .mx-md-10q3 {
    margin-right: 10.75rem !important; }
  .mb-md-10q3,
  .my-md-10q3 {
    margin-bottom: 10.75rem !important; }
  .ml-md-10q3,
  .mx-md-10q3 {
    margin-left: 10.75rem !important; }
  .m-md-11 {
    margin: 11rem !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 11rem !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 11rem !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 11rem !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 11rem !important; }
  .m-md-11q1 {
    margin: 11.25rem !important; }
  .mt-md-11q1,
  .my-md-11q1 {
    margin-top: 11.25rem !important; }
  .mr-md-11q1,
  .mx-md-11q1 {
    margin-right: 11.25rem !important; }
  .mb-md-11q1,
  .my-md-11q1 {
    margin-bottom: 11.25rem !important; }
  .ml-md-11q1,
  .mx-md-11q1 {
    margin-left: 11.25rem !important; }
  .m-md-11 q2 {
    margin: 11.5rem !important; }
  .mt-md-11 q2,
  .my-md-11 q2 {
    margin-top: 11.5rem !important; }
  .mr-md-11 q2,
  .mx-md-11 q2 {
    margin-right: 11.5rem !important; }
  .mb-md-11 q2,
  .my-md-11 q2 {
    margin-bottom: 11.5rem !important; }
  .ml-md-11 q2,
  .mx-md-11 q2 {
    margin-left: 11.5rem !important; }
  .m-md-11 q3 {
    margin: 11.75rem !important; }
  .mt-md-11 q3,
  .my-md-11 q3 {
    margin-top: 11.75rem !important; }
  .mr-md-11 q3,
  .mx-md-11 q3 {
    margin-right: 11.75rem !important; }
  .mb-md-11 q3,
  .my-md-11 q3 {
    margin-bottom: 11.75rem !important; }
  .ml-md-11 q3,
  .mx-md-11 q3 {
    margin-left: 11.75rem !important; }
  .m-md-12 {
    margin: 12rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 12rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 12rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 12rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 12rem !important; }
  .m-md-12 q1 {
    margin: 12.25rem !important; }
  .mt-md-12 q1,
  .my-md-12 q1 {
    margin-top: 12.25rem !important; }
  .mr-md-12 q1,
  .mx-md-12 q1 {
    margin-right: 12.25rem !important; }
  .mb-md-12 q1,
  .my-md-12 q1 {
    margin-bottom: 12.25rem !important; }
  .ml-md-12 q1,
  .mx-md-12 q1 {
    margin-left: 12.25rem !important; }
  .m-md-12 q2 {
    margin: 12.5rem !important; }
  .mt-md-12 q2,
  .my-md-12 q2 {
    margin-top: 12.5rem !important; }
  .mr-md-12 q2,
  .mx-md-12 q2 {
    margin-right: 12.5rem !important; }
  .mb-md-12 q2,
  .my-md-12 q2 {
    margin-bottom: 12.5rem !important; }
  .ml-md-12 q2,
  .mx-md-12 q2 {
    margin-left: 12.5rem !important; }
  .m-md-12 q3 {
    margin: 12.75rem !important; }
  .mt-md-12 q3,
  .my-md-12 q3 {
    margin-top: 12.75rem !important; }
  .mr-md-12 q3,
  .mx-md-12 q3 {
    margin-right: 12.75rem !important; }
  .mb-md-12 q3,
  .my-md-12 q3 {
    margin-bottom: 12.75rem !important; }
  .ml-md-12 q3,
  .mx-md-12 q3 {
    margin-left: 12.75rem !important; }
  .p-md-q1 {
    padding: 0.25rem !important; }
  .pt-md-q1,
  .py-md-q1 {
    padding-top: 0.25rem !important; }
  .pr-md-q1,
  .px-md-q1 {
    padding-right: 0.25rem !important; }
  .pb-md-q1,
  .py-md-q1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-q1,
  .px-md-q1 {
    padding-left: 0.25rem !important; }
  .p-md-q2 {
    padding: 0.5rem !important; }
  .pt-md-q2,
  .py-md-q2 {
    padding-top: 0.5rem !important; }
  .pr-md-q2,
  .px-md-q2 {
    padding-right: 0.5rem !important; }
  .pb-md-q2,
  .py-md-q2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-q2,
  .px-md-q2 {
    padding-left: 0.5rem !important; }
  .p-md-q3 {
    padding: 0.75rem !important; }
  .pt-md-q3,
  .py-md-q3 {
    padding-top: 0.75rem !important; }
  .pr-md-q3,
  .px-md-q3 {
    padding-right: 0.75rem !important; }
  .pb-md-q3,
  .py-md-q3 {
    padding-bottom: 0.75rem !important; }
  .pl-md-q3,
  .px-md-q3 {
    padding-left: 0.75rem !important; }
  .p-md-0 {
    padding: 0rem !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0rem !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0rem !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0rem !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0rem !important; }
  .p-md-1 {
    padding: 1rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 1rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 1rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 1rem !important; }
  .p-md-1q1 {
    padding: 1.25rem !important; }
  .pt-md-1q1,
  .py-md-1q1 {
    padding-top: 1.25rem !important; }
  .pr-md-1q1,
  .px-md-1q1 {
    padding-right: 1.25rem !important; }
  .pb-md-1q1,
  .py-md-1q1 {
    padding-bottom: 1.25rem !important; }
  .pl-md-1q1,
  .px-md-1q1 {
    padding-left: 1.25rem !important; }
  .p-md-1q2 {
    padding: 1.5rem !important; }
  .pt-md-1q2,
  .py-md-1q2 {
    padding-top: 1.5rem !important; }
  .pr-md-1q2,
  .px-md-1q2 {
    padding-right: 1.5rem !important; }
  .pb-md-1q2,
  .py-md-1q2 {
    padding-bottom: 1.5rem !important; }
  .pl-md-1q2,
  .px-md-1q2 {
    padding-left: 1.5rem !important; }
  .p-md-1q3 {
    padding: 1.75rem !important; }
  .pt-md-1q3,
  .py-md-1q3 {
    padding-top: 1.75rem !important; }
  .pr-md-1q3,
  .px-md-1q3 {
    padding-right: 1.75rem !important; }
  .pb-md-1q3,
  .py-md-1q3 {
    padding-bottom: 1.75rem !important; }
  .pl-md-1q3,
  .px-md-1q3 {
    padding-left: 1.75rem !important; }
  .p-md-2 {
    padding: 2rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 2rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 2rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 2rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 2rem !important; }
  .p-md-2q1 {
    padding: 2.25rem !important; }
  .pt-md-2q1,
  .py-md-2q1 {
    padding-top: 2.25rem !important; }
  .pr-md-2q1,
  .px-md-2q1 {
    padding-right: 2.25rem !important; }
  .pb-md-2q1,
  .py-md-2q1 {
    padding-bottom: 2.25rem !important; }
  .pl-md-2q1,
  .px-md-2q1 {
    padding-left: 2.25rem !important; }
  .p-md-2q2 {
    padding: 2.5rem !important; }
  .pt-md-2q2,
  .py-md-2q2 {
    padding-top: 2.5rem !important; }
  .pr-md-2q2,
  .px-md-2q2 {
    padding-right: 2.5rem !important; }
  .pb-md-2q2,
  .py-md-2q2 {
    padding-bottom: 2.5rem !important; }
  .pl-md-2q2,
  .px-md-2q2 {
    padding-left: 2.5rem !important; }
  .p-md-2q3 {
    padding: 2.75rem !important; }
  .pt-md-2q3,
  .py-md-2q3 {
    padding-top: 2.75rem !important; }
  .pr-md-2q3,
  .px-md-2q3 {
    padding-right: 2.75rem !important; }
  .pb-md-2q3,
  .py-md-2q3 {
    padding-bottom: 2.75rem !important; }
  .pl-md-2q3,
  .px-md-2q3 {
    padding-left: 2.75rem !important; }
  .p-md-3 {
    padding: 3rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 3rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 3rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 3rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 3rem !important; }
  .p-md-3q1 {
    padding: 3.25rem !important; }
  .pt-md-3q1,
  .py-md-3q1 {
    padding-top: 3.25rem !important; }
  .pr-md-3q1,
  .px-md-3q1 {
    padding-right: 3.25rem !important; }
  .pb-md-3q1,
  .py-md-3q1 {
    padding-bottom: 3.25rem !important; }
  .pl-md-3q1,
  .px-md-3q1 {
    padding-left: 3.25rem !important; }
  .p-md-3q2 {
    padding: 3.5rem !important; }
  .pt-md-3q2,
  .py-md-3q2 {
    padding-top: 3.5rem !important; }
  .pr-md-3q2,
  .px-md-3q2 {
    padding-right: 3.5rem !important; }
  .pb-md-3q2,
  .py-md-3q2 {
    padding-bottom: 3.5rem !important; }
  .pl-md-3q2,
  .px-md-3q2 {
    padding-left: 3.5rem !important; }
  .p-md-3q3 {
    padding: 3.75rem !important; }
  .pt-md-3q3,
  .py-md-3q3 {
    padding-top: 3.75rem !important; }
  .pr-md-3q3,
  .px-md-3q3 {
    padding-right: 3.75rem !important; }
  .pb-md-3q3,
  .py-md-3q3 {
    padding-bottom: 3.75rem !important; }
  .pl-md-3q3,
  .px-md-3q3 {
    padding-left: 3.75rem !important; }
  .p-md-4 {
    padding: 4rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 4rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 4rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 4rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 4rem !important; }
  .p-md-4q1 {
    padding: 4.25rem !important; }
  .pt-md-4q1,
  .py-md-4q1 {
    padding-top: 4.25rem !important; }
  .pr-md-4q1,
  .px-md-4q1 {
    padding-right: 4.25rem !important; }
  .pb-md-4q1,
  .py-md-4q1 {
    padding-bottom: 4.25rem !important; }
  .pl-md-4q1,
  .px-md-4q1 {
    padding-left: 4.25rem !important; }
  .p-md-4q2 {
    padding: 4.5rem !important; }
  .pt-md-4q2,
  .py-md-4q2 {
    padding-top: 4.5rem !important; }
  .pr-md-4q2,
  .px-md-4q2 {
    padding-right: 4.5rem !important; }
  .pb-md-4q2,
  .py-md-4q2 {
    padding-bottom: 4.5rem !important; }
  .pl-md-4q2,
  .px-md-4q2 {
    padding-left: 4.5rem !important; }
  .p-md-4q3 {
    padding: 4.75rem !important; }
  .pt-md-4q3,
  .py-md-4q3 {
    padding-top: 4.75rem !important; }
  .pr-md-4q3,
  .px-md-4q3 {
    padding-right: 4.75rem !important; }
  .pb-md-4q3,
  .py-md-4q3 {
    padding-bottom: 4.75rem !important; }
  .pl-md-4q3,
  .px-md-4q3 {
    padding-left: 4.75rem !important; }
  .p-md-5 {
    padding: 5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5rem !important; }
  .p-md-5q1 {
    padding: 5.25rem !important; }
  .pt-md-5q1,
  .py-md-5q1 {
    padding-top: 5.25rem !important; }
  .pr-md-5q1,
  .px-md-5q1 {
    padding-right: 5.25rem !important; }
  .pb-md-5q1,
  .py-md-5q1 {
    padding-bottom: 5.25rem !important; }
  .pl-md-5q1,
  .px-md-5q1 {
    padding-left: 5.25rem !important; }
  .p-md-5q2 {
    padding: 5.5rem !important; }
  .pt-md-5q2,
  .py-md-5q2 {
    padding-top: 5.5rem !important; }
  .pr-md-5q2,
  .px-md-5q2 {
    padding-right: 5.5rem !important; }
  .pb-md-5q2,
  .py-md-5q2 {
    padding-bottom: 5.5rem !important; }
  .pl-md-5q2,
  .px-md-5q2 {
    padding-left: 5.5rem !important; }
  .p-md-5q3 {
    padding: 5.75rem !important; }
  .pt-md-5q3,
  .py-md-5q3 {
    padding-top: 5.75rem !important; }
  .pr-md-5q3,
  .px-md-5q3 {
    padding-right: 5.75rem !important; }
  .pb-md-5q3,
  .py-md-5q3 {
    padding-bottom: 5.75rem !important; }
  .pl-md-5q3,
  .px-md-5q3 {
    padding-left: 5.75rem !important; }
  .p-md-6 {
    padding: 6rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 6rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 6rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 6rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 6rem !important; }
  .p-md-6q1 {
    padding: 6.25rem !important; }
  .pt-md-6q1,
  .py-md-6q1 {
    padding-top: 6.25rem !important; }
  .pr-md-6q1,
  .px-md-6q1 {
    padding-right: 6.25rem !important; }
  .pb-md-6q1,
  .py-md-6q1 {
    padding-bottom: 6.25rem !important; }
  .pl-md-6q1,
  .px-md-6q1 {
    padding-left: 6.25rem !important; }
  .p-md-6q2 {
    padding: 6.5rem !important; }
  .pt-md-6q2,
  .py-md-6q2 {
    padding-top: 6.5rem !important; }
  .pr-md-6q2,
  .px-md-6q2 {
    padding-right: 6.5rem !important; }
  .pb-md-6q2,
  .py-md-6q2 {
    padding-bottom: 6.5rem !important; }
  .pl-md-6q2,
  .px-md-6q2 {
    padding-left: 6.5rem !important; }
  .p-md-6q3 {
    padding: 6.75rem !important; }
  .pt-md-6q3,
  .py-md-6q3 {
    padding-top: 6.75rem !important; }
  .pr-md-6q3,
  .px-md-6q3 {
    padding-right: 6.75rem !important; }
  .pb-md-6q3,
  .py-md-6q3 {
    padding-bottom: 6.75rem !important; }
  .pl-md-6q3,
  .px-md-6q3 {
    padding-left: 6.75rem !important; }
  .p-md-7 {
    padding: 7rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 7rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 7rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 7rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 7rem !important; }
  .p-md-7q1 {
    padding: 7.25rem !important; }
  .pt-md-7q1,
  .py-md-7q1 {
    padding-top: 7.25rem !important; }
  .pr-md-7q1,
  .px-md-7q1 {
    padding-right: 7.25rem !important; }
  .pb-md-7q1,
  .py-md-7q1 {
    padding-bottom: 7.25rem !important; }
  .pl-md-7q1,
  .px-md-7q1 {
    padding-left: 7.25rem !important; }
  .p-md-7q2 {
    padding: 7.5rem !important; }
  .pt-md-7q2,
  .py-md-7q2 {
    padding-top: 7.5rem !important; }
  .pr-md-7q2,
  .px-md-7q2 {
    padding-right: 7.5rem !important; }
  .pb-md-7q2,
  .py-md-7q2 {
    padding-bottom: 7.5rem !important; }
  .pl-md-7q2,
  .px-md-7q2 {
    padding-left: 7.5rem !important; }
  .p-md-7q3 {
    padding: 7.75rem !important; }
  .pt-md-7q3,
  .py-md-7q3 {
    padding-top: 7.75rem !important; }
  .pr-md-7q3,
  .px-md-7q3 {
    padding-right: 7.75rem !important; }
  .pb-md-7q3,
  .py-md-7q3 {
    padding-bottom: 7.75rem !important; }
  .pl-md-7q3,
  .px-md-7q3 {
    padding-left: 7.75rem !important; }
  .p-md-8 {
    padding: 8rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 8rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 8rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 8rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 8rem !important; }
  .p-md-8q1 {
    padding: 8.25rem !important; }
  .pt-md-8q1,
  .py-md-8q1 {
    padding-top: 8.25rem !important; }
  .pr-md-8q1,
  .px-md-8q1 {
    padding-right: 8.25rem !important; }
  .pb-md-8q1,
  .py-md-8q1 {
    padding-bottom: 8.25rem !important; }
  .pl-md-8q1,
  .px-md-8q1 {
    padding-left: 8.25rem !important; }
  .p-md-8q2 {
    padding: 8.5rem !important; }
  .pt-md-8q2,
  .py-md-8q2 {
    padding-top: 8.5rem !important; }
  .pr-md-8q2,
  .px-md-8q2 {
    padding-right: 8.5rem !important; }
  .pb-md-8q2,
  .py-md-8q2 {
    padding-bottom: 8.5rem !important; }
  .pl-md-8q2,
  .px-md-8q2 {
    padding-left: 8.5rem !important; }
  .p-md-8q3 {
    padding: 8.75rem !important; }
  .pt-md-8q3,
  .py-md-8q3 {
    padding-top: 8.75rem !important; }
  .pr-md-8q3,
  .px-md-8q3 {
    padding-right: 8.75rem !important; }
  .pb-md-8q3,
  .py-md-8q3 {
    padding-bottom: 8.75rem !important; }
  .pl-md-8q3,
  .px-md-8q3 {
    padding-left: 8.75rem !important; }
  .p-md-9 {
    padding: 9rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 9rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 9rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 9rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 9rem !important; }
  .p-md-9q1 {
    padding: 9.25rem !important; }
  .pt-md-9q1,
  .py-md-9q1 {
    padding-top: 9.25rem !important; }
  .pr-md-9q1,
  .px-md-9q1 {
    padding-right: 9.25rem !important; }
  .pb-md-9q1,
  .py-md-9q1 {
    padding-bottom: 9.25rem !important; }
  .pl-md-9q1,
  .px-md-9q1 {
    padding-left: 9.25rem !important; }
  .p-md-9q2 {
    padding: 9.5rem !important; }
  .pt-md-9q2,
  .py-md-9q2 {
    padding-top: 9.5rem !important; }
  .pr-md-9q2,
  .px-md-9q2 {
    padding-right: 9.5rem !important; }
  .pb-md-9q2,
  .py-md-9q2 {
    padding-bottom: 9.5rem !important; }
  .pl-md-9q2,
  .px-md-9q2 {
    padding-left: 9.5rem !important; }
  .p-md-9q3 {
    padding: 9.75rem !important; }
  .pt-md-9q3,
  .py-md-9q3 {
    padding-top: 9.75rem !important; }
  .pr-md-9q3,
  .px-md-9q3 {
    padding-right: 9.75rem !important; }
  .pb-md-9q3,
  .py-md-9q3 {
    padding-bottom: 9.75rem !important; }
  .pl-md-9q3,
  .px-md-9q3 {
    padding-left: 9.75rem !important; }
  .p-md-10 {
    padding: 10rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10rem !important; }
  .p-md-10q1 {
    padding: 10.25rem !important; }
  .pt-md-10q1,
  .py-md-10q1 {
    padding-top: 10.25rem !important; }
  .pr-md-10q1,
  .px-md-10q1 {
    padding-right: 10.25rem !important; }
  .pb-md-10q1,
  .py-md-10q1 {
    padding-bottom: 10.25rem !important; }
  .pl-md-10q1,
  .px-md-10q1 {
    padding-left: 10.25rem !important; }
  .p-md-10q2 {
    padding: 10.5rem !important; }
  .pt-md-10q2,
  .py-md-10q2 {
    padding-top: 10.5rem !important; }
  .pr-md-10q2,
  .px-md-10q2 {
    padding-right: 10.5rem !important; }
  .pb-md-10q2,
  .py-md-10q2 {
    padding-bottom: 10.5rem !important; }
  .pl-md-10q2,
  .px-md-10q2 {
    padding-left: 10.5rem !important; }
  .p-md-10q3 {
    padding: 10.75rem !important; }
  .pt-md-10q3,
  .py-md-10q3 {
    padding-top: 10.75rem !important; }
  .pr-md-10q3,
  .px-md-10q3 {
    padding-right: 10.75rem !important; }
  .pb-md-10q3,
  .py-md-10q3 {
    padding-bottom: 10.75rem !important; }
  .pl-md-10q3,
  .px-md-10q3 {
    padding-left: 10.75rem !important; }
  .p-md-11 {
    padding: 11rem !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 11rem !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 11rem !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 11rem !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 11rem !important; }
  .p-md-11q1 {
    padding: 11.25rem !important; }
  .pt-md-11q1,
  .py-md-11q1 {
    padding-top: 11.25rem !important; }
  .pr-md-11q1,
  .px-md-11q1 {
    padding-right: 11.25rem !important; }
  .pb-md-11q1,
  .py-md-11q1 {
    padding-bottom: 11.25rem !important; }
  .pl-md-11q1,
  .px-md-11q1 {
    padding-left: 11.25rem !important; }
  .p-md-11 q2 {
    padding: 11.5rem !important; }
  .pt-md-11 q2,
  .py-md-11 q2 {
    padding-top: 11.5rem !important; }
  .pr-md-11 q2,
  .px-md-11 q2 {
    padding-right: 11.5rem !important; }
  .pb-md-11 q2,
  .py-md-11 q2 {
    padding-bottom: 11.5rem !important; }
  .pl-md-11 q2,
  .px-md-11 q2 {
    padding-left: 11.5rem !important; }
  .p-md-11 q3 {
    padding: 11.75rem !important; }
  .pt-md-11 q3,
  .py-md-11 q3 {
    padding-top: 11.75rem !important; }
  .pr-md-11 q3,
  .px-md-11 q3 {
    padding-right: 11.75rem !important; }
  .pb-md-11 q3,
  .py-md-11 q3 {
    padding-bottom: 11.75rem !important; }
  .pl-md-11 q3,
  .px-md-11 q3 {
    padding-left: 11.75rem !important; }
  .p-md-12 {
    padding: 12rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 12rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 12rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 12rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 12rem !important; }
  .p-md-12 q1 {
    padding: 12.25rem !important; }
  .pt-md-12 q1,
  .py-md-12 q1 {
    padding-top: 12.25rem !important; }
  .pr-md-12 q1,
  .px-md-12 q1 {
    padding-right: 12.25rem !important; }
  .pb-md-12 q1,
  .py-md-12 q1 {
    padding-bottom: 12.25rem !important; }
  .pl-md-12 q1,
  .px-md-12 q1 {
    padding-left: 12.25rem !important; }
  .p-md-12 q2 {
    padding: 12.5rem !important; }
  .pt-md-12 q2,
  .py-md-12 q2 {
    padding-top: 12.5rem !important; }
  .pr-md-12 q2,
  .px-md-12 q2 {
    padding-right: 12.5rem !important; }
  .pb-md-12 q2,
  .py-md-12 q2 {
    padding-bottom: 12.5rem !important; }
  .pl-md-12 q2,
  .px-md-12 q2 {
    padding-left: 12.5rem !important; }
  .p-md-12 q3 {
    padding: 12.75rem !important; }
  .pt-md-12 q3,
  .py-md-12 q3 {
    padding-top: 12.75rem !important; }
  .pr-md-12 q3,
  .px-md-12 q3 {
    padding-right: 12.75rem !important; }
  .pb-md-12 q3,
  .py-md-12 q3 {
    padding-bottom: 12.75rem !important; }
  .pl-md-12 q3,
  .px-md-12 q3 {
    padding-left: 12.75rem !important; }
  .m-md-nq1 {
    margin: -0.25rem !important; }
  .mt-md-nq1,
  .my-md-nq1 {
    margin-top: -0.25rem !important; }
  .mr-md-nq1,
  .mx-md-nq1 {
    margin-right: -0.25rem !important; }
  .mb-md-nq1,
  .my-md-nq1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-nq1,
  .mx-md-nq1 {
    margin-left: -0.25rem !important; }
  .m-md-nq2 {
    margin: -0.5rem !important; }
  .mt-md-nq2,
  .my-md-nq2 {
    margin-top: -0.5rem !important; }
  .mr-md-nq2,
  .mx-md-nq2 {
    margin-right: -0.5rem !important; }
  .mb-md-nq2,
  .my-md-nq2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-nq2,
  .mx-md-nq2 {
    margin-left: -0.5rem !important; }
  .m-md-nq3 {
    margin: -0.75rem !important; }
  .mt-md-nq3,
  .my-md-nq3 {
    margin-top: -0.75rem !important; }
  .mr-md-nq3,
  .mx-md-nq3 {
    margin-right: -0.75rem !important; }
  .mb-md-nq3,
  .my-md-nq3 {
    margin-bottom: -0.75rem !important; }
  .ml-md-nq3,
  .mx-md-nq3 {
    margin-left: -0.75rem !important; }
  .m-md-n1 {
    margin: -1rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -1rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -1rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -1rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -1rem !important; }
  .m-md-n1q1 {
    margin: -1.25rem !important; }
  .mt-md-n1q1,
  .my-md-n1q1 {
    margin-top: -1.25rem !important; }
  .mr-md-n1q1,
  .mx-md-n1q1 {
    margin-right: -1.25rem !important; }
  .mb-md-n1q1,
  .my-md-n1q1 {
    margin-bottom: -1.25rem !important; }
  .ml-md-n1q1,
  .mx-md-n1q1 {
    margin-left: -1.25rem !important; }
  .m-md-n1q2 {
    margin: -1.5rem !important; }
  .mt-md-n1q2,
  .my-md-n1q2 {
    margin-top: -1.5rem !important; }
  .mr-md-n1q2,
  .mx-md-n1q2 {
    margin-right: -1.5rem !important; }
  .mb-md-n1q2,
  .my-md-n1q2 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n1q2,
  .mx-md-n1q2 {
    margin-left: -1.5rem !important; }
  .m-md-n1q3 {
    margin: -1.75rem !important; }
  .mt-md-n1q3,
  .my-md-n1q3 {
    margin-top: -1.75rem !important; }
  .mr-md-n1q3,
  .mx-md-n1q3 {
    margin-right: -1.75rem !important; }
  .mb-md-n1q3,
  .my-md-n1q3 {
    margin-bottom: -1.75rem !important; }
  .ml-md-n1q3,
  .mx-md-n1q3 {
    margin-left: -1.75rem !important; }
  .m-md-n2 {
    margin: -2rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -2rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -2rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -2rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -2rem !important; }
  .m-md-n2q1 {
    margin: -2.25rem !important; }
  .mt-md-n2q1,
  .my-md-n2q1 {
    margin-top: -2.25rem !important; }
  .mr-md-n2q1,
  .mx-md-n2q1 {
    margin-right: -2.25rem !important; }
  .mb-md-n2q1,
  .my-md-n2q1 {
    margin-bottom: -2.25rem !important; }
  .ml-md-n2q1,
  .mx-md-n2q1 {
    margin-left: -2.25rem !important; }
  .m-md-n2q2 {
    margin: -2.5rem !important; }
  .mt-md-n2q2,
  .my-md-n2q2 {
    margin-top: -2.5rem !important; }
  .mr-md-n2q2,
  .mx-md-n2q2 {
    margin-right: -2.5rem !important; }
  .mb-md-n2q2,
  .my-md-n2q2 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n2q2,
  .mx-md-n2q2 {
    margin-left: -2.5rem !important; }
  .m-md-n2q3 {
    margin: -2.75rem !important; }
  .mt-md-n2q3,
  .my-md-n2q3 {
    margin-top: -2.75rem !important; }
  .mr-md-n2q3,
  .mx-md-n2q3 {
    margin-right: -2.75rem !important; }
  .mb-md-n2q3,
  .my-md-n2q3 {
    margin-bottom: -2.75rem !important; }
  .ml-md-n2q3,
  .mx-md-n2q3 {
    margin-left: -2.75rem !important; }
  .m-md-n3 {
    margin: -3rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -3rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -3rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -3rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -3rem !important; }
  .m-md-n3q1 {
    margin: -3.25rem !important; }
  .mt-md-n3q1,
  .my-md-n3q1 {
    margin-top: -3.25rem !important; }
  .mr-md-n3q1,
  .mx-md-n3q1 {
    margin-right: -3.25rem !important; }
  .mb-md-n3q1,
  .my-md-n3q1 {
    margin-bottom: -3.25rem !important; }
  .ml-md-n3q1,
  .mx-md-n3q1 {
    margin-left: -3.25rem !important; }
  .m-md-n3q2 {
    margin: -3.5rem !important; }
  .mt-md-n3q2,
  .my-md-n3q2 {
    margin-top: -3.5rem !important; }
  .mr-md-n3q2,
  .mx-md-n3q2 {
    margin-right: -3.5rem !important; }
  .mb-md-n3q2,
  .my-md-n3q2 {
    margin-bottom: -3.5rem !important; }
  .ml-md-n3q2,
  .mx-md-n3q2 {
    margin-left: -3.5rem !important; }
  .m-md-n3q3 {
    margin: -3.75rem !important; }
  .mt-md-n3q3,
  .my-md-n3q3 {
    margin-top: -3.75rem !important; }
  .mr-md-n3q3,
  .mx-md-n3q3 {
    margin-right: -3.75rem !important; }
  .mb-md-n3q3,
  .my-md-n3q3 {
    margin-bottom: -3.75rem !important; }
  .ml-md-n3q3,
  .mx-md-n3q3 {
    margin-left: -3.75rem !important; }
  .m-md-n4 {
    margin: -4rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -4rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -4rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -4rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -4rem !important; }
  .m-md-n4q1 {
    margin: -4.25rem !important; }
  .mt-md-n4q1,
  .my-md-n4q1 {
    margin-top: -4.25rem !important; }
  .mr-md-n4q1,
  .mx-md-n4q1 {
    margin-right: -4.25rem !important; }
  .mb-md-n4q1,
  .my-md-n4q1 {
    margin-bottom: -4.25rem !important; }
  .ml-md-n4q1,
  .mx-md-n4q1 {
    margin-left: -4.25rem !important; }
  .m-md-n4q2 {
    margin: -4.5rem !important; }
  .mt-md-n4q2,
  .my-md-n4q2 {
    margin-top: -4.5rem !important; }
  .mr-md-n4q2,
  .mx-md-n4q2 {
    margin-right: -4.5rem !important; }
  .mb-md-n4q2,
  .my-md-n4q2 {
    margin-bottom: -4.5rem !important; }
  .ml-md-n4q2,
  .mx-md-n4q2 {
    margin-left: -4.5rem !important; }
  .m-md-n4q3 {
    margin: -4.75rem !important; }
  .mt-md-n4q3,
  .my-md-n4q3 {
    margin-top: -4.75rem !important; }
  .mr-md-n4q3,
  .mx-md-n4q3 {
    margin-right: -4.75rem !important; }
  .mb-md-n4q3,
  .my-md-n4q3 {
    margin-bottom: -4.75rem !important; }
  .ml-md-n4q3,
  .mx-md-n4q3 {
    margin-left: -4.75rem !important; }
  .m-md-n5 {
    margin: -5rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -5rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -5rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -5rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -5rem !important; }
  .m-md-n5q1 {
    margin: -5.25rem !important; }
  .mt-md-n5q1,
  .my-md-n5q1 {
    margin-top: -5.25rem !important; }
  .mr-md-n5q1,
  .mx-md-n5q1 {
    margin-right: -5.25rem !important; }
  .mb-md-n5q1,
  .my-md-n5q1 {
    margin-bottom: -5.25rem !important; }
  .ml-md-n5q1,
  .mx-md-n5q1 {
    margin-left: -5.25rem !important; }
  .m-md-n5q2 {
    margin: -5.5rem !important; }
  .mt-md-n5q2,
  .my-md-n5q2 {
    margin-top: -5.5rem !important; }
  .mr-md-n5q2,
  .mx-md-n5q2 {
    margin-right: -5.5rem !important; }
  .mb-md-n5q2,
  .my-md-n5q2 {
    margin-bottom: -5.5rem !important; }
  .ml-md-n5q2,
  .mx-md-n5q2 {
    margin-left: -5.5rem !important; }
  .m-md-n5q3 {
    margin: -5.75rem !important; }
  .mt-md-n5q3,
  .my-md-n5q3 {
    margin-top: -5.75rem !important; }
  .mr-md-n5q3,
  .mx-md-n5q3 {
    margin-right: -5.75rem !important; }
  .mb-md-n5q3,
  .my-md-n5q3 {
    margin-bottom: -5.75rem !important; }
  .ml-md-n5q3,
  .mx-md-n5q3 {
    margin-left: -5.75rem !important; }
  .m-md-n6 {
    margin: -6rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -6rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -6rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -6rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -6rem !important; }
  .m-md-n6q1 {
    margin: -6.25rem !important; }
  .mt-md-n6q1,
  .my-md-n6q1 {
    margin-top: -6.25rem !important; }
  .mr-md-n6q1,
  .mx-md-n6q1 {
    margin-right: -6.25rem !important; }
  .mb-md-n6q1,
  .my-md-n6q1 {
    margin-bottom: -6.25rem !important; }
  .ml-md-n6q1,
  .mx-md-n6q1 {
    margin-left: -6.25rem !important; }
  .m-md-n6q2 {
    margin: -6.5rem !important; }
  .mt-md-n6q2,
  .my-md-n6q2 {
    margin-top: -6.5rem !important; }
  .mr-md-n6q2,
  .mx-md-n6q2 {
    margin-right: -6.5rem !important; }
  .mb-md-n6q2,
  .my-md-n6q2 {
    margin-bottom: -6.5rem !important; }
  .ml-md-n6q2,
  .mx-md-n6q2 {
    margin-left: -6.5rem !important; }
  .m-md-n6q3 {
    margin: -6.75rem !important; }
  .mt-md-n6q3,
  .my-md-n6q3 {
    margin-top: -6.75rem !important; }
  .mr-md-n6q3,
  .mx-md-n6q3 {
    margin-right: -6.75rem !important; }
  .mb-md-n6q3,
  .my-md-n6q3 {
    margin-bottom: -6.75rem !important; }
  .ml-md-n6q3,
  .mx-md-n6q3 {
    margin-left: -6.75rem !important; }
  .m-md-n7 {
    margin: -7rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -7rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -7rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -7rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -7rem !important; }
  .m-md-n7q1 {
    margin: -7.25rem !important; }
  .mt-md-n7q1,
  .my-md-n7q1 {
    margin-top: -7.25rem !important; }
  .mr-md-n7q1,
  .mx-md-n7q1 {
    margin-right: -7.25rem !important; }
  .mb-md-n7q1,
  .my-md-n7q1 {
    margin-bottom: -7.25rem !important; }
  .ml-md-n7q1,
  .mx-md-n7q1 {
    margin-left: -7.25rem !important; }
  .m-md-n7q2 {
    margin: -7.5rem !important; }
  .mt-md-n7q2,
  .my-md-n7q2 {
    margin-top: -7.5rem !important; }
  .mr-md-n7q2,
  .mx-md-n7q2 {
    margin-right: -7.5rem !important; }
  .mb-md-n7q2,
  .my-md-n7q2 {
    margin-bottom: -7.5rem !important; }
  .ml-md-n7q2,
  .mx-md-n7q2 {
    margin-left: -7.5rem !important; }
  .m-md-n7q3 {
    margin: -7.75rem !important; }
  .mt-md-n7q3,
  .my-md-n7q3 {
    margin-top: -7.75rem !important; }
  .mr-md-n7q3,
  .mx-md-n7q3 {
    margin-right: -7.75rem !important; }
  .mb-md-n7q3,
  .my-md-n7q3 {
    margin-bottom: -7.75rem !important; }
  .ml-md-n7q3,
  .mx-md-n7q3 {
    margin-left: -7.75rem !important; }
  .m-md-n8 {
    margin: -8rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -8rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -8rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -8rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -8rem !important; }
  .m-md-n8q1 {
    margin: -8.25rem !important; }
  .mt-md-n8q1,
  .my-md-n8q1 {
    margin-top: -8.25rem !important; }
  .mr-md-n8q1,
  .mx-md-n8q1 {
    margin-right: -8.25rem !important; }
  .mb-md-n8q1,
  .my-md-n8q1 {
    margin-bottom: -8.25rem !important; }
  .ml-md-n8q1,
  .mx-md-n8q1 {
    margin-left: -8.25rem !important; }
  .m-md-n8q2 {
    margin: -8.5rem !important; }
  .mt-md-n8q2,
  .my-md-n8q2 {
    margin-top: -8.5rem !important; }
  .mr-md-n8q2,
  .mx-md-n8q2 {
    margin-right: -8.5rem !important; }
  .mb-md-n8q2,
  .my-md-n8q2 {
    margin-bottom: -8.5rem !important; }
  .ml-md-n8q2,
  .mx-md-n8q2 {
    margin-left: -8.5rem !important; }
  .m-md-n8q3 {
    margin: -8.75rem !important; }
  .mt-md-n8q3,
  .my-md-n8q3 {
    margin-top: -8.75rem !important; }
  .mr-md-n8q3,
  .mx-md-n8q3 {
    margin-right: -8.75rem !important; }
  .mb-md-n8q3,
  .my-md-n8q3 {
    margin-bottom: -8.75rem !important; }
  .ml-md-n8q3,
  .mx-md-n8q3 {
    margin-left: -8.75rem !important; }
  .m-md-n9 {
    margin: -9rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -9rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -9rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -9rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -9rem !important; }
  .m-md-n9q1 {
    margin: -9.25rem !important; }
  .mt-md-n9q1,
  .my-md-n9q1 {
    margin-top: -9.25rem !important; }
  .mr-md-n9q1,
  .mx-md-n9q1 {
    margin-right: -9.25rem !important; }
  .mb-md-n9q1,
  .my-md-n9q1 {
    margin-bottom: -9.25rem !important; }
  .ml-md-n9q1,
  .mx-md-n9q1 {
    margin-left: -9.25rem !important; }
  .m-md-n9q2 {
    margin: -9.5rem !important; }
  .mt-md-n9q2,
  .my-md-n9q2 {
    margin-top: -9.5rem !important; }
  .mr-md-n9q2,
  .mx-md-n9q2 {
    margin-right: -9.5rem !important; }
  .mb-md-n9q2,
  .my-md-n9q2 {
    margin-bottom: -9.5rem !important; }
  .ml-md-n9q2,
  .mx-md-n9q2 {
    margin-left: -9.5rem !important; }
  .m-md-n9q3 {
    margin: -9.75rem !important; }
  .mt-md-n9q3,
  .my-md-n9q3 {
    margin-top: -9.75rem !important; }
  .mr-md-n9q3,
  .mx-md-n9q3 {
    margin-right: -9.75rem !important; }
  .mb-md-n9q3,
  .my-md-n9q3 {
    margin-bottom: -9.75rem !important; }
  .ml-md-n9q3,
  .mx-md-n9q3 {
    margin-left: -9.75rem !important; }
  .m-md-n10 {
    margin: -10rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -10rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -10rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -10rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -10rem !important; }
  .m-md-n10q1 {
    margin: -10.25rem !important; }
  .mt-md-n10q1,
  .my-md-n10q1 {
    margin-top: -10.25rem !important; }
  .mr-md-n10q1,
  .mx-md-n10q1 {
    margin-right: -10.25rem !important; }
  .mb-md-n10q1,
  .my-md-n10q1 {
    margin-bottom: -10.25rem !important; }
  .ml-md-n10q1,
  .mx-md-n10q1 {
    margin-left: -10.25rem !important; }
  .m-md-n10q2 {
    margin: -10.5rem !important; }
  .mt-md-n10q2,
  .my-md-n10q2 {
    margin-top: -10.5rem !important; }
  .mr-md-n10q2,
  .mx-md-n10q2 {
    margin-right: -10.5rem !important; }
  .mb-md-n10q2,
  .my-md-n10q2 {
    margin-bottom: -10.5rem !important; }
  .ml-md-n10q2,
  .mx-md-n10q2 {
    margin-left: -10.5rem !important; }
  .m-md-n10q3 {
    margin: -10.75rem !important; }
  .mt-md-n10q3,
  .my-md-n10q3 {
    margin-top: -10.75rem !important; }
  .mr-md-n10q3,
  .mx-md-n10q3 {
    margin-right: -10.75rem !important; }
  .mb-md-n10q3,
  .my-md-n10q3 {
    margin-bottom: -10.75rem !important; }
  .ml-md-n10q3,
  .mx-md-n10q3 {
    margin-left: -10.75rem !important; }
  .m-md-n11 {
    margin: -11rem !important; }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -11rem !important; }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -11rem !important; }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -11rem !important; }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -11rem !important; }
  .m-md-n11q1 {
    margin: -11.25rem !important; }
  .mt-md-n11q1,
  .my-md-n11q1 {
    margin-top: -11.25rem !important; }
  .mr-md-n11q1,
  .mx-md-n11q1 {
    margin-right: -11.25rem !important; }
  .mb-md-n11q1,
  .my-md-n11q1 {
    margin-bottom: -11.25rem !important; }
  .ml-md-n11q1,
  .mx-md-n11q1 {
    margin-left: -11.25rem !important; }
  .m-md-n11 q2 {
    margin: -11.5rem !important; }
  .mt-md-n11 q2,
  .my-md-n11 q2 {
    margin-top: -11.5rem !important; }
  .mr-md-n11 q2,
  .mx-md-n11 q2 {
    margin-right: -11.5rem !important; }
  .mb-md-n11 q2,
  .my-md-n11 q2 {
    margin-bottom: -11.5rem !important; }
  .ml-md-n11 q2,
  .mx-md-n11 q2 {
    margin-left: -11.5rem !important; }
  .m-md-n11 q3 {
    margin: -11.75rem !important; }
  .mt-md-n11 q3,
  .my-md-n11 q3 {
    margin-top: -11.75rem !important; }
  .mr-md-n11 q3,
  .mx-md-n11 q3 {
    margin-right: -11.75rem !important; }
  .mb-md-n11 q3,
  .my-md-n11 q3 {
    margin-bottom: -11.75rem !important; }
  .ml-md-n11 q3,
  .mx-md-n11 q3 {
    margin-left: -11.75rem !important; }
  .m-md-n12 {
    margin: -12rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -12rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -12rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -12rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -12rem !important; }
  .m-md-n12 q1 {
    margin: -12.25rem !important; }
  .mt-md-n12 q1,
  .my-md-n12 q1 {
    margin-top: -12.25rem !important; }
  .mr-md-n12 q1,
  .mx-md-n12 q1 {
    margin-right: -12.25rem !important; }
  .mb-md-n12 q1,
  .my-md-n12 q1 {
    margin-bottom: -12.25rem !important; }
  .ml-md-n12 q1,
  .mx-md-n12 q1 {
    margin-left: -12.25rem !important; }
  .m-md-n12 q2 {
    margin: -12.5rem !important; }
  .mt-md-n12 q2,
  .my-md-n12 q2 {
    margin-top: -12.5rem !important; }
  .mr-md-n12 q2,
  .mx-md-n12 q2 {
    margin-right: -12.5rem !important; }
  .mb-md-n12 q2,
  .my-md-n12 q2 {
    margin-bottom: -12.5rem !important; }
  .ml-md-n12 q2,
  .mx-md-n12 q2 {
    margin-left: -12.5rem !important; }
  .m-md-n12 q3 {
    margin: -12.75rem !important; }
  .mt-md-n12 q3,
  .my-md-n12 q3 {
    margin-top: -12.75rem !important; }
  .mr-md-n12 q3,
  .mx-md-n12 q3 {
    margin-right: -12.75rem !important; }
  .mb-md-n12 q3,
  .my-md-n12 q3 {
    margin-bottom: -12.75rem !important; }
  .ml-md-n12 q3,
  .mx-md-n12 q3 {
    margin-left: -12.75rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-q1 {
    margin: 0.25rem !important; }
  .mt-lg-q1,
  .my-lg-q1 {
    margin-top: 0.25rem !important; }
  .mr-lg-q1,
  .mx-lg-q1 {
    margin-right: 0.25rem !important; }
  .mb-lg-q1,
  .my-lg-q1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-q1,
  .mx-lg-q1 {
    margin-left: 0.25rem !important; }
  .m-lg-q2 {
    margin: 0.5rem !important; }
  .mt-lg-q2,
  .my-lg-q2 {
    margin-top: 0.5rem !important; }
  .mr-lg-q2,
  .mx-lg-q2 {
    margin-right: 0.5rem !important; }
  .mb-lg-q2,
  .my-lg-q2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-q2,
  .mx-lg-q2 {
    margin-left: 0.5rem !important; }
  .m-lg-q3 {
    margin: 0.75rem !important; }
  .mt-lg-q3,
  .my-lg-q3 {
    margin-top: 0.75rem !important; }
  .mr-lg-q3,
  .mx-lg-q3 {
    margin-right: 0.75rem !important; }
  .mb-lg-q3,
  .my-lg-q3 {
    margin-bottom: 0.75rem !important; }
  .ml-lg-q3,
  .mx-lg-q3 {
    margin-left: 0.75rem !important; }
  .m-lg-0 {
    margin: 0rem !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0rem !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0rem !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0rem !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0rem !important; }
  .m-lg-1 {
    margin: 1rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 1rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 1rem !important; }
  .m-lg-1q1 {
    margin: 1.25rem !important; }
  .mt-lg-1q1,
  .my-lg-1q1 {
    margin-top: 1.25rem !important; }
  .mr-lg-1q1,
  .mx-lg-1q1 {
    margin-right: 1.25rem !important; }
  .mb-lg-1q1,
  .my-lg-1q1 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-1q1,
  .mx-lg-1q1 {
    margin-left: 1.25rem !important; }
  .m-lg-1q2 {
    margin: 1.5rem !important; }
  .mt-lg-1q2,
  .my-lg-1q2 {
    margin-top: 1.5rem !important; }
  .mr-lg-1q2,
  .mx-lg-1q2 {
    margin-right: 1.5rem !important; }
  .mb-lg-1q2,
  .my-lg-1q2 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-1q2,
  .mx-lg-1q2 {
    margin-left: 1.5rem !important; }
  .m-lg-1q3 {
    margin: 1.75rem !important; }
  .mt-lg-1q3,
  .my-lg-1q3 {
    margin-top: 1.75rem !important; }
  .mr-lg-1q3,
  .mx-lg-1q3 {
    margin-right: 1.75rem !important; }
  .mb-lg-1q3,
  .my-lg-1q3 {
    margin-bottom: 1.75rem !important; }
  .ml-lg-1q3,
  .mx-lg-1q3 {
    margin-left: 1.75rem !important; }
  .m-lg-2 {
    margin: 2rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 2rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 2rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 2rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 2rem !important; }
  .m-lg-2q1 {
    margin: 2.25rem !important; }
  .mt-lg-2q1,
  .my-lg-2q1 {
    margin-top: 2.25rem !important; }
  .mr-lg-2q1,
  .mx-lg-2q1 {
    margin-right: 2.25rem !important; }
  .mb-lg-2q1,
  .my-lg-2q1 {
    margin-bottom: 2.25rem !important; }
  .ml-lg-2q1,
  .mx-lg-2q1 {
    margin-left: 2.25rem !important; }
  .m-lg-2q2 {
    margin: 2.5rem !important; }
  .mt-lg-2q2,
  .my-lg-2q2 {
    margin-top: 2.5rem !important; }
  .mr-lg-2q2,
  .mx-lg-2q2 {
    margin-right: 2.5rem !important; }
  .mb-lg-2q2,
  .my-lg-2q2 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-2q2,
  .mx-lg-2q2 {
    margin-left: 2.5rem !important; }
  .m-lg-2q3 {
    margin: 2.75rem !important; }
  .mt-lg-2q3,
  .my-lg-2q3 {
    margin-top: 2.75rem !important; }
  .mr-lg-2q3,
  .mx-lg-2q3 {
    margin-right: 2.75rem !important; }
  .mb-lg-2q3,
  .my-lg-2q3 {
    margin-bottom: 2.75rem !important; }
  .ml-lg-2q3,
  .mx-lg-2q3 {
    margin-left: 2.75rem !important; }
  .m-lg-3 {
    margin: 3rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 3rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 3rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 3rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 3rem !important; }
  .m-lg-3q1 {
    margin: 3.25rem !important; }
  .mt-lg-3q1,
  .my-lg-3q1 {
    margin-top: 3.25rem !important; }
  .mr-lg-3q1,
  .mx-lg-3q1 {
    margin-right: 3.25rem !important; }
  .mb-lg-3q1,
  .my-lg-3q1 {
    margin-bottom: 3.25rem !important; }
  .ml-lg-3q1,
  .mx-lg-3q1 {
    margin-left: 3.25rem !important; }
  .m-lg-3q2 {
    margin: 3.5rem !important; }
  .mt-lg-3q2,
  .my-lg-3q2 {
    margin-top: 3.5rem !important; }
  .mr-lg-3q2,
  .mx-lg-3q2 {
    margin-right: 3.5rem !important; }
  .mb-lg-3q2,
  .my-lg-3q2 {
    margin-bottom: 3.5rem !important; }
  .ml-lg-3q2,
  .mx-lg-3q2 {
    margin-left: 3.5rem !important; }
  .m-lg-3q3 {
    margin: 3.75rem !important; }
  .mt-lg-3q3,
  .my-lg-3q3 {
    margin-top: 3.75rem !important; }
  .mr-lg-3q3,
  .mx-lg-3q3 {
    margin-right: 3.75rem !important; }
  .mb-lg-3q3,
  .my-lg-3q3 {
    margin-bottom: 3.75rem !important; }
  .ml-lg-3q3,
  .mx-lg-3q3 {
    margin-left: 3.75rem !important; }
  .m-lg-4 {
    margin: 4rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 4rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 4rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 4rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 4rem !important; }
  .m-lg-4q1 {
    margin: 4.25rem !important; }
  .mt-lg-4q1,
  .my-lg-4q1 {
    margin-top: 4.25rem !important; }
  .mr-lg-4q1,
  .mx-lg-4q1 {
    margin-right: 4.25rem !important; }
  .mb-lg-4q1,
  .my-lg-4q1 {
    margin-bottom: 4.25rem !important; }
  .ml-lg-4q1,
  .mx-lg-4q1 {
    margin-left: 4.25rem !important; }
  .m-lg-4q2 {
    margin: 4.5rem !important; }
  .mt-lg-4q2,
  .my-lg-4q2 {
    margin-top: 4.5rem !important; }
  .mr-lg-4q2,
  .mx-lg-4q2 {
    margin-right: 4.5rem !important; }
  .mb-lg-4q2,
  .my-lg-4q2 {
    margin-bottom: 4.5rem !important; }
  .ml-lg-4q2,
  .mx-lg-4q2 {
    margin-left: 4.5rem !important; }
  .m-lg-4q3 {
    margin: 4.75rem !important; }
  .mt-lg-4q3,
  .my-lg-4q3 {
    margin-top: 4.75rem !important; }
  .mr-lg-4q3,
  .mx-lg-4q3 {
    margin-right: 4.75rem !important; }
  .mb-lg-4q3,
  .my-lg-4q3 {
    margin-bottom: 4.75rem !important; }
  .ml-lg-4q3,
  .mx-lg-4q3 {
    margin-left: 4.75rem !important; }
  .m-lg-5 {
    margin: 5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5rem !important; }
  .m-lg-5q1 {
    margin: 5.25rem !important; }
  .mt-lg-5q1,
  .my-lg-5q1 {
    margin-top: 5.25rem !important; }
  .mr-lg-5q1,
  .mx-lg-5q1 {
    margin-right: 5.25rem !important; }
  .mb-lg-5q1,
  .my-lg-5q1 {
    margin-bottom: 5.25rem !important; }
  .ml-lg-5q1,
  .mx-lg-5q1 {
    margin-left: 5.25rem !important; }
  .m-lg-5q2 {
    margin: 5.5rem !important; }
  .mt-lg-5q2,
  .my-lg-5q2 {
    margin-top: 5.5rem !important; }
  .mr-lg-5q2,
  .mx-lg-5q2 {
    margin-right: 5.5rem !important; }
  .mb-lg-5q2,
  .my-lg-5q2 {
    margin-bottom: 5.5rem !important; }
  .ml-lg-5q2,
  .mx-lg-5q2 {
    margin-left: 5.5rem !important; }
  .m-lg-5q3 {
    margin: 5.75rem !important; }
  .mt-lg-5q3,
  .my-lg-5q3 {
    margin-top: 5.75rem !important; }
  .mr-lg-5q3,
  .mx-lg-5q3 {
    margin-right: 5.75rem !important; }
  .mb-lg-5q3,
  .my-lg-5q3 {
    margin-bottom: 5.75rem !important; }
  .ml-lg-5q3,
  .mx-lg-5q3 {
    margin-left: 5.75rem !important; }
  .m-lg-6 {
    margin: 6rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 6rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 6rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 6rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 6rem !important; }
  .m-lg-6q1 {
    margin: 6.25rem !important; }
  .mt-lg-6q1,
  .my-lg-6q1 {
    margin-top: 6.25rem !important; }
  .mr-lg-6q1,
  .mx-lg-6q1 {
    margin-right: 6.25rem !important; }
  .mb-lg-6q1,
  .my-lg-6q1 {
    margin-bottom: 6.25rem !important; }
  .ml-lg-6q1,
  .mx-lg-6q1 {
    margin-left: 6.25rem !important; }
  .m-lg-6q2 {
    margin: 6.5rem !important; }
  .mt-lg-6q2,
  .my-lg-6q2 {
    margin-top: 6.5rem !important; }
  .mr-lg-6q2,
  .mx-lg-6q2 {
    margin-right: 6.5rem !important; }
  .mb-lg-6q2,
  .my-lg-6q2 {
    margin-bottom: 6.5rem !important; }
  .ml-lg-6q2,
  .mx-lg-6q2 {
    margin-left: 6.5rem !important; }
  .m-lg-6q3 {
    margin: 6.75rem !important; }
  .mt-lg-6q3,
  .my-lg-6q3 {
    margin-top: 6.75rem !important; }
  .mr-lg-6q3,
  .mx-lg-6q3 {
    margin-right: 6.75rem !important; }
  .mb-lg-6q3,
  .my-lg-6q3 {
    margin-bottom: 6.75rem !important; }
  .ml-lg-6q3,
  .mx-lg-6q3 {
    margin-left: 6.75rem !important; }
  .m-lg-7 {
    margin: 7rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 7rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 7rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 7rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 7rem !important; }
  .m-lg-7q1 {
    margin: 7.25rem !important; }
  .mt-lg-7q1,
  .my-lg-7q1 {
    margin-top: 7.25rem !important; }
  .mr-lg-7q1,
  .mx-lg-7q1 {
    margin-right: 7.25rem !important; }
  .mb-lg-7q1,
  .my-lg-7q1 {
    margin-bottom: 7.25rem !important; }
  .ml-lg-7q1,
  .mx-lg-7q1 {
    margin-left: 7.25rem !important; }
  .m-lg-7q2 {
    margin: 7.5rem !important; }
  .mt-lg-7q2,
  .my-lg-7q2 {
    margin-top: 7.5rem !important; }
  .mr-lg-7q2,
  .mx-lg-7q2 {
    margin-right: 7.5rem !important; }
  .mb-lg-7q2,
  .my-lg-7q2 {
    margin-bottom: 7.5rem !important; }
  .ml-lg-7q2,
  .mx-lg-7q2 {
    margin-left: 7.5rem !important; }
  .m-lg-7q3 {
    margin: 7.75rem !important; }
  .mt-lg-7q3,
  .my-lg-7q3 {
    margin-top: 7.75rem !important; }
  .mr-lg-7q3,
  .mx-lg-7q3 {
    margin-right: 7.75rem !important; }
  .mb-lg-7q3,
  .my-lg-7q3 {
    margin-bottom: 7.75rem !important; }
  .ml-lg-7q3,
  .mx-lg-7q3 {
    margin-left: 7.75rem !important; }
  .m-lg-8 {
    margin: 8rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 8rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 8rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 8rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 8rem !important; }
  .m-lg-8q1 {
    margin: 8.25rem !important; }
  .mt-lg-8q1,
  .my-lg-8q1 {
    margin-top: 8.25rem !important; }
  .mr-lg-8q1,
  .mx-lg-8q1 {
    margin-right: 8.25rem !important; }
  .mb-lg-8q1,
  .my-lg-8q1 {
    margin-bottom: 8.25rem !important; }
  .ml-lg-8q1,
  .mx-lg-8q1 {
    margin-left: 8.25rem !important; }
  .m-lg-8q2 {
    margin: 8.5rem !important; }
  .mt-lg-8q2,
  .my-lg-8q2 {
    margin-top: 8.5rem !important; }
  .mr-lg-8q2,
  .mx-lg-8q2 {
    margin-right: 8.5rem !important; }
  .mb-lg-8q2,
  .my-lg-8q2 {
    margin-bottom: 8.5rem !important; }
  .ml-lg-8q2,
  .mx-lg-8q2 {
    margin-left: 8.5rem !important; }
  .m-lg-8q3 {
    margin: 8.75rem !important; }
  .mt-lg-8q3,
  .my-lg-8q3 {
    margin-top: 8.75rem !important; }
  .mr-lg-8q3,
  .mx-lg-8q3 {
    margin-right: 8.75rem !important; }
  .mb-lg-8q3,
  .my-lg-8q3 {
    margin-bottom: 8.75rem !important; }
  .ml-lg-8q3,
  .mx-lg-8q3 {
    margin-left: 8.75rem !important; }
  .m-lg-9 {
    margin: 9rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 9rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 9rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 9rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 9rem !important; }
  .m-lg-9q1 {
    margin: 9.25rem !important; }
  .mt-lg-9q1,
  .my-lg-9q1 {
    margin-top: 9.25rem !important; }
  .mr-lg-9q1,
  .mx-lg-9q1 {
    margin-right: 9.25rem !important; }
  .mb-lg-9q1,
  .my-lg-9q1 {
    margin-bottom: 9.25rem !important; }
  .ml-lg-9q1,
  .mx-lg-9q1 {
    margin-left: 9.25rem !important; }
  .m-lg-9q2 {
    margin: 9.5rem !important; }
  .mt-lg-9q2,
  .my-lg-9q2 {
    margin-top: 9.5rem !important; }
  .mr-lg-9q2,
  .mx-lg-9q2 {
    margin-right: 9.5rem !important; }
  .mb-lg-9q2,
  .my-lg-9q2 {
    margin-bottom: 9.5rem !important; }
  .ml-lg-9q2,
  .mx-lg-9q2 {
    margin-left: 9.5rem !important; }
  .m-lg-9q3 {
    margin: 9.75rem !important; }
  .mt-lg-9q3,
  .my-lg-9q3 {
    margin-top: 9.75rem !important; }
  .mr-lg-9q3,
  .mx-lg-9q3 {
    margin-right: 9.75rem !important; }
  .mb-lg-9q3,
  .my-lg-9q3 {
    margin-bottom: 9.75rem !important; }
  .ml-lg-9q3,
  .mx-lg-9q3 {
    margin-left: 9.75rem !important; }
  .m-lg-10 {
    margin: 10rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10rem !important; }
  .m-lg-10q1 {
    margin: 10.25rem !important; }
  .mt-lg-10q1,
  .my-lg-10q1 {
    margin-top: 10.25rem !important; }
  .mr-lg-10q1,
  .mx-lg-10q1 {
    margin-right: 10.25rem !important; }
  .mb-lg-10q1,
  .my-lg-10q1 {
    margin-bottom: 10.25rem !important; }
  .ml-lg-10q1,
  .mx-lg-10q1 {
    margin-left: 10.25rem !important; }
  .m-lg-10q2 {
    margin: 10.5rem !important; }
  .mt-lg-10q2,
  .my-lg-10q2 {
    margin-top: 10.5rem !important; }
  .mr-lg-10q2,
  .mx-lg-10q2 {
    margin-right: 10.5rem !important; }
  .mb-lg-10q2,
  .my-lg-10q2 {
    margin-bottom: 10.5rem !important; }
  .ml-lg-10q2,
  .mx-lg-10q2 {
    margin-left: 10.5rem !important; }
  .m-lg-10q3 {
    margin: 10.75rem !important; }
  .mt-lg-10q3,
  .my-lg-10q3 {
    margin-top: 10.75rem !important; }
  .mr-lg-10q3,
  .mx-lg-10q3 {
    margin-right: 10.75rem !important; }
  .mb-lg-10q3,
  .my-lg-10q3 {
    margin-bottom: 10.75rem !important; }
  .ml-lg-10q3,
  .mx-lg-10q3 {
    margin-left: 10.75rem !important; }
  .m-lg-11 {
    margin: 11rem !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 11rem !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 11rem !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 11rem !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 11rem !important; }
  .m-lg-11q1 {
    margin: 11.25rem !important; }
  .mt-lg-11q1,
  .my-lg-11q1 {
    margin-top: 11.25rem !important; }
  .mr-lg-11q1,
  .mx-lg-11q1 {
    margin-right: 11.25rem !important; }
  .mb-lg-11q1,
  .my-lg-11q1 {
    margin-bottom: 11.25rem !important; }
  .ml-lg-11q1,
  .mx-lg-11q1 {
    margin-left: 11.25rem !important; }
  .m-lg-11 q2 {
    margin: 11.5rem !important; }
  .mt-lg-11 q2,
  .my-lg-11 q2 {
    margin-top: 11.5rem !important; }
  .mr-lg-11 q2,
  .mx-lg-11 q2 {
    margin-right: 11.5rem !important; }
  .mb-lg-11 q2,
  .my-lg-11 q2 {
    margin-bottom: 11.5rem !important; }
  .ml-lg-11 q2,
  .mx-lg-11 q2 {
    margin-left: 11.5rem !important; }
  .m-lg-11 q3 {
    margin: 11.75rem !important; }
  .mt-lg-11 q3,
  .my-lg-11 q3 {
    margin-top: 11.75rem !important; }
  .mr-lg-11 q3,
  .mx-lg-11 q3 {
    margin-right: 11.75rem !important; }
  .mb-lg-11 q3,
  .my-lg-11 q3 {
    margin-bottom: 11.75rem !important; }
  .ml-lg-11 q3,
  .mx-lg-11 q3 {
    margin-left: 11.75rem !important; }
  .m-lg-12 {
    margin: 12rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 12rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 12rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 12rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 12rem !important; }
  .m-lg-12 q1 {
    margin: 12.25rem !important; }
  .mt-lg-12 q1,
  .my-lg-12 q1 {
    margin-top: 12.25rem !important; }
  .mr-lg-12 q1,
  .mx-lg-12 q1 {
    margin-right: 12.25rem !important; }
  .mb-lg-12 q1,
  .my-lg-12 q1 {
    margin-bottom: 12.25rem !important; }
  .ml-lg-12 q1,
  .mx-lg-12 q1 {
    margin-left: 12.25rem !important; }
  .m-lg-12 q2 {
    margin: 12.5rem !important; }
  .mt-lg-12 q2,
  .my-lg-12 q2 {
    margin-top: 12.5rem !important; }
  .mr-lg-12 q2,
  .mx-lg-12 q2 {
    margin-right: 12.5rem !important; }
  .mb-lg-12 q2,
  .my-lg-12 q2 {
    margin-bottom: 12.5rem !important; }
  .ml-lg-12 q2,
  .mx-lg-12 q2 {
    margin-left: 12.5rem !important; }
  .m-lg-12 q3 {
    margin: 12.75rem !important; }
  .mt-lg-12 q3,
  .my-lg-12 q3 {
    margin-top: 12.75rem !important; }
  .mr-lg-12 q3,
  .mx-lg-12 q3 {
    margin-right: 12.75rem !important; }
  .mb-lg-12 q3,
  .my-lg-12 q3 {
    margin-bottom: 12.75rem !important; }
  .ml-lg-12 q3,
  .mx-lg-12 q3 {
    margin-left: 12.75rem !important; }
  .p-lg-q1 {
    padding: 0.25rem !important; }
  .pt-lg-q1,
  .py-lg-q1 {
    padding-top: 0.25rem !important; }
  .pr-lg-q1,
  .px-lg-q1 {
    padding-right: 0.25rem !important; }
  .pb-lg-q1,
  .py-lg-q1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-q1,
  .px-lg-q1 {
    padding-left: 0.25rem !important; }
  .p-lg-q2 {
    padding: 0.5rem !important; }
  .pt-lg-q2,
  .py-lg-q2 {
    padding-top: 0.5rem !important; }
  .pr-lg-q2,
  .px-lg-q2 {
    padding-right: 0.5rem !important; }
  .pb-lg-q2,
  .py-lg-q2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-q2,
  .px-lg-q2 {
    padding-left: 0.5rem !important; }
  .p-lg-q3 {
    padding: 0.75rem !important; }
  .pt-lg-q3,
  .py-lg-q3 {
    padding-top: 0.75rem !important; }
  .pr-lg-q3,
  .px-lg-q3 {
    padding-right: 0.75rem !important; }
  .pb-lg-q3,
  .py-lg-q3 {
    padding-bottom: 0.75rem !important; }
  .pl-lg-q3,
  .px-lg-q3 {
    padding-left: 0.75rem !important; }
  .p-lg-0 {
    padding: 0rem !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0rem !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0rem !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0rem !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0rem !important; }
  .p-lg-1 {
    padding: 1rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 1rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 1rem !important; }
  .p-lg-1q1 {
    padding: 1.25rem !important; }
  .pt-lg-1q1,
  .py-lg-1q1 {
    padding-top: 1.25rem !important; }
  .pr-lg-1q1,
  .px-lg-1q1 {
    padding-right: 1.25rem !important; }
  .pb-lg-1q1,
  .py-lg-1q1 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-1q1,
  .px-lg-1q1 {
    padding-left: 1.25rem !important; }
  .p-lg-1q2 {
    padding: 1.5rem !important; }
  .pt-lg-1q2,
  .py-lg-1q2 {
    padding-top: 1.5rem !important; }
  .pr-lg-1q2,
  .px-lg-1q2 {
    padding-right: 1.5rem !important; }
  .pb-lg-1q2,
  .py-lg-1q2 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-1q2,
  .px-lg-1q2 {
    padding-left: 1.5rem !important; }
  .p-lg-1q3 {
    padding: 1.75rem !important; }
  .pt-lg-1q3,
  .py-lg-1q3 {
    padding-top: 1.75rem !important; }
  .pr-lg-1q3,
  .px-lg-1q3 {
    padding-right: 1.75rem !important; }
  .pb-lg-1q3,
  .py-lg-1q3 {
    padding-bottom: 1.75rem !important; }
  .pl-lg-1q3,
  .px-lg-1q3 {
    padding-left: 1.75rem !important; }
  .p-lg-2 {
    padding: 2rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 2rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 2rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 2rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 2rem !important; }
  .p-lg-2q1 {
    padding: 2.25rem !important; }
  .pt-lg-2q1,
  .py-lg-2q1 {
    padding-top: 2.25rem !important; }
  .pr-lg-2q1,
  .px-lg-2q1 {
    padding-right: 2.25rem !important; }
  .pb-lg-2q1,
  .py-lg-2q1 {
    padding-bottom: 2.25rem !important; }
  .pl-lg-2q1,
  .px-lg-2q1 {
    padding-left: 2.25rem !important; }
  .p-lg-2q2 {
    padding: 2.5rem !important; }
  .pt-lg-2q2,
  .py-lg-2q2 {
    padding-top: 2.5rem !important; }
  .pr-lg-2q2,
  .px-lg-2q2 {
    padding-right: 2.5rem !important; }
  .pb-lg-2q2,
  .py-lg-2q2 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-2q2,
  .px-lg-2q2 {
    padding-left: 2.5rem !important; }
  .p-lg-2q3 {
    padding: 2.75rem !important; }
  .pt-lg-2q3,
  .py-lg-2q3 {
    padding-top: 2.75rem !important; }
  .pr-lg-2q3,
  .px-lg-2q3 {
    padding-right: 2.75rem !important; }
  .pb-lg-2q3,
  .py-lg-2q3 {
    padding-bottom: 2.75rem !important; }
  .pl-lg-2q3,
  .px-lg-2q3 {
    padding-left: 2.75rem !important; }
  .p-lg-3 {
    padding: 3rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 3rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 3rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 3rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 3rem !important; }
  .p-lg-3q1 {
    padding: 3.25rem !important; }
  .pt-lg-3q1,
  .py-lg-3q1 {
    padding-top: 3.25rem !important; }
  .pr-lg-3q1,
  .px-lg-3q1 {
    padding-right: 3.25rem !important; }
  .pb-lg-3q1,
  .py-lg-3q1 {
    padding-bottom: 3.25rem !important; }
  .pl-lg-3q1,
  .px-lg-3q1 {
    padding-left: 3.25rem !important; }
  .p-lg-3q2 {
    padding: 3.5rem !important; }
  .pt-lg-3q2,
  .py-lg-3q2 {
    padding-top: 3.5rem !important; }
  .pr-lg-3q2,
  .px-lg-3q2 {
    padding-right: 3.5rem !important; }
  .pb-lg-3q2,
  .py-lg-3q2 {
    padding-bottom: 3.5rem !important; }
  .pl-lg-3q2,
  .px-lg-3q2 {
    padding-left: 3.5rem !important; }
  .p-lg-3q3 {
    padding: 3.75rem !important; }
  .pt-lg-3q3,
  .py-lg-3q3 {
    padding-top: 3.75rem !important; }
  .pr-lg-3q3,
  .px-lg-3q3 {
    padding-right: 3.75rem !important; }
  .pb-lg-3q3,
  .py-lg-3q3 {
    padding-bottom: 3.75rem !important; }
  .pl-lg-3q3,
  .px-lg-3q3 {
    padding-left: 3.75rem !important; }
  .p-lg-4 {
    padding: 4rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 4rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 4rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 4rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 4rem !important; }
  .p-lg-4q1 {
    padding: 4.25rem !important; }
  .pt-lg-4q1,
  .py-lg-4q1 {
    padding-top: 4.25rem !important; }
  .pr-lg-4q1,
  .px-lg-4q1 {
    padding-right: 4.25rem !important; }
  .pb-lg-4q1,
  .py-lg-4q1 {
    padding-bottom: 4.25rem !important; }
  .pl-lg-4q1,
  .px-lg-4q1 {
    padding-left: 4.25rem !important; }
  .p-lg-4q2 {
    padding: 4.5rem !important; }
  .pt-lg-4q2,
  .py-lg-4q2 {
    padding-top: 4.5rem !important; }
  .pr-lg-4q2,
  .px-lg-4q2 {
    padding-right: 4.5rem !important; }
  .pb-lg-4q2,
  .py-lg-4q2 {
    padding-bottom: 4.5rem !important; }
  .pl-lg-4q2,
  .px-lg-4q2 {
    padding-left: 4.5rem !important; }
  .p-lg-4q3 {
    padding: 4.75rem !important; }
  .pt-lg-4q3,
  .py-lg-4q3 {
    padding-top: 4.75rem !important; }
  .pr-lg-4q3,
  .px-lg-4q3 {
    padding-right: 4.75rem !important; }
  .pb-lg-4q3,
  .py-lg-4q3 {
    padding-bottom: 4.75rem !important; }
  .pl-lg-4q3,
  .px-lg-4q3 {
    padding-left: 4.75rem !important; }
  .p-lg-5 {
    padding: 5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5rem !important; }
  .p-lg-5q1 {
    padding: 5.25rem !important; }
  .pt-lg-5q1,
  .py-lg-5q1 {
    padding-top: 5.25rem !important; }
  .pr-lg-5q1,
  .px-lg-5q1 {
    padding-right: 5.25rem !important; }
  .pb-lg-5q1,
  .py-lg-5q1 {
    padding-bottom: 5.25rem !important; }
  .pl-lg-5q1,
  .px-lg-5q1 {
    padding-left: 5.25rem !important; }
  .p-lg-5q2 {
    padding: 5.5rem !important; }
  .pt-lg-5q2,
  .py-lg-5q2 {
    padding-top: 5.5rem !important; }
  .pr-lg-5q2,
  .px-lg-5q2 {
    padding-right: 5.5rem !important; }
  .pb-lg-5q2,
  .py-lg-5q2 {
    padding-bottom: 5.5rem !important; }
  .pl-lg-5q2,
  .px-lg-5q2 {
    padding-left: 5.5rem !important; }
  .p-lg-5q3 {
    padding: 5.75rem !important; }
  .pt-lg-5q3,
  .py-lg-5q3 {
    padding-top: 5.75rem !important; }
  .pr-lg-5q3,
  .px-lg-5q3 {
    padding-right: 5.75rem !important; }
  .pb-lg-5q3,
  .py-lg-5q3 {
    padding-bottom: 5.75rem !important; }
  .pl-lg-5q3,
  .px-lg-5q3 {
    padding-left: 5.75rem !important; }
  .p-lg-6 {
    padding: 6rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 6rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 6rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 6rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 6rem !important; }
  .p-lg-6q1 {
    padding: 6.25rem !important; }
  .pt-lg-6q1,
  .py-lg-6q1 {
    padding-top: 6.25rem !important; }
  .pr-lg-6q1,
  .px-lg-6q1 {
    padding-right: 6.25rem !important; }
  .pb-lg-6q1,
  .py-lg-6q1 {
    padding-bottom: 6.25rem !important; }
  .pl-lg-6q1,
  .px-lg-6q1 {
    padding-left: 6.25rem !important; }
  .p-lg-6q2 {
    padding: 6.5rem !important; }
  .pt-lg-6q2,
  .py-lg-6q2 {
    padding-top: 6.5rem !important; }
  .pr-lg-6q2,
  .px-lg-6q2 {
    padding-right: 6.5rem !important; }
  .pb-lg-6q2,
  .py-lg-6q2 {
    padding-bottom: 6.5rem !important; }
  .pl-lg-6q2,
  .px-lg-6q2 {
    padding-left: 6.5rem !important; }
  .p-lg-6q3 {
    padding: 6.75rem !important; }
  .pt-lg-6q3,
  .py-lg-6q3 {
    padding-top: 6.75rem !important; }
  .pr-lg-6q3,
  .px-lg-6q3 {
    padding-right: 6.75rem !important; }
  .pb-lg-6q3,
  .py-lg-6q3 {
    padding-bottom: 6.75rem !important; }
  .pl-lg-6q3,
  .px-lg-6q3 {
    padding-left: 6.75rem !important; }
  .p-lg-7 {
    padding: 7rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 7rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 7rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 7rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 7rem !important; }
  .p-lg-7q1 {
    padding: 7.25rem !important; }
  .pt-lg-7q1,
  .py-lg-7q1 {
    padding-top: 7.25rem !important; }
  .pr-lg-7q1,
  .px-lg-7q1 {
    padding-right: 7.25rem !important; }
  .pb-lg-7q1,
  .py-lg-7q1 {
    padding-bottom: 7.25rem !important; }
  .pl-lg-7q1,
  .px-lg-7q1 {
    padding-left: 7.25rem !important; }
  .p-lg-7q2 {
    padding: 7.5rem !important; }
  .pt-lg-7q2,
  .py-lg-7q2 {
    padding-top: 7.5rem !important; }
  .pr-lg-7q2,
  .px-lg-7q2 {
    padding-right: 7.5rem !important; }
  .pb-lg-7q2,
  .py-lg-7q2 {
    padding-bottom: 7.5rem !important; }
  .pl-lg-7q2,
  .px-lg-7q2 {
    padding-left: 7.5rem !important; }
  .p-lg-7q3 {
    padding: 7.75rem !important; }
  .pt-lg-7q3,
  .py-lg-7q3 {
    padding-top: 7.75rem !important; }
  .pr-lg-7q3,
  .px-lg-7q3 {
    padding-right: 7.75rem !important; }
  .pb-lg-7q3,
  .py-lg-7q3 {
    padding-bottom: 7.75rem !important; }
  .pl-lg-7q3,
  .px-lg-7q3 {
    padding-left: 7.75rem !important; }
  .p-lg-8 {
    padding: 8rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 8rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 8rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 8rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 8rem !important; }
  .p-lg-8q1 {
    padding: 8.25rem !important; }
  .pt-lg-8q1,
  .py-lg-8q1 {
    padding-top: 8.25rem !important; }
  .pr-lg-8q1,
  .px-lg-8q1 {
    padding-right: 8.25rem !important; }
  .pb-lg-8q1,
  .py-lg-8q1 {
    padding-bottom: 8.25rem !important; }
  .pl-lg-8q1,
  .px-lg-8q1 {
    padding-left: 8.25rem !important; }
  .p-lg-8q2 {
    padding: 8.5rem !important; }
  .pt-lg-8q2,
  .py-lg-8q2 {
    padding-top: 8.5rem !important; }
  .pr-lg-8q2,
  .px-lg-8q2 {
    padding-right: 8.5rem !important; }
  .pb-lg-8q2,
  .py-lg-8q2 {
    padding-bottom: 8.5rem !important; }
  .pl-lg-8q2,
  .px-lg-8q2 {
    padding-left: 8.5rem !important; }
  .p-lg-8q3 {
    padding: 8.75rem !important; }
  .pt-lg-8q3,
  .py-lg-8q3 {
    padding-top: 8.75rem !important; }
  .pr-lg-8q3,
  .px-lg-8q3 {
    padding-right: 8.75rem !important; }
  .pb-lg-8q3,
  .py-lg-8q3 {
    padding-bottom: 8.75rem !important; }
  .pl-lg-8q3,
  .px-lg-8q3 {
    padding-left: 8.75rem !important; }
  .p-lg-9 {
    padding: 9rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 9rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 9rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 9rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 9rem !important; }
  .p-lg-9q1 {
    padding: 9.25rem !important; }
  .pt-lg-9q1,
  .py-lg-9q1 {
    padding-top: 9.25rem !important; }
  .pr-lg-9q1,
  .px-lg-9q1 {
    padding-right: 9.25rem !important; }
  .pb-lg-9q1,
  .py-lg-9q1 {
    padding-bottom: 9.25rem !important; }
  .pl-lg-9q1,
  .px-lg-9q1 {
    padding-left: 9.25rem !important; }
  .p-lg-9q2 {
    padding: 9.5rem !important; }
  .pt-lg-9q2,
  .py-lg-9q2 {
    padding-top: 9.5rem !important; }
  .pr-lg-9q2,
  .px-lg-9q2 {
    padding-right: 9.5rem !important; }
  .pb-lg-9q2,
  .py-lg-9q2 {
    padding-bottom: 9.5rem !important; }
  .pl-lg-9q2,
  .px-lg-9q2 {
    padding-left: 9.5rem !important; }
  .p-lg-9q3 {
    padding: 9.75rem !important; }
  .pt-lg-9q3,
  .py-lg-9q3 {
    padding-top: 9.75rem !important; }
  .pr-lg-9q3,
  .px-lg-9q3 {
    padding-right: 9.75rem !important; }
  .pb-lg-9q3,
  .py-lg-9q3 {
    padding-bottom: 9.75rem !important; }
  .pl-lg-9q3,
  .px-lg-9q3 {
    padding-left: 9.75rem !important; }
  .p-lg-10 {
    padding: 10rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10rem !important; }
  .p-lg-10q1 {
    padding: 10.25rem !important; }
  .pt-lg-10q1,
  .py-lg-10q1 {
    padding-top: 10.25rem !important; }
  .pr-lg-10q1,
  .px-lg-10q1 {
    padding-right: 10.25rem !important; }
  .pb-lg-10q1,
  .py-lg-10q1 {
    padding-bottom: 10.25rem !important; }
  .pl-lg-10q1,
  .px-lg-10q1 {
    padding-left: 10.25rem !important; }
  .p-lg-10q2 {
    padding: 10.5rem !important; }
  .pt-lg-10q2,
  .py-lg-10q2 {
    padding-top: 10.5rem !important; }
  .pr-lg-10q2,
  .px-lg-10q2 {
    padding-right: 10.5rem !important; }
  .pb-lg-10q2,
  .py-lg-10q2 {
    padding-bottom: 10.5rem !important; }
  .pl-lg-10q2,
  .px-lg-10q2 {
    padding-left: 10.5rem !important; }
  .p-lg-10q3 {
    padding: 10.75rem !important; }
  .pt-lg-10q3,
  .py-lg-10q3 {
    padding-top: 10.75rem !important; }
  .pr-lg-10q3,
  .px-lg-10q3 {
    padding-right: 10.75rem !important; }
  .pb-lg-10q3,
  .py-lg-10q3 {
    padding-bottom: 10.75rem !important; }
  .pl-lg-10q3,
  .px-lg-10q3 {
    padding-left: 10.75rem !important; }
  .p-lg-11 {
    padding: 11rem !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 11rem !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 11rem !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 11rem !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 11rem !important; }
  .p-lg-11q1 {
    padding: 11.25rem !important; }
  .pt-lg-11q1,
  .py-lg-11q1 {
    padding-top: 11.25rem !important; }
  .pr-lg-11q1,
  .px-lg-11q1 {
    padding-right: 11.25rem !important; }
  .pb-lg-11q1,
  .py-lg-11q1 {
    padding-bottom: 11.25rem !important; }
  .pl-lg-11q1,
  .px-lg-11q1 {
    padding-left: 11.25rem !important; }
  .p-lg-11 q2 {
    padding: 11.5rem !important; }
  .pt-lg-11 q2,
  .py-lg-11 q2 {
    padding-top: 11.5rem !important; }
  .pr-lg-11 q2,
  .px-lg-11 q2 {
    padding-right: 11.5rem !important; }
  .pb-lg-11 q2,
  .py-lg-11 q2 {
    padding-bottom: 11.5rem !important; }
  .pl-lg-11 q2,
  .px-lg-11 q2 {
    padding-left: 11.5rem !important; }
  .p-lg-11 q3 {
    padding: 11.75rem !important; }
  .pt-lg-11 q3,
  .py-lg-11 q3 {
    padding-top: 11.75rem !important; }
  .pr-lg-11 q3,
  .px-lg-11 q3 {
    padding-right: 11.75rem !important; }
  .pb-lg-11 q3,
  .py-lg-11 q3 {
    padding-bottom: 11.75rem !important; }
  .pl-lg-11 q3,
  .px-lg-11 q3 {
    padding-left: 11.75rem !important; }
  .p-lg-12 {
    padding: 12rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 12rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 12rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 12rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 12rem !important; }
  .p-lg-12 q1 {
    padding: 12.25rem !important; }
  .pt-lg-12 q1,
  .py-lg-12 q1 {
    padding-top: 12.25rem !important; }
  .pr-lg-12 q1,
  .px-lg-12 q1 {
    padding-right: 12.25rem !important; }
  .pb-lg-12 q1,
  .py-lg-12 q1 {
    padding-bottom: 12.25rem !important; }
  .pl-lg-12 q1,
  .px-lg-12 q1 {
    padding-left: 12.25rem !important; }
  .p-lg-12 q2 {
    padding: 12.5rem !important; }
  .pt-lg-12 q2,
  .py-lg-12 q2 {
    padding-top: 12.5rem !important; }
  .pr-lg-12 q2,
  .px-lg-12 q2 {
    padding-right: 12.5rem !important; }
  .pb-lg-12 q2,
  .py-lg-12 q2 {
    padding-bottom: 12.5rem !important; }
  .pl-lg-12 q2,
  .px-lg-12 q2 {
    padding-left: 12.5rem !important; }
  .p-lg-12 q3 {
    padding: 12.75rem !important; }
  .pt-lg-12 q3,
  .py-lg-12 q3 {
    padding-top: 12.75rem !important; }
  .pr-lg-12 q3,
  .px-lg-12 q3 {
    padding-right: 12.75rem !important; }
  .pb-lg-12 q3,
  .py-lg-12 q3 {
    padding-bottom: 12.75rem !important; }
  .pl-lg-12 q3,
  .px-lg-12 q3 {
    padding-left: 12.75rem !important; }
  .m-lg-nq1 {
    margin: -0.25rem !important; }
  .mt-lg-nq1,
  .my-lg-nq1 {
    margin-top: -0.25rem !important; }
  .mr-lg-nq1,
  .mx-lg-nq1 {
    margin-right: -0.25rem !important; }
  .mb-lg-nq1,
  .my-lg-nq1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-nq1,
  .mx-lg-nq1 {
    margin-left: -0.25rem !important; }
  .m-lg-nq2 {
    margin: -0.5rem !important; }
  .mt-lg-nq2,
  .my-lg-nq2 {
    margin-top: -0.5rem !important; }
  .mr-lg-nq2,
  .mx-lg-nq2 {
    margin-right: -0.5rem !important; }
  .mb-lg-nq2,
  .my-lg-nq2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-nq2,
  .mx-lg-nq2 {
    margin-left: -0.5rem !important; }
  .m-lg-nq3 {
    margin: -0.75rem !important; }
  .mt-lg-nq3,
  .my-lg-nq3 {
    margin-top: -0.75rem !important; }
  .mr-lg-nq3,
  .mx-lg-nq3 {
    margin-right: -0.75rem !important; }
  .mb-lg-nq3,
  .my-lg-nq3 {
    margin-bottom: -0.75rem !important; }
  .ml-lg-nq3,
  .mx-lg-nq3 {
    margin-left: -0.75rem !important; }
  .m-lg-n1 {
    margin: -1rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -1rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -1rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -1rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -1rem !important; }
  .m-lg-n1q1 {
    margin: -1.25rem !important; }
  .mt-lg-n1q1,
  .my-lg-n1q1 {
    margin-top: -1.25rem !important; }
  .mr-lg-n1q1,
  .mx-lg-n1q1 {
    margin-right: -1.25rem !important; }
  .mb-lg-n1q1,
  .my-lg-n1q1 {
    margin-bottom: -1.25rem !important; }
  .ml-lg-n1q1,
  .mx-lg-n1q1 {
    margin-left: -1.25rem !important; }
  .m-lg-n1q2 {
    margin: -1.5rem !important; }
  .mt-lg-n1q2,
  .my-lg-n1q2 {
    margin-top: -1.5rem !important; }
  .mr-lg-n1q2,
  .mx-lg-n1q2 {
    margin-right: -1.5rem !important; }
  .mb-lg-n1q2,
  .my-lg-n1q2 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n1q2,
  .mx-lg-n1q2 {
    margin-left: -1.5rem !important; }
  .m-lg-n1q3 {
    margin: -1.75rem !important; }
  .mt-lg-n1q3,
  .my-lg-n1q3 {
    margin-top: -1.75rem !important; }
  .mr-lg-n1q3,
  .mx-lg-n1q3 {
    margin-right: -1.75rem !important; }
  .mb-lg-n1q3,
  .my-lg-n1q3 {
    margin-bottom: -1.75rem !important; }
  .ml-lg-n1q3,
  .mx-lg-n1q3 {
    margin-left: -1.75rem !important; }
  .m-lg-n2 {
    margin: -2rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -2rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -2rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -2rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -2rem !important; }
  .m-lg-n2q1 {
    margin: -2.25rem !important; }
  .mt-lg-n2q1,
  .my-lg-n2q1 {
    margin-top: -2.25rem !important; }
  .mr-lg-n2q1,
  .mx-lg-n2q1 {
    margin-right: -2.25rem !important; }
  .mb-lg-n2q1,
  .my-lg-n2q1 {
    margin-bottom: -2.25rem !important; }
  .ml-lg-n2q1,
  .mx-lg-n2q1 {
    margin-left: -2.25rem !important; }
  .m-lg-n2q2 {
    margin: -2.5rem !important; }
  .mt-lg-n2q2,
  .my-lg-n2q2 {
    margin-top: -2.5rem !important; }
  .mr-lg-n2q2,
  .mx-lg-n2q2 {
    margin-right: -2.5rem !important; }
  .mb-lg-n2q2,
  .my-lg-n2q2 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n2q2,
  .mx-lg-n2q2 {
    margin-left: -2.5rem !important; }
  .m-lg-n2q3 {
    margin: -2.75rem !important; }
  .mt-lg-n2q3,
  .my-lg-n2q3 {
    margin-top: -2.75rem !important; }
  .mr-lg-n2q3,
  .mx-lg-n2q3 {
    margin-right: -2.75rem !important; }
  .mb-lg-n2q3,
  .my-lg-n2q3 {
    margin-bottom: -2.75rem !important; }
  .ml-lg-n2q3,
  .mx-lg-n2q3 {
    margin-left: -2.75rem !important; }
  .m-lg-n3 {
    margin: -3rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -3rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -3rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -3rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -3rem !important; }
  .m-lg-n3q1 {
    margin: -3.25rem !important; }
  .mt-lg-n3q1,
  .my-lg-n3q1 {
    margin-top: -3.25rem !important; }
  .mr-lg-n3q1,
  .mx-lg-n3q1 {
    margin-right: -3.25rem !important; }
  .mb-lg-n3q1,
  .my-lg-n3q1 {
    margin-bottom: -3.25rem !important; }
  .ml-lg-n3q1,
  .mx-lg-n3q1 {
    margin-left: -3.25rem !important; }
  .m-lg-n3q2 {
    margin: -3.5rem !important; }
  .mt-lg-n3q2,
  .my-lg-n3q2 {
    margin-top: -3.5rem !important; }
  .mr-lg-n3q2,
  .mx-lg-n3q2 {
    margin-right: -3.5rem !important; }
  .mb-lg-n3q2,
  .my-lg-n3q2 {
    margin-bottom: -3.5rem !important; }
  .ml-lg-n3q2,
  .mx-lg-n3q2 {
    margin-left: -3.5rem !important; }
  .m-lg-n3q3 {
    margin: -3.75rem !important; }
  .mt-lg-n3q3,
  .my-lg-n3q3 {
    margin-top: -3.75rem !important; }
  .mr-lg-n3q3,
  .mx-lg-n3q3 {
    margin-right: -3.75rem !important; }
  .mb-lg-n3q3,
  .my-lg-n3q3 {
    margin-bottom: -3.75rem !important; }
  .ml-lg-n3q3,
  .mx-lg-n3q3 {
    margin-left: -3.75rem !important; }
  .m-lg-n4 {
    margin: -4rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -4rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -4rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -4rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -4rem !important; }
  .m-lg-n4q1 {
    margin: -4.25rem !important; }
  .mt-lg-n4q1,
  .my-lg-n4q1 {
    margin-top: -4.25rem !important; }
  .mr-lg-n4q1,
  .mx-lg-n4q1 {
    margin-right: -4.25rem !important; }
  .mb-lg-n4q1,
  .my-lg-n4q1 {
    margin-bottom: -4.25rem !important; }
  .ml-lg-n4q1,
  .mx-lg-n4q1 {
    margin-left: -4.25rem !important; }
  .m-lg-n4q2 {
    margin: -4.5rem !important; }
  .mt-lg-n4q2,
  .my-lg-n4q2 {
    margin-top: -4.5rem !important; }
  .mr-lg-n4q2,
  .mx-lg-n4q2 {
    margin-right: -4.5rem !important; }
  .mb-lg-n4q2,
  .my-lg-n4q2 {
    margin-bottom: -4.5rem !important; }
  .ml-lg-n4q2,
  .mx-lg-n4q2 {
    margin-left: -4.5rem !important; }
  .m-lg-n4q3 {
    margin: -4.75rem !important; }
  .mt-lg-n4q3,
  .my-lg-n4q3 {
    margin-top: -4.75rem !important; }
  .mr-lg-n4q3,
  .mx-lg-n4q3 {
    margin-right: -4.75rem !important; }
  .mb-lg-n4q3,
  .my-lg-n4q3 {
    margin-bottom: -4.75rem !important; }
  .ml-lg-n4q3,
  .mx-lg-n4q3 {
    margin-left: -4.75rem !important; }
  .m-lg-n5 {
    margin: -5rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -5rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -5rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -5rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -5rem !important; }
  .m-lg-n5q1 {
    margin: -5.25rem !important; }
  .mt-lg-n5q1,
  .my-lg-n5q1 {
    margin-top: -5.25rem !important; }
  .mr-lg-n5q1,
  .mx-lg-n5q1 {
    margin-right: -5.25rem !important; }
  .mb-lg-n5q1,
  .my-lg-n5q1 {
    margin-bottom: -5.25rem !important; }
  .ml-lg-n5q1,
  .mx-lg-n5q1 {
    margin-left: -5.25rem !important; }
  .m-lg-n5q2 {
    margin: -5.5rem !important; }
  .mt-lg-n5q2,
  .my-lg-n5q2 {
    margin-top: -5.5rem !important; }
  .mr-lg-n5q2,
  .mx-lg-n5q2 {
    margin-right: -5.5rem !important; }
  .mb-lg-n5q2,
  .my-lg-n5q2 {
    margin-bottom: -5.5rem !important; }
  .ml-lg-n5q2,
  .mx-lg-n5q2 {
    margin-left: -5.5rem !important; }
  .m-lg-n5q3 {
    margin: -5.75rem !important; }
  .mt-lg-n5q3,
  .my-lg-n5q3 {
    margin-top: -5.75rem !important; }
  .mr-lg-n5q3,
  .mx-lg-n5q3 {
    margin-right: -5.75rem !important; }
  .mb-lg-n5q3,
  .my-lg-n5q3 {
    margin-bottom: -5.75rem !important; }
  .ml-lg-n5q3,
  .mx-lg-n5q3 {
    margin-left: -5.75rem !important; }
  .m-lg-n6 {
    margin: -6rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -6rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -6rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -6rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -6rem !important; }
  .m-lg-n6q1 {
    margin: -6.25rem !important; }
  .mt-lg-n6q1,
  .my-lg-n6q1 {
    margin-top: -6.25rem !important; }
  .mr-lg-n6q1,
  .mx-lg-n6q1 {
    margin-right: -6.25rem !important; }
  .mb-lg-n6q1,
  .my-lg-n6q1 {
    margin-bottom: -6.25rem !important; }
  .ml-lg-n6q1,
  .mx-lg-n6q1 {
    margin-left: -6.25rem !important; }
  .m-lg-n6q2 {
    margin: -6.5rem !important; }
  .mt-lg-n6q2,
  .my-lg-n6q2 {
    margin-top: -6.5rem !important; }
  .mr-lg-n6q2,
  .mx-lg-n6q2 {
    margin-right: -6.5rem !important; }
  .mb-lg-n6q2,
  .my-lg-n6q2 {
    margin-bottom: -6.5rem !important; }
  .ml-lg-n6q2,
  .mx-lg-n6q2 {
    margin-left: -6.5rem !important; }
  .m-lg-n6q3 {
    margin: -6.75rem !important; }
  .mt-lg-n6q3,
  .my-lg-n6q3 {
    margin-top: -6.75rem !important; }
  .mr-lg-n6q3,
  .mx-lg-n6q3 {
    margin-right: -6.75rem !important; }
  .mb-lg-n6q3,
  .my-lg-n6q3 {
    margin-bottom: -6.75rem !important; }
  .ml-lg-n6q3,
  .mx-lg-n6q3 {
    margin-left: -6.75rem !important; }
  .m-lg-n7 {
    margin: -7rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -7rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -7rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -7rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -7rem !important; }
  .m-lg-n7q1 {
    margin: -7.25rem !important; }
  .mt-lg-n7q1,
  .my-lg-n7q1 {
    margin-top: -7.25rem !important; }
  .mr-lg-n7q1,
  .mx-lg-n7q1 {
    margin-right: -7.25rem !important; }
  .mb-lg-n7q1,
  .my-lg-n7q1 {
    margin-bottom: -7.25rem !important; }
  .ml-lg-n7q1,
  .mx-lg-n7q1 {
    margin-left: -7.25rem !important; }
  .m-lg-n7q2 {
    margin: -7.5rem !important; }
  .mt-lg-n7q2,
  .my-lg-n7q2 {
    margin-top: -7.5rem !important; }
  .mr-lg-n7q2,
  .mx-lg-n7q2 {
    margin-right: -7.5rem !important; }
  .mb-lg-n7q2,
  .my-lg-n7q2 {
    margin-bottom: -7.5rem !important; }
  .ml-lg-n7q2,
  .mx-lg-n7q2 {
    margin-left: -7.5rem !important; }
  .m-lg-n7q3 {
    margin: -7.75rem !important; }
  .mt-lg-n7q3,
  .my-lg-n7q3 {
    margin-top: -7.75rem !important; }
  .mr-lg-n7q3,
  .mx-lg-n7q3 {
    margin-right: -7.75rem !important; }
  .mb-lg-n7q3,
  .my-lg-n7q3 {
    margin-bottom: -7.75rem !important; }
  .ml-lg-n7q3,
  .mx-lg-n7q3 {
    margin-left: -7.75rem !important; }
  .m-lg-n8 {
    margin: -8rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -8rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -8rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -8rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -8rem !important; }
  .m-lg-n8q1 {
    margin: -8.25rem !important; }
  .mt-lg-n8q1,
  .my-lg-n8q1 {
    margin-top: -8.25rem !important; }
  .mr-lg-n8q1,
  .mx-lg-n8q1 {
    margin-right: -8.25rem !important; }
  .mb-lg-n8q1,
  .my-lg-n8q1 {
    margin-bottom: -8.25rem !important; }
  .ml-lg-n8q1,
  .mx-lg-n8q1 {
    margin-left: -8.25rem !important; }
  .m-lg-n8q2 {
    margin: -8.5rem !important; }
  .mt-lg-n8q2,
  .my-lg-n8q2 {
    margin-top: -8.5rem !important; }
  .mr-lg-n8q2,
  .mx-lg-n8q2 {
    margin-right: -8.5rem !important; }
  .mb-lg-n8q2,
  .my-lg-n8q2 {
    margin-bottom: -8.5rem !important; }
  .ml-lg-n8q2,
  .mx-lg-n8q2 {
    margin-left: -8.5rem !important; }
  .m-lg-n8q3 {
    margin: -8.75rem !important; }
  .mt-lg-n8q3,
  .my-lg-n8q3 {
    margin-top: -8.75rem !important; }
  .mr-lg-n8q3,
  .mx-lg-n8q3 {
    margin-right: -8.75rem !important; }
  .mb-lg-n8q3,
  .my-lg-n8q3 {
    margin-bottom: -8.75rem !important; }
  .ml-lg-n8q3,
  .mx-lg-n8q3 {
    margin-left: -8.75rem !important; }
  .m-lg-n9 {
    margin: -9rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -9rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -9rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -9rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -9rem !important; }
  .m-lg-n9q1 {
    margin: -9.25rem !important; }
  .mt-lg-n9q1,
  .my-lg-n9q1 {
    margin-top: -9.25rem !important; }
  .mr-lg-n9q1,
  .mx-lg-n9q1 {
    margin-right: -9.25rem !important; }
  .mb-lg-n9q1,
  .my-lg-n9q1 {
    margin-bottom: -9.25rem !important; }
  .ml-lg-n9q1,
  .mx-lg-n9q1 {
    margin-left: -9.25rem !important; }
  .m-lg-n9q2 {
    margin: -9.5rem !important; }
  .mt-lg-n9q2,
  .my-lg-n9q2 {
    margin-top: -9.5rem !important; }
  .mr-lg-n9q2,
  .mx-lg-n9q2 {
    margin-right: -9.5rem !important; }
  .mb-lg-n9q2,
  .my-lg-n9q2 {
    margin-bottom: -9.5rem !important; }
  .ml-lg-n9q2,
  .mx-lg-n9q2 {
    margin-left: -9.5rem !important; }
  .m-lg-n9q3 {
    margin: -9.75rem !important; }
  .mt-lg-n9q3,
  .my-lg-n9q3 {
    margin-top: -9.75rem !important; }
  .mr-lg-n9q3,
  .mx-lg-n9q3 {
    margin-right: -9.75rem !important; }
  .mb-lg-n9q3,
  .my-lg-n9q3 {
    margin-bottom: -9.75rem !important; }
  .ml-lg-n9q3,
  .mx-lg-n9q3 {
    margin-left: -9.75rem !important; }
  .m-lg-n10 {
    margin: -10rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -10rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -10rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -10rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -10rem !important; }
  .m-lg-n10q1 {
    margin: -10.25rem !important; }
  .mt-lg-n10q1,
  .my-lg-n10q1 {
    margin-top: -10.25rem !important; }
  .mr-lg-n10q1,
  .mx-lg-n10q1 {
    margin-right: -10.25rem !important; }
  .mb-lg-n10q1,
  .my-lg-n10q1 {
    margin-bottom: -10.25rem !important; }
  .ml-lg-n10q1,
  .mx-lg-n10q1 {
    margin-left: -10.25rem !important; }
  .m-lg-n10q2 {
    margin: -10.5rem !important; }
  .mt-lg-n10q2,
  .my-lg-n10q2 {
    margin-top: -10.5rem !important; }
  .mr-lg-n10q2,
  .mx-lg-n10q2 {
    margin-right: -10.5rem !important; }
  .mb-lg-n10q2,
  .my-lg-n10q2 {
    margin-bottom: -10.5rem !important; }
  .ml-lg-n10q2,
  .mx-lg-n10q2 {
    margin-left: -10.5rem !important; }
  .m-lg-n10q3 {
    margin: -10.75rem !important; }
  .mt-lg-n10q3,
  .my-lg-n10q3 {
    margin-top: -10.75rem !important; }
  .mr-lg-n10q3,
  .mx-lg-n10q3 {
    margin-right: -10.75rem !important; }
  .mb-lg-n10q3,
  .my-lg-n10q3 {
    margin-bottom: -10.75rem !important; }
  .ml-lg-n10q3,
  .mx-lg-n10q3 {
    margin-left: -10.75rem !important; }
  .m-lg-n11 {
    margin: -11rem !important; }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -11rem !important; }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -11rem !important; }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -11rem !important; }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -11rem !important; }
  .m-lg-n11q1 {
    margin: -11.25rem !important; }
  .mt-lg-n11q1,
  .my-lg-n11q1 {
    margin-top: -11.25rem !important; }
  .mr-lg-n11q1,
  .mx-lg-n11q1 {
    margin-right: -11.25rem !important; }
  .mb-lg-n11q1,
  .my-lg-n11q1 {
    margin-bottom: -11.25rem !important; }
  .ml-lg-n11q1,
  .mx-lg-n11q1 {
    margin-left: -11.25rem !important; }
  .m-lg-n11 q2 {
    margin: -11.5rem !important; }
  .mt-lg-n11 q2,
  .my-lg-n11 q2 {
    margin-top: -11.5rem !important; }
  .mr-lg-n11 q2,
  .mx-lg-n11 q2 {
    margin-right: -11.5rem !important; }
  .mb-lg-n11 q2,
  .my-lg-n11 q2 {
    margin-bottom: -11.5rem !important; }
  .ml-lg-n11 q2,
  .mx-lg-n11 q2 {
    margin-left: -11.5rem !important; }
  .m-lg-n11 q3 {
    margin: -11.75rem !important; }
  .mt-lg-n11 q3,
  .my-lg-n11 q3 {
    margin-top: -11.75rem !important; }
  .mr-lg-n11 q3,
  .mx-lg-n11 q3 {
    margin-right: -11.75rem !important; }
  .mb-lg-n11 q3,
  .my-lg-n11 q3 {
    margin-bottom: -11.75rem !important; }
  .ml-lg-n11 q3,
  .mx-lg-n11 q3 {
    margin-left: -11.75rem !important; }
  .m-lg-n12 {
    margin: -12rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -12rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -12rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -12rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -12rem !important; }
  .m-lg-n12 q1 {
    margin: -12.25rem !important; }
  .mt-lg-n12 q1,
  .my-lg-n12 q1 {
    margin-top: -12.25rem !important; }
  .mr-lg-n12 q1,
  .mx-lg-n12 q1 {
    margin-right: -12.25rem !important; }
  .mb-lg-n12 q1,
  .my-lg-n12 q1 {
    margin-bottom: -12.25rem !important; }
  .ml-lg-n12 q1,
  .mx-lg-n12 q1 {
    margin-left: -12.25rem !important; }
  .m-lg-n12 q2 {
    margin: -12.5rem !important; }
  .mt-lg-n12 q2,
  .my-lg-n12 q2 {
    margin-top: -12.5rem !important; }
  .mr-lg-n12 q2,
  .mx-lg-n12 q2 {
    margin-right: -12.5rem !important; }
  .mb-lg-n12 q2,
  .my-lg-n12 q2 {
    margin-bottom: -12.5rem !important; }
  .ml-lg-n12 q2,
  .mx-lg-n12 q2 {
    margin-left: -12.5rem !important; }
  .m-lg-n12 q3 {
    margin: -12.75rem !important; }
  .mt-lg-n12 q3,
  .my-lg-n12 q3 {
    margin-top: -12.75rem !important; }
  .mr-lg-n12 q3,
  .mx-lg-n12 q3 {
    margin-right: -12.75rem !important; }
  .mb-lg-n12 q3,
  .my-lg-n12 q3 {
    margin-bottom: -12.75rem !important; }
  .ml-lg-n12 q3,
  .mx-lg-n12 q3 {
    margin-left: -12.75rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-q1 {
    margin: 0.25rem !important; }
  .mt-xl-q1,
  .my-xl-q1 {
    margin-top: 0.25rem !important; }
  .mr-xl-q1,
  .mx-xl-q1 {
    margin-right: 0.25rem !important; }
  .mb-xl-q1,
  .my-xl-q1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-q1,
  .mx-xl-q1 {
    margin-left: 0.25rem !important; }
  .m-xl-q2 {
    margin: 0.5rem !important; }
  .mt-xl-q2,
  .my-xl-q2 {
    margin-top: 0.5rem !important; }
  .mr-xl-q2,
  .mx-xl-q2 {
    margin-right: 0.5rem !important; }
  .mb-xl-q2,
  .my-xl-q2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-q2,
  .mx-xl-q2 {
    margin-left: 0.5rem !important; }
  .m-xl-q3 {
    margin: 0.75rem !important; }
  .mt-xl-q3,
  .my-xl-q3 {
    margin-top: 0.75rem !important; }
  .mr-xl-q3,
  .mx-xl-q3 {
    margin-right: 0.75rem !important; }
  .mb-xl-q3,
  .my-xl-q3 {
    margin-bottom: 0.75rem !important; }
  .ml-xl-q3,
  .mx-xl-q3 {
    margin-left: 0.75rem !important; }
  .m-xl-0 {
    margin: 0rem !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0rem !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0rem !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0rem !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0rem !important; }
  .m-xl-1 {
    margin: 1rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 1rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 1rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 1rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 1rem !important; }
  .m-xl-1q1 {
    margin: 1.25rem !important; }
  .mt-xl-1q1,
  .my-xl-1q1 {
    margin-top: 1.25rem !important; }
  .mr-xl-1q1,
  .mx-xl-1q1 {
    margin-right: 1.25rem !important; }
  .mb-xl-1q1,
  .my-xl-1q1 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-1q1,
  .mx-xl-1q1 {
    margin-left: 1.25rem !important; }
  .m-xl-1q2 {
    margin: 1.5rem !important; }
  .mt-xl-1q2,
  .my-xl-1q2 {
    margin-top: 1.5rem !important; }
  .mr-xl-1q2,
  .mx-xl-1q2 {
    margin-right: 1.5rem !important; }
  .mb-xl-1q2,
  .my-xl-1q2 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-1q2,
  .mx-xl-1q2 {
    margin-left: 1.5rem !important; }
  .m-xl-1q3 {
    margin: 1.75rem !important; }
  .mt-xl-1q3,
  .my-xl-1q3 {
    margin-top: 1.75rem !important; }
  .mr-xl-1q3,
  .mx-xl-1q3 {
    margin-right: 1.75rem !important; }
  .mb-xl-1q3,
  .my-xl-1q3 {
    margin-bottom: 1.75rem !important; }
  .ml-xl-1q3,
  .mx-xl-1q3 {
    margin-left: 1.75rem !important; }
  .m-xl-2 {
    margin: 2rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 2rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 2rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 2rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 2rem !important; }
  .m-xl-2q1 {
    margin: 2.25rem !important; }
  .mt-xl-2q1,
  .my-xl-2q1 {
    margin-top: 2.25rem !important; }
  .mr-xl-2q1,
  .mx-xl-2q1 {
    margin-right: 2.25rem !important; }
  .mb-xl-2q1,
  .my-xl-2q1 {
    margin-bottom: 2.25rem !important; }
  .ml-xl-2q1,
  .mx-xl-2q1 {
    margin-left: 2.25rem !important; }
  .m-xl-2q2 {
    margin: 2.5rem !important; }
  .mt-xl-2q2,
  .my-xl-2q2 {
    margin-top: 2.5rem !important; }
  .mr-xl-2q2,
  .mx-xl-2q2 {
    margin-right: 2.5rem !important; }
  .mb-xl-2q2,
  .my-xl-2q2 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-2q2,
  .mx-xl-2q2 {
    margin-left: 2.5rem !important; }
  .m-xl-2q3 {
    margin: 2.75rem !important; }
  .mt-xl-2q3,
  .my-xl-2q3 {
    margin-top: 2.75rem !important; }
  .mr-xl-2q3,
  .mx-xl-2q3 {
    margin-right: 2.75rem !important; }
  .mb-xl-2q3,
  .my-xl-2q3 {
    margin-bottom: 2.75rem !important; }
  .ml-xl-2q3,
  .mx-xl-2q3 {
    margin-left: 2.75rem !important; }
  .m-xl-3 {
    margin: 3rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 3rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 3rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 3rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 3rem !important; }
  .m-xl-3q1 {
    margin: 3.25rem !important; }
  .mt-xl-3q1,
  .my-xl-3q1 {
    margin-top: 3.25rem !important; }
  .mr-xl-3q1,
  .mx-xl-3q1 {
    margin-right: 3.25rem !important; }
  .mb-xl-3q1,
  .my-xl-3q1 {
    margin-bottom: 3.25rem !important; }
  .ml-xl-3q1,
  .mx-xl-3q1 {
    margin-left: 3.25rem !important; }
  .m-xl-3q2 {
    margin: 3.5rem !important; }
  .mt-xl-3q2,
  .my-xl-3q2 {
    margin-top: 3.5rem !important; }
  .mr-xl-3q2,
  .mx-xl-3q2 {
    margin-right: 3.5rem !important; }
  .mb-xl-3q2,
  .my-xl-3q2 {
    margin-bottom: 3.5rem !important; }
  .ml-xl-3q2,
  .mx-xl-3q2 {
    margin-left: 3.5rem !important; }
  .m-xl-3q3 {
    margin: 3.75rem !important; }
  .mt-xl-3q3,
  .my-xl-3q3 {
    margin-top: 3.75rem !important; }
  .mr-xl-3q3,
  .mx-xl-3q3 {
    margin-right: 3.75rem !important; }
  .mb-xl-3q3,
  .my-xl-3q3 {
    margin-bottom: 3.75rem !important; }
  .ml-xl-3q3,
  .mx-xl-3q3 {
    margin-left: 3.75rem !important; }
  .m-xl-4 {
    margin: 4rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 4rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 4rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 4rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 4rem !important; }
  .m-xl-4q1 {
    margin: 4.25rem !important; }
  .mt-xl-4q1,
  .my-xl-4q1 {
    margin-top: 4.25rem !important; }
  .mr-xl-4q1,
  .mx-xl-4q1 {
    margin-right: 4.25rem !important; }
  .mb-xl-4q1,
  .my-xl-4q1 {
    margin-bottom: 4.25rem !important; }
  .ml-xl-4q1,
  .mx-xl-4q1 {
    margin-left: 4.25rem !important; }
  .m-xl-4q2 {
    margin: 4.5rem !important; }
  .mt-xl-4q2,
  .my-xl-4q2 {
    margin-top: 4.5rem !important; }
  .mr-xl-4q2,
  .mx-xl-4q2 {
    margin-right: 4.5rem !important; }
  .mb-xl-4q2,
  .my-xl-4q2 {
    margin-bottom: 4.5rem !important; }
  .ml-xl-4q2,
  .mx-xl-4q2 {
    margin-left: 4.5rem !important; }
  .m-xl-4q3 {
    margin: 4.75rem !important; }
  .mt-xl-4q3,
  .my-xl-4q3 {
    margin-top: 4.75rem !important; }
  .mr-xl-4q3,
  .mx-xl-4q3 {
    margin-right: 4.75rem !important; }
  .mb-xl-4q3,
  .my-xl-4q3 {
    margin-bottom: 4.75rem !important; }
  .ml-xl-4q3,
  .mx-xl-4q3 {
    margin-left: 4.75rem !important; }
  .m-xl-5 {
    margin: 5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 5rem !important; }
  .m-xl-5q1 {
    margin: 5.25rem !important; }
  .mt-xl-5q1,
  .my-xl-5q1 {
    margin-top: 5.25rem !important; }
  .mr-xl-5q1,
  .mx-xl-5q1 {
    margin-right: 5.25rem !important; }
  .mb-xl-5q1,
  .my-xl-5q1 {
    margin-bottom: 5.25rem !important; }
  .ml-xl-5q1,
  .mx-xl-5q1 {
    margin-left: 5.25rem !important; }
  .m-xl-5q2 {
    margin: 5.5rem !important; }
  .mt-xl-5q2,
  .my-xl-5q2 {
    margin-top: 5.5rem !important; }
  .mr-xl-5q2,
  .mx-xl-5q2 {
    margin-right: 5.5rem !important; }
  .mb-xl-5q2,
  .my-xl-5q2 {
    margin-bottom: 5.5rem !important; }
  .ml-xl-5q2,
  .mx-xl-5q2 {
    margin-left: 5.5rem !important; }
  .m-xl-5q3 {
    margin: 5.75rem !important; }
  .mt-xl-5q3,
  .my-xl-5q3 {
    margin-top: 5.75rem !important; }
  .mr-xl-5q3,
  .mx-xl-5q3 {
    margin-right: 5.75rem !important; }
  .mb-xl-5q3,
  .my-xl-5q3 {
    margin-bottom: 5.75rem !important; }
  .ml-xl-5q3,
  .mx-xl-5q3 {
    margin-left: 5.75rem !important; }
  .m-xl-6 {
    margin: 6rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 6rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 6rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 6rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 6rem !important; }
  .m-xl-6q1 {
    margin: 6.25rem !important; }
  .mt-xl-6q1,
  .my-xl-6q1 {
    margin-top: 6.25rem !important; }
  .mr-xl-6q1,
  .mx-xl-6q1 {
    margin-right: 6.25rem !important; }
  .mb-xl-6q1,
  .my-xl-6q1 {
    margin-bottom: 6.25rem !important; }
  .ml-xl-6q1,
  .mx-xl-6q1 {
    margin-left: 6.25rem !important; }
  .m-xl-6q2 {
    margin: 6.5rem !important; }
  .mt-xl-6q2,
  .my-xl-6q2 {
    margin-top: 6.5rem !important; }
  .mr-xl-6q2,
  .mx-xl-6q2 {
    margin-right: 6.5rem !important; }
  .mb-xl-6q2,
  .my-xl-6q2 {
    margin-bottom: 6.5rem !important; }
  .ml-xl-6q2,
  .mx-xl-6q2 {
    margin-left: 6.5rem !important; }
  .m-xl-6q3 {
    margin: 6.75rem !important; }
  .mt-xl-6q3,
  .my-xl-6q3 {
    margin-top: 6.75rem !important; }
  .mr-xl-6q3,
  .mx-xl-6q3 {
    margin-right: 6.75rem !important; }
  .mb-xl-6q3,
  .my-xl-6q3 {
    margin-bottom: 6.75rem !important; }
  .ml-xl-6q3,
  .mx-xl-6q3 {
    margin-left: 6.75rem !important; }
  .m-xl-7 {
    margin: 7rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 7rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 7rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 7rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 7rem !important; }
  .m-xl-7q1 {
    margin: 7.25rem !important; }
  .mt-xl-7q1,
  .my-xl-7q1 {
    margin-top: 7.25rem !important; }
  .mr-xl-7q1,
  .mx-xl-7q1 {
    margin-right: 7.25rem !important; }
  .mb-xl-7q1,
  .my-xl-7q1 {
    margin-bottom: 7.25rem !important; }
  .ml-xl-7q1,
  .mx-xl-7q1 {
    margin-left: 7.25rem !important; }
  .m-xl-7q2 {
    margin: 7.5rem !important; }
  .mt-xl-7q2,
  .my-xl-7q2 {
    margin-top: 7.5rem !important; }
  .mr-xl-7q2,
  .mx-xl-7q2 {
    margin-right: 7.5rem !important; }
  .mb-xl-7q2,
  .my-xl-7q2 {
    margin-bottom: 7.5rem !important; }
  .ml-xl-7q2,
  .mx-xl-7q2 {
    margin-left: 7.5rem !important; }
  .m-xl-7q3 {
    margin: 7.75rem !important; }
  .mt-xl-7q3,
  .my-xl-7q3 {
    margin-top: 7.75rem !important; }
  .mr-xl-7q3,
  .mx-xl-7q3 {
    margin-right: 7.75rem !important; }
  .mb-xl-7q3,
  .my-xl-7q3 {
    margin-bottom: 7.75rem !important; }
  .ml-xl-7q3,
  .mx-xl-7q3 {
    margin-left: 7.75rem !important; }
  .m-xl-8 {
    margin: 8rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 8rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 8rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 8rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 8rem !important; }
  .m-xl-8q1 {
    margin: 8.25rem !important; }
  .mt-xl-8q1,
  .my-xl-8q1 {
    margin-top: 8.25rem !important; }
  .mr-xl-8q1,
  .mx-xl-8q1 {
    margin-right: 8.25rem !important; }
  .mb-xl-8q1,
  .my-xl-8q1 {
    margin-bottom: 8.25rem !important; }
  .ml-xl-8q1,
  .mx-xl-8q1 {
    margin-left: 8.25rem !important; }
  .m-xl-8q2 {
    margin: 8.5rem !important; }
  .mt-xl-8q2,
  .my-xl-8q2 {
    margin-top: 8.5rem !important; }
  .mr-xl-8q2,
  .mx-xl-8q2 {
    margin-right: 8.5rem !important; }
  .mb-xl-8q2,
  .my-xl-8q2 {
    margin-bottom: 8.5rem !important; }
  .ml-xl-8q2,
  .mx-xl-8q2 {
    margin-left: 8.5rem !important; }
  .m-xl-8q3 {
    margin: 8.75rem !important; }
  .mt-xl-8q3,
  .my-xl-8q3 {
    margin-top: 8.75rem !important; }
  .mr-xl-8q3,
  .mx-xl-8q3 {
    margin-right: 8.75rem !important; }
  .mb-xl-8q3,
  .my-xl-8q3 {
    margin-bottom: 8.75rem !important; }
  .ml-xl-8q3,
  .mx-xl-8q3 {
    margin-left: 8.75rem !important; }
  .m-xl-9 {
    margin: 9rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 9rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 9rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 9rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 9rem !important; }
  .m-xl-9q1 {
    margin: 9.25rem !important; }
  .mt-xl-9q1,
  .my-xl-9q1 {
    margin-top: 9.25rem !important; }
  .mr-xl-9q1,
  .mx-xl-9q1 {
    margin-right: 9.25rem !important; }
  .mb-xl-9q1,
  .my-xl-9q1 {
    margin-bottom: 9.25rem !important; }
  .ml-xl-9q1,
  .mx-xl-9q1 {
    margin-left: 9.25rem !important; }
  .m-xl-9q2 {
    margin: 9.5rem !important; }
  .mt-xl-9q2,
  .my-xl-9q2 {
    margin-top: 9.5rem !important; }
  .mr-xl-9q2,
  .mx-xl-9q2 {
    margin-right: 9.5rem !important; }
  .mb-xl-9q2,
  .my-xl-9q2 {
    margin-bottom: 9.5rem !important; }
  .ml-xl-9q2,
  .mx-xl-9q2 {
    margin-left: 9.5rem !important; }
  .m-xl-9q3 {
    margin: 9.75rem !important; }
  .mt-xl-9q3,
  .my-xl-9q3 {
    margin-top: 9.75rem !important; }
  .mr-xl-9q3,
  .mx-xl-9q3 {
    margin-right: 9.75rem !important; }
  .mb-xl-9q3,
  .my-xl-9q3 {
    margin-bottom: 9.75rem !important; }
  .ml-xl-9q3,
  .mx-xl-9q3 {
    margin-left: 9.75rem !important; }
  .m-xl-10 {
    margin: 10rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10rem !important; }
  .m-xl-10q1 {
    margin: 10.25rem !important; }
  .mt-xl-10q1,
  .my-xl-10q1 {
    margin-top: 10.25rem !important; }
  .mr-xl-10q1,
  .mx-xl-10q1 {
    margin-right: 10.25rem !important; }
  .mb-xl-10q1,
  .my-xl-10q1 {
    margin-bottom: 10.25rem !important; }
  .ml-xl-10q1,
  .mx-xl-10q1 {
    margin-left: 10.25rem !important; }
  .m-xl-10q2 {
    margin: 10.5rem !important; }
  .mt-xl-10q2,
  .my-xl-10q2 {
    margin-top: 10.5rem !important; }
  .mr-xl-10q2,
  .mx-xl-10q2 {
    margin-right: 10.5rem !important; }
  .mb-xl-10q2,
  .my-xl-10q2 {
    margin-bottom: 10.5rem !important; }
  .ml-xl-10q2,
  .mx-xl-10q2 {
    margin-left: 10.5rem !important; }
  .m-xl-10q3 {
    margin: 10.75rem !important; }
  .mt-xl-10q3,
  .my-xl-10q3 {
    margin-top: 10.75rem !important; }
  .mr-xl-10q3,
  .mx-xl-10q3 {
    margin-right: 10.75rem !important; }
  .mb-xl-10q3,
  .my-xl-10q3 {
    margin-bottom: 10.75rem !important; }
  .ml-xl-10q3,
  .mx-xl-10q3 {
    margin-left: 10.75rem !important; }
  .m-xl-11 {
    margin: 11rem !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 11rem !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 11rem !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 11rem !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 11rem !important; }
  .m-xl-11q1 {
    margin: 11.25rem !important; }
  .mt-xl-11q1,
  .my-xl-11q1 {
    margin-top: 11.25rem !important; }
  .mr-xl-11q1,
  .mx-xl-11q1 {
    margin-right: 11.25rem !important; }
  .mb-xl-11q1,
  .my-xl-11q1 {
    margin-bottom: 11.25rem !important; }
  .ml-xl-11q1,
  .mx-xl-11q1 {
    margin-left: 11.25rem !important; }
  .m-xl-11 q2 {
    margin: 11.5rem !important; }
  .mt-xl-11 q2,
  .my-xl-11 q2 {
    margin-top: 11.5rem !important; }
  .mr-xl-11 q2,
  .mx-xl-11 q2 {
    margin-right: 11.5rem !important; }
  .mb-xl-11 q2,
  .my-xl-11 q2 {
    margin-bottom: 11.5rem !important; }
  .ml-xl-11 q2,
  .mx-xl-11 q2 {
    margin-left: 11.5rem !important; }
  .m-xl-11 q3 {
    margin: 11.75rem !important; }
  .mt-xl-11 q3,
  .my-xl-11 q3 {
    margin-top: 11.75rem !important; }
  .mr-xl-11 q3,
  .mx-xl-11 q3 {
    margin-right: 11.75rem !important; }
  .mb-xl-11 q3,
  .my-xl-11 q3 {
    margin-bottom: 11.75rem !important; }
  .ml-xl-11 q3,
  .mx-xl-11 q3 {
    margin-left: 11.75rem !important; }
  .m-xl-12 {
    margin: 12rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 12rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 12rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 12rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 12rem !important; }
  .m-xl-12 q1 {
    margin: 12.25rem !important; }
  .mt-xl-12 q1,
  .my-xl-12 q1 {
    margin-top: 12.25rem !important; }
  .mr-xl-12 q1,
  .mx-xl-12 q1 {
    margin-right: 12.25rem !important; }
  .mb-xl-12 q1,
  .my-xl-12 q1 {
    margin-bottom: 12.25rem !important; }
  .ml-xl-12 q1,
  .mx-xl-12 q1 {
    margin-left: 12.25rem !important; }
  .m-xl-12 q2 {
    margin: 12.5rem !important; }
  .mt-xl-12 q2,
  .my-xl-12 q2 {
    margin-top: 12.5rem !important; }
  .mr-xl-12 q2,
  .mx-xl-12 q2 {
    margin-right: 12.5rem !important; }
  .mb-xl-12 q2,
  .my-xl-12 q2 {
    margin-bottom: 12.5rem !important; }
  .ml-xl-12 q2,
  .mx-xl-12 q2 {
    margin-left: 12.5rem !important; }
  .m-xl-12 q3 {
    margin: 12.75rem !important; }
  .mt-xl-12 q3,
  .my-xl-12 q3 {
    margin-top: 12.75rem !important; }
  .mr-xl-12 q3,
  .mx-xl-12 q3 {
    margin-right: 12.75rem !important; }
  .mb-xl-12 q3,
  .my-xl-12 q3 {
    margin-bottom: 12.75rem !important; }
  .ml-xl-12 q3,
  .mx-xl-12 q3 {
    margin-left: 12.75rem !important; }
  .p-xl-q1 {
    padding: 0.25rem !important; }
  .pt-xl-q1,
  .py-xl-q1 {
    padding-top: 0.25rem !important; }
  .pr-xl-q1,
  .px-xl-q1 {
    padding-right: 0.25rem !important; }
  .pb-xl-q1,
  .py-xl-q1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-q1,
  .px-xl-q1 {
    padding-left: 0.25rem !important; }
  .p-xl-q2 {
    padding: 0.5rem !important; }
  .pt-xl-q2,
  .py-xl-q2 {
    padding-top: 0.5rem !important; }
  .pr-xl-q2,
  .px-xl-q2 {
    padding-right: 0.5rem !important; }
  .pb-xl-q2,
  .py-xl-q2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-q2,
  .px-xl-q2 {
    padding-left: 0.5rem !important; }
  .p-xl-q3 {
    padding: 0.75rem !important; }
  .pt-xl-q3,
  .py-xl-q3 {
    padding-top: 0.75rem !important; }
  .pr-xl-q3,
  .px-xl-q3 {
    padding-right: 0.75rem !important; }
  .pb-xl-q3,
  .py-xl-q3 {
    padding-bottom: 0.75rem !important; }
  .pl-xl-q3,
  .px-xl-q3 {
    padding-left: 0.75rem !important; }
  .p-xl-0 {
    padding: 0rem !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0rem !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0rem !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0rem !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0rem !important; }
  .p-xl-1 {
    padding: 1rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 1rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 1rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 1rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 1rem !important; }
  .p-xl-1q1 {
    padding: 1.25rem !important; }
  .pt-xl-1q1,
  .py-xl-1q1 {
    padding-top: 1.25rem !important; }
  .pr-xl-1q1,
  .px-xl-1q1 {
    padding-right: 1.25rem !important; }
  .pb-xl-1q1,
  .py-xl-1q1 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-1q1,
  .px-xl-1q1 {
    padding-left: 1.25rem !important; }
  .p-xl-1q2 {
    padding: 1.5rem !important; }
  .pt-xl-1q2,
  .py-xl-1q2 {
    padding-top: 1.5rem !important; }
  .pr-xl-1q2,
  .px-xl-1q2 {
    padding-right: 1.5rem !important; }
  .pb-xl-1q2,
  .py-xl-1q2 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-1q2,
  .px-xl-1q2 {
    padding-left: 1.5rem !important; }
  .p-xl-1q3 {
    padding: 1.75rem !important; }
  .pt-xl-1q3,
  .py-xl-1q3 {
    padding-top: 1.75rem !important; }
  .pr-xl-1q3,
  .px-xl-1q3 {
    padding-right: 1.75rem !important; }
  .pb-xl-1q3,
  .py-xl-1q3 {
    padding-bottom: 1.75rem !important; }
  .pl-xl-1q3,
  .px-xl-1q3 {
    padding-left: 1.75rem !important; }
  .p-xl-2 {
    padding: 2rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 2rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 2rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 2rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 2rem !important; }
  .p-xl-2q1 {
    padding: 2.25rem !important; }
  .pt-xl-2q1,
  .py-xl-2q1 {
    padding-top: 2.25rem !important; }
  .pr-xl-2q1,
  .px-xl-2q1 {
    padding-right: 2.25rem !important; }
  .pb-xl-2q1,
  .py-xl-2q1 {
    padding-bottom: 2.25rem !important; }
  .pl-xl-2q1,
  .px-xl-2q1 {
    padding-left: 2.25rem !important; }
  .p-xl-2q2 {
    padding: 2.5rem !important; }
  .pt-xl-2q2,
  .py-xl-2q2 {
    padding-top: 2.5rem !important; }
  .pr-xl-2q2,
  .px-xl-2q2 {
    padding-right: 2.5rem !important; }
  .pb-xl-2q2,
  .py-xl-2q2 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-2q2,
  .px-xl-2q2 {
    padding-left: 2.5rem !important; }
  .p-xl-2q3 {
    padding: 2.75rem !important; }
  .pt-xl-2q3,
  .py-xl-2q3 {
    padding-top: 2.75rem !important; }
  .pr-xl-2q3,
  .px-xl-2q3 {
    padding-right: 2.75rem !important; }
  .pb-xl-2q3,
  .py-xl-2q3 {
    padding-bottom: 2.75rem !important; }
  .pl-xl-2q3,
  .px-xl-2q3 {
    padding-left: 2.75rem !important; }
  .p-xl-3 {
    padding: 3rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 3rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 3rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 3rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 3rem !important; }
  .p-xl-3q1 {
    padding: 3.25rem !important; }
  .pt-xl-3q1,
  .py-xl-3q1 {
    padding-top: 3.25rem !important; }
  .pr-xl-3q1,
  .px-xl-3q1 {
    padding-right: 3.25rem !important; }
  .pb-xl-3q1,
  .py-xl-3q1 {
    padding-bottom: 3.25rem !important; }
  .pl-xl-3q1,
  .px-xl-3q1 {
    padding-left: 3.25rem !important; }
  .p-xl-3q2 {
    padding: 3.5rem !important; }
  .pt-xl-3q2,
  .py-xl-3q2 {
    padding-top: 3.5rem !important; }
  .pr-xl-3q2,
  .px-xl-3q2 {
    padding-right: 3.5rem !important; }
  .pb-xl-3q2,
  .py-xl-3q2 {
    padding-bottom: 3.5rem !important; }
  .pl-xl-3q2,
  .px-xl-3q2 {
    padding-left: 3.5rem !important; }
  .p-xl-3q3 {
    padding: 3.75rem !important; }
  .pt-xl-3q3,
  .py-xl-3q3 {
    padding-top: 3.75rem !important; }
  .pr-xl-3q3,
  .px-xl-3q3 {
    padding-right: 3.75rem !important; }
  .pb-xl-3q3,
  .py-xl-3q3 {
    padding-bottom: 3.75rem !important; }
  .pl-xl-3q3,
  .px-xl-3q3 {
    padding-left: 3.75rem !important; }
  .p-xl-4 {
    padding: 4rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 4rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 4rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 4rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 4rem !important; }
  .p-xl-4q1 {
    padding: 4.25rem !important; }
  .pt-xl-4q1,
  .py-xl-4q1 {
    padding-top: 4.25rem !important; }
  .pr-xl-4q1,
  .px-xl-4q1 {
    padding-right: 4.25rem !important; }
  .pb-xl-4q1,
  .py-xl-4q1 {
    padding-bottom: 4.25rem !important; }
  .pl-xl-4q1,
  .px-xl-4q1 {
    padding-left: 4.25rem !important; }
  .p-xl-4q2 {
    padding: 4.5rem !important; }
  .pt-xl-4q2,
  .py-xl-4q2 {
    padding-top: 4.5rem !important; }
  .pr-xl-4q2,
  .px-xl-4q2 {
    padding-right: 4.5rem !important; }
  .pb-xl-4q2,
  .py-xl-4q2 {
    padding-bottom: 4.5rem !important; }
  .pl-xl-4q2,
  .px-xl-4q2 {
    padding-left: 4.5rem !important; }
  .p-xl-4q3 {
    padding: 4.75rem !important; }
  .pt-xl-4q3,
  .py-xl-4q3 {
    padding-top: 4.75rem !important; }
  .pr-xl-4q3,
  .px-xl-4q3 {
    padding-right: 4.75rem !important; }
  .pb-xl-4q3,
  .py-xl-4q3 {
    padding-bottom: 4.75rem !important; }
  .pl-xl-4q3,
  .px-xl-4q3 {
    padding-left: 4.75rem !important; }
  .p-xl-5 {
    padding: 5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 5rem !important; }
  .p-xl-5q1 {
    padding: 5.25rem !important; }
  .pt-xl-5q1,
  .py-xl-5q1 {
    padding-top: 5.25rem !important; }
  .pr-xl-5q1,
  .px-xl-5q1 {
    padding-right: 5.25rem !important; }
  .pb-xl-5q1,
  .py-xl-5q1 {
    padding-bottom: 5.25rem !important; }
  .pl-xl-5q1,
  .px-xl-5q1 {
    padding-left: 5.25rem !important; }
  .p-xl-5q2 {
    padding: 5.5rem !important; }
  .pt-xl-5q2,
  .py-xl-5q2 {
    padding-top: 5.5rem !important; }
  .pr-xl-5q2,
  .px-xl-5q2 {
    padding-right: 5.5rem !important; }
  .pb-xl-5q2,
  .py-xl-5q2 {
    padding-bottom: 5.5rem !important; }
  .pl-xl-5q2,
  .px-xl-5q2 {
    padding-left: 5.5rem !important; }
  .p-xl-5q3 {
    padding: 5.75rem !important; }
  .pt-xl-5q3,
  .py-xl-5q3 {
    padding-top: 5.75rem !important; }
  .pr-xl-5q3,
  .px-xl-5q3 {
    padding-right: 5.75rem !important; }
  .pb-xl-5q3,
  .py-xl-5q3 {
    padding-bottom: 5.75rem !important; }
  .pl-xl-5q3,
  .px-xl-5q3 {
    padding-left: 5.75rem !important; }
  .p-xl-6 {
    padding: 6rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 6rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 6rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 6rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 6rem !important; }
  .p-xl-6q1 {
    padding: 6.25rem !important; }
  .pt-xl-6q1,
  .py-xl-6q1 {
    padding-top: 6.25rem !important; }
  .pr-xl-6q1,
  .px-xl-6q1 {
    padding-right: 6.25rem !important; }
  .pb-xl-6q1,
  .py-xl-6q1 {
    padding-bottom: 6.25rem !important; }
  .pl-xl-6q1,
  .px-xl-6q1 {
    padding-left: 6.25rem !important; }
  .p-xl-6q2 {
    padding: 6.5rem !important; }
  .pt-xl-6q2,
  .py-xl-6q2 {
    padding-top: 6.5rem !important; }
  .pr-xl-6q2,
  .px-xl-6q2 {
    padding-right: 6.5rem !important; }
  .pb-xl-6q2,
  .py-xl-6q2 {
    padding-bottom: 6.5rem !important; }
  .pl-xl-6q2,
  .px-xl-6q2 {
    padding-left: 6.5rem !important; }
  .p-xl-6q3 {
    padding: 6.75rem !important; }
  .pt-xl-6q3,
  .py-xl-6q3 {
    padding-top: 6.75rem !important; }
  .pr-xl-6q3,
  .px-xl-6q3 {
    padding-right: 6.75rem !important; }
  .pb-xl-6q3,
  .py-xl-6q3 {
    padding-bottom: 6.75rem !important; }
  .pl-xl-6q3,
  .px-xl-6q3 {
    padding-left: 6.75rem !important; }
  .p-xl-7 {
    padding: 7rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 7rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 7rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 7rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 7rem !important; }
  .p-xl-7q1 {
    padding: 7.25rem !important; }
  .pt-xl-7q1,
  .py-xl-7q1 {
    padding-top: 7.25rem !important; }
  .pr-xl-7q1,
  .px-xl-7q1 {
    padding-right: 7.25rem !important; }
  .pb-xl-7q1,
  .py-xl-7q1 {
    padding-bottom: 7.25rem !important; }
  .pl-xl-7q1,
  .px-xl-7q1 {
    padding-left: 7.25rem !important; }
  .p-xl-7q2 {
    padding: 7.5rem !important; }
  .pt-xl-7q2,
  .py-xl-7q2 {
    padding-top: 7.5rem !important; }
  .pr-xl-7q2,
  .px-xl-7q2 {
    padding-right: 7.5rem !important; }
  .pb-xl-7q2,
  .py-xl-7q2 {
    padding-bottom: 7.5rem !important; }
  .pl-xl-7q2,
  .px-xl-7q2 {
    padding-left: 7.5rem !important; }
  .p-xl-7q3 {
    padding: 7.75rem !important; }
  .pt-xl-7q3,
  .py-xl-7q3 {
    padding-top: 7.75rem !important; }
  .pr-xl-7q3,
  .px-xl-7q3 {
    padding-right: 7.75rem !important; }
  .pb-xl-7q3,
  .py-xl-7q3 {
    padding-bottom: 7.75rem !important; }
  .pl-xl-7q3,
  .px-xl-7q3 {
    padding-left: 7.75rem !important; }
  .p-xl-8 {
    padding: 8rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 8rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 8rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 8rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 8rem !important; }
  .p-xl-8q1 {
    padding: 8.25rem !important; }
  .pt-xl-8q1,
  .py-xl-8q1 {
    padding-top: 8.25rem !important; }
  .pr-xl-8q1,
  .px-xl-8q1 {
    padding-right: 8.25rem !important; }
  .pb-xl-8q1,
  .py-xl-8q1 {
    padding-bottom: 8.25rem !important; }
  .pl-xl-8q1,
  .px-xl-8q1 {
    padding-left: 8.25rem !important; }
  .p-xl-8q2 {
    padding: 8.5rem !important; }
  .pt-xl-8q2,
  .py-xl-8q2 {
    padding-top: 8.5rem !important; }
  .pr-xl-8q2,
  .px-xl-8q2 {
    padding-right: 8.5rem !important; }
  .pb-xl-8q2,
  .py-xl-8q2 {
    padding-bottom: 8.5rem !important; }
  .pl-xl-8q2,
  .px-xl-8q2 {
    padding-left: 8.5rem !important; }
  .p-xl-8q3 {
    padding: 8.75rem !important; }
  .pt-xl-8q3,
  .py-xl-8q3 {
    padding-top: 8.75rem !important; }
  .pr-xl-8q3,
  .px-xl-8q3 {
    padding-right: 8.75rem !important; }
  .pb-xl-8q3,
  .py-xl-8q3 {
    padding-bottom: 8.75rem !important; }
  .pl-xl-8q3,
  .px-xl-8q3 {
    padding-left: 8.75rem !important; }
  .p-xl-9 {
    padding: 9rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 9rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 9rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 9rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 9rem !important; }
  .p-xl-9q1 {
    padding: 9.25rem !important; }
  .pt-xl-9q1,
  .py-xl-9q1 {
    padding-top: 9.25rem !important; }
  .pr-xl-9q1,
  .px-xl-9q1 {
    padding-right: 9.25rem !important; }
  .pb-xl-9q1,
  .py-xl-9q1 {
    padding-bottom: 9.25rem !important; }
  .pl-xl-9q1,
  .px-xl-9q1 {
    padding-left: 9.25rem !important; }
  .p-xl-9q2 {
    padding: 9.5rem !important; }
  .pt-xl-9q2,
  .py-xl-9q2 {
    padding-top: 9.5rem !important; }
  .pr-xl-9q2,
  .px-xl-9q2 {
    padding-right: 9.5rem !important; }
  .pb-xl-9q2,
  .py-xl-9q2 {
    padding-bottom: 9.5rem !important; }
  .pl-xl-9q2,
  .px-xl-9q2 {
    padding-left: 9.5rem !important; }
  .p-xl-9q3 {
    padding: 9.75rem !important; }
  .pt-xl-9q3,
  .py-xl-9q3 {
    padding-top: 9.75rem !important; }
  .pr-xl-9q3,
  .px-xl-9q3 {
    padding-right: 9.75rem !important; }
  .pb-xl-9q3,
  .py-xl-9q3 {
    padding-bottom: 9.75rem !important; }
  .pl-xl-9q3,
  .px-xl-9q3 {
    padding-left: 9.75rem !important; }
  .p-xl-10 {
    padding: 10rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10rem !important; }
  .p-xl-10q1 {
    padding: 10.25rem !important; }
  .pt-xl-10q1,
  .py-xl-10q1 {
    padding-top: 10.25rem !important; }
  .pr-xl-10q1,
  .px-xl-10q1 {
    padding-right: 10.25rem !important; }
  .pb-xl-10q1,
  .py-xl-10q1 {
    padding-bottom: 10.25rem !important; }
  .pl-xl-10q1,
  .px-xl-10q1 {
    padding-left: 10.25rem !important; }
  .p-xl-10q2 {
    padding: 10.5rem !important; }
  .pt-xl-10q2,
  .py-xl-10q2 {
    padding-top: 10.5rem !important; }
  .pr-xl-10q2,
  .px-xl-10q2 {
    padding-right: 10.5rem !important; }
  .pb-xl-10q2,
  .py-xl-10q2 {
    padding-bottom: 10.5rem !important; }
  .pl-xl-10q2,
  .px-xl-10q2 {
    padding-left: 10.5rem !important; }
  .p-xl-10q3 {
    padding: 10.75rem !important; }
  .pt-xl-10q3,
  .py-xl-10q3 {
    padding-top: 10.75rem !important; }
  .pr-xl-10q3,
  .px-xl-10q3 {
    padding-right: 10.75rem !important; }
  .pb-xl-10q3,
  .py-xl-10q3 {
    padding-bottom: 10.75rem !important; }
  .pl-xl-10q3,
  .px-xl-10q3 {
    padding-left: 10.75rem !important; }
  .p-xl-11 {
    padding: 11rem !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 11rem !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 11rem !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 11rem !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 11rem !important; }
  .p-xl-11q1 {
    padding: 11.25rem !important; }
  .pt-xl-11q1,
  .py-xl-11q1 {
    padding-top: 11.25rem !important; }
  .pr-xl-11q1,
  .px-xl-11q1 {
    padding-right: 11.25rem !important; }
  .pb-xl-11q1,
  .py-xl-11q1 {
    padding-bottom: 11.25rem !important; }
  .pl-xl-11q1,
  .px-xl-11q1 {
    padding-left: 11.25rem !important; }
  .p-xl-11 q2 {
    padding: 11.5rem !important; }
  .pt-xl-11 q2,
  .py-xl-11 q2 {
    padding-top: 11.5rem !important; }
  .pr-xl-11 q2,
  .px-xl-11 q2 {
    padding-right: 11.5rem !important; }
  .pb-xl-11 q2,
  .py-xl-11 q2 {
    padding-bottom: 11.5rem !important; }
  .pl-xl-11 q2,
  .px-xl-11 q2 {
    padding-left: 11.5rem !important; }
  .p-xl-11 q3 {
    padding: 11.75rem !important; }
  .pt-xl-11 q3,
  .py-xl-11 q3 {
    padding-top: 11.75rem !important; }
  .pr-xl-11 q3,
  .px-xl-11 q3 {
    padding-right: 11.75rem !important; }
  .pb-xl-11 q3,
  .py-xl-11 q3 {
    padding-bottom: 11.75rem !important; }
  .pl-xl-11 q3,
  .px-xl-11 q3 {
    padding-left: 11.75rem !important; }
  .p-xl-12 {
    padding: 12rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 12rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 12rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 12rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 12rem !important; }
  .p-xl-12 q1 {
    padding: 12.25rem !important; }
  .pt-xl-12 q1,
  .py-xl-12 q1 {
    padding-top: 12.25rem !important; }
  .pr-xl-12 q1,
  .px-xl-12 q1 {
    padding-right: 12.25rem !important; }
  .pb-xl-12 q1,
  .py-xl-12 q1 {
    padding-bottom: 12.25rem !important; }
  .pl-xl-12 q1,
  .px-xl-12 q1 {
    padding-left: 12.25rem !important; }
  .p-xl-12 q2 {
    padding: 12.5rem !important; }
  .pt-xl-12 q2,
  .py-xl-12 q2 {
    padding-top: 12.5rem !important; }
  .pr-xl-12 q2,
  .px-xl-12 q2 {
    padding-right: 12.5rem !important; }
  .pb-xl-12 q2,
  .py-xl-12 q2 {
    padding-bottom: 12.5rem !important; }
  .pl-xl-12 q2,
  .px-xl-12 q2 {
    padding-left: 12.5rem !important; }
  .p-xl-12 q3 {
    padding: 12.75rem !important; }
  .pt-xl-12 q3,
  .py-xl-12 q3 {
    padding-top: 12.75rem !important; }
  .pr-xl-12 q3,
  .px-xl-12 q3 {
    padding-right: 12.75rem !important; }
  .pb-xl-12 q3,
  .py-xl-12 q3 {
    padding-bottom: 12.75rem !important; }
  .pl-xl-12 q3,
  .px-xl-12 q3 {
    padding-left: 12.75rem !important; }
  .m-xl-nq1 {
    margin: -0.25rem !important; }
  .mt-xl-nq1,
  .my-xl-nq1 {
    margin-top: -0.25rem !important; }
  .mr-xl-nq1,
  .mx-xl-nq1 {
    margin-right: -0.25rem !important; }
  .mb-xl-nq1,
  .my-xl-nq1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-nq1,
  .mx-xl-nq1 {
    margin-left: -0.25rem !important; }
  .m-xl-nq2 {
    margin: -0.5rem !important; }
  .mt-xl-nq2,
  .my-xl-nq2 {
    margin-top: -0.5rem !important; }
  .mr-xl-nq2,
  .mx-xl-nq2 {
    margin-right: -0.5rem !important; }
  .mb-xl-nq2,
  .my-xl-nq2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-nq2,
  .mx-xl-nq2 {
    margin-left: -0.5rem !important; }
  .m-xl-nq3 {
    margin: -0.75rem !important; }
  .mt-xl-nq3,
  .my-xl-nq3 {
    margin-top: -0.75rem !important; }
  .mr-xl-nq3,
  .mx-xl-nq3 {
    margin-right: -0.75rem !important; }
  .mb-xl-nq3,
  .my-xl-nq3 {
    margin-bottom: -0.75rem !important; }
  .ml-xl-nq3,
  .mx-xl-nq3 {
    margin-left: -0.75rem !important; }
  .m-xl-n1 {
    margin: -1rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -1rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -1rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -1rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -1rem !important; }
  .m-xl-n1q1 {
    margin: -1.25rem !important; }
  .mt-xl-n1q1,
  .my-xl-n1q1 {
    margin-top: -1.25rem !important; }
  .mr-xl-n1q1,
  .mx-xl-n1q1 {
    margin-right: -1.25rem !important; }
  .mb-xl-n1q1,
  .my-xl-n1q1 {
    margin-bottom: -1.25rem !important; }
  .ml-xl-n1q1,
  .mx-xl-n1q1 {
    margin-left: -1.25rem !important; }
  .m-xl-n1q2 {
    margin: -1.5rem !important; }
  .mt-xl-n1q2,
  .my-xl-n1q2 {
    margin-top: -1.5rem !important; }
  .mr-xl-n1q2,
  .mx-xl-n1q2 {
    margin-right: -1.5rem !important; }
  .mb-xl-n1q2,
  .my-xl-n1q2 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n1q2,
  .mx-xl-n1q2 {
    margin-left: -1.5rem !important; }
  .m-xl-n1q3 {
    margin: -1.75rem !important; }
  .mt-xl-n1q3,
  .my-xl-n1q3 {
    margin-top: -1.75rem !important; }
  .mr-xl-n1q3,
  .mx-xl-n1q3 {
    margin-right: -1.75rem !important; }
  .mb-xl-n1q3,
  .my-xl-n1q3 {
    margin-bottom: -1.75rem !important; }
  .ml-xl-n1q3,
  .mx-xl-n1q3 {
    margin-left: -1.75rem !important; }
  .m-xl-n2 {
    margin: -2rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -2rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -2rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -2rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -2rem !important; }
  .m-xl-n2q1 {
    margin: -2.25rem !important; }
  .mt-xl-n2q1,
  .my-xl-n2q1 {
    margin-top: -2.25rem !important; }
  .mr-xl-n2q1,
  .mx-xl-n2q1 {
    margin-right: -2.25rem !important; }
  .mb-xl-n2q1,
  .my-xl-n2q1 {
    margin-bottom: -2.25rem !important; }
  .ml-xl-n2q1,
  .mx-xl-n2q1 {
    margin-left: -2.25rem !important; }
  .m-xl-n2q2 {
    margin: -2.5rem !important; }
  .mt-xl-n2q2,
  .my-xl-n2q2 {
    margin-top: -2.5rem !important; }
  .mr-xl-n2q2,
  .mx-xl-n2q2 {
    margin-right: -2.5rem !important; }
  .mb-xl-n2q2,
  .my-xl-n2q2 {
    margin-bottom: -2.5rem !important; }
  .ml-xl-n2q2,
  .mx-xl-n2q2 {
    margin-left: -2.5rem !important; }
  .m-xl-n2q3 {
    margin: -2.75rem !important; }
  .mt-xl-n2q3,
  .my-xl-n2q3 {
    margin-top: -2.75rem !important; }
  .mr-xl-n2q3,
  .mx-xl-n2q3 {
    margin-right: -2.75rem !important; }
  .mb-xl-n2q3,
  .my-xl-n2q3 {
    margin-bottom: -2.75rem !important; }
  .ml-xl-n2q3,
  .mx-xl-n2q3 {
    margin-left: -2.75rem !important; }
  .m-xl-n3 {
    margin: -3rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -3rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -3rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -3rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -3rem !important; }
  .m-xl-n3q1 {
    margin: -3.25rem !important; }
  .mt-xl-n3q1,
  .my-xl-n3q1 {
    margin-top: -3.25rem !important; }
  .mr-xl-n3q1,
  .mx-xl-n3q1 {
    margin-right: -3.25rem !important; }
  .mb-xl-n3q1,
  .my-xl-n3q1 {
    margin-bottom: -3.25rem !important; }
  .ml-xl-n3q1,
  .mx-xl-n3q1 {
    margin-left: -3.25rem !important; }
  .m-xl-n3q2 {
    margin: -3.5rem !important; }
  .mt-xl-n3q2,
  .my-xl-n3q2 {
    margin-top: -3.5rem !important; }
  .mr-xl-n3q2,
  .mx-xl-n3q2 {
    margin-right: -3.5rem !important; }
  .mb-xl-n3q2,
  .my-xl-n3q2 {
    margin-bottom: -3.5rem !important; }
  .ml-xl-n3q2,
  .mx-xl-n3q2 {
    margin-left: -3.5rem !important; }
  .m-xl-n3q3 {
    margin: -3.75rem !important; }
  .mt-xl-n3q3,
  .my-xl-n3q3 {
    margin-top: -3.75rem !important; }
  .mr-xl-n3q3,
  .mx-xl-n3q3 {
    margin-right: -3.75rem !important; }
  .mb-xl-n3q3,
  .my-xl-n3q3 {
    margin-bottom: -3.75rem !important; }
  .ml-xl-n3q3,
  .mx-xl-n3q3 {
    margin-left: -3.75rem !important; }
  .m-xl-n4 {
    margin: -4rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -4rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -4rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -4rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -4rem !important; }
  .m-xl-n4q1 {
    margin: -4.25rem !important; }
  .mt-xl-n4q1,
  .my-xl-n4q1 {
    margin-top: -4.25rem !important; }
  .mr-xl-n4q1,
  .mx-xl-n4q1 {
    margin-right: -4.25rem !important; }
  .mb-xl-n4q1,
  .my-xl-n4q1 {
    margin-bottom: -4.25rem !important; }
  .ml-xl-n4q1,
  .mx-xl-n4q1 {
    margin-left: -4.25rem !important; }
  .m-xl-n4q2 {
    margin: -4.5rem !important; }
  .mt-xl-n4q2,
  .my-xl-n4q2 {
    margin-top: -4.5rem !important; }
  .mr-xl-n4q2,
  .mx-xl-n4q2 {
    margin-right: -4.5rem !important; }
  .mb-xl-n4q2,
  .my-xl-n4q2 {
    margin-bottom: -4.5rem !important; }
  .ml-xl-n4q2,
  .mx-xl-n4q2 {
    margin-left: -4.5rem !important; }
  .m-xl-n4q3 {
    margin: -4.75rem !important; }
  .mt-xl-n4q3,
  .my-xl-n4q3 {
    margin-top: -4.75rem !important; }
  .mr-xl-n4q3,
  .mx-xl-n4q3 {
    margin-right: -4.75rem !important; }
  .mb-xl-n4q3,
  .my-xl-n4q3 {
    margin-bottom: -4.75rem !important; }
  .ml-xl-n4q3,
  .mx-xl-n4q3 {
    margin-left: -4.75rem !important; }
  .m-xl-n5 {
    margin: -5rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -5rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -5rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -5rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -5rem !important; }
  .m-xl-n5q1 {
    margin: -5.25rem !important; }
  .mt-xl-n5q1,
  .my-xl-n5q1 {
    margin-top: -5.25rem !important; }
  .mr-xl-n5q1,
  .mx-xl-n5q1 {
    margin-right: -5.25rem !important; }
  .mb-xl-n5q1,
  .my-xl-n5q1 {
    margin-bottom: -5.25rem !important; }
  .ml-xl-n5q1,
  .mx-xl-n5q1 {
    margin-left: -5.25rem !important; }
  .m-xl-n5q2 {
    margin: -5.5rem !important; }
  .mt-xl-n5q2,
  .my-xl-n5q2 {
    margin-top: -5.5rem !important; }
  .mr-xl-n5q2,
  .mx-xl-n5q2 {
    margin-right: -5.5rem !important; }
  .mb-xl-n5q2,
  .my-xl-n5q2 {
    margin-bottom: -5.5rem !important; }
  .ml-xl-n5q2,
  .mx-xl-n5q2 {
    margin-left: -5.5rem !important; }
  .m-xl-n5q3 {
    margin: -5.75rem !important; }
  .mt-xl-n5q3,
  .my-xl-n5q3 {
    margin-top: -5.75rem !important; }
  .mr-xl-n5q3,
  .mx-xl-n5q3 {
    margin-right: -5.75rem !important; }
  .mb-xl-n5q3,
  .my-xl-n5q3 {
    margin-bottom: -5.75rem !important; }
  .ml-xl-n5q3,
  .mx-xl-n5q3 {
    margin-left: -5.75rem !important; }
  .m-xl-n6 {
    margin: -6rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -6rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -6rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -6rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -6rem !important; }
  .m-xl-n6q1 {
    margin: -6.25rem !important; }
  .mt-xl-n6q1,
  .my-xl-n6q1 {
    margin-top: -6.25rem !important; }
  .mr-xl-n6q1,
  .mx-xl-n6q1 {
    margin-right: -6.25rem !important; }
  .mb-xl-n6q1,
  .my-xl-n6q1 {
    margin-bottom: -6.25rem !important; }
  .ml-xl-n6q1,
  .mx-xl-n6q1 {
    margin-left: -6.25rem !important; }
  .m-xl-n6q2 {
    margin: -6.5rem !important; }
  .mt-xl-n6q2,
  .my-xl-n6q2 {
    margin-top: -6.5rem !important; }
  .mr-xl-n6q2,
  .mx-xl-n6q2 {
    margin-right: -6.5rem !important; }
  .mb-xl-n6q2,
  .my-xl-n6q2 {
    margin-bottom: -6.5rem !important; }
  .ml-xl-n6q2,
  .mx-xl-n6q2 {
    margin-left: -6.5rem !important; }
  .m-xl-n6q3 {
    margin: -6.75rem !important; }
  .mt-xl-n6q3,
  .my-xl-n6q3 {
    margin-top: -6.75rem !important; }
  .mr-xl-n6q3,
  .mx-xl-n6q3 {
    margin-right: -6.75rem !important; }
  .mb-xl-n6q3,
  .my-xl-n6q3 {
    margin-bottom: -6.75rem !important; }
  .ml-xl-n6q3,
  .mx-xl-n6q3 {
    margin-left: -6.75rem !important; }
  .m-xl-n7 {
    margin: -7rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -7rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -7rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -7rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -7rem !important; }
  .m-xl-n7q1 {
    margin: -7.25rem !important; }
  .mt-xl-n7q1,
  .my-xl-n7q1 {
    margin-top: -7.25rem !important; }
  .mr-xl-n7q1,
  .mx-xl-n7q1 {
    margin-right: -7.25rem !important; }
  .mb-xl-n7q1,
  .my-xl-n7q1 {
    margin-bottom: -7.25rem !important; }
  .ml-xl-n7q1,
  .mx-xl-n7q1 {
    margin-left: -7.25rem !important; }
  .m-xl-n7q2 {
    margin: -7.5rem !important; }
  .mt-xl-n7q2,
  .my-xl-n7q2 {
    margin-top: -7.5rem !important; }
  .mr-xl-n7q2,
  .mx-xl-n7q2 {
    margin-right: -7.5rem !important; }
  .mb-xl-n7q2,
  .my-xl-n7q2 {
    margin-bottom: -7.5rem !important; }
  .ml-xl-n7q2,
  .mx-xl-n7q2 {
    margin-left: -7.5rem !important; }
  .m-xl-n7q3 {
    margin: -7.75rem !important; }
  .mt-xl-n7q3,
  .my-xl-n7q3 {
    margin-top: -7.75rem !important; }
  .mr-xl-n7q3,
  .mx-xl-n7q3 {
    margin-right: -7.75rem !important; }
  .mb-xl-n7q3,
  .my-xl-n7q3 {
    margin-bottom: -7.75rem !important; }
  .ml-xl-n7q3,
  .mx-xl-n7q3 {
    margin-left: -7.75rem !important; }
  .m-xl-n8 {
    margin: -8rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -8rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -8rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -8rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -8rem !important; }
  .m-xl-n8q1 {
    margin: -8.25rem !important; }
  .mt-xl-n8q1,
  .my-xl-n8q1 {
    margin-top: -8.25rem !important; }
  .mr-xl-n8q1,
  .mx-xl-n8q1 {
    margin-right: -8.25rem !important; }
  .mb-xl-n8q1,
  .my-xl-n8q1 {
    margin-bottom: -8.25rem !important; }
  .ml-xl-n8q1,
  .mx-xl-n8q1 {
    margin-left: -8.25rem !important; }
  .m-xl-n8q2 {
    margin: -8.5rem !important; }
  .mt-xl-n8q2,
  .my-xl-n8q2 {
    margin-top: -8.5rem !important; }
  .mr-xl-n8q2,
  .mx-xl-n8q2 {
    margin-right: -8.5rem !important; }
  .mb-xl-n8q2,
  .my-xl-n8q2 {
    margin-bottom: -8.5rem !important; }
  .ml-xl-n8q2,
  .mx-xl-n8q2 {
    margin-left: -8.5rem !important; }
  .m-xl-n8q3 {
    margin: -8.75rem !important; }
  .mt-xl-n8q3,
  .my-xl-n8q3 {
    margin-top: -8.75rem !important; }
  .mr-xl-n8q3,
  .mx-xl-n8q3 {
    margin-right: -8.75rem !important; }
  .mb-xl-n8q3,
  .my-xl-n8q3 {
    margin-bottom: -8.75rem !important; }
  .ml-xl-n8q3,
  .mx-xl-n8q3 {
    margin-left: -8.75rem !important; }
  .m-xl-n9 {
    margin: -9rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -9rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -9rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -9rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -9rem !important; }
  .m-xl-n9q1 {
    margin: -9.25rem !important; }
  .mt-xl-n9q1,
  .my-xl-n9q1 {
    margin-top: -9.25rem !important; }
  .mr-xl-n9q1,
  .mx-xl-n9q1 {
    margin-right: -9.25rem !important; }
  .mb-xl-n9q1,
  .my-xl-n9q1 {
    margin-bottom: -9.25rem !important; }
  .ml-xl-n9q1,
  .mx-xl-n9q1 {
    margin-left: -9.25rem !important; }
  .m-xl-n9q2 {
    margin: -9.5rem !important; }
  .mt-xl-n9q2,
  .my-xl-n9q2 {
    margin-top: -9.5rem !important; }
  .mr-xl-n9q2,
  .mx-xl-n9q2 {
    margin-right: -9.5rem !important; }
  .mb-xl-n9q2,
  .my-xl-n9q2 {
    margin-bottom: -9.5rem !important; }
  .ml-xl-n9q2,
  .mx-xl-n9q2 {
    margin-left: -9.5rem !important; }
  .m-xl-n9q3 {
    margin: -9.75rem !important; }
  .mt-xl-n9q3,
  .my-xl-n9q3 {
    margin-top: -9.75rem !important; }
  .mr-xl-n9q3,
  .mx-xl-n9q3 {
    margin-right: -9.75rem !important; }
  .mb-xl-n9q3,
  .my-xl-n9q3 {
    margin-bottom: -9.75rem !important; }
  .ml-xl-n9q3,
  .mx-xl-n9q3 {
    margin-left: -9.75rem !important; }
  .m-xl-n10 {
    margin: -10rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -10rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -10rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -10rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -10rem !important; }
  .m-xl-n10q1 {
    margin: -10.25rem !important; }
  .mt-xl-n10q1,
  .my-xl-n10q1 {
    margin-top: -10.25rem !important; }
  .mr-xl-n10q1,
  .mx-xl-n10q1 {
    margin-right: -10.25rem !important; }
  .mb-xl-n10q1,
  .my-xl-n10q1 {
    margin-bottom: -10.25rem !important; }
  .ml-xl-n10q1,
  .mx-xl-n10q1 {
    margin-left: -10.25rem !important; }
  .m-xl-n10q2 {
    margin: -10.5rem !important; }
  .mt-xl-n10q2,
  .my-xl-n10q2 {
    margin-top: -10.5rem !important; }
  .mr-xl-n10q2,
  .mx-xl-n10q2 {
    margin-right: -10.5rem !important; }
  .mb-xl-n10q2,
  .my-xl-n10q2 {
    margin-bottom: -10.5rem !important; }
  .ml-xl-n10q2,
  .mx-xl-n10q2 {
    margin-left: -10.5rem !important; }
  .m-xl-n10q3 {
    margin: -10.75rem !important; }
  .mt-xl-n10q3,
  .my-xl-n10q3 {
    margin-top: -10.75rem !important; }
  .mr-xl-n10q3,
  .mx-xl-n10q3 {
    margin-right: -10.75rem !important; }
  .mb-xl-n10q3,
  .my-xl-n10q3 {
    margin-bottom: -10.75rem !important; }
  .ml-xl-n10q3,
  .mx-xl-n10q3 {
    margin-left: -10.75rem !important; }
  .m-xl-n11 {
    margin: -11rem !important; }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -11rem !important; }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -11rem !important; }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -11rem !important; }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -11rem !important; }
  .m-xl-n11q1 {
    margin: -11.25rem !important; }
  .mt-xl-n11q1,
  .my-xl-n11q1 {
    margin-top: -11.25rem !important; }
  .mr-xl-n11q1,
  .mx-xl-n11q1 {
    margin-right: -11.25rem !important; }
  .mb-xl-n11q1,
  .my-xl-n11q1 {
    margin-bottom: -11.25rem !important; }
  .ml-xl-n11q1,
  .mx-xl-n11q1 {
    margin-left: -11.25rem !important; }
  .m-xl-n11 q2 {
    margin: -11.5rem !important; }
  .mt-xl-n11 q2,
  .my-xl-n11 q2 {
    margin-top: -11.5rem !important; }
  .mr-xl-n11 q2,
  .mx-xl-n11 q2 {
    margin-right: -11.5rem !important; }
  .mb-xl-n11 q2,
  .my-xl-n11 q2 {
    margin-bottom: -11.5rem !important; }
  .ml-xl-n11 q2,
  .mx-xl-n11 q2 {
    margin-left: -11.5rem !important; }
  .m-xl-n11 q3 {
    margin: -11.75rem !important; }
  .mt-xl-n11 q3,
  .my-xl-n11 q3 {
    margin-top: -11.75rem !important; }
  .mr-xl-n11 q3,
  .mx-xl-n11 q3 {
    margin-right: -11.75rem !important; }
  .mb-xl-n11 q3,
  .my-xl-n11 q3 {
    margin-bottom: -11.75rem !important; }
  .ml-xl-n11 q3,
  .mx-xl-n11 q3 {
    margin-left: -11.75rem !important; }
  .m-xl-n12 {
    margin: -12rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -12rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -12rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -12rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -12rem !important; }
  .m-xl-n12 q1 {
    margin: -12.25rem !important; }
  .mt-xl-n12 q1,
  .my-xl-n12 q1 {
    margin-top: -12.25rem !important; }
  .mr-xl-n12 q1,
  .mx-xl-n12 q1 {
    margin-right: -12.25rem !important; }
  .mb-xl-n12 q1,
  .my-xl-n12 q1 {
    margin-bottom: -12.25rem !important; }
  .ml-xl-n12 q1,
  .mx-xl-n12 q1 {
    margin-left: -12.25rem !important; }
  .m-xl-n12 q2 {
    margin: -12.5rem !important; }
  .mt-xl-n12 q2,
  .my-xl-n12 q2 {
    margin-top: -12.5rem !important; }
  .mr-xl-n12 q2,
  .mx-xl-n12 q2 {
    margin-right: -12.5rem !important; }
  .mb-xl-n12 q2,
  .my-xl-n12 q2 {
    margin-bottom: -12.5rem !important; }
  .ml-xl-n12 q2,
  .mx-xl-n12 q2 {
    margin-left: -12.5rem !important; }
  .m-xl-n12 q3 {
    margin: -12.75rem !important; }
  .mt-xl-n12 q3,
  .my-xl-n12 q3 {
    margin-top: -12.75rem !important; }
  .mr-xl-n12 q3,
  .mx-xl-n12 q3 {
    margin-right: -12.75rem !important; }
  .mb-xl-n12 q3,
  .my-xl-n12 q3 {
    margin-bottom: -12.75rem !important; }
  .ml-xl-n12 q3,
  .mx-xl-n12 q3 {
    margin-left: -12.75rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 767px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 767px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 767px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.row.gutter-0 {
  margin-left: 0;
  margin-right: 0; }

.row.gutter-0 > .col,
.row.gutter-0 > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.row.gutter-1 {
  margin-left: -5px;
  margin-right: -5px; }

.row.gutter-1 > .col,
.row.gutter-1 > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px; }

.row.gutter-2 {
  margin-left: -30px;
  margin-right: -30px; }

.row.gutter-2 > .col,
.row.gutter-2 > [class*="col-"] {
  padding-right: 30px;
  padding-left: 30px; }

.row.gutter-3 {
  margin-left: -40px;
  margin-right: -40px; }

.row.gutter-3 > .col,
.row.gutter-3 > [class*="col-"] {
  padding-right: 40px;
  padding-left: 40px; }

.appear {
  animation-name: appear;
  animation-duration: 1s;
  animation-fill-mode: both; }

.appear-lt {
  animation-name: appear-lt;
  animation-duration: 1s;
  animation-fill-mode: both; }

.appear-rt {
  animation-name: appear-rt;
  animation-duration: 1s;
  animation-fill-mode: both; }

.appear-lb {
  animation-name: appear-lb;
  animation-duration: 1s;
  animation-fill-mode: both; }

.appear-rb {
  animation-name: appear-rb;
  animation-duration: 1s;
  animation-fill-mode: both; }

@media (min-width: 576px) {
  .appear-sm {
    animation-name: appear;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 576px) {
  .appear-sm-lt {
    animation-name: appear-lt;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 576px) {
  .appear-sm-rt {
    animation-name: appear-rt;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 576px) {
  .appear-sm-lb {
    animation-name: appear-lb;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 576px) {
  .appear-sm-rb {
    animation-name: appear-rb;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 767px) {
  .appear-md {
    animation-name: appear;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 767px) {
  .appear-md-lt {
    animation-name: appear-lt;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 767px) {
  .appear-md-rt {
    animation-name: appear-rt;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 767px) {
  .appear-md-lb {
    animation-name: appear-lb;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 767px) {
  .appear-md-rb {
    animation-name: appear-rb;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 992px) {
  .appear-lg {
    animation-name: appear;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 992px) {
  .appear-lg-lt {
    animation-name: appear-lt;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 992px) {
  .appear-lg-rt {
    animation-name: appear-rt;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 992px) {
  .appear-lg-lb {
    animation-name: appear-lb;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 992px) {
  .appear-lg-rb {
    animation-name: appear-rb;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 1200px) {
  .appear-xl {
    animation-name: appear;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 1200px) {
  .appear-xl-lt {
    animation-name: appear-lt;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 1200px) {
  .appear-xl-rt {
    animation-name: appear-rt;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 1200px) {
  .appear-xl-lb {
    animation-name: appear-lb;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@media (min-width: 1200px) {
  .appear-xl-rb {
    animation-name: appear-rb;
    animation-duration: 1s;
    animation-fill-mode: both; } }

.appear-duration-0 {
  animation-duration: 0s; }

.appear-duration-1 {
  animation-duration: 0.25s; }

.appear-duration-2 {
  animation-duration: 0.5s; }

.appear-duration-3 {
  animation-duration: 0.75s; }

.appear-duration-4 {
  animation-duration: 1s; }

.appear-duration-5 {
  animation-duration: 1.25s; }

.appear-duration-6 {
  animation-duration: 1.5s; }

.appear-duration-7 {
  animation-duration: 1.75s; }

.appear-duration-8 {
  animation-duration: 2s; }

.appear-duration-9 {
  animation-duration: 2.25s; }

.appear-duration-10 {
  animation-duration: 2.5s; }

.appear-delay-0 {
  animation-delay: 0s; }

.appear-delay-1 {
  animation-delay: 0.25s; }

.appear-delay-2 {
  animation-delay: 0.5s; }

.appear-delay-3 {
  animation-delay: 0.75s; }

.appear-delay-4 {
  animation-delay: 1s; }

.appear-delay-5 {
  animation-delay: 1.25s; }

.appear-delay-6 {
  animation-delay: 1.5s; }

.appear-delay-7 {
  animation-delay: 1.75s; }

.appear-delay-8 {
  animation-delay: 2s; }

.appear-delay-9 {
  animation-delay: 2.25s; }

.appear-delay-10 {
  animation-delay: 2.5s; }

@keyframes slideDown {
  0% {
    max-height: 0; }
  99.99% {
    max-height: 100vh; }
  100% {
    max-height: none; } }

@keyframes slideUp {
  0% {
    max-height: 100vh; }
  100% {
    max-height: 0; } }

html, body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  line-height: 1.3;
  background: #fff;
  color: #000;
  font-weight: 400; }

* {
  box-sizing: border-box; }

*, *:active, *:focus {
  outline: 0 !important;
  -moz-outline-style: none !important; }

a[href^="tel"] {
  text-decoration: none;
  color: inherit;
  white-space: nowrap; }

.noScroll {
  overflow: hidden; }

body::-webkit-scrollbar {
  width: 10px;
  /* ширина всей полосы прокрутки */ }

body::-webkit-scrollbar-track {
  background: #669BA0;
  /* цвет зоны отслеживания */ }

body::-webkit-scrollbar-thumb {
  background-color: #AEDFE4;
  /* цвет бегунка */
  border-radius: 20px;
  /* округлось бегунка */ }

.error {
  color: red;
  position: absolute;
  top: 45px;
  font-size: 14px; }

.error.not-visible {
  display: none; }

.warning {
  color: red;
  position: absolute;
  top: 45px;
  font-size: 14px; }

.warning.not-visible {
  opacity: 0; }

.input-error {
  border-bottom: 1px solid red !important; }

.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow: hidden; }

.page-content {
  flex-grow: 2; }

.header {
  margin-bottom: -102px;
  z-index: 1060;
  position: relative; }

.header-fix {
  z-index: 1060;
  position: fixed;
  background: #FBFBFB;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04);
  width: 100%;
  top: -88px;
  transition-duration: .6s; }
  .header-fix .header-content {
    padding: 10px 0;
    margin: 0; }
  .header-fix .desktopMenu-li a {
    color: #1F1F1F; }
  .header-fix .header-phone a {
    color: #1F1F1F; }
  .header-fix .header-button .btn1 {
    color: #1F1F1F; }
  @media (max-width: 1230px) {
    .header-fix {
      display: none; } }

.fixed {
  top: 0;
  transition-duration: .6s; }

.header-content {
  display: flex;
  align-items: center;
  margin-top: 15px;
  position: relative;
  z-index: 5;
  justify-content: space-between; }

.header_bottom {
  margin-bottom: 0; }

.header-phone {
  display: flex;
  align-items: center; }
  .header-phone a {
    padding-left: 5px;
    color: white;
    font-size: 14px; }
    .header-phone a:hover {
      color: #AEDFE4;
      transition: all .2s; }
  @media (max-width: 1230px) {
    .header-phone {
      display: none; } }

@media (max-width: 1230px) {
  .header-button {
    display: none; } }

.header-logo {
  z-index: 1000; }

.footer {
  background: #000;
  z-index: 1059;
  position: relative; }

.footer-content {
  padding-top: 50px;
  display: flex;
  align-content: baseline;
  justify-content: space-between;
  color: white; }
  @media (max-width: 767px) {
    .footer-content {
      flex-direction: column;
      padding-top: 25px; } }

.footer-text {
  display: flex;
  align-content: baseline;
  justify-content: space-between;
  max-width: 780px;
  width: 100%; }
  @media (max-width: 767px) {
    .footer-text {
      max-width: 100%;
      flex-direction: column; } }

@media (max-width: 767px) {
  .footer-navigation {
    margin-top: 25px; } }

.footer-navigation h3 {
  margin-bottom: 10px; }

.footer-navigation ul {
  padding-left: 0;
  list-style: none;
  margin: 0; }

.footer-navigation a {
  font-size: 14px;
  margin-bottom: 9px;
  color: #CFCEDC;
  text-decoration: none;
  display: inline-block; }
  .footer-navigation a:hover {
    text-decoration: none;
    color: #AEDFE4;
    transition: all .2s; }

@media (max-width: 767px) {
  .footer-contacts {
    margin-top: 25px; } }

.footer-contacts h3 {
  margin-bottom: 17px; }

.footer-list {
  column-count: 2;
  column-gap: 100px; }
  @media (max-width: 767px) {
    .footer-list {
      max-width: 290px; } }

.footer-address {
  max-width: 360px;
  display: flex;
  align-items: flex-start; }
  .footer-address p {
    margin: 0;
    font-size: 14px;
    color: #CFCEDC;
    padding-left: 10px; }

.footer-phone, .footer-mail {
  display: flex;
  align-items: center;
  margin-top: 10px; }
  .footer-phone a, .footer-mail a {
    margin: 0;
    font-size: 14px;
    color: #CFCEDC;
    padding-left: 10px;
    text-decoration: none; }
    .footer-phone a:hover, .footer-mail a:hover {
      text-decoration: none;
      color: #AEDFE4;
      transition: all .2s; }

.footer-logo {
  margin-right: 50px; }
  @media (max-width: 767px) {
    .footer-logo {
      margin-right: 0; } }

.burger-toggle {
  position: relative; }

.burger-toggle:focus {
  outline-width: 0; }

.burger-toggle [class*='bar-'] {
  background: #AEDF;
  display: block;
  transform: rotate(0);
  transition: .2s ease all;
  width: 29px;
  height: 2px;
  margin-bottom: 6px; }

.burger-toggle .bar-bot {
  margin-bottom: 0; }

.opened .bar-top {
  transform: rotate(45deg);
  transform-origin: 15% 15%; }

.opened .bar-mid {
  opacity: 0; }

.opened .bar-bot {
  transform: rotate(-45deg);
  transform-origin: 6% 95%; }

.close-modal {
  background-image: url("../../img/close.png");
  width: 28px;
  height: 28px;
  display: block;
  position: relative;
  opacity: 1 !important; }
  .close-modal:hover {
    transform: rotate(360deg);
    transition: all .4s; }

.content {
  max-width: 1240px;
  width: 94%;
  position: relative;
  margin: 0 auto; }

.copyright {
  position: relative;
  height: auto;
  border-top: 1px solid #56697A;
  padding-bottom: 35px;
  margin-top: 25px;
  color: #CFCEDC; }
  @media (max-width: 767px) {
    .copyright {
      padding-bottom: 20px; } }

.copyright-content {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 20px; }
  @media (max-width: 767px) {
    .copyright-content {
      flex-direction: column; } }
  .copyright-content p {
    color: #CFCEDC;
    font-size: 14px; }
    @media (max-width: 767px) {
      .copyright-content p {
        margin-bottom: 10px; } }
  .copyright-content a {
    color: #CFCEDC;
    font-size: 14px;
    text-decoration: none; }
    .copyright-content a:hover {
      text-decoration: none;
      color: #AEDFE4;
      transition: all .2s; }

@media (max-width: 1230px) {
  .desktopMenu {
    display: none; } }

.desktopMenu-ul {
  display: flex;
  margin: 0;
  padding: 0; }

.desktopMenu-li {
  list-style: none; }
  .desktopMenu-li a {
    font-size: 0.77778rem;
    color: white;
    text-decoration: none; }
    .desktopMenu-li a:hover {
      color: #AEDFE4;
      transition: all .2s; }

.desktopMenu-li + .desktopMenu-li {
  padding-left: 35px; }

.icon-phone {
  background-image: url(../img/sprite.png?t1669380866883);
  background-position: -201px -70px;
  width: 18px;
  height: 18px;
  display: block; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon-phone {
      background-image: url(../img/sprite@2x.png?t1669380866883);
      background-size: 256px 223px; } }

.icon-phone-2 {
  background-image: url(../img/sprite.png?t1669380866883);
  background-position: -181px -70px;
  width: 18px;
  height: 18px;
  display: block; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon-phone-2 {
      background-image: url(../img/sprite@2x.png?t1669380866883);
      background-size: 256px 223px; } }

.icon-marker {
  background-image: url(../img/sprite.png?t1669380866883);
  background-position: -141px -167px;
  width: 14px;
  height: 20px;
  display: block; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon-marker {
      background-image: url(../img/sprite@2x.png?t1669380866883);
      background-size: 256px 223px; } }

.icon-mail {
  background-image: url(../img/sprite.png?t1669380866883);
  background-position: -221px -70px;
  width: 19px;
  height: 15px;
  display: block; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon-mail {
      background-image: url(../img/sprite@2x.png?t1669380866883);
      background-size: 256px 223px; } }

.icon-air {
  background-image: url("../../img/icons/air.png");
  width: 62px;
  height: 62px;
  display: block; }

.icon-big {
  background-image: url("../../img/icons/big.png");
  width: 62px;
  height: 62px;
  display: block; }

.icon-mechanism {
  background-image: url("../../img/icons/mechanism.png");
  width: 60px;
  height: 59px;
  display: block; }

.icon-modern {
  background-image: url("../../img/icons/modern.png");
  width: 61px;
  height: 61px;
  display: block; }

.icon-rights {
  background-image: url("../../img/icons/rights.png");
  width: 59px;
  height: 36px;
  display: block; }

.icon-safety {
  background-image: url("../../img/icons/safety.png");
  width: 45px;
  height: 45px;
  display: block; }

.icon-school {
  background-image: url("../../img/icons/school.png");
  width: 63px;
  height: 35px;
  display: block; }

.icon-support {
  background-image: url("../../img/icons/support.png");
  width: 55px;
  height: 61px;
  display: block; }

.icon-prev {
  background-image: url(../img/sprite.png?t1669380866883);
  background-position: -89px -167px;
  width: 50px;
  height: 50px;
  display: block; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon-prev {
      background-image: url(../img/sprite@2x.png?t1669380866883);
      background-size: 256px 223px; } }

.icon-next {
  background-image: url(../img/sprite.png?t1669380866883);
  background-position: -197px -97px;
  width: 50px;
  height: 50px;
  display: block; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon-next {
      background-image: url(../img/sprite@2x.png?t1669380866883);
      background-size: 256px 223px; } }

.icon-search {
  background-image: url("../../img/icons/search.png");
  width: 57px;
  height: 58px;
  display: block; }
  @media (max-width: 767px) {
    .icon-search {
      width: 45px;
      height: 46px;
      display: block;
      background-size: cover; } }

.icon-code {
  background-image: url("../../img/icons/code.png");
  width: 64px;
  height: 55px;
  display: block; }
  @media (max-width: 767px) {
    .icon-code {
      width: 53px;
      height: 46px;
      display: block;
      background-size: cover; } }

.icon-portal {
  background-image: url("../../img/icons/portal.png");
  width: 67px;
  height: 61px;
  display: block; }
  @media (max-width: 767px) {
    .icon-portal {
      width: 51px;
      height: 46px;
      background-size: contain;
      background-repeat: no-repeat; } }

.icon-graficks {
  background-image: url("../../img/icons/graficks.png");
  width: 61px;
  height: 61px;
  display: block; }
  @media (max-width: 767px) {
    .icon-graficks {
      width: 51px;
      height: 46px;
      background-size: contain;
      background-repeat: no-repeat; } }

.icon-window {
  background-image: url("../../img/icons/window.png");
  width: 61px;
  height: 61px;
  display: block; }
  @media (max-width: 767px) {
    .icon-window {
      width: 51px;
      height: 46px;
      background-size: contain;
      background-repeat: no-repeat; } }

.icon-book {
  background-image: url("../../img/icons/book.png");
  width: 49px;
  height: 61px;
  display: block; }
  @media (max-width: 767px) {
    .icon-book {
      width: 51px;
      height: 46px;
      background-size: contain;
      background-repeat: no-repeat; } }

.icon-escort {
  background-image: url("../../img/icons/escort.png");
  width: 54px;
  height: 56px;
  display: block; }
  @media (max-width: 767px) {
    .icon-escort {
      width: 51px;
      height: 46px;
      background-size: contain;
      background-repeat: no-repeat; } }

.icon-pazle {
  background-image: url("../../img/icons/pazle.png");
  width: 61px;
  height: 61px;
  display: block; }
  @media (max-width: 767px) {
    .icon-pazle {
      width: 51px;
      height: 46px;
      background-size: contain;
      background-repeat: no-repeat; } }

.icon-chat {
  background-image: url("../../img/icons/chat.png");
  width: 60px;
  height: 48px;
  display: block; }
  @media (max-width: 767px) {
    .icon-chat {
      width: 51px;
      height: 46px;
      background-size: contain;
      background-repeat: no-repeat; } }

.icon-prev-2 {
  background-image: url("../../img/icons/prev-2.png");
  width: 20px;
  height: 20px;
  display: block; }

.icon-next-2 {
  background-image: url("../../img/icons/next-2.png");
  width: 20px;
  height: 20px;
  display: block; }

.icon-scale {
  background-image: url("../../img/icons/scale.png");
  width: 70px;
  height: 70px;
  display: block; }

.icon-format {
  background-image: url("../../img/icons/format.png");
  width: 68px;
  height: 68px;
  display: block; }

.icon-intuition {
  background-image: url("../../img/icons/intuition.png");
  width: 70px;
  height: 70px;
  display: block; }

.icon-data {
  background-image: url("../../img/icons/data.png");
  width: 70px;
  height: 70px;
  display: block; }

.icon-log {
  background-image: url("../../img/icons/log.png");
  width: 72px;
  height: 67px;
  display: block; }

.icon-calendar {
  background-image: url("../../img/icons/calendar.png");
  width: 66px;
  height: 71px;
  display: block; }

.icon-structure {
  background-image: url("../../img/icons/structure.png");
  width: 70px;
  height: 68px;
  display: block; }

.icon-job {
  background-image: url("../../img/icons/job.png");
  width: 71px;
  height: 71px;
  display: block; }

.icon-user {
  background-image: url("../../img/icons/user.png");
  width: 68px;
  height: 68px;
  display: block; }

.icon-securety {
  background-image: url("../../img/icons/security.png");
  width: 55px;
  height: 71px;
  display: block; }

.icon-reserve {
  background-image: url("../../img/icons/reserve.png");
  width: 71px;
  height: 71px;
  display: block; }

.logo {
  background-image: url(../img/sprite.png?t1669380866883);
  background-position: 0px -97px;
  width: 106px;
  height: 68px;
  display: block; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .logo {
      background-image: url(../img/sprite@2x.png?t1669380866883);
      background-size: 256px 223px; } }
  @media (max-width: 992px) {
    .logo {
      background-image: url(../img/sprite.png?t1669380866883);
      background-position: 0px -167px;
      width: 87px;
      height: 56px; } }
  @media (max-width: 992px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 992px) and (min-resolution: 192dpi) {
    .logo {
      background-image: url(../img/sprite@2x.png?t1669380866883);
      background-size: 256px 223px; } }

.logo-footer {
  background-image: url(../img/sprite.png?t1669380866883);
  background-position: 0px 0px;
  width: 148px;
  height: 95px;
  display: block; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .logo-footer {
      background-image: url(../img/sprite@2x.png?t1669380866883);
      background-size: 256px 223px; } }
  @media (max-width: 992px) {
    .logo-footer {
      background-image: url(../img/sprite.png?t1669380866883);
      background-position: -108px -97px;
      width: 87px;
      height: 56px; } }
  @media (max-width: 992px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 992px) and (min-resolution: 192dpi) {
    .logo-footer {
      background-image: url(../img/sprite@2x.png?t1669380866883);
      background-size: 256px 223px; } }

.logo-fixed {
  background-image: url(../img/sprite.png?t1669380866883);
  background-position: -150px 0px;
  width: 106px;
  height: 68px;
  display: block; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .logo-fixed {
      background-image: url(../img/sprite@2x.png?t1669380866883);
      background-size: 256px 223px; } }

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 120px; }

.menu-ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.menu-a {
  font-size: 1.33333rem;
  color: white;
  font-family: "Dela Gothic One"; }
  .menu-a:hover {
    color: white;
    text-decoration: none; }

.menu-li + .menu-li {
  padding-top: 20px; }

.menu-phone {
  display: flex;
  align-items: center;
  position: relative;
  bottom: 100px; }
  .menu-phone a {
    padding-left: 5px;
    color: white;
    font-size: 14px; }
    .menu-phone a:hover {
      color: #AEDFE4;
      transition: all .2s; }

.mobileMenu {
  display: none;
  z-index: 1000; }
  @media (max-width: 1230px) {
    .mobileMenu {
      display: block; } }

.mobileMenu-content {
  display: flex;
  align-items: center; }

.mobile-button {
  margin-right: 18px; }

.sideBar {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  height: 100%;
  width: 100%;
  margin: 0;
  z-index: 999;
  transition: .2s; }

.sideBar-content {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  overflow: auto;
  background: #161717;
  position: relative; }

.sideBar_visible {
  transform: translateX(0); }

.banner {
  position: relative;
  height: 790px;
  background: #161717;
  z-index: 1057; }
  @media (max-width: 1200px) {
    .banner {
      height: 710px; } }
  @media (max-width: 992px) {
    .banner {
      height: 576px; } }
  @media (max-width: 767px) {
    .banner {
      height: 780px; } }
  @media (max-width: 576px) {
    .banner {
      height: 685px; } }

.banner-position {
  display: flex;
  align-items: center;
  height: 100%; }

.banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .banner-content {
      margin-top: 50px;
      flex-direction: column;
      justify-content: normal;
      width: 100%; } }

.banner-video {
  height: 690px; }
  @media (max-width: 767px) {
    .banner-video {
      margin: 40px auto 0;
      height: auto; } }
  .banner-video video {
    width: 100%;
    height: 100%; }
    @media (max-width: 767px) {
      .banner-video video {
        width: 380px;
        height: auto; } }
    @media (max-width: 576px) {
      .banner-video video {
        width: 295px; } }

.banner-title {
  position: relative;
  z-index: 1055; }
  .banner-title h1 {
    color: white;
    margin-bottom: 30px; }
    @media (max-width: 576px) {
      .banner-title h1 {
        margin-bottom: 10px; } }
  .banner-title p {
    color: #BCCDD7;
    margin: 0;
    max-width: 647px; }
  .banner-title:after {
    content: "";
    background-image: url(../../img/rutilans/e_3.png);
    background-repeat: no-repeat;
    width: 473px;
    height: 473px;
    position: absolute;
    z-index: 1055;
    bottom: 0;
    right: -70px; }
    @media (max-width: 992px) {
      .banner-title:after {
        right: -200px; } }
    @media (max-width: 767px) {
      .banner-title:after {
        right: 65px;
        bottom: -55px; } }
    @media (max-width: 576px) {
      .banner-title:after {
        right: -165px;
        bottom: 0;
        top: 240px; } }

.video-1 {
  display: none; }
  @media (max-width: 767px) {
    .video-1 {
      display: block; } }

@media (max-width: 767px) {
  .video-2 {
    display: none; } }

@media (max-width: 767px) {
  .banner-img {
    width: 380px;
    height: 350px;
    position: relative;
    left: -25px; } }

.banner-img img {
  width: 100%;
  min-height: 100%;
  object-fit: cover; }

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .banner-img {
    display: none; } }

@supports (-moz-appearance: none) {
  .banner-img {
    display: none; } }

@supports (-ms-ime-align: auto) {
  .banner-img {
    display: none; } }

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .banner-img {
      display: block; } } }

.safari .safariSpecific {
  display: block; }

.safari .safariNone {
  display: none; }

.btn1 {
  font-family: 'Exo 2', sans-serif;
  font-size: 12px;
  color: #AEDFE4;
  border: 1px solid #AEDFE4;
  height: 42px;
  padding: 0 20px;
  transition: all .2s;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 800; }
  @media (pointer: fine) {
    .btn1:hover {
      transition: all .2s;
      color: #1F1F1F;
      background: #88D4DC;
      text-decoration: none; } }

.btn1-mobile {
  font-family: 'Exo 2', sans-serif;
  font-weight: 800;
  font-size: 12px;
  color: #AEDFE4;
  border: 1px solid #AEDFE4;
  height: 36px;
  padding: 0 10px;
  transition: all .2s;
  display: flex;
  align-items: center;
  text-decoration: none; }
  .btn1-mobile:hover {
    text-decoration: none; }

.btn2 {
  background: #AEDFE4;
  height: 60px;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-family: 'Exo 2', sans-serif;
  font-weight: 800;
  color: #000000; }
  @media (pointer: fine) {
    .btn2:hover {
      transition: all .2s;
      background: #88D4DC; } }

.customModal {
  position: relative;
  z-index: 1061; }
  .customModal .modal-dialog {
    max-width: 563px; }
  .customModal .modal-content {
    border-radius: 0;
    border: none;
    padding-bottom: 40px; }
  .customModal .modal-header {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    justify-content: flex-end; }
  .customModal .modal-title {
    text-align: center;
    margin: 0 auto; }
    .customModal .modal-title h4 {
      font-family: 'Exo 2', sans-serif;
      font-weight: 800;
      font-size: 32px; }
      @media (max-width: 576px) {
        .customModal .modal-title h4 {
          font-size: 24px; } }
    .customModal .modal-title p {
      margin: 15px auto 0;
      max-width: 328px; }
      @media (max-width: 576px) {
        .customModal .modal-title p {
          font-size: 14px; } }
  .customModal .modal-body {
    padding: 0 50px; }
    @media (max-width: 576px) {
      .customModal .modal-body {
        padding: 0 25px; } }
  .customModal .form-control {
    border: none;
    border-bottom: 1px solid #747474;
    border-radius: 0;
    font-size: 16px;
    color: #2E3234; }
    .customModal .form-control:focus {
      box-shadow: none; }
    .customModal .form-control::placeholder {
      color: #2E3234; }
  .customModal .form-group {
    margin-bottom: 30px;
    position: relative; }
  .customModal .modal-form {
    margin-top: 30px; }
  .customModal .form-textarea {
    border: 1px solid #747474;
    border-radius: 0;
    font-size: 16px;
    color: #2E3234;
    width: 100%;
    resize: none;
    padding: 0.375rem 0.75rem; }
    .customModal .form-textarea::placeholder {
      color: #2E3234; }
  .customModal .modal-politics {
    max-width: 343px;
    text-align: center;
    margin: 25px auto 0; }
    .customModal .modal-politics p {
      margin: 0;
      color: #747474;
      font-size: 14px; }

.modal-backdrop {
  z-index: 1060; }

.rutilans-1 {
  background-image: url("../../img/rutilans/e_1.png");
  background-repeat: no-repeat;
  width: 287px;
  height: 793px;
  position: absolute;
  left: 0;
  top: 120px;
  z-index: 1055; }
  @media (max-width: 1200px) {
    .rutilans-1 {
      top: 70px; } }
  @media (max-width: 992px) {
    .rutilans-1 {
      top: 0; } }
  @media (max-width: 576px) {
    .rutilans-1 {
      top: 170px;
      bottom: -56px;
      height: 490px;
      background-size: contain; } }

.rutilans-2 {
  background-image: url("../../img/rutilans/e_2.png");
  background-repeat: no-repeat;
  width: 263px;
  height: 496px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1055; }

.rutilans-3 {
  background-image: url("../../img/rutilans/e_4.png");
  background-repeat: no-repeat;
  width: 305px;
  height: 793px;
  position: absolute;
  right: 0;
  top: 290px;
  z-index: 1055; }
  @media (max-width: 767px) {
    .rutilans-3 {
      top: 705px; } }
  @media (max-width: 576px) {
    .rutilans-3 {
      top: 620px; } }

.rutilans-4 {
  background-image: url("../../img/rutilans/e_5.png");
  background-repeat: no-repeat;
  width: 530px;
  height: 793px;
  position: absolute;
  left: 0;
  bottom: -480px;
  z-index: 1055; }
  @media (max-width: 767px) {
    .rutilans-4 {
      bottom: 0;
      top: -435px; } }

.rutilans-5 {
  background-image: url("../../img/rutilans/e_6.png");
  background-repeat: no-repeat;
  width: 382px;
  height: 793px;
  position: absolute;
  left: 0;
  bottom: -180px;
  z-index: 1055; }
  @media (max-width: 992px) {
    .rutilans-5 {
      left: -100px; } }
  @media (max-width: 767px) {
    .rutilans-5 {
      bottom: -380px; } }

.rutilans-6 {
  background-image: url("../../img/rutilans/e_7.png");
  background-repeat: no-repeat;
  width: 377px;
  height: 614px;
  position: absolute;
  right: 0;
  top: -30px;
  z-index: 1055; }
  @media (max-width: 1200px) {
    .rutilans-6 {
      right: -100px; } }
  @media (max-width: 576px) {
    .rutilans-6 {
      top: -130px;
      right: -140px; } }

.rutilans-7 {
  background-image: url("../../img/rutilans/e_8.png");
  background-repeat: no-repeat;
  width: 614px;
  height: 614px;
  position: absolute;
  top: 0;
  z-index: 1055;
  left: 50%;
  transform: translateX(-50%); }
  @media (max-width: 992px) {
    .rutilans-7 {
      top: -135px; } }
  @media (max-width: 767px) {
    .rutilans-7 {
      display: none; } }

.rutilans-9 {
  background-image: url("../../img/rutilans/e_9.png");
  background-repeat: no-repeat;
  width: 250px;
  height: 793px;
  position: absolute;
  bottom: -210px;
  z-index: 1055;
  left: 0; }
  @media (max-width: 1200px) {
    .rutilans-9 {
      left: -50px; } }
  @media (max-width: 767px) {
    .rutilans-9 {
      bottom: -50px; } }

.rutilans-10 {
  background-image: url("../../img/rutilans/e_10.png");
  background-repeat: no-repeat;
  width: 396px;
  height: 793px;
  position: absolute;
  top: 0;
  z-index: 1055;
  right: 0; }
  @media (max-width: 992px) {
    .rutilans-10 {
      top: -155px;
      right: -100px; } }
  @media (max-width: 576px) {
    .rutilans-10 {
      top: -320px; } }

.rutilans-11 {
  background-image: url("../../img/rutilans/e_11.png");
  background-repeat: no-repeat;
  width: 426px;
  height: 793px;
  position: absolute;
  top: -23px;
  z-index: 1056;
  right: -111px; }
  @media (max-width: 1200px) {
    .rutilans-11 {
      top: 50px; } }
  @media (max-width: 992px) {
    .rutilans-11 {
      display: none; } }

.rutilans-12 {
  background-image: url("../../img/rutilans/e_11.png");
  background-repeat: no-repeat;
  width: 426px;
  height: 793px;
  position: absolute;
  top: 70px;
  z-index: 1055;
  right: 0;
  display: none; }
  @media (max-width: 992px) {
    .rutilans-12 {
      display: block; } }
  @media (max-width: 767px) {
    .rutilans-12 {
      top: 385px;
      right: -160px; } }

.rutilans-13 {
  background-image: url("../../img/rutilans/e_13.png");
  background-repeat: no-repeat;
  width: 446px;
  height: 793px;
  position: absolute;
  top: -240px;
  z-index: 1055;
  left: 0; }
  @media (max-width: 992px) {
    .rutilans-13 {
      left: -50px; } }
  @media (max-width: 767px) {
    .rutilans-13 {
      top: -210px;
      left: -94px;
      display: none; } }

.company {
  position: relative;
  height: auto;
  background: #FAF9FF;
  z-index: 1056;
  display: flex;
  flex-direction: column; }

.company-content {
  padding-top: 115px;
  display: flex;
  position: relative;
  z-index: 1056; }
  @media (max-width: 767px) {
    .company-content {
      flex-direction: column-reverse;
      padding-top: 65px; } }

.company-text {
  margin-top: 95px;
  margin-left: 65px;
  max-width: 500px; }
  @media (max-width: 1200px) {
    .company-text {
      margin-top: 35px; } }
  @media (max-width: 992px) {
    .company-text {
      margin-top: 10px;
      margin-left: 35px; } }
  @media (max-width: 767px) {
    .company-text {
      margin-left: 0;
      margin-top: 0; } }
  .company-text h2 {
    margin-bottom: 10px; }
    @media (max-width: 576px) {
      .company-text h2 {
        margin-bottom: 5px; } }
  .company-text p {
    margin: 0;
    color: #2E3234; }

.company-img {
  min-width: 584px; }
  @media (max-width: 1200px) {
    .company-img {
      min-width: 500px; } }
  @media (max-width: 992px) {
    .company-img {
      min-width: 400px; } }
  @media (max-width: 767px) {
    .company-img {
      min-width: auto;
      margin-top: 40px; } }
  .company-img img {
    width: 100%;
    object-fit: cover; }

.benefits {
  position: relative;
  height: auto; }

.benefits-content {
  margin-top: 150px;
  padding-bottom: 115px; }
  @media (max-width: 767px) {
    .benefits-content {
      margin-top: 65px;
      padding-bottom: 40px; } }

.benefits-content {
  position: relative;
  z-index: 1056; }
  .benefits-content h2 {
    margin: 0; }

.benefits-icon {
  width: 61px;
  height: 61px; }
  @media (max-width: 767px) {
    .benefits-icon {
      margin: 0 auto; } }
  @media (max-width: 576px) {
    .benefits-icon {
      margin: 0; } }

.benefits-grid {
  margin-top: 30px; }
  @media (max-width: 767px) {
    .benefits-grid {
      display: none; } }

.benefits-slider {
  margin-top: 40px;
  display: none; }
  @media (max-width: 767px) {
    .benefits-slider {
      display: block; } }

.benefits-text {
  max-width: 255px;
  margin-top: 20px;
  margin-bottom: 75px; }
  @media (max-width: 767px) {
    .benefits-text {
      margin: 20px auto 75px;
      text-align: center; } }
  @media (max-width: 576px) {
    .benefits-text {
      text-align: left;
      max-width: 150px;
      margin: 20px 0 55px; } }
  .benefits-text p {
    margin: 0;
    color: #2E3234; }
  .benefits-text span {
    display: block; }

.benefits-slider {
  margin-top: 35px; }

.benefits-slider-item {
  width: 100%;
  margin-right: 30px; }

.slider-benefits-button {
  display: none; }
  @media (max-width: 767px) {
    .slider-benefits-button {
      display: flex;
      max-width: 140px;
      justify-content: space-between;
      margin: 0 auto;
      z-index: 1056;
      position: relative; } }

.benefits-slider .flickity-page-dots {
  bottom: -28px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 2px;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
  background: #D8D5E7; }

.flickity-page-dots .dot.is-selected {
  background: #B5E4E8; }

.activity {
  position: relative;
  height: auto;
  background: #161717;
  z-index: 1056;
  color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column; }

.activity-content {
  padding-top: 130px;
  padding-bottom: 120px; }
  .activity-content h2 {
    margin: 0; }
  @media (max-width: 767px) {
    .activity-content {
      padding-top: 60px;
      padding-bottom: 130px; } }

@media (max-width: 767px) {
  .slider-activity {
    display: none; } }

.activity-slider-md {
  display: none; }
  @media (max-width: 767px) {
    .activity-slider-md {
      display: block; } }

.activity-slider {
  margin-top: 30px;
  position: relative;
  z-index: 1056; }

.slider-activity-icon {
  width: 67px;
  height: 61px;
  margin: 0 auto; }
  @media (max-width: 767px) {
    .slider-activity-icon {
      width: 45px; } }

.slider-activity-text {
  margin: 40px auto 0; }
  .slider-activity-text p {
    margin: 0;
    color: white;
    max-width: 240px;
    text-align: center; }
  @media (max-width: 767px) {
    .slider-activity-text {
      margin: 5px auto 0; } }

.slider-activity-item {
  background: rgba(0, 0, 0, 0.4);
  width: 287px;
  height: 270px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 30px; }

.slide-activity-mobileItem {
  background: rgba(0, 0, 0, 0.4);
  width: 328px;
  height: 193px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto; }
  @media (max-width: 500px) {
    .slide-activity-mobileItem {
      width: 100%; } }

.activity-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.slider-activity-button {
  display: flex;
  align-items: baseline;
  max-width: 162px;
  justify-content: space-between;
  width: 100%;
  z-index: 1056; }
  @media (max-width: 978px) {
    .slider-activity-button {
      max-width: 181px; } }
  @media (max-width: 767px) {
    .slider-activity-button {
      max-width: 188px;
      position: absolute;
      margin: 0 auto;
      width: 100%;
      transform: translateX(-50%);
      left: 50%;
      bottom: 43px; } }

.slider-activity-button button {
  background: rgba(0, 0, 0, 0.4);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.button-previous-2:before {
  content: "";
  background-image: url("../../img/icons/prev-2.png");
  width: 20px;
  height: 20px; }

.button-previous-2:hover:before {
  background-image: url("../../img/icons/prev-mobile.png");
  width: 20px;
  height: 20px;
  transition: all .2s; }

.button-next-2:before {
  content: "";
  background-image: url("../../img/icons/next-2.png");
  width: 21px;
  height: 20px; }

.button-next-2:hover:before {
  background-image: url("../../img/icons/next-mobile.png");
  width: 21px;
  height: 20px;
  transition: all .2s; }

.button-previous-3:before {
  content: "";
  background-image: url("../../img/icons/prev-2.png");
  width: 20px;
  height: 20px; }

.button-next-3:before {
  content: "";
  background-image: url("../../img/icons/next-2.png");
  width: 21px;
  height: 20px; }

.slider-activity .flickity-page-dots {
  position: absolute;
  width: auto;
  padding: 0;
  margin: 0;
  top: -71px;
  right: 66px; }
  @media (max-width: 1200px) {
    .slider-activity .flickity-page-dots {
      top: -69px; } }
  @media (max-width: 1070px) {
    .slider-activity .flickity-page-dots {
      top: -65px; } }
  @media (max-width: 960px) {
    .slider-activity .flickity-page-dots {
      top: -61px; } }
  @media (max-width: 767px) {
    .slider-activity .flickity-page-dots {
      position: absolute;
      width: 100%;
      bottom: -65px;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      line-height: 1;
      top: auto;
      right: auto; } }

.slide-activity-mobileItem + .slide-activity-mobileItem {
  margin-top: 30px; }

.activity-slider-md .flickity-page-dots {
  bottom: -65px; }

.slider-activity-mobile {
  width: 100%; }

.flickity-enabled {
  position: relative;
  z-index: 1056; }

.projects {
  position: relative;
  height: auto;
  z-index: 1056;
  background: #FAF9FF;
  display: flex;
  flex-direction: column; }

.projects-content {
  padding-top: 130px; }
  @media (max-width: 767px) {
    .projects-content {
      padding-top: 60px;
      padding-bottom: 130px; } }

.projects-title h2 {
  margin: 0; }

.projects-carousel {
  margin-top: 30px;
  background: #FFFFFF;
  padding: 35px 30px 30px; }

.slider-projects-text {
  max-width: 585px;
  height: 100%;
  position: relative; }

.slider-projects-list ul {
  margin-top: 25px;
  padding-left: 25px; }
  @media (max-width: 767px) {
    .slider-projects-list ul {
      margin-top: 15px; } }

.slider-projects-list li {
  color: #2E3234; }
  .slider-projects-list li p {
    margin-bottom: 6px; }

.slider-projects-description {
  margin-top: 15px; }
  @media (max-width: 767px) {
    .slider-projects-description {
      margin-top: 10px; } }
  .slider-projects-description p {
    margin-bottom: 6px;
    color: #2E3234; }

.slider-projects-item {
  width: 100%;
  margin-right: 30px; }
  @media (max-width: 767px) {
    .slider-projects-item {
      background: transparent;
      padding: 0; } }

.slider-projects-block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1060px; }
  @media (max-width: 767px) {
    .slider-projects-block {
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 100%;
      height: 100%; } }

.slider-projects-img {
  margin-left: 35px; }
  @media (max-width: 767px) {
    .slider-projects-img {
      margin: 0 auto 25px; } }
  .slider-projects-img img {
    width: 100%;
    object-fit: cover; }
    @media (max-width: 767px) {
      .slider-projects-img img {
        width: 241px;
        height: 228px; } }

.slider-projects-button {
  display: flex;
  align-items: baseline;
  max-width: 162px;
  justify-content: space-between;
  width: 100%;
  z-index: 1056; }
  @media (max-width: 767px) {
    .slider-projects-button {
      max-width: 155px;
      position: absolute;
      margin: 0 auto;
      width: 100%;
      transform: translateX(-50%);
      left: 50%;
      bottom: -86px; } }

.projects-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.slider-projects-button button {
  border: 1px solid #7E889B;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (pointer: fine) {
    .slider-projects-button button:hover {
      border: 1px solid #88D4DC;
      transition: all .2s; } }

.button-previous-4:before {
  content: "";
  background-image: url("../../img/icons/prev-gray.png");
  width: 21px;
  height: 20px; }

.button-next-4:before {
  content: "";
  background-image: url("../../img/icons/next-gray.png");
  width: 21px;
  height: 20px; }

@media (pointer: fine) {
  .button-previous-4:hover:before {
    background-image: url("../../img/icons/prev-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s; }
  .button-next-4:hover:before {
    background-image: url("../../img/icons/next-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s; } }

.slider-projects .flickity-page-dots {
  position: absolute;
  width: auto;
  padding: 0;
  margin: 0;
  top: -106px;
  right: 35px; }
  @media (max-width: 1220px) {
    .slider-projects .flickity-page-dots {
      top: -104px; } }
  @media (max-width: 1120px) {
    .slider-projects .flickity-page-dots {
      top: -101px; } }
  @media (max-width: 992px) {
    .slider-projects .flickity-page-dots {
      top: -98px; } }
  @media (max-width: 900px) {
    .slider-projects .flickity-page-dots {
      top: -95px; } }
  @media (max-width: 767px) {
    .slider-projects .flickity-page-dots {
      position: absolute;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      line-height: 1;
      top: auto;
      right: auto;
      bottom: -94px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px; } }

@media (max-width: 767px) {
  .slider-count {
    display: none; } }

.slider-count .counter {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.slider-count .counter__item {
  padding-left: 6px;
  color: #AEDFE4;
  font-size: 20px;
  font-family: 'Exo 2',sans-serif;
  font-weight: 800; }

.projects-carousel {
  position: relative; }
  .projects-carousel .flickity-viewport {
    overflow: hidden;
    position: relative; }

.flickity-viewport {
  transition: height 0.2s; }

.technology {
  position: relative;
  height: auto;
  z-index: 1056;
  background: #FAF9FF;
  display: flex;
  flex-direction: column; }

.technology-content {
  padding-top: 130px;
  position: relative;
  z-index: 1056; }
  @media (max-width: 767px) {
    .technology-content {
      padding-top: 0; } }

.technology-title h2 {
  margin: 0; }

.technology-stack {
  padding-top: 30px; }

.tab-content {
  background: #F9F9FF;
  border: 1px solid #AEDFE4;
  width: 100%;
  height: 100%; }
  @media (max-width: 767px) {
    .tab-content {
      height: 386px; } }

.nav-link {
  font-size: 16px;
  color: #1f1f1f;
  border: 1px solid #aedfe4;
  background: #faf9ff;
  font-family: 'Exo 2',sans-serif;
  font-weight: 800;
  padding: 0;
  text-align: center;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0 !important;
  margin-bottom: 30px; }
  .nav-link:hover {
    color: #1f1f1f;
    background: #AEDFE4;
    border: 1px solid #AEDFE4;
    transition: all .2s; }
  @media (max-width: 767px) {
    .nav-link {
      text-transform: uppercase;
      font-size: 14px;
      height: 50px;
      margin-bottom: 20px; } }

.nav {
  display: grid;
  grid-template-columns: 48% 45%;
  justify-content: space-between; }
  .nav .row {
    width: 100%; }
  @media (max-width: 767px) {
    .nav {
      grid-template-columns: 48% 48%;
      margin-bottom: 30px; } }

.nav-pills .nav-link:hover {
  color: #1f1f1f; }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #1f1f1f;
  background: #AEDFE4;
  border: 1px solid #AEDFE4;
  border-radius: 0; }

.nav-link.noPadding {
  margin-bottom: 0; }

.technology-logo {
  max-width: 755px;
  margin: 0 auto;
  position: relative;
  top: -20px; }
  .technology-logo .row {
    align-items: center;
    width: 100%; }
    @media (max-width: 767px) {
      .technology-logo .row {
        display: grid;
        grid-template-columns: repeat(6, calc((100% / 3))); } }

.technology-item {
  text-align: center;
  height: 125px;
  width: 162px;
  display: flex;
  align-items: center;
  justify-content: center; }

.technology-scroll {
  overflow-y: scroll;
  height: 213px;
  margin-top: 20px;
  margin-right: 5px; }
  @media (max-width: 767px) {
    .technology-scroll {
      overflow-y: clip;
      overflow-x: scroll;
      margin-right: 25px;
      margin-left: 25px;
      height: 360px; } }
  .technology-scroll::-webkit-scrollbar {
    width: 3px;
    /* ширина всей полосы прокрутки */ }
  .technology-scroll::-webkit-scrollbar-track {
    background: #E4F0F2;
    /* цвет зоны отслеживания */ }
  .technology-scroll::-webkit-scrollbar-thumb {
    background-color: #AEDFE4;
    /* цвет бегунка */ }

.tab-flex {
  display: flex;
  width: 60%; }

.landmarks {
  position: relative;
  height: auto;
  z-index: 1057;
  background: #FAF9FF;
  display: flex;
  flex-direction: column; }

.landmarks-content {
  padding-top: 130px; }
  @media (max-width: 767px) {
    .landmarks-content {
      padding-top: 60px; } }
  .landmarks-content h2 {
    margin-bottom: 0; }

.landmarks-all {
  max-width: 1100px;
  margin-top: 30px; }

.landmarks-slider {
  height: 429px;
  background: #D0F2F5;
  padding: 50px 50px 35px;
  width: 100%;
  position: relative; }
  @media (max-width: 576px) {
    .landmarks-slider {
      padding: 18px 18px 20px;
      height: 380px; } }

.landmarks-text {
  margin-top: 30px; }
  @media (max-width: 576px) {
    .landmarks-text {
      margin-top: 15px; } }
  .landmarks-text h3 {
    margin-bottom: 15px; }
    @media (max-width: 576px) {
      .landmarks-text h3 {
        margin-bottom: 5px; } }
  .landmarks-text p {
    max-width: 473px;
    margin: 0; }

.landmarks-list {
  position: relative;
  margin-left: 100px;
  margin-top: 25px; }
  @media (max-width: 992px) {
    .landmarks-list {
      display: none; } }
  .landmarks-list p {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #B9B9B9;
    position: relative; }
  .landmarks-list .active_slide {
    color: #1F1F1F; }
    .landmarks-list .active_slide::before {
      content: "";
      background-image: url("../../img/icons/arrow-right.png");
      width: 23px;
      height: 22px;
      display: block;
      position: absolute;
      left: -35px;
      top: 3px; }

.landmarks-carousel-item {
  width: 100%;
  padding: 1px;
  margin-right: 30px; }

.slider-landmarks-button {
  display: flex;
  align-items: baseline;
  max-width: 120px;
  justify-content: space-between;
  width: 100%;
  z-index: 1056;
  position: absolute;
  bottom: 30px; }

.slider-landmarks-button button {
  border: 1px solid #7E889B;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (pointer: fine) {
    .slider-landmarks-button button:hover {
      border: 1px solid #88D4DC;
      transition: all .2s; } }

.button-previous-5:before {
  content: "";
  background-image: url("../../img/icons/prev-gray.png");
  width: 21px;
  height: 20px; }

.button-next-5:before {
  content: "";
  background-image: url("../../img/icons/next-gray.png");
  width: 21px;
  height: 20px; }

@media (pointer: fine) {
  .button-previous-5:hover:before {
    background-image: url("../../img/icons/prev-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s; }
  .button-next-5:hover:before {
    background-image: url("../../img/icons/next-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s; } }

.landmarks-button {
  margin-top: 30px; }

.slider-landmarks-counter {
  position: absolute;
  bottom: 30px;
  right: 30px; }
  .slider-landmarks-counter span {
    font-family: 'Exo 2', sans-serif;
    font-weight: 800;
    color: #AEDFE4;
    font-size: 24px; }

.btn3 {
  background: #AEDFE4;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  text-transform: uppercase;
  color: #000000;
  font-size: 16px;
  font-family: 'Exo 2', sans-serif;
  font-weight: 800; }
  .btn3:hover {
    background: #88D4DC;
    transition: all .2s; }
  @media (max-width: 576px) {
    .btn3 {
      padding: 0;
      width: 100%;
      justify-content: center;
      font-size: 14px; } }

.partners {
  position: relative;
  height: auto;
  z-index: 1056;
  background: #FAF9FF;
  display: flex;
  flex-direction: column; }

.partners-content {
  padding-top: 130px;
  position: relative;
  z-index: 1060; }
  @media (max-width: 767px) {
    .partners-content {
      padding-top: 60px;
      padding-bottom: 130px; } }

.partners-title h2 {
  margin-bottom: 0; }

.partners-carousel {
  margin-top: 30px;
  position: relative; }

.slider-partners-button {
  display: flex;
  align-items: baseline;
  max-width: 145px;
  justify-content: space-between;
  width: 100%;
  z-index: 1056; }
  @media (max-width: 767px) {
    .slider-partners-button {
      max-width: 155px;
      position: absolute;
      margin: 0 auto;
      width: 100%;
      transform: translateX(-50%);
      left: 50%;
      bottom: -86px; } }

.partners-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.slider-partners-button button {
  border: 1px solid #7E889B;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (pointer: fine) {
    .slider-partners-button button:hover {
      border: 1px solid #88D4DC;
      transition: all .2s; } }

.button-previous-6:before {
  content: "";
  background-image: url("../../img/icons/prev-gray.png");
  width: 21px;
  height: 20px; }

.button-next-6:before {
  content: "";
  background-image: url("../../img/icons/next-gray.png");
  width: 21px;
  height: 20px; }

@media (pointer: fine) {
  .button-previous-6:hover:before {
    background-image: url("../../img/icons/prev-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s; }
  .button-next-6:hover:before {
    background-image: url("../../img/icons/next-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s; } }

.partners-slider-item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 30px; }
  @media (max-width: 767px) {
    .partners-slider-item {
      flex-direction: column;
      align-items: flex-start; } }

.partners-carousel {
  padding: 35px 45px;
  background: white; }
  @media (max-width: 767px) {
    .partners-carousel {
      padding: 35px 20px; } }

.partners-slider-text {
  margin-left: 50px;
  max-width: 777px; }
  @media (max-width: 767px) {
    .partners-slider-text {
      max-width: 100%;
      margin-left: 0;
      margin-top: 20px; } }
  .partners-slider-text p {
    margin: 0; }

.partners-slider .flickity-page-dots {
  position: absolute;
  width: auto;
  padding: 0;
  margin: 0;
  top: -105px;
  right: 16px; }
  @media (max-width: 1250px) {
    .partners-slider .flickity-page-dots {
      top: -105px; } }
  @media (max-width: 1100px) {
    .partners-slider .flickity-page-dots {
      top: -99px; } }
  @media (max-width: 992px) {
    .partners-slider .flickity-page-dots {
      top: -96px; } }
  @media (max-width: 830px) {
    .partners-slider .flickity-page-dots {
      top: -93px; } }
  @media (max-width: 767px) {
    .partners-slider .flickity-page-dots {
      position: absolute;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      line-height: 1;
      top: auto;
      right: auto;
      bottom: -100px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px; } }

@media (max-width: 767px) {
  .partners-slider-img {
    margin: 0 auto; } }

@media (max-width: 576px) {
  .partners-slider-img img {
    width: 100%;
    object-fit: cover; } }

.vacancies {
  position: relative;
  height: auto;
  z-index: 1058;
  background: #FAF9FF;
  display: flex;
  flex-direction: column; }
  @media (max-width: 767px) {
    .vacancies {
      z-index: 1059; } }

.vacancies-content {
  padding-top: 130px;
  padding-bottom: 130px; }
  @media (max-width: 767px) {
    .vacancies-content {
      padding-top: 0; } }

.vacancies-title h2 {
  margin-bottom: 0; }

.vacancies-carousel {
  margin-top: 30px;
  position: relative; }

.slider-vacancies-button {
  display: flex;
  align-items: baseline;
  max-width: 162px;
  justify-content: space-between;
  width: 100%;
  z-index: 1056; }
  @media (max-width: 767px) {
    .slider-vacancies-button {
      max-width: 155px;
      position: absolute;
      margin: 0 auto;
      width: 100%;
      transform: translateX(-50%);
      left: 50%;
      bottom: -86px; } }

.vacancies-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.slider-vacancies-button button {
  border: 1px solid #7E889B;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (pointer: fine) {
    .slider-vacancies-button button:hover {
      border: 1px solid #88D4DC;
      transition: all .2s; } }

.button-previous-7:before {
  content: "";
  background-image: url("../../img/icons/prev-gray.png");
  width: 21px;
  height: 20px; }

.button-next-7:before {
  content: "";
  background-image: url("../../img/icons/next-gray.png");
  width: 21px;
  height: 20px; }

@media (pointer: fine) {
  .button-previous-7:hover:before {
    background-image: url("../../img/icons/prev-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s; }
  .button-next-7:hover:before {
    background-image: url("../../img/icons/next-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s; } }

.vacancies-slider .flickity-page-dots {
  position: absolute;
  width: auto;
  padding: 0;
  margin: 0;
  top: -70px;
  right: 61px; }
  @media (max-width: 1140px) {
    .vacancies-slider .flickity-page-dots {
      top: -66px; } }
  @media (max-width: 992px) {
    .vacancies-slider .flickity-page-dots {
      top: -62px; } }
  @media (max-width: 820px) {
    .vacancies-slider .flickity-page-dots {
      top: -59px; } }
  @media (max-width: 767px) {
    .vacancies-slider .flickity-page-dots {
      position: absolute;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      line-height: 1;
      top: auto;
      right: auto;
      bottom: -65px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px; } }

.vacancies-slider-item {
  background: #FFFFFF;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-right: 30px;
  width: 393px; }
  @media (max-width: 500px) {
    .vacancies-slider-item {
      width: 100%;
      margin-right: 0; } }

.vacancies-slider-text {
  margin-top: 20px; }
  .vacancies-slider-text p {
    margin: 0;
    color: #2E3234;
    max-width: 333px; }

.vacancies-slider-button {
  margin-top: 15px; }

.flickity-slider {
  height: 400px; }

.callback {
  position: relative;
  height: auto;
  background: #161717;
  padding: 125px 0 100px;
  z-index: 1058;
  display: flex;
  flex-direction: column; }
  @media (max-width: 767px) {
    .callback {
      padding: 60px 0; } }

.callback-title h2 {
  margin: 0;
  color: white; }

.form {
  margin-top: 75px;
  max-width: 920px;
  position: relative;
  z-index: 1056; }
  @media (max-width: 767px) {
    .form {
      max-width: 100%;
      margin-top: 25px; } }
  .form .form-control {
    border-radius: 0;
    border: none;
    background: transparent;
    color: #CFCEDC;
    border-bottom: 1px solid #AEDFE4;
    font-size: 16px;
    padding-left: 10px; }
    .form .form-control:focus {
      box-shadow: none;
      background: transparent;
      outline: none; }
    .form .form-control::placeholder {
      color: #CFCEDC; }
    @media (max-width: 767px) {
      .form .form-control {
        width: 350px; } }
  @media (max-width: 767px) and (max-width: 576px) {
    .form .form-control {
      width: 100%; } }
  .form .form-area {
    margin-top: 50px; }
    @media (max-width: 767px) {
      .form .form-area {
        margin-top: 35px; } }
    .form .form-area textarea {
      border-radius: 0;
      border: none;
      background: transparent;
      color: #CFCEDC;
      border-bottom: 1px solid #AEDFE4;
      font-size: 16px;
      resize: none;
      width: 100%;
      height: calc(1.5em + 0.75rem + -2px);
      padding: 0.375rem 0 0 10px;
      overflow-y: clip; }
      .form .form-area textarea::placeholder {
        color: #CFCEDC; }
      @media (max-width: 767px) {
        .form .form-area textarea {
          border: 1px solid #AEDFE4;
          height: calc(1em * 1.5 * 5 + 3px + 3px + 1px + 1px);
          padding-right: 10px; } }
  .form .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px; }
    @media (max-width: 767px) {
      .form .form-row {
        grid-template-columns: 100%;
        grid-gap: 25px; } }
  .form .form-politics {
    max-width: 287px;
    margin-right: 20px; }
    .form .form-politics p {
      margin: 0;
      font-size: 14px;
      color: #6A6883; }
    @media (max-width: 767px) {
      .form .form-politics {
        margin-top: 15px;
        margin-right: 0; } }
  .form .form-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px; }
    @media (max-width: 767px) {
      .form .form-button {
        flex-direction: column-reverse;
        margin-top: 30px;
        justify-content: flex-start;
        align-items: flex-start; } }

.up {
  width: 98px;
  height: 98px;
  background: #161717;
  border-radius: 100%;
  top: -43%;
  right: 0;
  position: absolute;
  z-index: 1060;
  display: flex;
  justify-content: center; }
  @media (max-width: 767px) {
    .up {
      display: none; } }
  .up::before {
    content: "";
    background-image: url("../../img/icons/up.png");
    background-repeat: no-repeat;
    width: 52px;
    height: 43px;
    display: block;
    margin-top: 15px; }
  .up:hover::before {
    background-image: url("../../img/icons/up_hover.png");
    transition: all .3s; }

.triangle-1 {
  background-image: url("../../img/triangle/t_1.png");
  width: 199px;
  height: 205px;
  position: absolute;
  top: 130px;
  left: 0; }
  @media (max-width: 992px) {
    .triangle-1 {
      display: none; } }

.triangle-2 {
  background-image: url("../../img/triangle/t_2.png");
  width: 182px;
  height: 181px;
  position: absolute;
  top: 80px;
  right: 0; }
  @media (max-width: 992px) {
    .triangle-2 {
      top: 20px;
      right: -50px;
      background-size: contain;
      background-repeat: no-repeat;
      width: 133px;
      height: 100px; } }

.triangle-3 {
  background-image: url("../../img/triangle/t_3.png");
  width: 65px;
  height: 58px;
  position: absolute;
  top: -165px;
  left: -60px; }
  @media (max-width: 992px) {
    .triangle-3 {
      left: 0;
      background-size: contain;
      background-repeat: no-repeat;
      width: 40px;
      height: 27px;
      bottom: 90px;
      top: auto; } }
  @media (max-width: 767px) {
    .triangle-3 {
      display: none; } }

.triangle-4 {
  background-image: url("../../img/triangle/t_4.png");
  width: 139px;
  height: 245px;
  position: absolute;
  top: -300px;
  left: 0; }
  @media (max-width: 992px) {
    .triangle-4 {
      display: none; } }

.triangle-5 {
  background-image: url("../../img/triangle/t_5.png");
  width: 366px;
  height: 221px;
  position: absolute;
  top: auto;
  left: 32%;
  bottom: -160px; }
  @media (max-width: 992px) {
    .triangle-5 {
      display: none; } }

.triangle-6 {
  background-image: url("../../img/triangle/t_6.png");
  width: 440px;
  height: 362px;
  position: absolute;
  top: auto;
  right: 14%;
  bottom: -160px; }
  @media (max-width: 992px) {
    .triangle-6 {
      right: 0; } }
  @media (max-width: 767px) {
    .triangle-6 {
      background-image: url("../../img/triangle/t-6-mobile.png");
      width: 368px;
      height: 466px; } }

.triangle-7 {
  background-image: url("../../img/triangle/t_7.png");
  width: 139px;
  height: 83px;
  position: absolute;
  top: -560px;
  right: -40px; }
  @media (max-width: 767px) {
    .triangle-7 {
      display: none; } }

.triangle-8 {
  background-image: url("../../img/triangle/t_8.png");
  width: 106px;
  height: 176px;
  position: absolute;
  top: -710px;
  left: 0; }
  @media (max-width: 767px) {
    .triangle-8 {
      display: none; } }

.triangle-9 {
  background-image: url("../../img/triangle/t_9.png");
  width: 177px;
  height: 156px;
  position: absolute;
  right: 0;
  top: -1030; }
  @media (max-width: 767px) {
    .triangle-9 {
      right: -70px;
      top: -1285px; } }

.triangle-10 {
  background-image: url("../../img/triangle/t_10.png");
  width: 200px;
  height: 216px;
  position: absolute;
  right: 220px;
  top: -910px;
  bottom: auto; }
  @media (max-width: 767px) {
    .triangle-10 {
      width: 103px;
      height: 104px;
      right: -25px;
      top: -1300px;
      background-repeat: no-repeat;
      background-size: contain; } }
