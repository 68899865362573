@import "../sprite";
@import "../misc/bootstrap";

.company{
  position: relative;
  height: auto;
  background: #FAF9FF;
  z-index: 1056;

  display: flex;
  flex-direction: column;
}

.company-content{
  padding-top: 115px;
  display: flex;
  position: relative;
  z-index: 1056;

  @include media-max(md){
    flex-direction: column-reverse;
    padding-top: 65px;
  }
}

.company-text{
  margin-top: 95px;
  margin-left: 65px;
  max-width: 500px;

  @include media-max(xl){
    margin-top: 35px;
  }

  @include media-max(lg){
    margin-top: 10px;
    margin-left: 35px;
  }

  @include media-max(md){
    margin-left: 0;
    margin-top: 0;
  }

  h2{
    margin-bottom: 10px;

    @include media-max(sm){
      margin-bottom: 5px;
    }
  }

  p{
    margin: 0;
    color: #2E3234;
  }
}

.company-img{
  min-width: 584px;

  @include media-max(xl){
    min-width: 500px;
  }

  @include media-max(lg){
    min-width: 400px;
  }

  @include media-max(md){
    min-width: auto;
    margin-top: 40px;
  }

  img{
    width: 100%;
    object-fit: cover;
  }
}