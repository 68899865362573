@import "../sprite";
@import "../misc/bootstrap";


.flickity-page-dots .dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 2px;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
  background: #D8D5E7;
}

.flickity-page-dots .dot.is-selected {
  background: #B5E4E8;
}
