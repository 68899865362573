@import "misc/bootstrap";
@import "misc/bootstrap-init";
@import "misc/button";
@import "misc/text";
@import "misc/animation";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700;800;900&display=swap');



html, body {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 0;
	margin: 0;
	font-family: 'Inter', sans-serif;
	font-size: $baseFontSize;
	line-height: 1.3;
	background: #fff;
	color: #000;
	font-weight: 400;
}

* {
	box-sizing: border-box;
}

*, *:active, *:focus {
	outline: 0 !important;
	-moz-outline-style: none !important;
}

a[href^="tel"] {
	text-decoration: none;
	color: inherit;
	white-space: nowrap;
}

.noScroll {
	overflow: hidden;
}

body::-webkit-scrollbar {
	width: 10px;               /* ширина всей полосы прокрутки */
}

body::-webkit-scrollbar-track {
	background: #669BA0;        /* цвет зоны отслеживания */
}

body::-webkit-scrollbar-thumb {
	background-color: #AEDFE4;    /* цвет бегунка */
	border-radius: 20px;       /* округлось бегунка */

}

.error {
	color: red;
	position: absolute;
	top: 45px;
	font-size: 14px;
}

.error.not-visible {
	display: none;
}

.warning{
	color: red;
	position: absolute;
	top: 45px;
	font-size: 14px;
}

.warning.not-visible {
	opacity: 0;
}

.input-error{
	border-bottom: 1px solid red !important;
}


@import "block/page";
@import "block/header";
@import "block/footer";
@import "block/burger";
@import "block/close";
@import "block/content";
@import "block/copyright";
@import "block/desktopMenu";
@import "block/icon";
@import "block/logo";
@import "block/menu";
@import "block/mobileMenu";
@import "block/sideBar";
@import "block/banner";
@import "block/btn1";
@import "block/btn2";
@import "block/customModal";
@import "block/rutilans";
@import "block/company";
@import "block/benefits";
@import "block/slider";
@import "block/activity";
@import "block/projects";
@import "block/technology";
@import "block/landmarks";
@import "block/btn3";
@import "block/partners";
@import "block/vacancies";
@import "block/callback";
@import "block/up";
@import "block/triangle";