@import "../sprite";
@import "../misc/bootstrap";



.triangle-1{
  background-image: url("../../img/triangle/t_1.png");
  width: 199px;
  height: 205px;
  position: absolute;
  top: 130px;
  left: 0;

  @include media-max(lg){
    display: none;
  }
}

.triangle-2{
  background-image: url("../../img/triangle/t_2.png");
  width: 182px;
  height: 181px;
  position: absolute;
  top: 80px;
  right: 0;

  @include media-max(lg){
    top: 20px;
    right: -50px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 133px;
    height: 100px;
  }
}

.triangle-3{
  background-image: url("../../img/triangle/t_3.png");
  width: 65px;
  height: 58px;
  position: absolute;
  top: -165px;
  left: -60px;

  @include media-max(lg){
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;
    height: 27px;
    bottom: 90px;
    top: auto;
  }

  @include media-max(md){
    display: none;
  }
}

.triangle-4{
  background-image: url("../../img/triangle/t_4.png");
  width: 139px;
  height: 245px;
  position: absolute;
  top: -300px;
  left: 0;

  @include media-max(lg){
    display: none;
  }
}

.triangle-5{
  background-image: url("../../img/triangle/t_5.png");
  width: 366px;
  height: 221px;
  position: absolute;
  top: auto;
  left: 32%;
  bottom: -160px;

  @include media-max(lg){
    display: none;
  }
}

.triangle-6{
  background-image: url("../../img/triangle/t_6.png");
  width: 440px;
  height: 362px;
  position: absolute;
  top: auto;
  right: 14%;
  bottom: -160px;

  @include media-max(lg){
    right: 0;
    //bottom: 200px;
  }

  @include media-max(md){
    background-image: url("../../img/triangle/t-6-mobile.png");
    width: 368px;
    height: 466px;
    //bottom: 115px;
  }
}

.triangle-7{
  background-image: url("../../img/triangle/t_7.png");
  width: 139px;
  height: 83px;
  position: absolute;
  top: -560px;
  right: -40px;

  @include media-max(md){
    display: none;
  }
}

.triangle-8{
  background-image: url("../../img/triangle/t_8.png");
  width: 106px;
  height: 176px;
  position: absolute;
  top: -710px;
  left: 0;

  @include media-max(md){
    display: none;
  }
}

.triangle-9{
  background-image: url("../../img/triangle/t_9.png");
  width: 177px;
  height: 156px;
  position: absolute;
  right: 0;
  top: -1030;

  @include media-max(md){
    right: -70px;
    top: -1285px;
  }
}

.triangle-10{
  background-image: url("../../img/triangle/t_10.png");
  width: 200px;
  height: 216px;
  position: absolute;
  right: 220px;
  top: -910px;
  bottom: auto;

  @include media-max(md){
    width: 103px;
    height: 104px;
    right: -25px;
    top: -1300px;
    //bottom: 0;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
