@import "../sprite";
@import "../misc/bootstrap";


.icon {
}

.icon-phone {
	@include retina-sprite($phone-group);
	display: block;
}

.icon-phone-2 {
	@include retina-sprite($phone-2-group);
	display: block;
}

.icon-marker {
	@include retina-sprite($marker-group);
	display: block;
}

.icon-mail {
	@include retina-sprite($mail-group);
	display: block;
}

.icon-air {
	background-image: url("../../img/icons/air.png");
	width: 62px;
	height: 62px;
	display: block;
}

.icon-big {
	background-image: url("../../img/icons/big.png");
	width: 62px;
	height: 62px;
	display: block;
}

.icon-mechanism {
	background-image: url("../../img/icons/mechanism.png");
	width: 60px;
	height: 59px;
	display: block;
}

.icon-modern {
	background-image: url("../../img/icons/modern.png");
	width: 61px;
	height: 61px;
	display: block;
}

.icon-rights {
	background-image: url("../../img/icons/rights.png");
	width: 59px;
	height: 36px;
	display: block;
}

.icon-safety {
	background-image: url("../../img/icons/safety.png");
	width: 45px;
	height: 45px;
	display: block;
}

.icon-school{
	background-image: url("../../img/icons/school.png");
	width: 63px;
	height: 35px;
	display: block;
}

.icon-support{
	background-image: url("../../img/icons/support.png");
	width: 55px;
	height: 61px;
	display: block;
}

.icon-prev{
	@include retina-sprite($prev-benefits-group);
	display: block;
}

.icon-next{
	@include retina-sprite($next-benefits-group);
	display: block;
}

.icon-search{
	background-image: url("../../img/icons/search.png");
	width: 57px;
	height: 58px;
	display: block;

	@include media-max(md){
		width: 45px;
		height: 46px;
		display: block;
		background-size: cover;
	}
}

.icon-code{
	background-image: url("../../img/icons/code.png");
	width: 64px;
	height: 55px;
	display: block;

	@include media-max(md){
		width: 53px;
		height: 46px;
		display: block;
		background-size: cover;
	}

}

.icon-portal{
	background-image: url("../../img/icons/portal.png");
	width: 67px;
	height: 61px;
	display: block;

	@include media-max(md){
		width: 51px;
		height: 46px;
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.icon-graficks{
	background-image: url("../../img/icons/graficks.png");
	width: 61px;
	height: 61px;
	display: block;

	@include media-max(md){
		width: 51px;
		height: 46px;
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.icon-window{
	background-image: url("../../img/icons/window.png");
	width: 61px;
	height: 61px;
	display: block;

	@include media-max(md){
		width: 51px;
		height: 46px;
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.icon-book{
	background-image: url("../../img/icons/book.png");
	width: 49px;
	height: 61px;
	display: block;

	@include media-max(md){
		width: 51px;
		height: 46px;
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.icon-escort{
	background-image: url("../../img/icons/escort.png");
	width: 54px;
	height: 56px;
	display: block;

	@include media-max(md){
		width: 51px;
		height: 46px;
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.icon-pazle{
	background-image: url("../../img/icons/pazle.png");
	width: 61px;
	height: 61px;
	display: block;

	@include media-max(md){
		width: 51px;
		height: 46px;
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.icon-chat{
	background-image: url("../../img/icons/chat.png");
	width: 60px;
	height: 48px;
	display: block;

	@include media-max(md){
		width: 51px;
		height: 46px;
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.icon-prev-2{
	background-image: url("../../img/icons/prev-2.png");
	width: 20px;
	height: 20px;
	display: block;
}

.icon-next-2{
	background-image: url("../../img/icons/next-2.png");
	width: 20px;
	height: 20px;
	display: block;
}

.icon-scale{
	background-image: url("../../img/icons/scale.png");
	width: 70px;
	height: 70px;
	display: block;
}

.icon-format{
	background-image: url("../../img/icons/format.png");
	width: 68px;
	height: 68px;
	display: block;
}

.icon-intuition{
	background-image: url("../../img/icons/intuition.png");
	width: 70px;
	height: 70px;
	display: block;
}

.icon-data{
	background-image: url("../../img/icons/data.png");
	width: 70px;
	height: 70px;
	display: block;
}

.icon-log{
	background-image: url("../../img/icons/log.png");
	width: 72px;
	height: 67px;
	display: block;
}

.icon-calendar{
	background-image: url("../../img/icons/calendar.png");
	width: 66px;
	height: 71px;
	display: block;
}

.icon-structure{
	background-image: url("../../img/icons/structure.png");
	width: 70px;
	height: 68px;
	display: block;
}

.icon-job{
	background-image: url("../../img/icons/job.png");
	width: 71px;
	height: 71px;
	display: block;
}

.icon-user{
	background-image: url("../../img/icons/user.png");
	width: 68px;
	height: 68px;
	display: block;
}

.icon-securety{
	background-image: url("../../img/icons/security.png");
	width: 55px;
	height: 71px;
	display: block;
}

.icon-reserve{
	background-image: url("../../img/icons/reserve.png");
	width: 71px;
	height: 71px;
	display: block;
}

