@import "../sprite";
@import "../misc/bootstrap";



.mobileMenu{
	display: none;
	z-index: 1000;

	@media (max-width: 1230px){
		display: block;
	}
}

.mobileMenu-content{
	display: flex;
	align-items: center;
}

.mobile-button{
	margin-right: 18px;
}