@import "../misc/bootstrap";
@import "../sprite";

.close-modal {
	background-image: url("../../img/close.png");
	width: 28px;
	height: 28px;
	display: block;
	position: relative;
	opacity: 1 !important;

	&:hover{
		transform: rotate(360deg);
		transition: all .4s;
	}
}