@import "../sprite";
@import "../misc/bootstrap";


.rutilans-1{
  background-image: url("../../img/rutilans/e_1.png");
  background-repeat: no-repeat;
  width: 287px;
  height: 793px;
  position: absolute;
  left: 0;
  top: 120px;
  z-index: 1055;

  @include media-max(xl){
    top: 70px;
  }

  @include media-max(lg){
    top: 0;
  }

  @include media-max(sm){
    top: 170px;
    bottom: -56px;
    height: 490px;
    background-size: contain;
  }
}

.rutilans-2{
  background-image: url("../../img/rutilans/e_2.png");
  background-repeat: no-repeat;
  width: 263px;
  height: 496px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1055;
}

.rutilans-3{
  background-image: url("../../img/rutilans/e_4.png");
  background-repeat: no-repeat;
  width: 305px;
  height: 793px;
  position: absolute;
  right: 0;
  top: 290px;
  z-index: 1055;

  @include media-max(md){
    top: 705px;
  }

  @include media-max(sm){
    top: 620px;
  }
}

.rutilans-4{
  background-image: url("../../img/rutilans/e_5.png");
  background-repeat: no-repeat;
  width: 530px;
  height: 793px;
  position: absolute;
  left: 0;
  bottom: -480px;
  z-index: 1055;

  @include media-max(md){
    bottom: 0;
    top: -435px;
  }
}

.rutilans-5 {
  background-image: url("../../img/rutilans/e_6.png");
  background-repeat: no-repeat;
  width: 382px;
  height: 793px;
  position: absolute;
  left: 0;
  bottom: -180px;
  z-index: 1055;

  @include media-max(lg){
    left: -100px;
  }

  @include media-max(md){
    bottom: -380px;
  }
}

.rutilans-6 {
  background-image: url("../../img/rutilans/e_7.png");
  background-repeat: no-repeat;
  width: 377px;
  height: 614px;
  position: absolute;
  right: 0;
  top: -30px;
  z-index: 1055;

  @include media-max(xl){
    right: -100px;
  }

  @include media-max(sm){
    top: -130px;
    right: -140px;
  }
}

.rutilans-7 {
  background-image: url("../../img/rutilans/e_8.png");
  background-repeat: no-repeat;
  width: 614px;
  height: 614px;
  position: absolute;
  top: 0;
  z-index: 1055;
  left: 50%;
  transform: translateX(-50%);

  @include media-max(lg){
    top: -135px;
  }

  @include media-max(md){
    display: none;
  }
}

.rutilans-9 {
  background-image: url("../../img/rutilans/e_9.png");
  background-repeat: no-repeat;
  width: 250px;
  height: 793px;
  position: absolute;
  bottom: -210px;
  z-index: 1055;
  left: 0;

  @include media-max(xl){
    left: -50px;
  }

  @include media-max(md){
    bottom: -50px;
  }
}

.rutilans-10 {
  background-image: url("../../img/rutilans/e_10.png");
  background-repeat: no-repeat;
  width: 396px;
  height: 793px;
  position: absolute;
  top: 0;
  z-index: 1055;
  right: 0;

  @include media-max(lg){
    top: -155px;
    right: -100px;
  }

  @include media-max(sm){
    top: -320px;
  }

}

.rutilans-11 {
  background-image: url("../../img/rutilans/e_11.png");
  background-repeat: no-repeat;
  width: 426px;
  height: 793px;
  position: absolute;
  top: -23px;
  z-index: 1056;
  right: -111px;

  @include media-max(xl){
    top: 50px;
  }

  @include media-max(lg){
   display: none;
  }
}

.rutilans-12 {
  background-image: url("../../img/rutilans/e_11.png");
  background-repeat: no-repeat;
  width: 426px;
  height: 793px;
  position: absolute;
  top: 70px;
  z-index: 1055;
  right: 0;
  display: none;

  @include media-max(lg){
    display: block;
  }

  @include media-max(md){
    top: 385px;
    right: -160px;
  }
}

.rutilans-13 {
  background-image: url("../../img/rutilans/e_13.png");
  background-repeat: no-repeat;
  width: 446px;
  height: 793px;
  position: absolute;
  top: -240px;
  z-index: 1055;
  left: 0;

  @include media-max(lg){
    left: -50px;
  }

  @include media-max(md){
    top: -210px;
    left: -94px;
    display: none;
  }
}

