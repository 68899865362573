@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";

$spacers: (
        q1: $spacer * .25,
        q2: $spacer * .50,
        q3: $spacer * .75,
        
        0: $spacer * 0,
        
        1: $spacer * 1,
        1q1: $spacer * 1.25,
        1q2: $spacer * 1.50,
        1q3: $spacer * 1.75,
        
        2: $spacer * 2,
        2q1: $spacer * 2.25,
        2q2: $spacer * 2.50,
        2q3: $spacer * 2.75,
        
        3: $spacer * 3,
        3q1: $spacer * 3.25,
        3q2: $spacer * 3.50,
        3q3: $spacer * 3.75,
        
        4: $spacer * 4,
        4q1: $spacer * 4.25,
        4q2: $spacer * 4.50,
        4q3: $spacer * 4.75,
        
        5: $spacer * 5,
        5q1: $spacer * 5.25,
        5q2: $spacer * 5.50,
        5q3: $spacer * 5.75,
        
        6: $spacer * 6,
        6q1: $spacer * 6.25,
        6q2: $spacer * 6.50,
        6q3: $spacer * 6.75,
        
        7: $spacer * 7,
        7q1: $spacer * 7.25,
        7q2: $spacer * 7.50,
        7q3: $spacer * 7.75,
        
        8: $spacer * 8,
        8q1: $spacer * 8.25,
        8q2: $spacer * 8.50,
        8q3: $spacer * 8.75,
        
        9: $spacer * 9,
        9q1: $spacer * 9.25,
        9q2: $spacer * 9.50,
        9q3: $spacer * 9.75,
        
        10: $spacer * 10,
        10q1: $spacer * 10.25,
        10q2: $spacer * 10.50,
        10q3: $spacer * 10.75,
        
        11: $spacer * 11,
        11q1: $spacer * 11.25,
        11 q2: $spacer * 11.50,
        11 q3: $spacer * 11.75,
        12: $spacer * 12,
        12 q1: $spacer * 12.25,
        12 q2: $spacer * 12.50,
        12 q3: $spacer * 12.75,
);

@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/grid";

.row.gutter-0 {
  margin-left: 0;
  margin-right: 0;
}

.row.gutter-0 > .col,
.row.gutter-0 > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.row.gutter-1 {
  margin-left: -5px;
  margin-right: -5px;
}

.row.gutter-1 > .col,
.row.gutter-1 > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.row.gutter-2 {
  margin-left: -30px;
  margin-right: -30px;
}

.row.gutter-2 > .col,
.row.gutter-2 > [class*="col-"] {
  padding-right: 30px;
  padding-left: 30px;
}

.row.gutter-3 {
  margin-left: -40px;
  margin-right: -40px;
}

.row.gutter-3 > .col,
.row.gutter-3 > [class*="col-"] {
  padding-right: 40px;
  padding-left: 40px;
}