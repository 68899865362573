@import "../sprite";
@import "../misc/bootstrap";

.desktopMenu {

	@media (max-width: 1230px){
		display: none;
	}
}

.desktopMenu-ul {
	display: flex;
	margin: 0;
	padding: 0;
}

.desktopMenu-li {
	list-style: none;

	a {
		font-size: rem(14px);
		color: white;
		text-decoration: none;

		&:hover {
			color: #AEDFE4;
			transition: all .2s;
		}
	}
}

.desktopMenu-li + .desktopMenu-li {
	padding-left: 35px;
}


