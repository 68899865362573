@import "../sprite";
@import "../misc/bootstrap";


.header {
	margin-bottom: -102px;
	z-index: 1060;
	position: relative;
}

.header-fix{
	z-index: 1060;
	position: fixed;
	background: #FBFBFB;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04);
	width: 100%;
	top: -88px;
	transition-duration: .6s;

	.header-content{
		padding: 10px 0;
		margin: 0;
	}

	.desktopMenu-li a{
		color: #1F1F1F;
	}

	.header-phone a{
		color: #1F1F1F;
	}

	.header-button .btn1{
		color: #1F1F1F;
	}

	@media (max-width: 1230px){
		display: none;
	}
}

.fixed{
	top: 0;
	transition-duration: .6s;
}


.header-content {
	display: flex;
	align-items: center;
	margin-top: 15px;
	position: relative;
	z-index: 5;
	justify-content: space-between;
}


.header_bottom {
	margin-bottom: 0;
}

.header-phone{
	display: flex;
	align-items: center;

	a{
		padding-left: 5px;
		color: white;
		font-size: 14px;

		&:hover {
			color: #AEDFE4;
			transition: all .2s;
		}
	}

	@media (max-width: 1230px){
		display: none;
	}
}

.header-button{
	@media (max-width: 1230px){
		display: none;
	}
}

.header-logo{
	z-index: 1000;
}


