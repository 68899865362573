@import "../sprite";
@import "../misc/bootstrap";


.projects{
  position: relative;
  height: auto;
  z-index: 1056;
  background: #FAF9FF;

  display: flex;
  flex-direction: column;
}

.projects-content{
  padding-top: 130px;

  @include media-max(md){
    padding-top: 60px;
    padding-bottom: 130px;
  }
}

.projects-title{
  h2{
    margin: 0;
  }
}

.projects-carousel{
  margin-top: 30px;
  background: #FFFFFF;
  padding: 35px 30px 30px;
}

.slider-projects-text{
  max-width: 585px;
  height: 100%;
  position: relative;
}

.slider-projects-list{
  ul{
    margin-top: 25px;
    padding-left: 25px;

    @include media-max(md){
      margin-top: 15px;
    }
  }

  li{
    color: #2E3234;
    p{
      margin-bottom: 6px;
    }
  }


}

.slider-projects-description{
  margin-top: 15px;

    @include media-max(md){
      margin-top: 10px;
    }
    p {
      margin-bottom: 6px;
      color: #2E3234;
    }
}

.slider-projects-item{
  width: 100%;
  margin-right: 30px;


  @include media-max(md){
    background: transparent;
    padding: 0;
  }
}

.slider-projects-block{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1060px;

  @include media-max(md){
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    height: 100%;
  }
}


.slider-projects-img{
  margin-left: 35px;

  @include media-max(md){
    margin: 0 auto 25px;
  }

  img{
    width: 100%;
    object-fit: cover;

    @include media-max(md){

      width: 241px;
      height: 228px;

    }
  }
}

.slider-projects-button {
  display: flex;
  align-items: baseline;
  max-width: 162px;
  justify-content: space-between;
  width: 100%;
  z-index: 1056;

  @include media-max(md){
    max-width: 155px;
    position: absolute;
    margin: 0 auto;
    width: 100%;
    transform: translateX(-50%);
    left: 50%;
    bottom: -86px;
  }
}

.projects-navigation{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.slider-projects-button button{
  border: 1px solid #7E889B;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (pointer: fine){
    &:hover{
      border: 1px solid #88D4DC;
      transition: all .2s;
    }
  }
}

.button-previous-4:before{
  content: "";
  background-image: url("../../img/icons/prev-gray.png");
  width: 21px;
  height: 20px;
}


.button-next-4:before{
  content: "";
  background-image: url("../../img/icons/next-gray.png");
  width: 21px;
  height: 20px;
}

@media (pointer: fine){
  .button-previous-4:hover:before{
    background-image: url("../../img/icons/prev-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s;
  }

  .button-next-4:hover:before{
    background-image: url("../../img/icons/next-mobile.png");
    width: 21px;
    height: 20px;
    transition: all .2s;
  }
}




.slider-projects {

  .flickity-page-dots{
    position: absolute;
    width: auto;
    padding: 0;
    margin: 0;
    top: -106px;
    right: 35px;

    @media (max-width: 1220px){
      top: -104px;
    }

    @media (max-width: 1120px){
      top: -101px;
    }

    @include media-max(lg){
      top: -98px;
    }

    @media (max-width: 900px){
      top: -95px;
    }

    @include media-max(md){
      position: absolute;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      line-height: 1;
      top: auto;
      right: auto;
      bottom: -94px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
    }
  }
}

.slider-count{
  //position: absolute;
  //right: 10px;
  //bottom: 0px;

  @include media-max(md){
    display: none;
  }

  .counter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .counter__item{
    padding-left: 6px;
    color: #AEDFE4;
    font-size: 20px;
    font-family: 'Exo 2',sans-serif;
    font-weight: 800;
  }
}

.projects-carousel{
  position: relative;

  .flickity-viewport {
    overflow: hidden;
    position: relative;
  }
}


.flickity-viewport {
  transition: height 0.2s;
}