@import "../sprite";
@import "../misc/bootstrap";

.btn1{
  font-family: 'Exo 2', sans-serif;
  font-size: 12px;
  color: #AEDFE4;
  border: 1px solid #AEDFE4;
  height: 42px;
  padding: 0 20px;
  transition: all .2s;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 800;

  @media (pointer: fine){
    &:hover{
      transition: all .2s;
      color: #1F1F1F;
      background: #88D4DC;
      text-decoration: none;
    }
  }
}

.btn1-mobile{
  font-family: 'Exo 2', sans-serif;
  font-weight: 800;
  font-size: 12px;
  color: #AEDFE4;
  border: 1px solid #AEDFE4;
  height: 36px;
  padding: 0 10px;
  transition: all .2s;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover{
    text-decoration: none;
  }
}