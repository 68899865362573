@import "../sprite";
@import "../misc/bootstrap";


.menu{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding-top: 120px;
}


.menu-ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.menu-a {
	font-size: rem(24px);
	color: white;
	font-family: "Dela Gothic One";

	&:hover{
		color: white;
		text-decoration: none;
	}
}

.menu-li + .menu-li {
	padding-top: 20px;
}

.menu-phone{
	display: flex;
	align-items: center;
	position: relative;
	bottom: 100px;

	a{
		padding-left: 5px;
		color: white;
		font-size: 14px;

		&:hover {
			color: #AEDFE4;
			transition: all .2s;
		}
	}
}

