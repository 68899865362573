@import "../sprite";
@import "../misc/bootstrap";

.btn3{
  background: #AEDFE4;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  text-transform: uppercase;
  color: #000000;
  font-size: 16px;
  font-family: 'Exo 2', sans-serif;
  font-weight: 800;

  &:hover{
    background: #88D4DC;
    transition: all .2s;
  }

  @include media-max(sm){
    padding: 0;
    width: 100%;
    justify-content: center;
    font-size: 14px;
  }
}